import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { makeStyles } from "@material-ui/core/styles";
import drawerCss from "../../style/drawer";
import classNames from "classnames";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import {
  updateSelectedItem,
  getFinishedGoods,
  getWarehouses,
  getYardOccupancy,
} from "../../store";
import Config from "../../config/index";

const desLength = Config.descriptionLength;

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

const YardOccupancy = ({
  drawerState,
  handleListItemClick,
  getFinishedGoods,
  finishedGoods,
  getWarehouses,
  warehouses,
  getYardOccupancy,
  getYardOccupancyState,
}) => {
  useEffect(() => {
    handleListItemClick(null, 22);
  }, []);

  const classes = useStyles();
  let drawer = drawerState;

  useEffect(() => {
    getFinishedGoods();
  }, []);

  useEffect(() => {
    getWarehouses();
  }, []);

  const [fgIdSelected, setFgIdSelected] = useState("");
  const [warehouseIdSelected, setWarehouseIdSelected] = useState("");

  useEffect(() => {
    getYardOccupancy(fgIdSelected, warehouseIdSelected);
  }, [fgIdSelected, warehouseIdSelected]);

  const [fgCodeOptions, setFgCodeOptions] = useState([]);

  useEffect(() => {
    let tempFgCodeOptions = [];
    let data = finishedGoods.fg ? finishedGoods.fg : [];
    data.map((fg) => {
      tempFgCodeOptions.push({
        key: fg.fgCode,
        value: fg.id,
      });
    });
    setFgCodeOptions(tempFgCodeOptions);
  }, [finishedGoods]);

  const [warehouseOptions, setWarehouseOptions] = useState([]);

  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      if (warehouse.type === "f") {
        tempWarehouseOptions.push({
          key: warehouse.warehouseName,
          value: warehouse.id,
        });
      }
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  const [state, setState] = useState({
    columns: [
      { title: "FG Code", field: "fgCode" },
      { title: "FG Description", field: "fgDes" },
      { title: "VIN/Serial No", field: "vinSerialNo" },
      { title: "Location", field: "location" },
      { title: "Warehouse", field: "warehouse" },
    ],
    data: [],
  });

  useEffect(() => {
    let tempReports = [];
    let data = getYardOccupancyState.YardOccupancy
      ? getYardOccupancyState.YardOccupancy
      : [];
    data.map((singleData) => {
      let des = singleData?.description;
      let len = desLength;
      const dataObject = {
        fgCode: singleData.fgCode,
        fgDes: des ? des.substring(0, len) : "",
        vinSerialNo: `${singleData.vin} / ${singleData.sNo}`,
        location: `${singleData.zoneName} / ${singleData.locationName}`,
        warehouse: singleData.warehouseName,
      };
      tempReports.push(dataObject);
    });
    setState({ ...state, data: tempReports });
  }, [getYardOccupancyState]);

  const [showFilters, setShowFilters] = useState(false);

  const showFiltersHandler = () => {
    setShowFilters(!showFilters);
  };

  const changeFg = (e) => {
    setFgIdSelected(e.target.value);
  };

  const changeWarehouse = (e) => {
    setWarehouseIdSelected(e.target.value);
  };

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title={
                <div>
                  <br />
                  <h5>Yard Occupancy</h5>
                  <br />
                  {!showFilters && <br />}
                  {!showFilters && <br />}
                  {showFilters && (
                    <span style={{ display: "flex" }}>
                      <select
                        style={{ width: "11rem" }}
                        as="select"
                        id="warehouseId"
                        name="warehouseId"
                        className="form-control"
                        onChange={changeWarehouse}
                      >
                        <option value="" selected>
                          All Warehouses
                        </option>
                        {warehouseOptions.map((option) => {
                          return (
                            <option key={option.value} value={option.value}>
                              {option.key}
                            </option>
                          );
                        })}
                      </select>
                      <select
                        style={{ marginLeft: "1rem", width: "11rem" }}
                        as="select"
                        id="fgId"
                        name="fgId"
                        className="form-control"
                        onChange={changeFg}
                      >
                        <option value="" selected>
                          All FG
                        </option>
                        {fgCodeOptions.map((option) => {
                          return (
                            <option key={option.value} value={option.value}>
                              {option.key}
                            </option>
                          );
                        })}
                      </select>
                    </span>
                  )}
                </div>
              }
              columns={state.columns}
              data={state.data}
              localization={{
                body: {
                  emptyDataSourceMessage: "Choose a filter to display data",
                },
              }}
              actions={[
                {
                  icon: "search",
                  tooltip: "Show Filters",
                  isFreeAction: true,
                  onClick: () => {
                    showFiltersHandler();
                  },
                },
              ]}
              options={{
                draggable: false,
                exportButton: true,
                exportAllData: true,
                exportFileName: `Yard Occupancy`,
                filtering: true,
              }}
              components={{
                Action: (props) => (
                  <button
                    style={{ width: "4.5rem" }}
                    onClick={() => props.action.onClick()}
                  >
                    Filters
                  </button>
                ),
              }}
            />
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    finishedGoods: state.finishedGoods,
    warehouses: state.warehouses,
    getYardOccupancyState: state.getYardOccupancy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getFinishedGoods: () => dispatch(getFinishedGoods()),
    getWarehouses: () => dispatch(getWarehouses()),
    getYardOccupancy: (fgId, warehouseId) =>
      dispatch(getYardOccupancy(fgId, warehouseId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(YardOccupancy);
