/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React, { useEffect } from "react";
import Header from "./Header";
import { connect } from "react-redux";
import drawerCss from "../style/drawer";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { updateSelectedItem } from "../store";
import Footer from "./Footer";
import WarehouseHomePageCard from "./Home/WarehouseHomePageCard";
import PickupHomePageCard from "./Home/PickupHomePageCard";
import FgWarehouseHomePageCard from "./Home/FgWarehouseHomePageCard";
import PdiHomePageCard from "./Home/PdiHomePageCard";
import RejectionHomePageCard from "./Home/RejectionHomePageCard";
import FinishedGoodsHomePageCard from "./Home/FinishedGoodsHomePageCard";
import InventoryHomePageCard from "./Home/InventoryHomePageCard";

const useStyles = makeStyles((theme) => drawerCss(theme));

function Home({ drawerState, handleListItemClick }) {
  const classes = useStyles();
  let drawer = drawerState;

  useEffect(() => {
    handleListItemClick(null, 15);
  });

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <div className="row">
              <div className="col-md-3" style={{ marginBottom: "2%" }}>
                <WarehouseHomePageCard />
              </div>
              <div className="col-md-3" style={{ marginBottom: "2%" }}>
                <FgWarehouseHomePageCard />
              </div>
              <div className="col-md-3" style={{ marginBottom: "2%" }}>
                <InventoryHomePageCard />
              </div>
              <div className="col-md-3" style={{ marginBottom: "2%" }}>
                <PickupHomePageCard />
              </div>
              <div className="col-md-3" style={{ marginBottom: "2%" }}>
                <FinishedGoodsHomePageCard />
              </div>
              <div className="col-md-3" style={{ marginBottom: "2%" }}>
                <PdiHomePageCard />
              </div>
              <div className="col-md-3" style={{ marginBottom: "2%" }}>
                <RejectionHomePageCard />
              </div>
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
