import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import dashboardCard from "../../style/dashboardCard";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Link as RouterLink } from "react-router-dom";
import { getFg } from "../../store";

import "chart.js/auto";
import { Bar, Doughnut } from "react-chartjs-2";
import { useRef } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => dashboardCard(theme));

const FinishedGoodsHomePageCard = ({ getFg, fgHomePage }) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    getFg();
  }, []);

  const [putawayNotDone, setPutawayNotDone] = useState(0);
  const [lessThanThirty, setLessThanThirty] = useState(0);
  const [thirtyOneToSixty, setThirtyOneToSixty] = useState(0);
  const [sixtyOneToNinety, setSixtyOneToNinety] = useState(0);
  const [greaterThanNinety, setGreaterThanNinety] = useState(0);
  const [expired, setExpired] = useState(0);

  useEffect(() => {
    setLessThanThirty(fgHomePage.fg.lessThanThirty);
    setThirtyOneToSixty(fgHomePage.fg.thirtyOneToSixty);
    setSixtyOneToNinety(fgHomePage.fg.sixtyOneToNinety);
    setGreaterThanNinety(fgHomePage.fg.greaterThanNinety);
    setExpired(fgHomePage.fg.expired);
    setPutawayNotDone(fgHomePage.fg.putawayNotDone);
  }, [fgHomePage]);

  const data = {
    labels: [
      "< 30 days",
      "31-60 days",
      "61-90 days",
      "> 90 days",
      "Expired",
      "Putaway ND",
    ],
    datasets: [
      {
        data: [
          lessThanThirty,
          thirtyOneToSixty,
          sixtyOneToNinety,
          greaterThanNinety,
          expired,
          putawayNotDone,
        ],
        backgroundColor: [
          "red",
          "#FF9A00",
          "#FB929E",
          "#1687A7",
          "#17B978",
          "orange",
        ],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    title: {
      display: true,
      text: "Doughnut Chart",
    },
  };

  const chartRef = useRef();
  const onClick = (event) => {
    history.push(`/fg_ageing`);
  };

  return (
    <div>
      <Card>
        <CardContent>
          <h4>Finished Goods</h4>
          <hr />
          <Bar
            className={classes.root}
            data={data}
            options={options}
            onClick={onClick}
            ref={chartRef}
            height={240}
          />
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    fgHomePage: state.getFinishedGoodsHomePage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFg: () => dispatch(getFg()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinishedGoodsHomePageCard);
