/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  SET_PASSWORD_FOR_NEW_USER_REQUEST,
  SET_PASSWORD_FOR_NEW_USER_SUCCESS,
  SET_PASSWORD_FOR_NEW_USER_FAILURE,
} from "./setPasswordForNewUserTypes";

import axios from "axios";
import Config from "../../../config/index";
let auth3Url = Config.auth3Url;
let baseUrl = Config.baseUrl;

const setPasswordForNewUserRequest = () => {
  return {
    type: SET_PASSWORD_FOR_NEW_USER_REQUEST,
  };
};

const setPasswordForNewUserSuccess = (payload) => {
  return {
    type: SET_PASSWORD_FOR_NEW_USER_SUCCESS,
    payload: payload,
  };
};

const setPasswordForNewUserFaliure = (error) => {
  return {
    type: SET_PASSWORD_FOR_NEW_USER_FAILURE,
    payload: error,
  };
};

const setPasswordForNewUser = (setPasswordState) => {
  return (dispatch) => {
    let payload = {
      email: setPasswordState.email,
      newpassword: setPasswordState.newpassword,
      confirmpassword: setPasswordState.confirmpassword,
    };
    dispatch(setPasswordForNewUserRequest());
    // let url = `${auth3Url}/password/reset_password`
    let url = `${baseUrl}/user_access/set_password`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(setPasswordForNewUserSuccess(response.data));
        return { status: true };
      })
      .catch((err) => {
        dispatch(setPasswordForNewUserFaliure(err.message));
        return { status: false };
      });
  };
};

export { setPasswordForNewUser };
