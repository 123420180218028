import {
  GET_REJECTIONAGEING_REQUEST,
  GET_REJECTIONAGEING_SUCCESS,
  GET_REJECTIONAGEING_FAILURE,
} from "./RejectionAgeingTypes";
import axios from "axios";
import Config from "../../../config/index";
let baseUrl = Config.baseUrl;

const getRejectionAgeingRequest = () => {
  return {
    type: GET_REJECTIONAGEING_REQUEST,
  };
};

const getRejectionAgeingSuccess = (payload) => {
  return {
    type: GET_REJECTIONAGEING_SUCCESS,
    payload: payload,
  };
};

const getRejectionAgeingFailure = (error) => {
  return {
    type: GET_REJECTIONAGEING_FAILURE,
    payload: error,
  };
};

const getRejectionAgeing = (itemId, warehouseId, status) => {
  return (dispatch) => {
    dispatch(getRejectionAgeingRequest());
    let url = `${baseUrl}/reports/rejection_by_expiry_days?item_id=${itemId}&warehouse_id=${warehouseId}&status=${status}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getRejectionAgeingSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getRejectionAgeingFailure(err.message));
      });
  };
};

export { getRejectionAgeing };
