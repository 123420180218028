import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { makeStyles } from "@material-ui/core/styles";
import drawerCss from "../../style/drawer";
import classNames from "classnames";
import { connect } from "react-redux";
import {
  updateSelectedItem,
  getWarehouses,
  getCreatePickupList,
  createPickupList,
} from "../../store";
import MaterialTable from "material-table";
import Modal from "react-bootstrap/Modal";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import Config from "../../config/index";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const desLength = Config.descriptionLength;

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

const CreatePickupList = ({
  drawerState,
  handleListItemClick,
  getWarehouses,
  warehouses,
  getCreatePickupList,
  createPickupListTableData,
  createPickupList,
}) => {
  useEffect(() => {
    handleListItemClick(null, 5);
  }, []);

  const classes = useStyles();
  let drawer = drawerState;

  let loginDetails = cookies.get("loginDetails");
  let role = loginDetails.data.role;

  const [warehouseIdSelected, setWarehouseIdSelected] = useState("");

  useEffect(() => {
    setWarehouseIdSelected(warehouses.warehouses[0]?.id);
  }, [warehouses]);

  useEffect(() => {
    getCreatePickupList(warehouseIdSelected);
  }, [warehouseIdSelected]);

  useEffect(() => {
    getWarehouses();
  }, []);

  const [state, setState] = useState({
    columns: [
      { title: "Transaction No", field: "transactionNo" },
      { title: "Route Code", field: "routeCode" },
      { title: "Part No", field: "partNo" },
      { title: "Description", field: "description" },
      { title: "Pick Qty", field: "pickQty" },
      // { title: "Source Location", field: "sourceStBin" },
      { title: "Delivery Location", field: "deliveryLocation" },
      { title: "Source Loc / SU No", field: "srcLocSuNo" },
    ],
    data: [],
  });

  const [warehouseOptions, setWarehouseOptions] = useState([]);

  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      tempWarehouseOptions.push({
        key: warehouse.warehouseName,
        value: warehouse.id,
      });
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  const [skuNo, setSkuNo] = useState([]);
  const [transactionNo, setTransactionNo] = useState("");

  useEffect(() => {
    let tempCreatePickupListData = [];
    let data = createPickupListTableData.createPickupList
      ? createPickupListTableData.createPickupList
      : [];

    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      const itemObject = {
        transactionNo: item.transactionCode,
        routeCode: item.routeCode,
        partNo: item.itemCode,
        description: des ? des.substring(0, len) : "",
        pickQty: `${item.trQty} ${item.uom}`,
        deliveryLocation: item.dWarehouse,
        srcLocSuNo: (
          <button
            className="btn btn-info"
            type="button"
            onClick={() => {
              setTransactionNo(item.transactionCode);
              setSkuNo(item.skuLocation);
              handleOpenSkuNoModal();
            }}
          >
            View
          </button>
        ),
      };
      tempCreatePickupListData.push(itemObject);
    });
    setState({ ...state, data: tempCreatePickupListData });
  }, [createPickupListTableData]);

  const [soureLocationModal, setOpenSourceLocationModal] = useState(false);

  const handleOpenSourceLocationModal = () => {
    setOpenSourceLocationModal(!soureLocationModal);
  };

  const [skuNoModal, setOpenSkuNoModal] = useState(false);

  const handleOpenSkuNoModal = () => {
    setOpenSkuNoModal(!skuNoModal);
  };

  const [skuNoTable, setSkuNoTable] = useState({
    columns: [
      { title: "Source Location", field: "srcLoc" },
      { title: "SKU No", field: "skuNo" },
    ],
    data: [],
  });

  useEffect(() => {
    let tempSkuNo = [];
    let data = skuNo ? skuNo : [];
    data.map((sku) => {
      const skuInfo = {
        srcLoc: sku.targetLocation,
        skuNo: sku.sku,
      };
      tempSkuNo.push(skuInfo);
    });
    setSkuNoTable({
      ...skuNoTable,
      data: tempSkuNo,
    });
  }, [skuNo]);

  const [isErrorCreatePickupList, setIsErrorCreatePickupList] = useState(false);

  const [openCreatePickupListModal, setOpenCreatePickupListModal] =
    useState(false);

  const [pickupListData, setPickupListData] = useState({});

  const handleOpenCreatePickupListModal = (data = {}) => {
    setPickupListData(data);
    setOpenCreatePickupListModal(true);
  };

  const handleCloseCreatePickupListModal = () => {
    setOpenCreatePickupListModal(false);
  };

  const createPickupListHandler = (event, data) => {
    handleOpenCreatePickupListModal(data);
  };

  const onClickCreatePickupList = async () => {
    let transactionCode = [];
    pickupListData.map((data) => {
      const object = {
        transactionCode: data.transactionNo,
      };
      transactionCode.push(object);
    });

    let res = await createPickupList(transactionCode);

    if (res.status) {
      handleCloseCreatePickupListModal();
      getCreatePickupList(warehouseIdSelected);
    }
    if (!res.status) {
      setIsErrorCreatePickupList(true);
    }
  };

  const changeWarehouse = (e) => {
    setWarehouseIdSelected(e.target.value);
  };

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title={
                <div>
                  <br />
                  <h5>Create Pickup List</h5>
                  <br />
                  <select
                    style={{ width: "11rem" }}
                    as="select"
                    id="warehouseName"
                    name="warehouseName"
                    className="form-control"
                    onChange={changeWarehouse}
                  >
                    {warehouseOptions.map((option) => {
                      return (
                        <option key={option.value} value={option.value}>
                          {option.key}
                        </option>
                      );
                    })}
                  </select>
                </div>
              }
              columns={state.columns}
              data={state.data}
              options={{
                draggable: false,
                selection: true,
                toolbarButtonAlignment: "left",
              }}
              actions={[
                role === "admin"
                  ? {
                      // tooltip: "Create Pick List",
                      icon: () => (
                        <button
                          disabled={role !== "admin" ? true : false}
                          className="btn btn-info"
                        >
                          Create Pick List
                        </button>
                      ),
                      onClick: (evt, data) =>
                        createPickupListHandler(evt, data),
                    }
                  : "",
              ]}
            />
            <div>
              <Modal
                show={skuNoModal}
                onHide={handleOpenSkuNoModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>{transactionNo}</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <MaterialTable
                          // title={`Transaction No: ${transactionNo}`}
                          title="SKU No"
                          columns={skuNoTable.columns}
                          data={skuNoTable.data}
                          options={{ draggable: false }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                show={openCreatePickupListModal}
                onHide={handleCloseCreatePickupListModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Create Pickup List</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <p>Are you sure you want to create pickup list?</p>

                        <div className="clearfix">
                          <button
                            type="button"
                            className="cancelbtn button1"
                            onClick={handleCloseCreatePickupListModal}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="deletebtn button1"
                            onClick={onClickCreatePickupList}
                          >
                            Create
                          </button>
                        </div>
                        <div className="mt-3">
                          <Collapse in={isErrorCreatePickupList}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorCreatePickupList(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Could not Create the Pickup List
                            </Alert>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    warehouses: state.warehouses,
    createPickupListTableData: state.createPickupList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getWarehouses: () => dispatch(getWarehouses()),
    getCreatePickupList: (id) => dispatch(getCreatePickupList(id)),
    createPickupList: (warehouseId) => dispatch(createPickupList(warehouseId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePickupList);
