import React, { useState, useEffect } from "react";
import Header from "../Header";
import { connect } from "react-redux";
import drawerCss from "../../style/drawer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import Footer from "../Footer";
import MaterialTable from "material-table";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../utils/TextError";

import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import "../../style/delete.css";
import Select from "react-select";
import Cookies from "universal-cookie";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import {
  createDevice,
  deleteDevice,
  getDevices,
  updateSelectedItem,
  updateDevice,
} from "../../store";
import { getLoginIds } from "../../store";
const cookies = new Cookies();

const useStyles = makeStyles((theme) => drawerCss(theme));

function DeviceOnboarding({
  drawerState,
  handleListItemClick,
  createDevice,
  getDevices,
  devicesReducer,
  deleteDevice,
  updateDevice,
  getLoginIdsReducer,
  getLoginIds,
}) {
  const classes = useStyles();
  const theme = useTheme();
  let drawer = drawerState;
  let userDetails = cookies.get("loginDetails").data;
  let clientId = userDetails.clientId;

  useEffect(() => {
    handleListItemClick(null, 17);
  });

  useEffect(() => {
    getLoginIds();
  }, [getLoginIds]);

  const [state, setState] = useState({
    columns: [
      { title: "Name", field: "name" },
      { title: "Owner", field: "owner" },
      { title: "UUID", field: "uuid" },
      { title: "TYPE", field: "type" },
      { title: "Actions", field: "actions" },
    ],
    data: [],
  });

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [deviceData, setDeviceData] = useState(false);

  useEffect(() => {
    getDevices();
  }, [getDevices]);

  useEffect(() => {
    let devicesData = devicesReducer.devices
      ? Array.from(devicesReducer.devices)
      : [];
    let devicesTemp = [];
    devicesData.map((device) => {
      device["actions"] = (
        <div>
          {/* <button type="button" className="btn btn-primary" style={{ marginRight: "5px" }} onClick={() => handleOpenViewUserRoleModal(roleTemp)}>
            <i className="fa fa-eye"></i>
        </button> */}
          <button
            type="button"
            className="btn btn-success"
            style={{ marginRight: "5px" }}
            onClick={() => handleUpdateDeviceModalOpen(device)}
          >
            <i className="fa fa-edit"></i>
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handleDeleteDeviceModalOpen(device)}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      );
      devicesTemp.push(device);
    });
    setState({ ...state, data: devicesTemp });
  }, [devicesReducer]);

  //   Start create Device code

  const [createDeviceModal, setCreateDeviceModal] = useState(false);
  const handleCreateDeviceModalOpen = () => {
    setIsSuccess(false);
    setIsError(false);
    setCreateDeviceModal(true);
  };

  const handleCreateDeviceModalClose = () => {
    setIsSuccess(false);
    setIsError(false);
    setCreateDeviceModal(false);
  };

  const [loginIdsOptions, setLoginIdsOptions] = useState([]);
  useEffect(() => {
    let loginIdsDataTemp = [];
    let loginIdsData = getLoginIdsReducer.loginIds.data
      ? getLoginIdsReducer.loginIds.data
      : [];
    loginIdsData.map((loginId) => {
      loginIdsDataTemp.push({
        label: loginId.name,
        value: loginId.name,
      });
    });
    setLoginIdsOptions(loginIdsDataTemp);
  }, [getLoginIdsReducer]);

  const [ownerSelected, setOwnerSelected] = useState("");
  const handleOwnerSelected = (selectedOption) => {
    setOwnerSelected(selectedOption);
  };

  const deviceTypeOptions = [
    { key: "Normal", value: "normal" },
    { key: "Industrial", value: "industrial" },
  ];

  const initialValue = {
    name: "",
    owner: "",
    uuid: "",
    type: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Enter the Name"),
    uuid: Yup.string().required("Enter the UUID"),
    type: Yup.string().required("Select the Type"),
  });

  const onSubmit = async (values, { resetForm }) => {
    values.owner = ownerSelected.value;
    let res = await createDevice(values);
    if (res.status) {
      setIsSuccess(true);
      await getDevices();
      resetForm();
    }
    if (!res.status) {
      setIsError(true);
    }
  };

  //   End create Device code

  //   Start Update Device code

  const [updateDeviceModal, setUpdateDeviceModal] = useState(false);
  const handleUpdateDeviceModalOpen = (device) => {
    setIsSuccess(false);
    setIsError(false);
    setUpdateDeviceModal(true);
    setDeviceData(device);
  };

  const handleUpdateDeviceModalClose = () => {
    setIsSuccess(false);
    setIsError(false);
    setUpdateDeviceModal(false);
    setDeviceData({});
  };

  const initialValueForUpdate = {
    name: deviceData.name ? deviceData.name : "",
    owner: deviceData.owner ? deviceData.owner : "",
    uuid: deviceData.uuid ? deviceData.uuid : "",
    type: deviceData.type ? deviceData.type : "",
  };

  const validationSchemaForUpdate = Yup.object({
    name: Yup.string().required("Enter the Name"),
    owner: Yup.string().required("Select the Owner"),
    uuid: Yup.string().required("Enter the UUID"),
    type: Yup.string().required("Select the Type"),
  });

  const onSubmitForUpdate = async (values) => {
    let deviceId = deviceData.id;
    let res = await updateDevice(values, deviceId);
    if (res.status) {
      setIsSuccess(true);
      await getDevices();
    }
    if (!res.status) {
      setIsError(true);
    }
  };

  //   End Update Device code

  //   Start Delete Device code

  const [deletDeviceModal, setDeleteDeviceModal] = useState(false);
  const handleDeleteDeviceModalOpen = (Device) => {
    setDeleteDeviceModal(true);
    setDeviceData(Device);
  };

  const handleDeleteDeviceModalClose = () => {
    setDeleteDeviceModal(false);
    setDeviceData({});
  };

  const deleteDeviceData = async () => {
    let deviceId = deviceData.id;
    let response = await deleteDevice(deviceId);
    if (response.status) {
      await getDevices();
      handleDeleteDeviceModalClose();
    }
  };

  //   End Delete Device code

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title="Devices"
              columns={state.columns}
              data={state.data}
              options={{ draggable: false }}
              actions={[
                {
                  icon: "add",
                  tooltip: "Add Device",
                  isFreeAction: true,
                  onClick: (event) => handleCreateDeviceModalOpen(),
                  iconProps: {
                    color: "error",
                    fontSize: "large",
                  },
                },
              ]}
            />
            {/* Start create Device code */}
            <div>
              <Modal
                show={createDeviceModal}
                onHide={handleCreateDeviceModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Create Device</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Formik
                          initialValues={initialValue}
                          validationSchema={validationSchema}
                          onSubmit={onSubmit}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="role">Name</label>
                                  <Field
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component={TextError}
                                  />
                                </div>
                                {/* <div className="form-group">
                                  <label htmlFor="role">Owner</label>
                                  <Field
                                    type="text"
                                    name="owner"
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="owner"
                                    component={TextError}
                                  />
                                </div> */}
                                <div className="form-group">
                                  <label htmlFor="owner">Owner</label>
                                  <Select
                                    value={ownerSelected}
                                    onChange={handleOwnerSelected}
                                    options={loginIdsOptions}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="role">UUID</label>
                                  <Field
                                    type="text"
                                    name="uuid"
                                    className="form-control"
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    name="uuid"
                                    component={TextError}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="role">Type</label>
                                  <Field
                                    as="select"
                                    id="type"
                                    name="type"
                                    className="form-control"
                                  >
                                    <option value="" disabled selected>
                                      Type
                                    </option>
                                    {deviceTypeOptions.map((option) => {
                                      return (
                                        <option
                                          key={option.key}
                                          value={option.value}
                                        >
                                          {option.key}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                  <ErrorMessage
                                    name="type"
                                    component={TextError}
                                  />
                                </div>
                                <div className="mt-3">
                                  <Collapse in={isError}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsError(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      please check your Device details. Server
                                      error!
                                    </Alert>
                                  </Collapse>

                                  <Collapse in={isSuccess}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccess(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Device has been created!
                                    </Alert>
                                  </Collapse>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                    disabled={!formik.isValid}
                                  >
                                    Create Device
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            {/* End create Device code */}
            {/*    Start Update Device code */}
            <div>
              <Modal
                show={updateDeviceModal}
                onHide={handleUpdateDeviceModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Update Device</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Formik
                          initialValues={initialValueForUpdate}
                          validationSchema={validationSchemaForUpdate}
                          onSubmit={onSubmitForUpdate}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="role">Name</label>
                                  <Field
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component={TextError}
                                  />
                                </div>

                                <div className="form-group">
                                  <label htmlFor="owner">Owner</label>
                                  <Select
                                    value={ownerSelected}
                                    onChange={handleOwnerSelected}
                                    options={loginIdsOptions}
                                  />
                                </div>

                                <div className="form-group">
                                  <label htmlFor="role">Type</label>
                                  <Field
                                    as="select"
                                    id="type"
                                    name="type"
                                    className="form-control"
                                  >
                                    {deviceTypeOptions.map((option) => {
                                      return (
                                        <option
                                          key={option.key}
                                          value={option.value}
                                        >
                                          {option.key}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                  <ErrorMessage
                                    name="type"
                                    component={TextError}
                                  />
                                </div>
                                <div className="mt-3">
                                  <Collapse in={isError}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsError(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      please check your Device details. Server
                                      error!
                                    </Alert>
                                  </Collapse>

                                  <Collapse in={isSuccess}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccess(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Device update successfully
                                    </Alert>
                                  </Collapse>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                    disabled={!formik.isValid}
                                  >
                                    Update Device
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            {/*    End Update Device code */}
            {/*    Start Delete Device code */}
            <div>
              <Modal
                show={deletDeviceModal}
                onHide={handleDeleteDeviceModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Delete Device</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <p>Are you sure you want to delete this Device?</p>

                        <div class="clearfix">
                          <button
                            type="button"
                            className="cancelbtn button1"
                            onClick={handleDeleteDeviceModalClose}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="deletebtn button1"
                            onClickCapture={deleteDeviceData}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            {/*    End Delete Device code */}
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    devicesReducer: state.devicesReducer,
    getLoginIdsReducer: state.getLoginIdsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    createDevice: (payload) => dispatch(createDevice(payload)),
    getDevices: () => dispatch(getDevices()),
    deleteDevice: (id) => dispatch(deleteDevice(id)),
    updateDevice: (payload, id) => dispatch(updateDevice(payload, id)),
    getLoginIds: () => dispatch(getLoginIds()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceOnboarding);
