import {
  GET_SALESORDER_REQUEST,
  GET_SALESORDER_SUCCESS,
  GET_SALESORDER_FAILURE,
  GET_VINSBYTRIPNO_REQUEST,
  GET_VINSBYTRIPNO_SUCCESS,
  GET_VINSBYTRIPNO_FAILURE,
  PUT_APPROVEVIN_REQUEST,
  PUT_APPROVEVIN_SUCCESS,
  PUT_APPROVEVIN_FAILURE,
} from "./DispatchTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getSalesOrderRequest = () => {
  return {
    type: GET_SALESORDER_REQUEST,
  };
};

const getSalesOrderSuccess = (payload) => {
  return {
    type: GET_SALESORDER_SUCCESS,
    payload: payload,
  };
};

const getSalesOrderFailure = (error) => {
  return {
    type: GET_SALESORDER_FAILURE,
    payload: error,
  };
};

const getSalesOrder = () => {
  return (dispatch) => {
    dispatch(getSalesOrderRequest());
    let url = `${baseUrl}/production/dispatch/get_sales_orders`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getSalesOrderSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getSalesOrderFailure(err.message));
      });
  };
};

const approveVinRequest = () => {
  return {
    type: PUT_APPROVEVIN_REQUEST,
  };
};

const approveVinSuccess = (payload) => {
  return {
    type: PUT_APPROVEVIN_SUCCESS,
    payload: payload,
  };
};

const approveVinFailure = (error) => {
  return {
    type: PUT_APPROVEVIN_FAILURE,
    payload: error,
  };
};

const approveVin = (payload) => {
  return (dispatch) => {
    dispatch(approveVinRequest());
    let url = `${baseUrl}/production/dispatch/accept_vin`;
    return axios
      .put(url, payload)
      .then((response) => {
        dispatch(approveVinSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(approveVinFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const getVinStatusByTripNoRequest = () => {
  return {
    type: GET_VINSBYTRIPNO_REQUEST,
  };
};

const getVinStatusByTripNoSuccess = (payload) => {
  return {
    type: GET_VINSBYTRIPNO_SUCCESS,
    payload: payload,
  };
};

const getVinStatusByTripNoFailure = (error) => {
  return {
    type: GET_VINSBYTRIPNO_FAILURE,
    payload: error,
  };
};

const getVinStatusByTripNo = (tripNo) => {
  return (dispatch) => {
    dispatch(getVinStatusByTripNoRequest());
    let url = `${baseUrl}/production/dispatch/get_vin_by_trip_number?trip_number=${tripNo}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getVinStatusByTripNoSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getVinStatusByTripNoFailure(err.message));
      });
  };
};

export { getSalesOrder, approveVin, getVinStatusByTripNo };
