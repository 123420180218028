import {
  GET_WAREHOUSEOCCUPANCY_REQUEST,
  GET_WAREHOUSEOCCUPANCY_SUCCESS,
  GET_WAREHOUSEOCCUPANCY_FAILURE,
} from "./WarehouseOccupancyTypes";
import axios from "axios";
import Config from "../../../config/index";
let baseUrl = Config.baseUrl;

const getWarehouseOccupancyRequest = () => {
  return {
    type: GET_WAREHOUSEOCCUPANCY_REQUEST,
  };
};

const getWarehouseOccupancySuccess = (payload) => {
  return {
    type: GET_WAREHOUSEOCCUPANCY_SUCCESS,
    payload: payload,
  };
};

const getWarehouseOccupancyFailure = (error) => {
  return {
    type: GET_WAREHOUSEOCCUPANCY_FAILURE,
    payload: error,
  };
};

const getWarehouseOccupancy = (warehouseId, itemId) => {
  return (dispatch) => {
    dispatch(getWarehouseOccupancyRequest());
    let url = `${baseUrl}/reports/get_items_occupancy?item_id=${itemId}&warehouse_id=${warehouseId}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getWarehouseOccupancySuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getWarehouseOccupancyFailure(err.message));
      });
  };
};

export { getWarehouseOccupancy };
