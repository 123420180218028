import {
  GET_WAREHOUSE_REQUEST,
  GET_WAREHOUSE_SUCCESS,
  GET_WAREHOUSE_FAILURE,
  GET_YARDOCCUPANCY_REQUEST,
  GET_YARDOCCUPANCY_SUCCESS,
  GET_YARDOCCUPANCY_FAILURE,
  GET_INVENTORY_REQUEST,
  GET_INVENTORY_SUCCESS,
  GET_INVENTORY_FAILURE,
  GET_PICKUP_REQUEST,
  GET_PICKUP_SUCCESS,
  GET_PICKUP_FAILURE,
  GET_FINISHEDGOODSHP_REQUEST,
  GET_FINISHEDGOODSHP_SUCCESS,
  GET_FINISHEDGOODSHP_FAILURE,
  GET_PDI_REQUEST,
  GET_PDI_SUCCESS,
  GET_PDI_FAILURE,
  GET_REJECTION_REQUEST,
  GET_REJECTION_SUCCESS,
  GET_REJECTION_FAILURE,
} from "./HomeTypes";

const initialStateForWarehouse = {
  loading: false,
  warehouses: [],
  error: "",
};

const getWarehouseReducer = (state = initialStateForWarehouse, action) => {
  switch (action.type) {
    case GET_WAREHOUSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_WAREHOUSE_SUCCESS:
      return {
        loading: false,
        warehouses: action.payload,
        error: "",
      };
    case GET_WAREHOUSE_FAILURE:
      return {
        loading: false,
        warehouses: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForYardOccupancy = {
  loading: false,
  warehouses: [],
  error: "",
};

const getYardReducer = (state = initialStateForYardOccupancy, action) => {
  switch (action.type) {
    case GET_YARDOCCUPANCY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_YARDOCCUPANCY_SUCCESS:
      return {
        loading: false,
        warehouses: action.payload,
        error: "",
      };
    case GET_YARDOCCUPANCY_FAILURE:
      return {
        loading: false,
        warehouses: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForInventory = {
  loading: false,
  categories: [],
  error: "",
};

const getInventoryReducer = (state = initialStateForInventory, action) => {
  switch (action.type) {
    case GET_INVENTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_INVENTORY_SUCCESS:
      return {
        loading: false,
        categories: action.payload,
        error: "",
      };
    case GET_INVENTORY_FAILURE:
      return {
        loading: false,
        categories: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForPickup = {
  loading: false,
  pickupList: [],
  error: "",
};

const getPickupReducer = (state = initialStateForPickup, action) => {
  switch (action.type) {
    case GET_PICKUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PICKUP_SUCCESS:
      return {
        loading: false,
        pickupList: action.payload,
        error: "",
      };
    case GET_PICKUP_FAILURE:
      return {
        loading: false,
        pickupList: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForFg = {
  loading: false,
  fg: [],
  error: "",
};

const getFgReducer = (state = initialStateForFg, action) => {
  switch (action.type) {
    case GET_FINISHEDGOODSHP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_FINISHEDGOODSHP_SUCCESS:
      return {
        loading: false,
        fg: action.payload,
        error: "",
      };
    case GET_FINISHEDGOODSHP_FAILURE:
      return {
        loading: false,
        fg: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForPdi = {
  loading: false,
  pdi: [],
  error: "",
};

const getPdiReducer = (state = initialStateForPdi, action) => {
  switch (action.type) {
    case GET_PDI_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PDI_SUCCESS:
      return {
        loading: false,
        pdi: action.payload,
        error: "",
      };
    case GET_PDI_FAILURE:
      return {
        loading: false,
        pdi: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForRejection = {
  loading: false,
  rejection: [],
  error: "",
};

const getRejectionReducer = (state = initialStateForRejection, action) => {
  switch (action.type) {
    case GET_REJECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_REJECTION_SUCCESS:
      return {
        loading: false,
        rejection: action.payload,
        error: "",
      };
    case GET_REJECTION_FAILURE:
      return {
        loading: false,
        rejection: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getWarehouseReducer,
  getYardReducer,
  getInventoryReducer,
  getPickupReducer,
  getFgReducer,
  getPdiReducer,
  getRejectionReducer,
};
