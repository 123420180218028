import {
  GET_ALLGRN_REQUEST,
  GET_ALLGRN_SUCCESS,
  GET_ALLGRN_FAILURE,
  GET_LINEITEMSBYGRN_REQUEST,
  GET_LINEITEMSBYGRN_SUCCESS,
  GET_LINEITEMSBYGRN_FAILURE,
} from "./GRNTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getAllGRNRequest = () => {
  return {
    type: GET_ALLGRN_REQUEST,
  };
};

const getAllGRNSuccess = (payload) => {
  return {
    type: GET_ALLGRN_SUCCESS,
    payload: payload,
  };
};

const getAllGRNFailure = (error) => {
  return {
    type: GET_ALLGRN_FAILURE,
    payload: error,
  };
};

const getAllGRN = () => {
  return (dispatch) => {
    dispatch(getAllGRNRequest());
    let url = `${baseUrl}/grn`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getAllGRNSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getAllGRNFailure(err.message));
      });
  };
};

const getLineItemsByGrnRequest = () => {
  return {
    type: GET_LINEITEMSBYGRN_REQUEST,
  };
};

const getLineItemsByGrnSuccess = (payload) => {
  return {
    type: GET_LINEITEMSBYGRN_SUCCESS,
    payload: payload,
  };
};

const getLineItemsByGrnFailure = (error) => {
  return {
    type: GET_LINEITEMSBYGRN_FAILURE,
    payload: error,
  };
};

const getLineItemsByGrn = (grn) => {
  return (dispatch) => {
    dispatch(getLineItemsByGrnRequest());
    let url = `${baseUrl}/items_by_grn?grn_number=${grn}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getLineItemsByGrnSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getLineItemsByGrnFailure(err.message));
      });
  };
};

export { getAllGRN, getLineItemsByGrn };
