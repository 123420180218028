import {
  GET_REJECTIONAGEING_REQUEST,
  GET_REJECTIONAGEING_SUCCESS,
  GET_REJECTIONAGEING_FAILURE,
} from "./RejectionAgeingTypes";

const initialStateForRejectionAgeing = {
  loading: false,
  rejectionAgeing: [],
  error: "",
};

const getRejectionAgeingReducer = (
  state = initialStateForRejectionAgeing,
  action
) => {
  switch (action.type) {
    case GET_REJECTIONAGEING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_REJECTIONAGEING_SUCCESS:
      return {
        loading: false,
        rejectionAgeing: action.payload,
        error: "",
      };
    case GET_REJECTIONAGEING_FAILURE:
      return {
        loading: false,
        rejectionAgeing: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getRejectionAgeingReducer };
