/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 * 
 *  Mobillor Technologies Pvt. Ltd. 
 *  All Rights Reserved.
 * 
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.  
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
*/

import {
    GET_DEPARTMENTS_REQUEST,
    GET_DEPARTMENTS_SUCCESS,
    GET_DEPARTMENTS_FAILURE,
    CREATE_DEPARTMENT_REQUEST,
    CREATE_DEPARTMENT_SUCCESS,
    CREATE_DEPARTMENT_FAILURE,
    UPDATE_DEPARTMENT_REQUEST,
    UPDATE_DEPARTMENT_SUCCESS,
    UPDATE_DEPARTMENT_FAILURE,
    DELETE_DEPARTMENT_REQUEST,
    DELETE_DEPARTMENT_SUCCESS,
    DELETE_DEPARTMENT_FAILURE
} from './departmentTypes'

const initialState = {
    loading: false,
    departments: {},
    error: ""
}

const getDepartmentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DEPARTMENTS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_DEPARTMENTS_SUCCESS:
            return {
                loading: true,
                departments: action.payload,
                error: ''
            }
        case GET_DEPARTMENTS_FAILURE:
            return {
                loading: false,
                departments: {},
                error: action.payload
            }
        default:
            return state
    }
}


const initialStateForCreate = {
    loading: false,
    department: {},
    error: ""
}

const createDepartmentReducer = (state = initialStateForCreate, action) => {
    switch (action.type) {
        case CREATE_DEPARTMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_DEPARTMENT_SUCCESS:
            return {
                loading: true,
                department: action.payload,
                error: ''
            }
        case CREATE_DEPARTMENT_FAILURE:
            return {
                loading: false,
                department: {},
                error: action.payload
            }
        default:
            return state
    }
}


const initialStateForUpdate = {
    loading: false,
    department: {},
    error: ""
}

const updateDepartmentReducer = (state = initialStateForUpdate, action) => {
    switch (action.type) {
        case UPDATE_DEPARTMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_DEPARTMENT_SUCCESS:
            return {
                loading: true,
                department: action.payload,
                error: ''
            }
        case UPDATE_DEPARTMENT_FAILURE:
            return {
                loading: false,
                department: {},
                error: action.payload
            }
        default:
            return state
    }
}


const initialStateForDelete = {
    loading: false,
    department: {},
    error: ""
}

const deleteDepartmentReducer = (state = initialStateForDelete, action) => {
    switch (action.type) {
        case DELETE_DEPARTMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_DEPARTMENT_SUCCESS:
            return {
                loading: true,
                department: action.payload,
                error: ''
            }
        case DELETE_DEPARTMENT_FAILURE:
            return {
                loading: false,
                department: {},
                error: action.payload
            }
        default:
            return state
    }
}


export {
    getDepartmentsReducer,
    createDepartmentReducer,
    updateDepartmentReducer,
    deleteDepartmentReducer
}