import React, { useState, useEffect } from "react";
import Header from "../../Header";
import { connect } from "react-redux";
import drawerCss from "../../../style/drawer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import Footer from "../../Footer";
import MaterialTable from "material-table";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../../utils/TextError";

import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import {
  createDepartment,
  deleteDepartment,
  getDepartments,
  updateDepartment,
  updateSelectedItem,
} from "../../../store";
import "../../../style/delete.css";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const useStyles = makeStyles((theme) => drawerCss(theme));

function Department({
  drawerState,
  handleListItemClick,
  departments,
  getDepartments,
  createDepartment,
  updateDepartment,
  deleteDepartment,
}) {
  const classes = useStyles();
  const theme = useTheme();
  let drawer = drawerState;
  let userDetails = cookies.get("loginDetails").data;
  let clientId = userDetails.clientId;

  useEffect(() => {
    handleListItemClick(null, 18);
  });

  const [state, setState] = useState({
    columns: [
      { title: "Department Name", field: "departmentName" },
      { title: "Actions", field: "actions" },
    ],
    data: [],
  });

  const [openDeleteDepartmentModal, setOpenDeleteDepartmentModal] =
    useState(false);
  const [singleDepartmentDataForDelete, setDepartmentDataForDelete] = useState(
    {}
  );
  const handleOpenDeleteDepartmentModal = (department = {}) => {
    setDepartmentDataForDelete(department);
    setOpenDeleteDepartmentModal(true);
  };
  const handleCloseDeleteDepartmentModal = () => {
    setOpenDeleteDepartmentModal(false);
  };

  const [openUpdateDepartmentModal, setOpenUpdateDepartmentModal] =
    useState(false);
  const [singleDepartmentDataForUpdate, setDepartmentDataForUpdate] = useState(
    {}
  );
  const handleOpenUpdateDepartmentModal = (department = {}) => {
    setDepartmentDataForUpdate(department);
    setOpenUpdateDepartmentModal(true);
    setIsErrorForUpdate(false);
    setIsSuccessForUpdate(false);
  };
  const handleCloseUpdateDepartmentModal = () => {
    setOpenUpdateDepartmentModal(false);
  };

  useEffect(() => {
    getDepartments(clientId);
  }, [getDepartments]);

  useEffect(() => {
    let tempDepartment = [];
    let departmentsTemp = departments.departments.departmentsData
      ? departments.departments.departmentsData
      : [];
    departmentsTemp.map((department) => {
      department["actions"] = (
        <div>
          <button
            title="Edit Department"
            type="button"
            className="btn btn-success"
            style={{ marginRight: "5px" }}
            onClick={() => handleOpenUpdateDepartmentModal(department)}
          >
            <i className="fa fa-edit"></i>
          </button>
          <button
            title="Delete Department"
            type="button"
            className="btn btn-danger"
            onClick={() => handleOpenDeleteDepartmentModal(department)}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      );
      tempDepartment.push(department);
    });
    setState({ ...state, data: tempDepartment });
  }, [departments]);

  const [createDepartmentModal, setCreateDepartmentModal] = useState(false);
  const handleCreateDepartmentModal = () => {
    setCreateDepartmentModal(!createDepartmentModal);
  };

  const initialValue = {
    departmentName: "",
    clientId: clientId,
  };

  const validationSchema = Yup.object({
    departmentName: Yup.string().required("Enter the Department Name"),
  });

  const [isErrorCreateDepartment, setIsErrorCreateDepartment] = useState(false);
  const [isSuccessCreateDepartment, setIsSuccessCreateDepartment] =
    useState(false);

  const onSubmit = async (values, { resetForm }) => {
    let res = await createDepartment(values);
    if (res.status) {
      setIsSuccessCreateDepartment(true);
      getDepartments();
    }
    if (!res.status) {
      setIsErrorCreateDepartment(true);
    }
    resetForm();
  };

  const initialValueForUpdate = {
    departmentId: singleDepartmentDataForUpdate.departmentId,
    departmentName: singleDepartmentDataForUpdate.departmentName,
    clientId: singleDepartmentDataForUpdate.clientId,
  };

  const validationSchemaForUpdate = Yup.object({
    departmentName: Yup.string().required("Enter the Department Name"),
  });

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);

  const onSubmitForUpdate = async (values, { resetForm }) => {
    let res = await updateDepartment(values);
    if (res.status) {
      setIsSuccessForUpdate(true);
      getDepartments(clientId);
    }
    if (!res.status) {
      setIsErrorForUpdate(true);
    }
    // resetForm();
  };

  const deleteDepartmentData = async () => {
    let departmentId = singleDepartmentDataForDelete.departmentId;
    let response = await deleteDepartment(departmentId);
    if (response.status) {
      await getDepartments(clientId);
      handleCloseDeleteDepartmentModal();
    }
  };

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title="Departments"
              columns={state.columns}
              data={state.data}
              options={{ draggable: false }}
              actions={[
                {
                  icon: "add",
                  tooltip: "Add New Department",
                  isFreeAction: true,
                  onClick: (event) => handleCreateDepartmentModal(),
                  iconProps: {
                    color: "error",
                    fontSize: "large",
                  },
                },
              ]}
            />
            <div>
              <Modal
                show={createDepartmentModal}
                onHide={handleCreateDepartmentModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Create Department</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Formik
                          initialValues={initialValue}
                          validationSchema={validationSchema}
                          onSubmit={onSubmit}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="departmentName">Name</label>
                                  <Field
                                    type="text"
                                    name="departmentName"
                                    className="form-control"
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    name="departmentName"
                                    component={TextError}
                                  />
                                </div>
                                <div className="mt-3">
                                  <Collapse in={isErrorCreateDepartment}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsErrorCreateDepartment(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      please check your department details.
                                      Server error!
                                    </Alert>
                                  </Collapse>

                                  <Collapse in={isSuccessCreateDepartment}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccessCreateDepartment(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Department Created Successfully
                                    </Alert>
                                  </Collapse>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                    disabled={!formik.isValid}
                                  >
                                    Create Department
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                show={openUpdateDepartmentModal}
                onHide={handleCloseUpdateDepartmentModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Update Department</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Formik
                          initialValues={initialValueForUpdate}
                          validationSchema={validationSchemaForUpdate}
                          onSubmit={onSubmitForUpdate}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="departmentName">Name</label>
                                  <Field
                                    type="text"
                                    name="departmentName"
                                    className="form-control"
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    name="departmentName"
                                    component={TextError}
                                  />
                                </div>
                                <div className="mt-3">
                                  <Collapse in={isErrorForUpdate}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsErrorForUpdate(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      please check your department details.
                                      Server error!
                                    </Alert>
                                  </Collapse>

                                  <Collapse in={isSuccessForUpdate}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccessForUpdate(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Department update successfully
                                    </Alert>
                                  </Collapse>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                    disabled={!formik.isValid}
                                  >
                                    Update Department
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                show={openDeleteDepartmentModal}
                onHide={handleCloseDeleteDepartmentModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Delete Department</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <p>Are you sure you want to delete this department?</p>

                        <div class="clearfix">
                          <button
                            type="button"
                            className="cancelbtn button1"
                            onClick={handleCloseDeleteDepartmentModal}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="deletebtn button1"
                            onClick={deleteDepartmentData}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    departments: state.getDepartmentsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getDepartments: () => dispatch(getDepartments()),
    createDepartment: (payload) => dispatch(createDepartment(payload)),
    updateDepartment: (payload) => dispatch(updateDepartment(payload)),
    deleteDepartment: (departmentId) =>
      dispatch(deleteDepartment(departmentId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Department);
