/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import "../../style/resetPassword.css";
import { connect } from "react-redux";
import { forgotPassword } from "../../store";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../utils/TextError";
import Footer from "../Footer";

function ForgotPassword({ forgotPasswordData, forgotPassword }) {
  const initialValue = {
    email: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required!"),
  });
  const onSubmit = (values) => {
    forgotPassword(values);
  };
  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <div id="cover-caption">
          <div className="container-fluid">
            <div className="row text-white">
              <div className="col-xl-5 col-lg-8 col-md-8 col-sm-12 col-xs-12 col mx-auto form-4">
                <div className="template" style={{ color: "black" }}>
                  <div className="card">
                    <h4 className="card-header">Forgot Password</h4>
                    <div className="card-body">
                      <Formik
                        initialValues={initialValue}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                      >
                        {(formik) => {
                          return (
                            <Form>
                              <div className="form-group">
                                <label htmlFor="email">Email-Id</label>
                                <Field
                                  type="email"
                                  name="email"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="email"
                                  component={TextError}
                                />
                              </div>
                              {forgotPasswordData.forgotPassword.status ===
                              false ? (
                                <div
                                  className="alert alert-danger alert-dismissible fade show"
                                  role="alert"
                                >
                                  <span className="help-block">
                                    {forgotPasswordData.forgotPassword.msg}
                                  </span>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                              ) : (
                                ""
                              )}
                              {forgotPasswordData.forgotPassword.status ? (
                                <div
                                  className="alert alert-success alert-dismissible fade show"
                                  role="alert"
                                >
                                  <span className="help-block">
                                    Please check your email!
                                  </span>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                              ) : null}
                              <div
                                style={{
                                  display: "flex",
                                  float: "right",
                                  display: "inline",
                                }}
                              >
                                <button type="button" className="btn btn-light">
                                  <Link className="link" to="/">
                                    Back
                                  </Link>
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-danger"
                                  disabled={!formik.isValid}
                                >
                                  Forgot Password
                                </button>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    forgotPasswordData: state.forgotPassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (forgotPasswordPayload) =>
      dispatch(forgotPassword(forgotPasswordPayload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
