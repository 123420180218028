/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

const initialState = {
  open: true,
};

let drawerState = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_DRAWER":
      return {
        ...state,
        open: !state.open,
      };
    default:
      return state;
  }
};

const updateDrawer = () => {
  return {
    type: "UPDATE_DRAWER",
  };
};

const handleDrawerOpen = () => {
  return (dispatch) => {
    dispatch(updateDrawer());
  };
};

const initialStateWarehousesState = {
  open: false,
};

let warehousesState = (state = initialStateWarehousesState, action) => {
  switch (action.type) {
    case "WAREHOUSES_TYPES":
      return {
        ...state,
        open: !state.open,
      };
    default:
      return state;
  }
};

const warehousesTypes = () => {
  return {
    type: "WAREHOUSES_TYPES",
  };
};

const warehousesTypesHandle = () => {
  return (dispatch) => {
    dispatch(warehousesTypes());
  };
};

const initialStateUserManagementModuleState = {
  open: false,
};

let userManagementModuleState = (
  state = initialStateUserManagementModuleState,
  action
) => {
  switch (action.type) {
    case "USER_MANAGEMENT_MODULE":
      return {
        ...state,
        open: !state.open,
      };
    default:
      return state;
  }
};

const userManagementModule = () => {
  return {
    type: "USER_MANAGEMENT_MODULE",
  };
};

const userManagementModuleHandle = () => {
  return (dispatch) => {
    dispatch(userManagementModule());
  };
};

const initialStateReportsState = {
  open: false,
};

let reportsState = (state = initialStateReportsState, action) => {
  switch (action.type) {
    case "REPORTS_TYPES":
      return {
        ...state,
        open: !state.open,
      };
    default:
      return state;
  }
};

const reportsTypes = () => {
  return {
    type: "REPORTS_TYPES",
  };
};

const reportsHandle = () => {
  return (dispatch) => {
    dispatch(reportsTypes());
  };
};

export {
  drawerState,
  handleDrawerOpen,
  warehousesState,
  warehousesTypesHandle,
  userManagementModuleState,
  userManagementModuleHandle,
  reportsState,
  reportsHandle,
};
