import {
    GET_YARDOCCUPANCY_REQUEST,
    GET_YARDOCCUPANCY_SUCCESS,
    GET_YARDOCCUPANCY_FAILURE,
  } from "./YardOccupancyTypes";
  
  const initialStateForYardOccupancy = {
    loading: false,
    YardOccupancy: [],
    error: "",
  };
  
  const getYardOccupancyReducer = (state = initialStateForYardOccupancy, action) => {
    switch (action.type) {
      case GET_YARDOCCUPANCY_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_YARDOCCUPANCY_SUCCESS:
        return {
          loading: false,
          YardOccupancy: action.payload,
          error: "",
        };
      case GET_YARDOCCUPANCY_FAILURE:
        return {
          loading: false,
          YardOccupancy: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  
  
  export { getYardOccupancyReducer};
  