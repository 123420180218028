/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React, { useState } from "react";
import { connect } from "react-redux";
import { login } from "../../store";
import { useHistory, Link } from "react-router-dom";
import logo from "../../assets/mobillor.png";
import styles from "../../style/header.module.css";
import "../../style/form.css";
import "../../style/form1.css";
import Header from "../Header";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../utils/TextError";
import Alert from "@material-ui/lab/Alert";
import Footer from "../Footer";
import "../../style/login.css";
import Cookies from "universal-cookie";
import Config from "../../config/index";
let dashboardUrl = Config.dashboardUrl;
const cookies = new Cookies();

function Login({ isAuth, isAuthCall }) {
  let history = useHistory();
  const [isChecked, setIsChecked] = useState(false);

  const initialValue = {
    email: localStorage.checkbox ? localStorage.email : "",
    password: localStorage.checkbox ? localStorage.password : "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().required("Enter the Email or Login ID"),
    password: Yup.string().required("Enter the password"),
  });
  const onSubmit = async (values) => {
    if (isChecked && values.email !== "") {
      localStorage.email = values.email;
      localStorage.password = values.password;
      localStorage.checkbox = isChecked;
    }
    let data = await isAuthCall(values);

    if (data.status && data.data.data.lastLogin) {
      cookies.set("loginDetails", JSON.stringify(data.data), { path: "/" });
      cookies.Domain = dashboardUrl;
      window.location.reload();
      setTimeout(() => {
        history.push("/home");
      }, 1000);
    }

    if (data.data.status && !data.data.data.lastLogin) {
      history.push("/set_password_for_new_user");
    }
  };

  const changeCheckBox = () => {
    setIsChecked(!isChecked);
    localStorage.checkbox = !isChecked;
  };

  // let loginStatus = localStorage.getItem("isAuth")
  // if (loginStatus === "true") {
  //     history.push('/home')
  // }
  return (
    <div>
      <Header />
      <section id="cover" className="min-vh-100">
        <div id="cover-caption">
          <div className="container-fluid">
            <div className="row text-white">
              <div className="col-xl-5 col-lg-8 col-md-8 col-sm-12 col-xs-12 col mx-auto form-4">
                <div className="template" style={{ color: "black" }}>
                  <div className="card">
                    <div className="card-header">
                      {" "}
                      <img src={logo} alt="logo" className={styles.logo} />
                    </div>
                    <div className="card-body" style={{ display: "flex" }}>
                      <div style={{ width: "70%", marginLeft: "30%" }}>
                        <Formik
                          initialValues={initialValue}
                          validationSchema={validationSchema}
                          onSubmit={onSubmit}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="email">Email/Login-ID</label>
                                  <Field
                                    type="text"
                                    name="email"
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component={TextError}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="password">Password</label>
                                  <Field
                                    type="password"
                                    name="password"
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component={TextError}
                                  />
                                </div>
                                {/* <div className="form-group">
                                                                    <input id="check" type="checkbox" checked={isChecked} className="check" onClick={changeCheckBox} />
                                                                    <label htmlFor="check"><span className="icon"></span>&nbsp; Remember me</label>
                                                                </div> */}
                                <br />

                                {isAuth.error ||
                                isAuth.userData.status === false ? (
                                  <div>
                                    <Alert severity="error">
                                      Invalid email/password
                                    </Alert>
                                    <br />
                                    <br />
                                  </div>
                                ) : null}

                                <div
                                  style={{
                                    display: "flex",
                                    float: "right",
                                    display: "inline",
                                    marginTop: "-8%",
                                  }}
                                >
                                  <button
                                    type="submit"
                                    className="btn btn-danger"
                                    disabled={!formik.isValid}
                                  >
                                    Login
                                  </button>
                                </div>
                                <hr />
                                <div className="foot-lnk">
                                  <Link className="link" to="/forgot_password">
                                    Forgot Password?
                                  </Link>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.login,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    isAuthCall: (loginDetails) => dispatch(login(loginDetails)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Login);
