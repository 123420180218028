import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { makeStyles } from "@material-ui/core/styles";
import drawerCss from "../../style/drawer";
import classNames from "classnames";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import {
  updateSelectedItem,
  getRejectionAgeing,
  getItemMaster,
  getWarehouses,
} from "../../store";
import Config from "../../config/index";

const desLength = Config.descriptionLength;

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

const RejectionAgeing = ({
  drawerState,
  handleListItemClick,
  getItemMaster,
  itemMaster,
  getWarehouses,
  warehouses,
  getRejectionAgeing,
  rejections,
}) => {
  useEffect(() => {
    handleListItemClick(null, 23);
  }, []);

  useEffect(() => {
    getItemMaster();
  }, []);

  useEffect(() => {
    getWarehouses();
  }, []);

  const classes = useStyles();
  let drawer = drawerState;

  const [itemIdSelected, setItemIdSelected] = useState("");
  const [warehouseIdSelected, setWarehouseIdSelected] = useState("");
  const [statusIdSelected, setStatusIdSelected] = useState(undefined);

  useEffect(() => {
    getRejectionAgeing(itemIdSelected, warehouseIdSelected, statusIdSelected);
  }, [itemIdSelected, warehouseIdSelected, statusIdSelected]);

  const [warehouseOptions, setWarehouseOptions] = useState([]);

  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      tempWarehouseOptions.push({
        key: warehouse.warehouseName,
        value: warehouse.id,
      });
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = itemMaster.itemMaster ? itemMaster.itemMaster : [];
    data.map((item) => {
      tempItemCodeOptions.push({
        key: item.itemCode,
        value: item.id,
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [itemMaster]);

  const [state, setState] = useState({
    columns: [
      { title: "SKU/VIN", field: "skuVin" },
      { title: "Item/FG Code", field: "itemFgCode" },
      { title: "Item/FG Description", field: "itemFgDes" },
      { title: "Location", field: "location" },
      { title: "Warehouse", field: "warehouse" },
      { title: "Status", field: "status" },
    ],
    data: [],
  });

  useEffect(() => {
    let tempRejections = [];
    let data = rejections.rejectionAgeing ? rejections.rejectionAgeing : [];
    data.map((data) => {
      let des = data?.itemDescription;
      let len = desLength;
      const dataInfo = {
        skuVin: data.skuVin,
        itemFgCode: data.itemCode,
        itemFgDes: des ? des.substring(0, len) : "",
        location: `${data.zoneName} / ${data.locationName}`,
        warehouse: data.warehouseName,
        status: data.status,
      };
      tempRejections.push(dataInfo);
    });
    setState({ ...state, data: tempRejections });
  }, [rejections]);

  const [showFilters, setShowFilters] = useState(false);

  const showFiltersHandler = () => {
    setShowFilters(!showFilters);
  };

  const changeItem = (e) => {
    setItemIdSelected(e.target.value);
  };

  const changeWarehouse = (e) => {
    setWarehouseIdSelected(e.target.value);
  };

  const changeStatus = (e) => {
    setStatusIdSelected(e.target.value);
  };

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title={
                <div>
                  <br />
                  <h5>RejectionAgeing</h5>
                  <br />
                  {!showFilters && <br />}
                  {!showFilters && <br />}
                  {showFilters && (
                    <span style={{ display: "flex" }}>
                      <select
                        style={{ width: "11rem" }}
                        as="select"
                        id="warehouseId"
                        name="warehouseId"
                        className="form-control"
                        onChange={changeWarehouse}
                      >
                        <option value="" defaultValue>
                          All Warehouses
                        </option>
                        {warehouseOptions.map((option) => {
                          return (
                            <option key={option.value} value={option.value}>
                              {option.key}
                            </option>
                          );
                        })}
                      </select>
                      <select
                        style={{ marginLeft: "1rem", width: "11rem" }}
                        as="select"
                        id="itemId"
                        name="itemId"
                        className="form-control"
                        onChange={changeItem}
                      >
                        <option value="" defaultValue>
                          All Items
                        </option>
                        {itemCodeOptions.map((option) => {
                          return (
                            <option key={option.value} value={option.value}>
                              {option.key}
                            </option>
                          );
                        })}
                      </select>
                      <select
                        style={{ width: "11rem", marginLeft: "1rem" }}
                        as="select"
                        id="status"
                        name="status"
                        className="form-control"
                        onChange={changeStatus}
                      >
                        <option value={`${undefined}`} defaultValue>
                          All Status
                        </option>
                        <option value={1}>less than 30 days</option>
                        <option value={2}>31 - 60 days</option>
                        <option value={3}>61 - 90 days</option>
                        <option value={4}>greater than 90 days</option>
                        <option value={0}>Expired</option>
                      </select>
                    </span>
                  )}
                </div>
              }
              columns={state.columns}
              data={state.data}
              localization={{
                body: {
                  emptyDataSourceMessage: "Choose a filter to display data",
                },
              }}
              actions={[
                {
                  icon: "search",
                  tooltip: "Show Filters",
                  isFreeAction: true,
                  onClick: () => {
                    showFiltersHandler();
                  },
                },
              ]}
              options={{
                draggable: false,
                exportButton: true,
                exportAllData: true,
                exportFileName: `Rejection Ageing`,
                filtering: true,
              }}
              components={{
                Action: (props) => (
                  <button
                    style={{ width: "4.5rem" }}
                    onClick={() => props.action.onClick()}
                  >
                    Filters
                  </button>
                ),
              }}
            />
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    itemMaster: state.itemMaster,
    warehouses: state.warehouses,
    rejections: state.getRejectionAgeing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getItemMaster: () => dispatch(getItemMaster()),
    getWarehouses: () => dispatch(getWarehouses()),
    getRejectionAgeing: (itemId, warehouseId, status) =>
      dispatch(getRejectionAgeing(itemId, warehouseId, status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RejectionAgeing);
