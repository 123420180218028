import {
  GET_FGAGEING_REQUEST,
  GET_FGAGEING_SUCCESS,
  GET_FGAGEING_FAILURE,
} from "./FgAgeingTypes";
import axios from "axios";
import Config from "../../../config/index";
let baseUrl = Config.baseUrl;

const getFgAgeingRequest = () => {
  return {
    type: GET_FGAGEING_REQUEST,
  };
};

const getFgAgeingSuccess = (payload) => {
  return {
    type: GET_FGAGEING_SUCCESS,
    payload: payload,
  };
};

const getFgAgeingFailure = (error) => {
  return {
    type: GET_FGAGEING_FAILURE,
    payload: error,
  };
};

const getFgAgeing = (fgCode, status) => {
  return (dispatch) => {
    dispatch(getFgAgeingRequest());
    let url = `${baseUrl}/reports/get_fg_list_by_date?fg_code=${fgCode}&status=${status}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getFgAgeingSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getFgAgeingFailure(err.message));
      });
  };
};

export { getFgAgeing };
