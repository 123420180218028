import {
  GET_ALLOCATEDZONES_REQUEST,
  GET_ALLOCATEDZONES_SUCCESS,
  GET_ALLOCATEDZONES_FAILURE,
  ADD_ZONEALLOCATION_REQUEST,
  ADD_ZONEALLOCATION_SUCCESS,
  ADD_ZONEALLOCATION_FAILURE,
  REMOVE_ZONEALLOCATION_REQUEST,
  REMOVE_ZONEALLOCATION_SUCCESS,
  REMOVE_ZONEALLOCATION_FAILURE,
} from "./ZoneAllocationTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getAllocatedZonesRequest = () => {
  return {
    type: GET_ALLOCATEDZONES_REQUEST,
  };
};

const getAllocatedZonesSuccess = (payload) => {
  return {
    type: GET_ALLOCATEDZONES_SUCCESS,
    payload: payload,
  };
};

const getAllocatedZonesFailure = (error) => {
  return {
    type: GET_ALLOCATEDZONES_FAILURE,
    payload: error,
  };
};

const getAllocatedZones = (id) => {
  return (dispatch) => {
    dispatch(getAllocatedZonesRequest());
    let url = `${baseUrl}/zone_allocation?warehouseId=${id}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getAllocatedZonesSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getAllocatedZonesFailure(err.message));
      });
  };
};

const addZoneAllocationRequest = () => {
  return {
    type: ADD_ZONEALLOCATION_REQUEST,
  };
};

const addZoneAllocationSuccess = (payload) => {
  return {
    type: ADD_ZONEALLOCATION_SUCCESS,
    payload: payload,
  };
};

const addZoneAllocationFailure = (error) => {
  return {
    type: ADD_ZONEALLOCATION_FAILURE,
    payload: error,
  };
};

const addZoneAllocation = (payload) => {
  return (dispatch) => {
    dispatch(addZoneAllocationRequest());
    let url = `${baseUrl}/zone_allocation`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(addZoneAllocationSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(addZoneAllocationFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const removeZoneAllocationRequest = () => {
  return {
    type: REMOVE_ZONEALLOCATION_REQUEST,
  };
};

const removeZoneAllocationSuccess = (payload) => {
  return {
    type: REMOVE_ZONEALLOCATION_SUCCESS,
    payload: payload,
  };
};

const removeZoneAllocationFailure = (error) => {
  return {
    type: REMOVE_ZONEALLOCATION_FAILURE,
    payload: error,
  };
};

const removeZoneAllocation = (payload) => {
  return (dispatch) => {
    dispatch(removeZoneAllocationRequest());
    let url = `${baseUrl}/zone_allocation`;
    return axios
      .put(url, payload)
      .then((response) => {
        dispatch(removeZoneAllocationSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(removeZoneAllocationFailure(err.message));
        return { status: false, message: err.message };
      });
  };
};

export { getAllocatedZones, addZoneAllocation, removeZoneAllocation };
