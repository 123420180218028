import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { makeStyles } from "@material-ui/core/styles";
import drawerCss from "../../style/drawer";
import classNames from "classnames";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import {
  updateSelectedItem,
  getItemMaster,
  getWarehouses,
  getWarehouseOccupancy,
} from "../../store";
import Config from "../../config/index";

const desLength = Config.descriptionLength;

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

const WarehouseOccupancy = ({
  drawerState,
  handleListItemClick,
  getItemMaster,
  itemMaster,
  getWarehouses,
  warehouses,
  getWarehouseOccupancy,
  getWarehouseOccupancyState,
}) => {
  useEffect(() => {
    handleListItemClick(null, 21);
  }, []);

  const classes = useStyles();
  let drawer = drawerState;

  useEffect(() => {
    getItemMaster();
  }, []);

  useEffect(() => {
    getWarehouses();
  }, []);

  const [itemIdSelected, setItemIdSelected] = useState("");
  const [warehouseIdSelected, setWarehouseIdSelected] = useState("");

  useEffect(() => {
    getWarehouseOccupancy(warehouseIdSelected, itemIdSelected);
  }, [warehouseIdSelected, itemIdSelected]);

  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = itemMaster.itemMaster ? itemMaster.itemMaster : [];
    data.map((item) => {
      tempItemCodeOptions.push({
        key: item.itemCode,
        value: item.id,
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [itemMaster]);

  const [warehouseOptions, setWarehouseOptions] = useState([]);

  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      if (warehouse.type === "s" || warehouse.type === "d") {
        tempWarehouseOptions.push({
          key: warehouse.warehouseName,
          value: warehouse.id,
        });
      }
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  const [state, setState] = useState({
    columns: [
      { title: "Item Code", field: "itemCode" },
      { title: "Item Description", field: "itemDes" },
      { title: "SKU", field: "sku" },
      { title: "Qty", field: "qty" },
      { title: "Location", field: "location" },
      { title: "Warehouse", field: "warehouse" },
    ],
    data: [],
  });

  useEffect(() => {
    let tempReports = [];
    let data = getWarehouseOccupancyState.WarehouseOccupancy
      ? getWarehouseOccupancyState.WarehouseOccupancy
      : [];
    data.map((singleData) => {
      let des = singleData?.itemDescription;
      let len = desLength;
      const dataObject = {
        itemCode: singleData.itemCode,
        itemDes: des ? des.substring(0, len) : "",
        sku: singleData.sku,
        qty: `${singleData.qty} ${singleData.uom}`,
        location: `${singleData.zoneName} / ${singleData.locationName}`,
        warehouse: singleData.warehouseName,
      };
      tempReports.push(dataObject);
    });
    setState({ ...state, data: tempReports });
  }, [getWarehouseOccupancyState]);

  const [showFilters, setShowFilters] = useState(false);

  const showFiltersHandler = () => {
    setShowFilters(!showFilters);
  };

  const changeItem = (e) => {
    setItemIdSelected(e.target.value);
  };

  const changeWarehouse = (e) => {
    setWarehouseIdSelected(e.target.value);
  };

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title={
                <div>
                  <br />
                  <h5>Warehouse Occupancy</h5>
                  <br />
                  {!showFilters && <br />}
                  {!showFilters && <br />}
                  {showFilters && (
                    <span style={{ display: "flex" }}>
                      <select
                        style={{ width: "11rem" }}
                        as="select"
                        id="warehouseId"
                        name="warehouseId"
                        className="form-control"
                        onChange={changeWarehouse}
                      >
                        <option value="" selected>
                          All Warehouses
                        </option>
                        {warehouseOptions.map((option) => {
                          return (
                            <option key={option.value} value={option.value}>
                              {option.key}
                            </option>
                          );
                        })}
                      </select>
                      <select
                        style={{ marginLeft: "1rem", width: "11rem" }}
                        as="select"
                        id="itemId"
                        name="itemId"
                        className="form-control"
                        onChange={changeItem}
                      >
                        <option value="" selected>
                          All Items
                        </option>
                        {itemCodeOptions.map((option) => {
                          return (
                            <option key={option.value} value={option.value}>
                              {option.key}
                            </option>
                          );
                        })}
                      </select>
                    </span>
                  )}
                </div>
              }
              columns={state.columns}
              data={state.data}
              localization={{
                body: {
                  emptyDataSourceMessage: "Choose a filter to display data",
                },
              }}
              actions={[
                {
                  icon: "search",
                  tooltip: "Show Filters",
                  isFreeAction: true,
                  onClick: () => {
                    showFiltersHandler();
                  },
                },
              ]}
              options={{
                draggable: false,
                exportButton: true,
                exportAllData: true,
                exportFileName: `Warehouse Occupancy`,
                filtering: true,
              }}
              components={{
                Action: (props) => (
                  <button
                    style={{ width: "4.5rem" }}
                    onClick={() => props.action.onClick()}
                  >
                    Filters
                  </button>
                ),
              }}
            />
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    itemMaster: state.itemMaster,
    warehouses: state.warehouses,
    getWarehouseOccupancyState: state.getWarehouseOccupancy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getItemMaster: () => dispatch(getItemMaster()),
    getWarehouses: () => dispatch(getWarehouses()),
    getWarehouseOccupancy: (warehouseId, itemId) =>
      dispatch(getWarehouseOccupancy(warehouseId, itemId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseOccupancy);
