import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import dashboardCard from "../../style/dashboardCard";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { getWarehouse, getWarehouses } from "../../store";

import "chart.js/auto";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import { useRef } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => dashboardCard(theme));

const WarehouseHomePageCard = ({
  getWarehouse,
  warehouseOccupancy,
  getWarehouses,
  warehouses,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [warehouseId, setWarehouseId] = useState("");

  useEffect(() => {
    getWarehouse(warehouseId);
  }, [warehouseId]);

  useEffect(() => {
    getWarehouses();
  }, []);

  const [warehouseOptions, setWarehouseOptions] = useState([]);

  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      if (warehouse.type === "s" || warehouse.type === "d") {
        tempWarehouseOptions.push({
          key: warehouse.warehouseName,
          value: warehouse.id,
        });
      }
    });
    setWarehouseId(tempWarehouseOptions[0]?.value);
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  const [category, setCategory] = useState([]);
  const [emptyLocations, setEmptyLocations] = useState([]);
  const [occupiedLocations, setOccupiedLocations] = useState([]);
  const [itemQty, setItemQty] = useState([]);

  useEffect(() => {
    let tempCategory = [];
    let tempEmptyLocations = [];
    let tempOccupiedLocations = [];
    let tempItemQty = [];

    let data = warehouseOccupancy.warehouses
      ? warehouseOccupancy.warehouses
      : [];

    data.map((category) => {
      tempCategory.push(category.categoryName);
      tempEmptyLocations.push(category.emptyLocations);
      tempOccupiedLocations.push(category.occupiedLocations);
      tempItemQty.push(category.occupiedQty);
    });
    setCategory(tempCategory);
    setEmptyLocations(tempEmptyLocations);
    setOccupiedLocations(tempOccupiedLocations);
    setItemQty(tempItemQty);
  }, [warehouseOccupancy]);

  // useEffect(() => {
  //   let tempEmptyLocations = [];
  //   let tempOccupiedLocations = [];
  //   let tempItemQty = [];
  //   let data = warehouse.warehouses ? warehouse.warehouses : [];
  //   data.filter((warehouse) => {
  //     if (warehouse.warehouseType === "s" || warehouse.warehouseType === "d") {
  //       tempEmptyLocations.push(warehouse.emptyLocations);
  //       tempOccupiedLocations.push(warehouse.occupiedLocations);
  //       tempItemQty.push(warehouse.qty);
  //     }
  //   });
  //   setEmptyLocations(tempEmptyLocations);
  //   setOccupiedLocations(tempOccupiedLocations);
  //   setItemQty(tempItemQty);
  // }, [warehouse]);

  const data = {
    // labels: ["Electrical", "Mechanical", "Rubber", "Consumables"],
    labels: category,
    datasets: [
      {
        label: "Empty",
        data: emptyLocations,
        // data: [1, 2, 3, 4],
        borderColor: ["green", "green", "green", "green"],
        backgroundColor: ["#00FFAB", "#00FFAB", "#00FFAB", "#00FFAB"],
      },
      {
        label: "Occupied",
        data: occupiedLocations,
        // data: [5, 6, 7, 8],
        items: itemQty,
        // items: [7, 8, 9, 5],
        borderColor: ["red", "red", "red", "red"],
        backgroundColor: ["#FF4949", "#FF4949", "#FF4949", "#FF4949"],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        displayColors: false,
        backgroundColor: "#fff",
        bodyColor: "#000",
        borderColor: "#000",
        bodyFont: { size: 15 },
        borderWidth: 1,
        padding: 10,
        titleColor: "#000",
        titleFont: { weight: "bold", size: 20 },
        yAlign: "bottom",
        borderColor: "red",

        callbacks: {
          label: function (context) {
            let label;
            if (context.dataset.label === "Empty") {
              label = `Empty : ${context.parsed.y}`;
            }
            if (context.dataset.label === "Occupied") {
              label = `Occupied : ${context.parsed.y}   Qty : ${
                context.dataset.items[context.dataIndex]
              }`;
            }
            return label;
          },
        },
      },
    },
    title: {
      display: true,
      text: "Bar Chart",
    },
    // scales: {
    //   yAxes: [
    //     {
    //       ticks: {
    //         min: 0,
    //         max: 6,
    //         stepSize: 1,
    //       },
    //     },
    //   ],
    // },
  };

  const chartRef = useRef();

  const onClick = (event) => {
    // const index = getElementAtEvent(chartRef.current, event)[0]?.index;

    history.push(`/warehouse_occupancy`);
  };

  const changeWarehouse = (e) => {
    setWarehouseId(e.target.value);
  };

  return (
    <div>
      <Card>
        <CardContent>
          <h4>Warehouse Occupancy</h4>
          <hr />
          <select
            as="select"
            id="warehouseId"
            name="warehouseId"
            className="form-control"
            onChange={changeWarehouse}
          >
            {warehouseOptions.map((option) => {
              return (
                <option key={option.value} value={option.value}>
                  {option.key}
                </option>
              );
            })}
          </select>
          <Bar
            className={classes.root}
            data={data}
            options={options}
            onClick={onClick}
            ref={chartRef}
            height={193.5}
          />
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    warehouseOccupancy: state.warehouseOccupancyHomePage,
    warehouses: state.warehouses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWarehouse: (id) => dispatch(getWarehouse(id)),
    getWarehouses: () => dispatch(getWarehouses()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WarehouseHomePageCard);

// let label;

// if (context.dataset.label === "Empty") {
//   label = `Empty : ${context.parsed.y}`;
// }
// if (context.dataset.label === "Occupied") {
//   label = `Occupied : ${context.parsed.y}   Items : ${
//     context.dataset.items[context.dataIndex]
//   }`;
// }

// return label;
