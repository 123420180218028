import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import dashboardCard from "../../style/dashboardCard";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { getYard, getWarehouses } from "../../store";

import "chart.js/auto";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import { useRef } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => dashboardCard(theme));

const FgWarehouseHomePageCard = ({
  getYard,
  yardOccupancy,
  getWarehouses,
  warehouses,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [warehouseId, setWarehouseId] = useState("");

  useEffect(() => {
    getYard(warehouseId);
  }, [warehouseId]);

  useEffect(() => {
    getWarehouses();
  }, []);

  const [warehouseOptions, setWarehouseOptions] = useState([]);

  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    setWarehouseId(data[0]?.id);
    data.map((warehouse) => {
      if (warehouse.type === "f") {
        tempWarehouseOptions.push({
          key: warehouse.warehouseName,
          value: warehouse.id,
        });
      }
    });
    setWarehouseId(tempWarehouseOptions[0]?.value);
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  const [emptyLocations, setEmptyLocations] = useState("");
  const [occupiedLocations, setOccupiedLocations] = useState("");
  const [itemQty, setItemQty] = useState("");

  useEffect(() => {
    setEmptyLocations(yardOccupancy.warehouses[0]?.emptyLocations);
    setOccupiedLocations(yardOccupancy.warehouses[0]?.occupiedLocations);
    setItemQty(yardOccupancy.warehouses[0]?.occupiedQty);
  }, [yardOccupancy]);

  const data = {
    labels: ["Empty", "Occupied"],
    datasets: [
      {
        data: [emptyLocations, occupiedLocations],
        items: itemQty,
        borderColor: ["green", "red"],
        backgroundColor: ["#00FFAB", "#FF4949"],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        backgroundColor: "#fff",
        bodyColor: "#000",
        borderColor: "#000",
        bodyFont: { size: 15 },
        borderWidth: 1,
        padding: 10,
        titleColor: "#000",
        titleFont: { weight: "bold", size: 20 },
        yAlign: "bottom",
        borderColor: "red",

        callbacks: {
          label: function (context) {
            let label;
            if (context.label === "Empty") {
              label = `Empty : ${context.parsed.y}`;
            }
            if (context.label === "Occupied") {
              label = `Occupied : ${context.parsed.y}   Qty : ${context.dataset.items}`;
            }
            return label;
          },
        },
      },
    },
    title: {
      display: true,
      text: "Bar Chart",
    },
    // scales: {
    //   yAxes: [
    //     {
    //       ticks: {
    //         min: 0,
    //         max: 6,
    //         stepSize: 1,
    //       },
    //     },
    //   ],
    // },
  };

  const chartRef = useRef();
  const onClick = (event) => {
    history.push(`/yard_occupancy`);
  };

  const changeWarehouse = (e) => {
    setWarehouseId(e.target.value);
  };

  return (
    <div>
      <Card>
        <CardContent>
          <h4>Yard Occupancy</h4>
          <hr />
          <select
            as="select"
            id="warehouseId"
            name="warehouseId"
            className="form-control"
            onChange={changeWarehouse}
          >
            {warehouseOptions.map((option) => {
              return (
                <option key={option.value} value={option.value}>
                  {option.key}
                </option>
              );
            })}
          </select>
          <Bar
            className={classes.root}
            data={data}
            options={options}
            onClick={onClick}
            ref={chartRef}
            height={193.5}
          />
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    yardOccupancy: state.getYardOccupancyHomePage,
    warehouses: state.warehouses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getYard: (id) => dispatch(getYard(id)),
    getWarehouses: () => dispatch(getWarehouses()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FgWarehouseHomePageCard);

// let label;

// if (context.dataset.label === "Empty") {
//   label = `Empty : ${context.parsed.y}`;
// }
// if (context.dataset.label === "Occupied") {
//   label = `Occupied : ${context.parsed.y}   Items : ${
//     context.dataset.items[context.dataIndex]
//   }`;
// }

// return label;
