import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { makeStyles } from "@material-ui/core/styles";
import drawerCss from "../../style/drawer";
import classNames from "classnames";
import { connect } from "react-redux";
import {
  updateSelectedItem,
  getItemMaster,
  editItemMaster,
  getBinsByItemCode,
  uploadCsv,
} from "../../store";
import MaterialTable from "material-table";

import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../utils/TextError";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import Config from "../../config/index";
import ReactExport from "react-data-export";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const desLength = Config.descriptionLength;

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

const ItemMaster = ({
  drawerState,
  handleListItemClick,
  getItemMaster,
  itemMaster,
  getBinsByItemCode,
  binsByItemCode,
  editItemMaster,
  uploadCsv,
}) => {
  useEffect(() => {
    handleListItemClick(null, 4);
  }, []);

  const classes = useStyles();
  let drawer = drawerState;

  let loginDetails = cookies.get("loginDetails");
  let role = loginDetails.data.role;

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

  useEffect(() => {
    getItemMaster();
  }, []);

  const [itemId, setItemId] = useState("");
  const [binId, setBinId] = useState("");
  const [tableHeading, setTableHeading] = useState("");

  useEffect(() => {
    getBinsByItemCode(itemId);
  }, [itemId]);

  const [state, setState] = useState({
    columns: [
      { title: "Item Code/Des", field: "itemCodeDes" },
      { title: "Category Code/Des", field: "categoryCodeDes" },
      { title: "UOM", field: "uom" },
      { title: "BIN", field: "bin" },
      { title: "Minimum Qty", field: "minQty" },
      { title: "Threshold Qty", field: "thresholdQty" },
      { title: "Action", field: "action", filtering: false, export: false },
    ],
    data: [],
  });

  const [thresholdQtyModal, setOpenThresholdQtyModal] = useState(false);

  const handleOpenThresholdQtyModal = () => {
    setOpenThresholdQtyModal(!thresholdQtyModal);
  };

  const [thresholdQtyTable, setThresholdQtyTable] = useState({
    columns: [
      { title: "BIN", field: "bin" },
      { title: "Minimum Qty", field: "minQty" },
      { title: "Threshold Qty", field: "thresholdQty" },
      { title: "Action", field: "action" },
    ],
    data: [],
  });

  useEffect(() => {
    let tempItemMaster = [];
    let data = itemMaster.itemMaster ? itemMaster.itemMaster : [];
    data.map((item) => {
      let des = item?.itemDescription;
      let len = desLength;
      const itemObject = {
        itemCodeDes: `${item.itemCode} / ${des ? des.substring(0, len) : ""}`,
        categoryCodeDes: `${item.categoryCode} / ${item.categoryDescription}`,
        uom: item.uom,
        bin: item.locationName,
        thresholdQty: item.thresholdQty,
        minQty: item.minQty,
        action: (
          <button
            type="button"
            className="btn btn-danger"
            // disabled={role !== "admin" ? true : false}
            title="Edit"
            onClick={() => {
              setItemId(item.id);
              setBinId(item.locationId);
              handleOpenEditSingleItemMasterModal(item);
            }}
          >
            <i className="fa fa-pencil"></i>
          </button>
        ),
      };
      tempItemMaster.push(itemObject);
    });
    setState({ ...state, data: tempItemMaster });
  }, [itemMaster]);

  // const DataSet = [
  //   {
  //     columns: [
  //       {
  //         title: "Item Code",
  //         style: { font: { sz: "18", bold: true } },
  //         width: { wpx: 125 },
  //       },
  //       {
  //         title: "Description",
  //         style: { font: { sz: "18", bold: true } },
  //         width: { wch: 30 },
  //       },
  //       {
  //         title: "UOM",
  //         style: { font: { sz: "18", bold: true } },
  //         width: { wpx: 100 },
  //       },
  //     ],
  //     data: itemMaster.itemMaster.map((item) => [
  //       { value: item.itemCode },
  //       { value: item.itemDescription },
  //       { value: item.uom },
  //     ]),
  //   },
  // ];

  useEffect(() => {
    let tempBins = [];
    let data = binsByItemCode.bins ? binsByItemCode.bins : [];

    data.map((bin) => {
      const binInfo = {
        bin: bin.locationName,
        thresholdQty: bin.thresholdQty,
        minQty: bin.minQty,
        action: (
          <button
            type="button"
            className="btn btn-danger"
            disabled={role !== "admin" ? true : false}
            title="Edit"
            onClick={() => {
              setBinId(bin.id);
              handleOpenEditSingleItemMasterModal(bin);
            }}
          >
            <i className="fa fa-pencil"></i>
          </button>
        ),
      };
      tempBins.push(binInfo);
    });
    setThresholdQtyTable({ ...thresholdQtyTable, data: tempBins });
  }, [binsByItemCode]);

  const [isSuccessEditThreshold, setIsSuccessEditThreshold] = useState(false);
  const [isErrorEditThreshold, setIsErrorEditThreshold] = useState(false);

  const [editThresholdModal, setEditThresholdModal] = useState(false);
  const handleEditThresholdModal = () => {
    setEditThresholdModal(!editThresholdModal);
  };

  const [singleItemMasterDataForEdit, setSingleItemMasterDataForEdit] =
    useState({});

  const handleOpenEditSingleItemMasterModal = (data = {}) => {
    setSingleItemMasterDataForEdit(data);
    setEditThresholdModal(true);
  };

  const initialValueForEditThreshold = {
    minQty: singleItemMasterDataForEdit?.minQty,
    thresholdQty: singleItemMasterDataForEdit?.thresholdQty,
  };

  const validationSchemaForEditThreshold = Yup.object({
    minQty: Yup.number().required("Enter the Minimum Qty"),
    thresholdQty: Yup.number().required("Enter the Threshold Qty"),
  });

  const onSubmitForEditThreshold = async (values, { resetForm }) => {
    // singleItemMasterDataForEdit.thresholdQty = values.thresholdQty;

    const payload = {
      itemId: itemId,
      thresholdQty: values.thresholdQty,
      locationId: binId,
      minQty: values.minQty,
    };

    let res = await editItemMaster(payload);

    if (res.status) {
      setIsSuccessEditThreshold(true);
      getItemMaster();
      // getBinsByItemCode(itemId);
    }
    if (!res.status) {
      setIsErrorEditThreshold(true);
    }
    // resetForm();
  };

  const [uploadCsvFilter, setUploadCsvFilter] = useState(false);

  const uploadCsvFilterHandler = () => {
    setUploadCsvFilter(!uploadCsvFilter);
  };

  const [uploadCsvData, setUploadCsvData] = useState({});

  const uploadCsvHandler = async () => {
    let data = new FormData();

    data.append("grn", uploadCsvData);

    let res = await uploadCsv(data);

    if (res.status) {
      getItemMaster();
      setUploadCsvFilter(false);
    }
  };

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title={
                <div>
                  <br />
                  <h5>Item Master</h5>
                  <br />
                  {/* <ExcelFile
                    filename="Item Master Details"
                    element={
                      <button type="button" className="btn btn-success">
                        Export Data
                      </button>
                    }
                  >
                    <ExcelSheet dataSet={DataSet} name="Item Master" />
                  </ExcelFile> */}
                  {/* <input
                    className="aadithya"
                    type="file"
                    accept=".csv"
                  /> */}
                  {uploadCsvFilter && (
                    <div>
                      <input
                        className="upload-box"
                        type="file"
                        accept=".csv"
                        onChange={(e) => {
                          setUploadCsvData(e.target.files[0]);
                        }}
                      />
                      <button
                        className="uploadsubmit"
                        type="button"
                        onClick={uploadCsvHandler}
                      >
                        UPLOAD
                      </button>
                    </div>
                  )}
                </div>
              }
              columns={state.columns}
              data={state.data}
              options={{
                draggable: false,
                exportButton: true,
                exportAllData: true,
                exportFileName: `Item Master`,
                filtering: true,
              }}
              actions={[
                {
                  icon: "upload",
                  tooltip: "Upload CSV",
                  isFreeAction: true,
                  onClick: () => uploadCsvFilterHandler(),
                },
              ]}
            />

            <div>
              <Modal
                show={thresholdQtyModal}
                onHide={handleOpenThresholdQtyModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
                size="xl"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Threshold Qty</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <MaterialTable
                          title={`Item Code: ${tableHeading}`}
                          columns={thresholdQtyTable.columns}
                          data={thresholdQtyTable.data}
                          options={{ draggable: false }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                show={editThresholdModal}
                onHide={handleEditThresholdModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Formik
                          initialValues={initialValueForEditThreshold}
                          validationSchema={validationSchemaForEditThreshold}
                          onSubmit={onSubmitForEditThreshold}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="minQty">Minimum Qty</label>
                                  <Field
                                    type="number"
                                    name="minQty"
                                    className="form-control"
                                    autocomplete="off"
                                  />
                                  <ErrorMessage
                                    name="minQty"
                                    component={TextError}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="thresholdQty">
                                    Threshold Qty
                                  </label>
                                  <Field
                                    type="number"
                                    name="thresholdQty"
                                    className="form-control"
                                    autocomplete="off"
                                  />
                                  <ErrorMessage
                                    name="thresholdQty"
                                    component={TextError}
                                  />
                                </div>
                                <div className="mt-3">
                                  <Collapse in={isErrorEditThreshold}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsErrorEditThreshold(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Could not edit
                                    </Alert>
                                  </Collapse>
                                  <Collapse in={isSuccessEditThreshold}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccessEditThreshold(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Edited successfully
                                    </Alert>
                                  </Collapse>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                    disabled={!formik.isValid}
                                  >
                                    Edit
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    itemMaster: state.itemMaster,
    binsByItemCode: state.binsByItemCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getItemMaster: () => dispatch(getItemMaster()),
    editItemMaster: (payload) => dispatch(editItemMaster(payload)),
    getBinsByItemCode: (id) => dispatch(getBinsByItemCode(id)),
    uploadCsv: (payload) => dispatch(uploadCsv(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemMaster);

{
  /*  */
}
