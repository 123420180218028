import {
  GET_ITEMALLOCATION_REQUEST,
  GET_ITEMALLOCATION_SUCCESS,
  GET_ITEMALLOCATION_FAILURE,
  ADD_ITEMALLOCATION_REQUEST,
  ADD_ITEMALLOCATION_SUCCESS,
  ADD_ITEMALLOCATION_FAILURE,
  REMOVE_ITEMALLOCATION_REQUEST,
  REMOVE_ITEMALLOCATION_SUCCESS,
  REMOVE_ITEMALLOCATION_FAILURE,
  EDIT_ITEMALLOCATION_REQUEST,
  EDIT_ITEMALLOCATION_SUCCESS,
  EDIT_ITEMALLOCATION_FAILURE,
} from "./ItemAllocationTypes";

const initialState = {
  loading: false,
  bins: [],
  error: "",
};

const getAllocatedItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ITEMALLOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ITEMALLOCATION_SUCCESS:
      return {
        loading: false,
        bins: action.payload,
        error: "",
      };
    case GET_ITEMALLOCATION_FAILURE:
      return {
        loading: false,
        bins: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAddBin = {
  loading: false,
  bin: [],
  error: "",
};

const addBinAllocationReducer = (state = initialStateForAddBin, action) => {
  switch (action.type) {
    case ADD_ITEMALLOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_ITEMALLOCATION_SUCCESS:
      return {
        loading: false,
        bin: action.payload,
        error: "",
      };
    case ADD_ITEMALLOCATION_FAILURE:
      return {
        loading: false,
        bin: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForRemoveBin = {
  loading: false,
  bin: [],
  error: "",
};

const removeBinAllocationReducer = (
  state = initialStateForRemoveBin,
  action
) => {
  switch (action.type) {
    case REMOVE_ITEMALLOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_ITEMALLOCATION_SUCCESS:
      return {
        loading: false,
        bin: action.payload,
        error: "",
      };
    case REMOVE_ITEMALLOCATION_FAILURE:
      return {
        loading: false,
        bin: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForEditBinAllocation = {
  loading: false,
  itemAllocation: [],
  error: "",
};

const editBinAllocationReducer = (
  state = initialStateForEditBinAllocation,
  action
) => {
  switch (action.type) {
    case EDIT_ITEMALLOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_ITEMALLOCATION_SUCCESS:
      return {
        loading: false,
        itemAllocation: action.payload,
        error: "",
      };
    case EDIT_ITEMALLOCATION_FAILURE:
      return {
        loading: false,
        itemAllocation: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getAllocatedItemsReducer,
  addBinAllocationReducer,
  removeBinAllocationReducer,
  editBinAllocationReducer,
};
