/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_USER_ROLES_REQUEST,
  GET_USER_ROLES_SUCCESS,
  GET_USER_ROLES_FAILURE,
  CREATE_USER_ROLE_REQUEST,
  CREATE_USER_ROLE_SUCCESS,
  CREATE_USER_ROLE_FAILURE,
  UPDATE_USER_ROLE_REQUEST,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_FAILURE,
  DELETE_USER_ROLE_REQUEST,
  DELETE_USER_ROLE_SUCCESS,
  DELETE_USER_ROLE_FAILURE,
  GET_PRODUCTS_BY_CLIENT_ID_REQUEST,
  GET_PRODUCTS_BY_CLIENT_ID_SUCCESS,
  GET_PRODUCTS_BY_CLIENT_ID_FAILURE,
  GET_USER_ROLES_BY_DEPARTMENT_REQUEST,
  GET_USER_ROLES_BY_DEPARTMENT_SUCCESS,
  GET_USER_ROLES_BY_DEPARTMENT_FAILURE,
} from "./userRolesTypes";
import axios from "axios";
import Config from "../../../config/index";
let userManagementUrl = Config.userManagementUrl;
let licensesUrl = Config.licensesUrl;
// let userManagementUrl = `http://13.71.2.248:1338/api/v2/users_management`;

const getUserRolesRequest = () => {
  return {
    type: GET_USER_ROLES_REQUEST,
  };
};

const getUserRolesSuccess = (payload) => {
  return {
    type: GET_USER_ROLES_SUCCESS,
    payload: payload,
  };
};

const getUserRolesFaliure = (error) => {
  return {
    type: GET_USER_ROLES_FAILURE,
    payload: error,
  };
};

const getUserRoles = () => {
  return (dispatch) => {
    dispatch(getUserRolesRequest());
    let url = `${userManagementUrl}/users_management/roles/get_roles`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getUserRolesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getUserRolesFaliure(err.message));
      });
  };
};

const createUserRoleRequest = () => {
  return {
    type: CREATE_USER_ROLE_REQUEST,
  };
};

const createUserRoleSuccess = (payload) => {
  return {
    type: CREATE_USER_ROLE_SUCCESS,
    payload: payload,
  };
};

const createUserRoleFaliure = (error) => {
  return {
    type: CREATE_USER_ROLE_FAILURE,
    payload: error,
  };
};

const createUserRole = (payload) => {
  return (dispatch) => {
    dispatch(createUserRoleRequest());
    let url = `${userManagementUrl}/users_management/roles/create_role`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(createUserRoleSuccess(response.data));
        return response.data;
      })
      .catch((err) => {
        dispatch(createUserRoleFaliure(err.message));
        return err.message;
      });
  };
};

const updateUserRoleRequest = () => {
  return {
    type: UPDATE_USER_ROLE_REQUEST,
  };
};

const updateUserRoleSuccess = (payload) => {
  return {
    type: UPDATE_USER_ROLE_SUCCESS,
    payload: payload,
  };
};

const updateUserRoleFaliure = (error) => {
  return {
    type: UPDATE_USER_ROLE_FAILURE,
    payload: error,
  };
};

const updateUserRole = (payload) => {
  return (dispatch) => {
    dispatch(updateUserRoleRequest());
    let url = `${userManagementUrl}/users_management/roles/update_role`;
    return axios
      .put(url, payload)
      .then((response) => {
        dispatch(updateUserRoleSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateUserRoleFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const deleteUserRoleRequest = () => {
  return {
    type: DELETE_USER_ROLE_REQUEST,
  };
};

const deleteUserRoleSuccess = (payload) => {
  return {
    type: DELETE_USER_ROLE_SUCCESS,
    payload: payload,
  };
};

const deleteUserRoleFaliure = (error) => {
  return {
    type: DELETE_USER_ROLE_FAILURE,
    payload: error,
  };
};

const deleteUserRole = (userRoleId) => {
  return (dispatch) => {
    dispatch(deleteUserRoleRequest());
    let url = `${userManagementUrl}/users_management/roles/delete_role?roleId=${userRoleId}`;
    return axios
      .put(url, {})
      .then((response) => {
        dispatch(deleteUserRoleSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteUserRoleFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const getProductsRequest = () => {
  return {
    type: GET_PRODUCTS_BY_CLIENT_ID_REQUEST,
  };
};

const getProductsSuccess = (payload) => {
  return {
    type: GET_PRODUCTS_BY_CLIENT_ID_SUCCESS,
    payload: payload,
  };
};

const getProductsFaliure = (error) => {
  return {
    type: GET_PRODUCTS_BY_CLIENT_ID_FAILURE,
    payload: error,
  };
};

const getProducts = (clientId) => {
  return (dispatch) => {
    dispatch(getProductsRequest());
    let url = `${licensesUrl}/orders_management/orders/get_products_by_clientId?client_id=${clientId}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getProductsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getProductsFaliure(err.message));
      });
  };
};

const getUserRolesByDepartmentRequest = () => {
  return {
    type: GET_USER_ROLES_BY_DEPARTMENT_REQUEST,
  };
};

const getUserRolesByDepartmentSuccess = (payload) => {
  return {
    type: GET_USER_ROLES_BY_DEPARTMENT_SUCCESS,
    payload: payload,
  };
};

const getUserRolesByDepartmentFaliure = (error) => {
  return {
    type: GET_USER_ROLES_BY_DEPARTMENT_FAILURE,
    payload: error,
  };
};

const getUserRolesByDepartment = (departmentId) => {
  return (dispatch) => {
    dispatch(getUserRolesByDepartmentRequest());
    let url = `${userManagementUrl}/users_management/roles/get_roles_by_department?departmentId=${departmentId}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getUserRolesByDepartmentSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getUserRolesByDepartmentFaliure(err.message));
      });
  };
};

export {
  getUserRoles,
  createUserRole,
  updateUserRole,
  deleteUserRole,
  getProducts,
  getUserRolesByDepartment,
};
