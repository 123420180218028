/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { combineReducers } from "redux";
import {
  drawerState,
  warehousesState,
  userManagementModuleState,
  reportsState,
} from "./Header/headerRedux";
import { setecedItem } from "./Header/headerSelected";
import {
  getLocationsByWarehouseReducer,
  addLocationReducer,
  getItemByLocationReducer,
  getLocationsByWarehouseAndZoneReducer,
  deleteLocationReducer,
} from "./ViewLocation/ViewLocationReducer";
import {
  getWarehousesReducer,
  getWarehouseByIdReducer,
  updateWarehouseReducer,
} from "./Warehouse/WarehouseReducer";
import {
  getAllocatedZonesReducer,
  addZoneAllocationReducer,
  removeZoneAllocationReducer,
} from "./ZoneAllocation/ZoneAllocationReducer";
import {
  getZonesByCategoryAndWarehouseReducer,
  getOpenCategoryZonesByWarehouseReducer,
  getAllCategoriesReducer,
  addZoneToWarehouseReducer,
  getZonesByWarehouseReducer,
  getOpenBinsByWarehouseReducer,
} from "./ZonesAndCategories/ZoneCategoryReducer";
import { getAllGRNReducer, getLineItemsByGrnReducer } from "./GRN/GRNReducer";
import {
  getSkuForReprintReducer,
  reprintReducer,
} from "./Reprint/ReprintReducer";
import {
  individualPrintReducer,
  packagePrintReducer,
  transferOrderIndividualPrintReducer,
  transferOrderPackagePrintReducer,
} from "./LabelPrinting/LabelPrintingReducer";
import {
  getItemMasterReducer,
  editItemMasterReducer,
  getBinsByItemCodeReducer,
  uploadCsvReducer,
} from "./ItemMaster/ItemMasterReducer";
import {
  createPickupListReducer,
  createPickupListPutRequestReducer,
  pickupListReducer,
  getPickupItemReducer,
} from "./PickupList/PickupListReducer";
import {
  getAllocatedItemsReducer,
  addBinAllocationReducer,
  removeBinAllocationReducer,
  editBinAllocationReducer,
} from "./ItemAllocation/ItemAllocationReducer";
import {
  getFinishedGoodsReducer,
  getVinByFgCodeReducer,
} from "./FinishedGoods/FinishedGoodsReducer";
import {
  getSalesOrderReducer,
  approveVinReducer,
  getVinStatusByTripNoReducer,
} from "./Dispatch/DispatchReducer";
import {
  getRejectionsReducer,
  acceptRejectionReducer,
  createRejectionReducer,
} from "./Rejections/RejectionsReducer";
import {
  getInventoryReducer,
  getPickupReducer,
  getWarehouseReducer,
  getYardReducer,
  getFgReducer,
  getPdiReducer,
  getRejectionReducer,
} from "./Home/HomeReducers";
import { getWarehouseOccupancyReducer } from "./Reports/WarehouseOccupancy/WarehouseOccupancyReducers";
import { getYardOccupancyReducer } from "./Reports/YardOccupancy/YardOccupancyReducers";
import { getRejectionAgeingReducer } from "./Reports/RejectionAgeing/RejectionAgeingReducers";
import { getGrnTimestampReducer } from "./Reports/GrnTimestamp/GrnTimestampReducers";
import { getPicklistAgeingReducer } from "./Reports/PicklistAgeing/PicklistAgeingReducers";
import { getInventoryAgeingReducer } from "./Reports/InventoryAgeing/InventoryAgeingReducers";
import { getFgAgeingReducer } from "./Reports/FgAgeing/FgAgeingReducers";

import loginReducer from "./UserManagement/Login/loginReducer";
import usersReducer from "./UserManagement/Users/userReducer";
import addUserReducer from "./UserManagement/Users/addUserReducer";
import resetPasswordReducer from "./UserManagement/Reset_password/resetPasswordReducer";
import profileReducer from "./UserManagement/Profile/profileReducer";
import deleteUserReducer from "./UserManagement/Users/deleteUserReducer";
import forgotPasswordReducer from "./UserManagement/ForgotPassword/forgotPasswordReducer";
import setPasswordReducer from "./UserManagement/SetPassword/setPasswordReducer";
import setPasswordForNewUserReducer from "./UserManagement/SetPasswordForNewUser/setPasswordForNewUserReducer";
import {
  getSkuByItemCodeAndWarehouseCodeReducer,
  getAllTransferOrdersReducer,
} from "./TransferOrder/TransferOrderReducer";
import printersReducer from "./PrinterOnboarding/printerOnboardingReducer";
import devicesReducer from "./DeviceOnboarding/deviceOnboardingReducer";
import {
  getDepartmentsReducer,
  createDepartmentReducer,
  updateDepartmentReducer,
  deleteDepartmentReducer,
} from "./UserManagementModule/Department/departmentReducer";
import {
  getUserRolesReducer,
  createUserRoleReducer,
  updateUserRoleReducer,
  deleteUserRoleReducer,
  getProductsReducer,
  getUserRolesByDepartment,
} from "./UserManagementModule/UserRoles/userRolesReducer";
import { getModulesReducer } from "./UserManagementModule/Modules/modulesReducer";
import {
  getUsersDetailsReducer,
  createUserDetailsReducer,
  updateUserDetailsReducer,
  deleteUserDetailsReducer,
} from "./UserManagementModule/UserDetails/userDetailsReducer";
import { getLoginIdsReducer } from "./UserManagementModule/LoginId/loginIdReducer";

const rootReducer = combineReducers({
  drawerState: drawerState,
  setecedItem: setecedItem,
  locationsByWarehouse: getLocationsByWarehouseReducer,
  locationsByWarehouseAndZone: getLocationsByWarehouseAndZoneReducer,
  deleteLocation: deleteLocationReducer,
  addLocationToWarehouse: addLocationReducer,
  item: getItemByLocationReducer,
  warehouses: getWarehousesReducer,
  warehousesState: warehousesState,
  warehouseById: getWarehouseByIdReducer,
  updateWarehouse: updateWarehouseReducer,
  allocatedZones: getAllocatedZonesReducer,
  addZoneAllocation: addZoneAllocationReducer,
  removeZoneAllocation: removeZoneAllocationReducer,
  zonesByCategoryAndWarehouse: getZonesByCategoryAndWarehouseReducer,
  openCategoryZones: getOpenCategoryZonesByWarehouseReducer,
  zonesByWarehouse: getZonesByWarehouseReducer,
  openBinsByWarehouse: getOpenBinsByWarehouseReducer,
  allCategories: getAllCategoriesReducer,
  addZoneToWarehouse: addZoneToWarehouseReducer,
  allGRN: getAllGRNReducer,
  lineItemsByGrn: getLineItemsByGrnReducer,
  skuForReprint: getSkuForReprintReducer,
  reprintReducer: reprintReducer,
  individualLabelPrint: individualPrintReducer,
  packageLabelPrint: packagePrintReducer,
  transferOrderIndividualPrint: transferOrderIndividualPrintReducer,
  transferOrderPackagePrint: transferOrderPackagePrintReducer,
  itemMaster: getItemMasterReducer,
  binsByItemCode: getBinsByItemCodeReducer,
  uploadCsvState: uploadCsvReducer,
  editItemMaster: editItemMasterReducer,
  createPickupList: createPickupListReducer,
  createPickupListPutRequest: createPickupListPutRequestReducer,
  pickupList: pickupListReducer,
  pickupItem: getPickupItemReducer,
  allocatedBins: getAllocatedItemsReducer,
  addBinAllocation: addBinAllocationReducer,
  removeBinAllocation: removeBinAllocationReducer,
  editBinAllocation: editBinAllocationReducer,
  finishedGoods: getFinishedGoodsReducer,
  vin: getVinByFgCodeReducer,
  salesOrder: getSalesOrderReducer,
  approveVin: approveVinReducer,
  vinStatus: getVinStatusByTripNoReducer,
  rejections: getRejectionsReducer,
  acceptRejection: acceptRejectionReducer,
  createRejectionState: createRejectionReducer,
  skuByItemCodeAndWarehouseCode: getSkuByItemCodeAndWarehouseCodeReducer,
  transferOrders: getAllTransferOrdersReducer,
  printersReducer: printersReducer,
  devicesReducer: devicesReducer,
  userManagementModuleState: userManagementModuleState,
  getDepartmentsReducer: getDepartmentsReducer,
  createDepartmentReducer: createDepartmentReducer,
  updateDepartmentReducer: updateDepartmentReducer,
  deleteDepartmentReducer: deleteDepartmentReducer,
  getUserRolesReducer: getUserRolesReducer,
  createUserRoleReducer: createUserRoleReducer,
  updateUserRoleReducer: updateUserRoleReducer,
  deleteUserRoleReducer: deleteUserRoleReducer,
  modules: getModulesReducer,
  getUsersDetailsReducer: getUsersDetailsReducer,
  createUserDetailsReducer: createUserDetailsReducer,
  updateUserDetailsReducer: updateUserDetailsReducer,
  deleteUserDetailsReducer: deleteUserDetailsReducer,
  products: getProductsReducer,
  getLoginIdsReducer: getLoginIdsReducer,
  getUserRolesByDepartment: getUserRolesByDepartment,
  getInventoryHomePage: getInventoryReducer,
  getPickupListHomePage: getPickupReducer,
  warehouseOccupancyHomePage: getWarehouseReducer,
  getYardOccupancyHomePage: getYardReducer,
  getFinishedGoodsHomePage: getFgReducer,
  getPdiHomePage: getPdiReducer,
  getRejectionHomePage: getRejectionReducer,
  reportsState: reportsState,
  getWarehouseOccupancy: getWarehouseOccupancyReducer,
  getYardOccupancy: getYardOccupancyReducer,
  getRejectionAgeing: getRejectionAgeingReducer,
  getGrnTimestamp: getGrnTimestampReducer,
  getPicklistAgeing: getPicklistAgeingReducer,
  getInventoryAgeing: getInventoryAgeingReducer,
  getFgAgeing: getFgAgeingReducer,

  login: loginReducer,
  users: usersReducer,
  user: addUserReducer,
  resetPassword: resetPasswordReducer,
  profile: profileReducer,
  deleteUser: deleteUserReducer,
  forgotPassword: forgotPasswordReducer,
  setPasswordReducer: setPasswordReducer,
  setPasswordForNewUserReducer: setPasswordForNewUserReducer,
});

export default rootReducer;
