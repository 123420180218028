import {
  GETLOCATION_BYWAREHOUSE_REQUEST,
  GETLOCATION_BYWAREHOUSE_SUCCESS,
  GETLOCATION_BYWAREHOUSE_FAILURE,
  ADDLOCATION_TOWAREHOUSE_REQUEST,
  ADDLOCATION_TOWAREHOUSE_SUCCESS,
  ADDLOCATION_TOWAREHOUSE_FAILURE,
  GETITEM_BYLOCATION_REQUEST,
  GETITEM_BYLOCATION_SUCCESS,
  GETITEM_BYLOCATION_FAILURE,
  GETLOCATION_BYWAREHOUSEANDZONE_REQUEST,
  GETLOCATION_BYWAREHOUSEANDZONE_SUCCESS,
  GETLOCATION_BYWAREHOUSEANDZONE_FAILURE,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
} from "./ViewLocationTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getLocationsByWarehouseRequest = () => {
  return {
    type: GETLOCATION_BYWAREHOUSE_REQUEST,
  };
};

const getLocationsByWarehouseSuccess = (payload) => {
  return {
    type: GETLOCATION_BYWAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const getLocationsByWarehouseFailure = (error) => {
  return {
    type: GETLOCATION_BYWAREHOUSE_FAILURE,
    payload: error,
  };
};

const getLocationsByWarehouse = (warehouseId, categoryId, zoneId) => {
  return (dispatch) => {
    dispatch(getLocationsByWarehouseRequest());
    let url = `${baseUrl}/location/filter_location?warehouse_id=${warehouseId}${
      categoryId ? `&category_id=${categoryId}` : ``
    }${zoneId ? `&zone_id=${zoneId}` : ""}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getLocationsByWarehouseSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getLocationsByWarehouseFailure(err.message));
      });
  };
};

const addLocationToWarehouseRequest = () => {
  return {
    type: ADDLOCATION_TOWAREHOUSE_REQUEST,
  };
};

const addLocationToWarehouseSuccess = (payload) => {
  return {
    type: ADDLOCATION_TOWAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const addLocationToWarehouseFailure = (error) => {
  return {
    type: ADDLOCATION_TOWAREHOUSE_FAILURE,
    payload: error,
  };
};

const addLocationToWarehouse = (payload) => {
  return (dispatch) => {
    dispatch(addLocationToWarehouseRequest());
    let url = `${baseUrl}/location`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(addLocationToWarehouseSuccess(response.data));
        return { status: true, data: response.data.data };
      })
      .catch((err) => {
        dispatch(addLocationToWarehouseFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const getItemByLocationRequest = () => {
  return {
    type: GETITEM_BYLOCATION_REQUEST,
  };
};

const getItemByLocationSuccess = (payload) => {
  return {
    type: GETITEM_BYLOCATION_SUCCESS,
    payload: payload,
  };
};

const getItemByLocationFailure = (error) => {
  return {
    type: GETITEM_BYLOCATION_FAILURE,
    payload: error,
  };
};

const getItemByLocation = (warehouseId, zoneId, locationId, warehouseType) => {
  return (dispatch) => {
    dispatch(getItemByLocationRequest());
    let url;
    if (warehouseType === "f") {
      url = `${baseUrl}/fg/stored_item?warehouse_id=${warehouseId}&zone_id=${zoneId}&location_id=${locationId}`;
    } else {
      url = `${baseUrl}/putaway/stored_item?warehouse_id=${warehouseId}&zone_id=${zoneId}&location_id=${locationId}&type=${warehouseType}`;
    }

    axios
      .get(url)
      .then((response) => {
        dispatch(getItemByLocationSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getItemByLocationFailure(err.message));
      });
  };
};

const getLocationsByWarehouseAndZoneRequest = () => {
  return {
    type: GETLOCATION_BYWAREHOUSEANDZONE_REQUEST,
  };
};

const getLocationsByWarehouseAndZoneSuccess = (payload) => {
  return {
    type: GETLOCATION_BYWAREHOUSEANDZONE_SUCCESS,
    payload: payload,
  };
};

const getLocationsByWarehouseAndZoneFailure = (error) => {
  return {
    type: GETLOCATION_BYWAREHOUSEANDZONE_FAILURE,
    payload: error,
  };
};

const getLocationsByWarehouseAndZone = (warehouseId, categoryId, zoneId) => {
  return (dispatch) => {
    dispatch(getLocationsByWarehouseAndZoneRequest());

    let url = `${baseUrl}/location/by_wh?warehouse_id=${warehouseId}${
      categoryId ? `&category_id=${categoryId}` : ``
    }${zoneId ? `&zone_id=${zoneId}` : ""}`;

    axios
      .get(url)
      .then((response) => {
        dispatch(getLocationsByWarehouseAndZoneSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getLocationsByWarehouseAndZoneFailure(err.message));
      });
  };
};

const deleteLocationRequest = () => {
  return {
    type: DELETE_LOCATION_REQUEST,
  };
};

const deleteLocationSuccess = (payload) => {
  return {
    type: DELETE_LOCATION_SUCCESS,
    payload: payload,
  };
};

const deleteLocationFaliure = (error) => {
  return {
    type: DELETE_LOCATION_FAILURE,
    payload: error,
  };
};

const deleteLocation = (id) => {
  return (dispatch) => {
    let url = `${baseUrl}/location?location_id=${id}`;
    dispatch(deleteLocationRequest());
    return axios
      .delete(url)
      .then((response) => {
        dispatch(deleteLocationSuccess(response.data));
        return { status: true };
      })
      .catch((error) => {
        dispatch(deleteLocationFaliure(error.message));
        return { status: false };
      });
  };
};

export {
  getLocationsByWarehouse,
  addLocationToWarehouse,
  getItemByLocation,
  getLocationsByWarehouseAndZone,
  deleteLocation,
};
