import {
  GETZONES_BYCATEGORYANDWAREHOUSE_REQUEST,
  GETZONES_BYCATEGORYANDWAREHOUSE_SUCCESS,
  GETZONES_BYCATEGORYANDWAREHOUSE_FAILURE,
  GETOPENCATEGORYZONES_BYWAREHOUSE_REQUEST,
  GETOPENCATEGORYZONES_BYWAREHOUSE_SUCCESS,
  GETOPENCATEGORYZONES_BYWAREHOUSE_FAILURE,
  GETALLCATEGORIES_REQUEST,
  GETALLCATEGORIES_SUCCESS,
  GETALLCATEGORIES_FAILURE,
  ADDZONE_TOWAREHOUSE_REQUEST,
  ADDZONE_TOWAREHOUSE_SUCCESS,
  ADDZONE_TOWAREHOUSE_FAILURE,
  GETZONES_BYWAREHOUSE_REQUEST,
  GETZONES_BYWAREHOUSE_SUCCESS,
  GETZONES_BYWAREHOUSE_FAILURE,
  GETOPENBINS_BYWAREHOUSE_REQUEST,
  GETOPENBINS_BYWAREHOUSE_SUCCESS,
  GETOPENBINS_BYWAREHOUSE_FAILURE,
  GETZONES_BYITEMCODE_REQUEST,
  GETZONES_BYITEMCODE_SUCCESS,
  GETZONES_BYITEMCODE_FAILURE,
  GETLOCATIONS_BYITEMCODE_REQUEST,
  GETLOCATIONS_BYITEMCODE_SUCCESS,
  GETLOCATIONS_BYITEMCODE_FAILURE,
} from "./ZoneCategoryTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getZonesByCategoryAndWarehouseRequest = () => {
  return {
    type: GETZONES_BYCATEGORYANDWAREHOUSE_REQUEST,
  };
};

const getZonesByCategoryAndWarehouseSuccess = (payload) => {
  return {
    type: GETZONES_BYCATEGORYANDWAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const getZonesByCategoryAndWarehouseFailure = (error) => {
  return {
    type: GETZONES_BYCATEGORYANDWAREHOUSE_FAILURE,
    payload: error,
  };
};

const getZonesByCategoryAndWarehouse = (warehouseId, categoryId) => {
  return (dispatch) => {
    dispatch(getZonesByCategoryAndWarehouseRequest());
    let url = `${baseUrl}/zone/by_category_wh?warehouse_id=${warehouseId}&category_id=${categoryId}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getZonesByCategoryAndWarehouseSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getZonesByCategoryAndWarehouseFailure(err.message));
      });
  };
};

const getOpenCategoryZonesByWarehouseRequest = () => {
  return {
    type: GETOPENCATEGORYZONES_BYWAREHOUSE_REQUEST,
  };
};

const getOpenCategoryZonesByWarehouseSuccess = (payload) => {
  return {
    type: GETOPENCATEGORYZONES_BYWAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const getOpenCategoryZonesByWarehouseFailure = (error) => {
  return {
    type: GETOPENCATEGORYZONES_BYWAREHOUSE_FAILURE,
    payload: error,
  };
};

const getOpenCategoryZonesByWarehouse = (warehouseId) => {
  return (dispatch) => {
    dispatch(getOpenCategoryZonesByWarehouseRequest());
    let url = `${baseUrl}/zone/for_open_category?warehouse_id=${warehouseId}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getOpenCategoryZonesByWarehouseSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getOpenCategoryZonesByWarehouseFailure(err.message));
      });
  };
};

const getAllCategoriesRequest = () => {
  return {
    type: GETALLCATEGORIES_REQUEST,
  };
};

const getAllCategoriesSuccess = (payload) => {
  return {
    type: GETALLCATEGORIES_SUCCESS,
    payload: payload,
  };
};

const getAllCategoriesFailure = (error) => {
  return {
    type: GETALLCATEGORIES_FAILURE,
    payload: error,
  };
};

const getAllCategories = () => {
  return (dispatch) => {
    dispatch(getAllCategoriesRequest());
    let url = `${baseUrl}/category`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getAllCategoriesSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getAllCategoriesFailure(err.message));
      });
  };
};

const addZoneToWarehouseRequest = () => {
  return {
    type: ADDZONE_TOWAREHOUSE_REQUEST,
  };
};

const addZoneToWarehouseSuccess = (payload) => {
  return {
    type: ADDZONE_TOWAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const addZoneToWarehouseFailure = (error) => {
  return {
    type: ADDZONE_TOWAREHOUSE_FAILURE,
    payload: error,
  };
};

const addZoneToWarehouse = (payload) => {
  return (dispatch) => {
    dispatch(addZoneToWarehouseRequest());
    let url = `${baseUrl}/zone`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(addZoneToWarehouseSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(addZoneToWarehouseFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

/*----------------------------------------------------------------------------------------*/

const getZonesByWarehouseRequest = () => {
  return {
    type: GETZONES_BYWAREHOUSE_REQUEST,
  };
};

const getZonesByWarehouseSuccess = (payload) => {
  return {
    type: GETZONES_BYWAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const getZonesByWarehouseFailure = (error) => {
  return {
    type: GETZONES_BYWAREHOUSE_FAILURE,
    payload: error,
  };
};

const getZonesByWarehouse = (warehouseId) => {
  return (dispatch) => {
    dispatch(getZonesByWarehouseRequest());
    let url = `${baseUrl}/zone_by_wh?warehouseId=${warehouseId}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getZonesByWarehouseSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getZonesByWarehouseFailure(err.message));
      });
  };
};

const getOpenBinsByWarehouseRequest = () => {
  return {
    type: GETOPENBINS_BYWAREHOUSE_REQUEST,
  };
};

const getOpenBinsByWarehouseSuccess = (payload) => {
  return {
    type: GETOPENBINS_BYWAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const getOpenBinsByWarehouseFailure = (error) => {
  return {
    type: GETOPENBINS_BYWAREHOUSE_FAILURE,
    payload: error,
  };
};

const getOpenBinsByWarehouseAndZone = (warehouseId, zoneId) => {
  return (dispatch) => {
    dispatch(getOpenBinsByWarehouseRequest());
    let url = `${baseUrl}/production/pickup/get_empty_bins_by_zone?warehouse_id=${warehouseId}&zone_id=${zoneId}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getOpenBinsByWarehouseSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getOpenBinsByWarehouseFailure(err.message));
      });
  };
};

export {
  getZonesByCategoryAndWarehouse,
  getOpenCategoryZonesByWarehouse,
  getAllCategories,
  addZoneToWarehouse,
  getZonesByWarehouse,
  getOpenBinsByWarehouseAndZone,
};
