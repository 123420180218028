import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import dashboardCard from "../../style/dashboardCard";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Link as RouterLink } from "react-router-dom";
import { getInventory } from "../../store";

import "chart.js/auto";
import { Bar, Doughnut } from "react-chartjs-2";
import { useRef } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => dashboardCard(theme));

const InventoryHomePageCard = ({ getInventory, inventory }) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    getInventory();
  }, []);

  const [lessThanThirty, setLessThanThirty] = useState(0);
  const [thirtyOneToSixty, setThirtyOneToSixty] = useState(0);
  const [sixtyOneToNinety, setSixtyOneToNinety] = useState(0);
  const [greaterThanNinety, setGreaterThanNinety] = useState(0);
  const [expired, setExpired] = useState(0);

  useEffect(() => {
    setLessThanThirty(inventory.categories.lessThanThirty);
    setThirtyOneToSixty(inventory.categories.thirtyOneToSixty);
    setSixtyOneToNinety(inventory.categories.sixtyOneToNinety);
    setGreaterThanNinety(inventory.categories.greaterThanNinety);
    setExpired(inventory.categories.expired);
  }, [inventory]);

  const data = {
    labels: ["< 30 days", "31-60 days", "61-90 days", "> 90 days", "Expired"],
    datasets: [
      {
        data: [
          lessThanThirty,
          thirtyOneToSixty,
          sixtyOneToNinety,
          greaterThanNinety,
          expired,
        ],
        backgroundColor: [
          "#DA9FF9",
          "#9E7777",
          "#0DCEDA",
          "#D79771",
          "#FFCD38",
        ],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    title: {
      display: true,
      text: "Doughnut Chart",
    },
  };

  const chartRef = useRef();
  const onClick = (event) => {
    history.push(`/inventory_ageing`);
  };

  return (
    <div>
      <Card>
        <CardContent>
          <h4>Inventory</h4>
          <hr />
          <Bar
            className={classes.root}
            data={data}
            options={options}
            onClick={onClick}
            ref={chartRef}
            height={240}
          />
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    inventory: state.getInventoryHomePage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInventory: () => dispatch(getInventory()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryHomePageCard);
