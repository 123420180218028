/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_MODULES_REQUEST,
  GET_MODULES_SUCCESS,
  GET_MODULES_FAILURE,
} from "./modulesTypes";

import axios from "axios";
import Config from "../../../config/index";
let licensesUrl = Config.licensesUrl;
let userManagementUrl = Config.userManagementUrl;

const getModulesRequest = () => {
  return {
    type: GET_MODULES_REQUEST,
  };
};

const getModuleSuccess = (payload) => {
  return {
    type: GET_MODULES_SUCCESS,
    payload: payload,
  };
};

const getModuleFaliure = (error) => {
  return {
    type: GET_MODULES_FAILURE,
    payload: error,
  };
};

const getModulesByProductId = () => {
  return (dispatch) => {
    dispatch(getModulesRequest());
    // let url = `${licensesUrl}/modules_management/module/get_modules_by_productId?productId=${productId}`;
    let url = `${userManagementUrl}/users_management/modules/get`;
    return axios
      .get(url)
      .then((response) => {
        dispatch(getModuleSuccess(response.data));
        return response.data;
      })
      .catch((err) => {
        dispatch(getModuleFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export { getModulesByProductId };
