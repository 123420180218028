/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
// import MasterStore from "./components/StorageLocation/MasterStore";
import Home from "./components/Home";
import Warehouse from "./components/Warehouse/Warehouse";
import GRN from "./components/GRN/GRN";
// import StorageLocation from "./components/StorageLocation/StorageLocation";
import ZoneAllocation from "./components/ZoneAllocation/ZoneAllocation";
import ItemMaster from "./components/ItemMaster/ItemMaster";
import PickupList from "./components/PickupList/PickupList";
import CreatePickupList from "./components/CreatePickupList/CreatePickupList";
import DeliveryWarehouse from "./components/DeliveryWarehouse/DeliveryWarehouse";
import ItemAllocation from "./components/ItemAllocation/ItemAllocation";
import FinishedGoods from "./components/FinishedGoods/FinishedGoods";
import Rejections from "./components/Rejections/Rejections";
import Dispatch from "./components/Dispatch/Dispatch";
import ViewLocation from "./components/ViewLocation/ViewLocation";

function AppRoutes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/home" component={Home} />
        <Route exact path="/source_warehouse" component={Warehouse} />
        <Route exact path="/delivery_warehouse" component={DeliveryWarehouse} />
        <Route
          exact
          path="/:warehouseType/:warehouseId"
          component={ViewLocation}
        />
        {/* <Route
          exact
          path="/:warehouseType/:warehouseId"
          component={StorageLocation}
        /> */}
        {/* <Route
          exact
          path="/:warehouseType/:warehouseId"
          component={StorageLocation}
        /> */}
        <Route exact path="/grn_details" component={GRN} />
        <Route exact path="/zone_allocation" component={ZoneAllocation} />
        <Route exact path="/item_allocation" component={ItemAllocation} />
        <Route exact path="/item_master" component={ItemMaster} />
        <Route exact path="/create_pickup_list" component={CreatePickupList} />
        <Route exact path="/pickup_list" component={PickupList} />
        <Route exact path="/finished_goods" component={FinishedGoods} />
        <Route exact path="/rejection_warehouse" component={Rejections} />
        <Route exact path="/dispatch" component={Dispatch} />
        <Redirect to="/source_warehouse" />
      </Switch>
    </Router>
  );
}

export default AppRoutes;
