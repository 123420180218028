import {
  PRINTLABEL_INDIVIDUALPRINT_REQUEST,
  PRINTLABEL_INDIVIDUALPRINT_SUCCESS,
  PRINTLABEL_INDIVIDUALPRINT_FAILURE,
  PRINTLABEL_PACKAGEPRINT_REQUEST,
  PRINTLABEL_PACKAGEPRINT_SUCCESS,
  PRINTLABEL_PACKAGEPRINT_FAILURE,
  TRANSFERORDER_INDIVIDUALPRINT_REQUEST,
  TRANSFERORDER_INDIVIDUALPRINT_SUCCESS,
  TRANSFERORDER_INDIVIDUALPRINT_FAILURE,
  TRANSFERORDER_PACKAGEPRINT_REQUEST,
  TRANSFERORDER_PACKAGEPRINT_SUCCESS,
  TRANSFERORDER_PACKAGEPRINT_FAILURE,
} from "./LabelPrintingTypes";

const initialStateForIndividualPrint = {
  loading: false,
  lineItem: [],
  error: "",
};

const individualPrintReducer = (
  state = initialStateForIndividualPrint,
  action
) => {
  switch (action.type) {
    case PRINTLABEL_INDIVIDUALPRINT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PRINTLABEL_INDIVIDUALPRINT_SUCCESS:
      return {
        loading: false,
        lineItem: action.payload,
        error: "",
      };
    case PRINTLABEL_INDIVIDUALPRINT_FAILURE:
      return {
        loading: false,
        lineItem: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForPackagePrint = {
  loading: false,
  lineItem: [],
  error: "",
};

const packagePrintReducer = (state = initialStateForPackagePrint, action) => {
  switch (action.type) {
    case PRINTLABEL_PACKAGEPRINT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PRINTLABEL_PACKAGEPRINT_SUCCESS:
      return {
        loading: false,
        lineItem: action.payload,
        error: "",
      };
    case PRINTLABEL_PACKAGEPRINT_FAILURE:
      return {
        loading: false,
        lineItem: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForTransferOrderIndividualPrint = {
  loading: false,
  qty: [],
  error: "",
};

const transferOrderIndividualPrintReducer = (
  state = initialStateForTransferOrderIndividualPrint,
  action
) => {
  switch (action.type) {
    case TRANSFERORDER_INDIVIDUALPRINT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TRANSFERORDER_INDIVIDUALPRINT_SUCCESS:
      return {
        loading: false,
        qty: action.payload,
        error: "",
      };
    case TRANSFERORDER_INDIVIDUALPRINT_FAILURE:
      return {
        loading: false,
        qty: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForTransferOrderPackagePrint = {
  loading: false,
  qty: [],
  error: "",
};

const transferOrderPackagePrintReducer = (
  state = initialStateForTransferOrderPackagePrint,
  action
) => {
  switch (action.type) {
    case TRANSFERORDER_PACKAGEPRINT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TRANSFERORDER_PACKAGEPRINT_SUCCESS:
      return {
        loading: false,
        qty: action.payload,
        error: "",
      };
    case TRANSFERORDER_PACKAGEPRINT_FAILURE:
      return {
        loading: false,
        qty: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  individualPrintReducer,
  packagePrintReducer,
  transferOrderIndividualPrintReducer,
  transferOrderPackagePrintReducer,
};
