import {
  GET_ITEMMASTER_REQUEST,
  GET_ITEMMASTER_SUCCESS,
  GET_ITEMMASTER_FAILURE,
  EDIT_ITEMMASTER_REQUEST,
  EDIT_ITEMMASTER_SUCCESS,
  EDIT_ITEMMASTER_FAILURE,
  GET_BINSBYITEMCODE_REQUEST,
  GET_BINSBYITEMCODE_SUCCESS,
  GET_BINSBYITEMCODE_FAILURE,
  UPLOADCSV_REQUEST,
  UPLOADCSV_SUCCESS,
  UPLOADCSV_FAILURE,
} from "./ItemMasterTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getItemMasterRequest = () => {
  return {
    type: GET_ITEMMASTER_REQUEST,
  };
};

const getItemMasterSuccess = (payload) => {
  return {
    type: GET_ITEMMASTER_SUCCESS,
    payload: payload,
  };
};

const getItemMasterFailure = (error) => {
  return {
    type: GET_ITEMMASTER_FAILURE,
    payload: error,
  };
};

const getItemMaster = () => {
  return (dispatch) => {
    dispatch(getItemMasterRequest());
    let url = `${baseUrl}/item`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getItemMasterSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getItemMasterFailure(err.message));
      });
  };
};

const editItemMasterRequest = () => {
  return {
    type: EDIT_ITEMMASTER_REQUEST,
  };
};

const editItemMasterSuccess = (payload) => {
  return {
    type: EDIT_ITEMMASTER_SUCCESS,
    payload: payload,
  };
};

const editItemMasterFailure = (error) => {
  return {
    type: EDIT_ITEMMASTER_FAILURE,
    payload: error,
  };
};

const editItemMaster = (payload) => {
  return (dispatch) => {
    dispatch(editItemMasterRequest());
    let url = `${baseUrl}/production/pickup/update_threshold`;
    return axios
      .put(url, payload)
      .then((response) => {
        dispatch(editItemMasterSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(editItemMasterFailure(err.message));
        return { status: false, message: err.message };
      });
  };
};

const getBinsByItemCodeRequest = () => {
  return {
    type: GET_BINSBYITEMCODE_REQUEST,
  };
};

const getBinsByItemCodeSuccess = (payload) => {
  return {
    type: GET_BINSBYITEMCODE_SUCCESS,
    payload: payload,
  };
};

const getBinsByItemCodeFailure = (error) => {
  return {
    type: GET_BINSBYITEMCODE_FAILURE,
    payload: error,
  };
};

const getBinsByItemCode = (id) => {
  return (dispatch) => {
    dispatch(getBinsByItemCodeRequest());
    let url = `${baseUrl}/production/pickup/location_by_itemCode?item_id=${id}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getBinsByItemCodeSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getBinsByItemCodeFailure(err.message));
      });
  };
};

const uploadCsvRequest = () => {
  return {
    type: UPLOADCSV_REQUEST,
  };
};

const uploadCsvSuccess = (payload) => {
  return {
    type: UPLOADCSV_SUCCESS,
    payload: payload,
  };
};

const uploadCsvFailure = (error) => {
  return {
    type: UPLOADCSV_FAILURE,
    payload: error,
  };
};

const uploadCsv = (payload) => {
  return (dispatch) => {
    dispatch(uploadCsvRequest());
    let url = `${baseUrl}/allocation/upload_csv`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(uploadCsvSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(uploadCsvFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export { getItemMaster, editItemMaster, getBinsByItemCode, uploadCsv };
