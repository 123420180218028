import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { makeStyles } from "@material-ui/core/styles";
import drawerCss from "../../style/drawer";
import classNames from "classnames";
import { connect } from "react-redux";
import {
  updateSelectedItem,
  getAllocatedZones,
  addZoneAllocation,
  removeZoneAllocation,
  getZonesByCategoryAndWarehouse,
  getOpenCategoryZonesByWarehouse,
  getAllCategories,
  getWarehouses,
  getWarehouseById,
} from "../../store";
import MaterialTable from "material-table";

import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../utils/TextError";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import Select from "react-select";

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

const ZoneAllocation = ({
  drawerState,
  handleListItemClick,
  getAllocatedZones,
  allocatedZones,
  addZoneAllocation,
  removeZoneAllocation,
  getZonesByCategoryAndWarehouse,
  zonesByCategoryAndWarehouse,
  getOpenCategoryZonesByWarehouse,
  openCategoryZones,
  getAllCategories,
  allCategories,
  getWarehouses,
  warehouses,
  getWarehouseById,
  warehouseById,
}) => {
  useEffect(() => {
    handleListItemClick(null, 3);
  }, []);

  const classes = useStyles();
  let drawer = drawerState;

  const [warehouseIdSelected, setWarehouseIdSelected] = useState("");

  const [categoryIdSelected, setCategoryIdSelected] = useState("");

  useEffect(() => {
    setWarehouseIdSelected(warehouses.warehouses[0]?.id);
  }, [warehouses]);

  useEffect(() => {
    getAllocatedZones(warehouseIdSelected);
  }, [warehouseIdSelected]);

  useEffect(() => {
    getWarehouses();
  }, []);

  useEffect(() => {
    getWarehouseById(warehouseIdSelected);
  }, [warehouseIdSelected]);

  useEffect(() => {
    getAllCategories();
  }, []);

  useEffect(() => {
    getOpenCategoryZonesByWarehouse(warehouseIdSelected);
  }, [warehouseIdSelected]);

  useEffect(() => {
    getZonesByCategoryAndWarehouse(warehouseIdSelected, categoryIdSelected);
  }, [warehouseIdSelected, categoryIdSelected]);

  const [state, setState] = useState({
    columns: [
      { title: "Category Code", field: "categoryCode" },
      { title: "Category Name", field: "categoryName" },
      { title: "Zones", field: "zones" },
      { title: "Action", field: "action" },
    ],
    data: [],
  });

  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [warehouseType, setWarehouseType] = useState("");

  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      setWarehouseType(warehouse.type);
      // if (warehouse.type === "s") {
      tempWarehouseOptions.push({
        key: warehouse.warehouseName,
        value: warehouse.id,
      });
      // }
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    let tempCategoryOptions = [];
    let data = allCategories.categories ? allCategories.categories : [];
    data.map((category) => {
      tempCategoryOptions.push({
        key: category.categoryName,
        value: category.id,
      });
    });
    setCategoryOptions(tempCategoryOptions);
  }, [allCategories]);

  const [openCategoryZonesOptions, setOpenCategoryZonesOptions] = useState([]);

  useEffect(() => {
    let tempOpenCategoryZonesOptions = [];
    let data = openCategoryZones.zones ? openCategoryZones.zones : [];
    data.map((zone) => {
      tempOpenCategoryZonesOptions.push({
        key: zone.zoneName,
        value: zone.id,
      });
    });
    setOpenCategoryZonesOptions(tempOpenCategoryZonesOptions);
  }, [openCategoryZones]);

  const [zoneOptions, setZoneOptions] = useState([]);

  useEffect(() => {
    let tempZoneOptions = [];
    let data = zonesByCategoryAndWarehouse.zones
      ? zonesByCategoryAndWarehouse.zones
      : [];
    data.map((zone) => {
      tempZoneOptions.push({
        key: zone.zoneName,
        value: zone.id,
      });
    });
    setZoneOptions(tempZoneOptions);
  }, [zonesByCategoryAndWarehouse]);

  useEffect(() => {
    let tempZones = [];
    let data = allocatedZones.zones ? allocatedZones.zones : [];
    data.map((category) => {
      category["categoryCode"] = category.categoryCode;
      category["categoryName"] = category.categoryName;
      category["zones"] = category.zones;
      category["action"] =
        category.categoryName !== "Open-Category" ? (
          <div>
            <button
              title="Add Zone"
              className="btn btn-info"
              onClick={() => {
                handleOpenCategoryForAddZoneAllocationModal(category);
              }}
            >
              <i className="fa fa-plus"></i>
            </button>
            <button
              // style={{ marginLeft: "1rem" }}
              title="Remove Zone"
              className="btn btn-danger spacing2"
              onClick={() => {
                setRemoveZoneAllocationModal(true);
                setCategoryIdSelected(category.categoryId);
              }}
            >
              <i className="fa fa-minus"></i>
            </button>
          </div>
        ) : (
          ""
        );
      tempZones.push(category);
    });
    setState({ ...state, data: tempZones });
  }, [allocatedZones]);

  const [isSuccessAllocateZone, setIsSuccessAllocateZone] = useState(false);
  const [isErrorAllocateZone, setIsErrorAllocateZone] = useState(false);

  const [allocateZoneModal, setAllocateZoneModal] = useState(false);
  const handleAllocateZoneModal = () => {
    getOpenCategoryZonesByWarehouse(warehouseIdSelected);
    setAllocateZoneModal(!allocateZoneModal);
  };

  const initialValue = {
    zoneCategory: "",
    zoneName: "",
  };

  const validationSchema = Yup.object({
    zoneCategory: Yup.string().required("Select the Zone Category"),
    zoneName: Yup.string().required("Select the Zone Name"),
  });

  const onSubmit = async (values, { resetForm }) => {
    const payload = {
      zoneId: values.zoneName,
      categoryId: values.zoneCategory,
      warehouseId: warehouseIdSelected,
    };

    let res = await addZoneAllocation(payload);

    if (res.status) {
      setIsSuccessAllocateZone(true);
      getOpenCategoryZonesByWarehouse(warehouseIdSelected);
      getAllocatedZones(warehouseIdSelected);
    }
    if (!res.status) {
      setIsErrorAllocateZone(true);
    }
    resetForm();
  };

  const [isSuccessAddZoneAllocation, setIsSuccessAddZoneAllocation] =
    useState(false);
  const [isErrorAddZoneAllocation, setIsErrorAddZoneAllocation] =
    useState(false);

  const [addZoneAllocationModal, setAddZoneAllocationModal] = useState(false);
  const handleAddZoneAllocationModal = () => {
    setAddZoneAllocationModal(!addZoneAllocationModal);
  };

  const [categoryForAddZoneAllocation, setCategoryForAddZoneAllocation] =
    useState({});

  const handleOpenCategoryForAddZoneAllocationModal = (user = {}) => {
    setCategoryForAddZoneAllocation(user);
    setAddZoneAllocationModal(true);
  };

  const initialValueForAddZoneAllocation = {
    zoneName: "",
  };

  const validationSchemaForAddZoneAllocation = Yup.object({
    zoneName: Yup.string().required("Select the Zone Name"),
  });

  const onSubmitForAddZoneAllocation = async (values, { resetForm }) => {
    const payload = {
      zoneId: values.zoneName,
      categoryId: categoryForAddZoneAllocation.categoryId,
      warehouseId: warehouseIdSelected,
    };

    let res = await addZoneAllocation(payload);

    if (res.status) {
      setIsSuccessAddZoneAllocation(true);
      getOpenCategoryZonesByWarehouse(warehouseIdSelected);
      getAllocatedZones(warehouseIdSelected);
    }
    if (!res.status) {
      setIsErrorAddZoneAllocation(true);
    }
    resetForm();
  };

  const [isSuccessRemoveZoneAllocation, setIsSuccessRemoveZoneAllocation] =
    useState(false);
  const [isErrorRemoveZoneAllocation, setIsErrorRemoveZoneAllocation] =
    useState(false);

  const [removeZoneAllocationModal, setRemoveZoneAllocationModal] =
    useState(false);
  const handleRemoveZoneAllocationModal = () => {
    setRemoveZoneAllocationModal(!removeZoneAllocationModal);
  };

  const initialValueForRemoveZoneAllocation = {
    zoneName: "",
  };

  const validationSchemaForRemoveZoneAllocation = Yup.object({
    zoneName: Yup.string().required("Select the Zone Name"),
  });

  const onSubmitForRemoveZoneAllocation = async (values, { resetForm }) => {
    const payload = {
      zoneId: values.zoneName,
      categoryId: categoryIdSelected,
      warehouseId: warehouseIdSelected,
    };

    let res = await removeZoneAllocation(payload);

    if (res.status) {
      setIsSuccessRemoveZoneAllocation(true);
      getZonesByCategoryAndWarehouse(warehouseIdSelected, categoryIdSelected);
      getAllocatedZones(warehouseIdSelected);
    }
    if (!res.status) {
      setIsErrorRemoveZoneAllocation(true);
    }
    resetForm();
  };

  const changeWarehouse = (e) => {
    setWarehouseIdSelected(e.target.value);
  };

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title={
                <div>
                  <br />
                  <h5>Zone Allocation</h5>
                  <br />
                  <select
                    as="select"
                    id="warehouseName"
                    name="warehouseName"
                    className="form-control"
                    onChange={changeWarehouse}
                  >
                    {warehouseOptions.map((option) => {
                      return (
                        <option key={option.key} value={option.value}>
                          {option.key}
                        </option>
                      );
                    })}
                  </select>
                </div>
              }
              columns={state.columns}
              data={state.data}
              options={{ draggable: false }}
              actions={[
                {
                  tooltip: "Allocate Zone",
                  isFreeAction: true,
                  onClick: (event) => handleAllocateZoneModal(),
                  icon: "add",
                  iconProps: {
                    color: "error",
                    fontSize: "large",
                  },
                },
              ]}
            />
            <div>
              <Modal
                show={allocateZoneModal}
                onHide={handleAllocateZoneModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Allocate Zone</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Formik
                          initialValues={initialValue}
                          validationSchema={validationSchema}
                          onSubmit={onSubmit}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="zoneCategory">
                                    Select the Zone Category
                                  </label>
                                  <Field
                                    as="select"
                                    id="zoneCategory"
                                    name="zoneCategory"
                                    className="form-control"
                                  >
                                    <option value="" disabled selected>
                                      Choose one...
                                    </option>
                                    {categoryOptions.map((option) => {
                                      return (
                                        <option
                                          key={option.key}
                                          value={option.value}
                                        >
                                          {option.key}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                  <ErrorMessage
                                    name="zoneCategory"
                                    component={TextError}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="zoneName">
                                    Select the Zone Name
                                  </label>
                                  <Field
                                    as="select"
                                    id="zoneName"
                                    name="zoneName"
                                    className="form-control"
                                  >
                                    <option value="" disabled selected>
                                      Choose one...
                                    </option>
                                    {openCategoryZonesOptions.map((option) => {
                                      return (
                                        <option
                                          key={option.key}
                                          value={option.value}
                                        >
                                          {option.key}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                  <ErrorMessage
                                    name="zoneName"
                                    component={TextError}
                                  />
                                </div>
                                <div className="mt-3">
                                  <Collapse in={isErrorAllocateZone}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsErrorAllocateZone(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Could not Allocate the Zone
                                    </Alert>
                                  </Collapse>
                                  <Collapse in={isSuccessAllocateZone}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccessAllocateZone(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Zone Allocated successfully
                                    </Alert>
                                  </Collapse>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                    disabled={!formik.isValid}
                                  >
                                    Allocate Zone
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                show={addZoneAllocationModal}
                onHide={handleAddZoneAllocationModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Add Zone</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Formik
                          initialValues={initialValueForAddZoneAllocation}
                          validationSchema={
                            validationSchemaForAddZoneAllocation
                          }
                          onSubmit={onSubmitForAddZoneAllocation}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="zoneName">
                                    Select the Zone Name
                                  </label>
                                  <Field
                                    as="select"
                                    id="zoneName"
                                    name="zoneName"
                                    className="form-control"
                                  >
                                    <option value="" disabled selected>
                                      Choose one...
                                    </option>
                                    {openCategoryZonesOptions.map((option) => {
                                      return (
                                        <option
                                          key={option.key}
                                          value={option.value}
                                        >
                                          {option.key}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                  <ErrorMessage
                                    name="zoneName"
                                    component={TextError}
                                  />
                                </div>
                                <div className="mt-3">
                                  <Collapse in={isErrorAddZoneAllocation}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsErrorAddZoneAllocation(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Could not add the Zone
                                    </Alert>
                                  </Collapse>
                                  <Collapse in={isSuccessAddZoneAllocation}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccessAddZoneAllocation(
                                              false
                                            );
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Zone added successfully
                                    </Alert>
                                  </Collapse>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                    disabled={!formik.isValid}
                                  >
                                    Add Zone
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                show={removeZoneAllocationModal}
                onHide={handleRemoveZoneAllocationModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Remove Zone</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Formik
                          initialValues={initialValueForRemoveZoneAllocation}
                          validationSchema={
                            validationSchemaForRemoveZoneAllocation
                          }
                          onSubmit={onSubmitForRemoveZoneAllocation}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="zoneName">
                                    Select the Zone Name
                                  </label>
                                  <Field
                                    as="select"
                                    id="zoneName"
                                    name="zoneName"
                                    className="form-control"
                                  >
                                    <option value="" disabled selected>
                                      Choose one...
                                    </option>
                                    {zoneOptions.map((option) => {
                                      return (
                                        <option
                                          key={option.key}
                                          value={option.value}
                                        >
                                          {option.key}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                  <ErrorMessage
                                    name="zoneName"
                                    component={TextError}
                                  />
                                </div>
                                <div className="mt-3">
                                  <Collapse in={isErrorRemoveZoneAllocation}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsErrorRemoveZoneAllocation(
                                              false
                                            );
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Could not remove the Zone
                                    </Alert>
                                  </Collapse>
                                  <Collapse in={isSuccessRemoveZoneAllocation}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccessRemoveZoneAllocation(
                                              false
                                            );
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Zone removed successfully
                                    </Alert>
                                  </Collapse>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                    disabled={!formik.isValid}
                                  >
                                    Remove Zone
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    allocatedZones: state.allocatedZones,
    zonesByCategoryAndWarehouse: state.zonesByCategoryAndWarehouse,
    openCategoryZones: state.openCategoryZones,
    allCategories: state.allCategories,
    warehouses: state.warehouses,
    warehouseById: state.warehouseById,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getAllocatedZones: (id) => dispatch(getAllocatedZones(id)),
    addZoneAllocation: (payload) => dispatch(addZoneAllocation(payload)),
    removeZoneAllocation: (payload) => dispatch(removeZoneAllocation(payload)),
    getZonesByCategoryAndWarehouse: (warehouseId, categoryId) =>
      dispatch(getZonesByCategoryAndWarehouse(warehouseId, categoryId)),
    getOpenCategoryZonesByWarehouse: (warehouseId) =>
      dispatch(getOpenCategoryZonesByWarehouse(warehouseId)),
    getAllCategories: () => dispatch(getAllCategories()),
    getWarehouses: () => dispatch(getWarehouses()),
    getWarehouseById: (id) => dispatch(getWarehouseById(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ZoneAllocation);
