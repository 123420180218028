import {
  GET_REJECTIONS_REQUEST,
  GET_REJECTIONS_SUCCESS,
  GET_REJECTIONS_FAILURE,
  PUT_ACCEPTREJECTION_REQUEST,
  PUT_ACCEPTREJECTION_SUCCESS,
  PUT_ACCEPTREJECTION_FAILURE,
  CREATE_REJECTION_REQUEST,
  CREATE_REJECTION_SUCCESS,
  CREATE_REJECTION_FAILURE,
} from "./RejectionsTypes";

const initialState = {
  loading: false,
  rejections: [],
  error: "",
};

const getRejectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REJECTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_REJECTIONS_SUCCESS:
      return {
        loading: false,
        rejections: action.payload,
        error: "",
      };
    case GET_REJECTIONS_FAILURE:
      return {
        loading: false,
        rejections: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAcceptRejection = {
  loading: false,
  rejection: [],
  error: "",
};

const acceptRejectionReducer = (
  state = initialStateForAcceptRejection,
  action
) => {
  switch (action.type) {
    case PUT_ACCEPTREJECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PUT_ACCEPTREJECTION_SUCCESS:
      return {
        loading: false,
        rejection: action.payload,
        error: "",
      };
    case PUT_ACCEPTREJECTION_FAILURE:
      return {
        loading: false,
        rejection: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForRejection = {
  loading: false,
  rejection: [],
  error: "",
};

const createRejectionReducer = (state = initialStateForRejection, action) => {
  switch (action.type) {
    case CREATE_REJECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_REJECTION_SUCCESS:
      return {
        loading: false,
        rejection: action.payload,
        error: "",
      };
    case CREATE_REJECTION_FAILURE:
      return {
        loading: false,
        rejection: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getRejectionsReducer, acceptRejectionReducer, createRejectionReducer };
