import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { makeStyles } from "@material-ui/core/styles";
import drawerCss from "../../style/drawer";
import classNames from "classnames";
import { connect } from "react-redux";
import {
  updateSelectedItem,
  getWarehouses,
  getRejections,
  acceptRejection,
  createRejection,
} from "../../store";
import MaterialTable from "material-table";
import { format, addMinutes } from "date-fns";

import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../utils/TextError";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import Config from "../../config/index";

const desLength = Config.descriptionLength;

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

const Rejections = ({
  drawerState,
  handleListItemClick,
  getWarehouses,
  warehouses,
  getRejections,
  rejections,
  acceptRejection,
  createRejection,
}) => {
  useEffect(() => {
    handleListItemClick(null, 11);
  }, []);

  const classes = useStyles();
  let drawer = drawerState;

  useEffect(() => {
    getWarehouses();
  }, []);

  const [warehouseOptions, setWarehouseOptions] = useState([]);

  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      tempWarehouseOptions.push({
        key: warehouse.warehouseName,
        value: warehouse.id,
      });
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  const [location, setLocation] = useState(undefined);
  const [status, setStatus] = useState("");

  useEffect(() => {
    getRejections(location, status);
  }, [location, status]);

  const [state, setState] = useState({
    columns: [
      { title: "SKU/VIN", field: "skuVin" },
      { title: "Item/FG Code", field: "itemFgCode" },
      { title: "Item/FG Des", field: "description" },
      { title: "Reason", field: "reason" },
      { title: "Qty", field: "qty" },
      { title: "Date & Time", field: "dateTime" },
      { title: "Location", field: "location" },
      { title: "Status", field: "status" },
      { title: "Comment", field: "comment" },
    ],
    data: [],
  });

  useEffect(() => {
    let tempRejections = [];
    let data = rejections.rejections ? rejections.rejections : [];

    data.map((data) => {
      let des = data?.itemDescription;
      let len = desLength;
      const formatDate = new Date(data.cd);
      const dataInfo = {
        warehouseId: data.warehouseId,
        skuVin: data.skuVin,
        itemFgCode: data.itemFgCode,
        description: des ? des.substring(0, len) : "",
        reason: data.rejectionDescription,
        qty: `${data.rejectedQty} ${data.uom}`,
        dateTime: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        location: `${data.warehouseName} / ${data.zoneName} ${data.locationName}`,
        status:
          (data.isAccepted === 1 && "Accepted") ||
          (data.isAccepted === 0 &&
            data.discardType === 1 &&
            "Supplier or Rework") ||
          (data.isAccepted && data.discardType === 1 && "Scrap") ||
          (data.isAccepted === 0 && "Rejected"),
        comment: data.comment ? data.comment : "",
      };
      tempRejections.push(dataInfo);
    });
    setState({ ...state, data: tempRejections });
  }, [rejections]);

  const locationOptions = [
    { key: "Pickup", value: "store" },
    { key: "Main Line", value: "production" },
    { key: "Dispatch", value: "dispatch" },
  ];

  const changeLocation = (e) => {
    setLocation(e.target.value);
  };

  const changeStatus = (e) => {
    setStatus(e.target.value);
  };

  const [isErrorAcceptItem, setIsErrorAcceptItem] = useState(false);

  const [openAcceptItemModal, setOpenAcceptItemModal] = useState(false);

  const [acceptItemData, setAcceptItemData] = useState({});

  const handleOpenAcceptItemModal = (data = {}) => {
    setAcceptItemData(data);
    setOpenAcceptItemModal(true);
  };

  const handleCloseAcceptItemModal = () => {
    setOpenAcceptItemModal(false);
  };

  const acceptHandler = (event, data) => {
    handleOpenAcceptItemModal(data);
  };

  const [isErrorRejectItem, setIsErrorRejectItem] = useState(false);

  const [openRejectItemModal, setOpenRejectItemModal] = useState(false);

  const [rejectItemData, setRejectItemData] = useState({});

  const handleOpenRejectItemModal = (data = {}) => {
    setRejectItemData(data);
    setOpenRejectItemModal(true);
  };

  const handleCloseRejectItemModal = () => {
    setOpenRejectItemModal(false);
  };

  const rejectHandler = (event, data) => {
    handleOpenRejectItemModal(data);
  };

  const initialValueForAcceptItem = {
    comment: "",
  };

  const validationSchemaForAcceptItem = Yup.object({
    comment: Yup.string().required("Enter the Comment"),
  });

  const [errorMsg, setErrorMsg] = useState("");

  const onClickAcceptHandler = async (values) => {
    let tempPayload = [];
    acceptItemData.map((data) => {
      const payload = {
        skuVin: data.skuVin,
        warehouseId: data.warehouseId,
        acceptDescription: values.comment,
      };
      tempPayload.push(payload);
    });

    let res = await acceptRejection(tempPayload);

    if (res.status) {
      handleCloseAcceptItemModal();
      getRejections(location, status);
    }
    if (!res.status) {
      setIsErrorAcceptItem(true);

      setErrorMsg(res.message.response.data.msg);
    }
  };

  const initialValueForRejectItem = {
    rejectionType: "",
    comment: "",
  };

  const validationSchemaForRejectItem = Yup.object({
    rejectionType: Yup.string().required("Select the Rejection Type"),
    comment: Yup.string().required("Enter the Comment"),
  });

  const onClickRejectHandler = async (values) => {
    let tempPayload = [];
    rejectItemData.map((data) => {
      const payload = {
        skuVin: data.skuVin,
        warehouseId: data.warehouseId,
        comment: values.comment,
        discardType: +values.rejectionType,
      };
      tempPayload.push(payload);
    });

    let res = await createRejection(tempPayload);

    if (res.status) {
      handleCloseRejectItemModal();
      getRejections(location, status);
    }
    if (!res.status) {
      setIsErrorRejectItem(true);

      setErrorMsg(res.message.response.data.msg);
    }
  };

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              //   title="Rejection"
              title={
                <div>
                  <br />
                  <h5>Rejection Warehouse</h5>
                  <br />
                  <span style={{ display: "flex" }}>
                    <select
                      style={{ width: "11rem" }}
                      as="select"
                      id="warehouseName"
                      name="warehouseName"
                      className="form-control"
                      onChange={changeLocation}
                    >
                      <option value={`${undefined}`} defaultValue>
                        All Warehouses
                      </option>
                      {warehouseOptions.map((option) => {
                        return (
                          <option key={option.value} value={option.value}>
                            {option.key}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      style={{ width: "11rem", marginLeft: "1rem" }}
                      as="select"
                      id="status"
                      name="status"
                      className="form-control"
                      onChange={changeStatus}
                    >
                      <option value="" defaultValue>
                        All Status
                      </option>
                      <option value={1}>Accepted</option>
                      <option value={0}>Rejected</option>
                    </select>
                  </span>
                </div>
              }
              columns={state.columns}
              data={state.data}
              options={{
                draggable: false,
                selection: true,
                toolbarButtonAlignment: "left",
              }}
              actions={[
                {
                  icon: () => (
                    <button className="btn btn-info" style={{ width: "5rem" }}>
                      Accept
                    </button>
                  ),
                  onClick: (evt, data) => acceptHandler(evt, data),
                },
                {
                  icon: () => (
                    <button className="btn btn-info" style={{ width: "5rem" }}>
                      Reject
                    </button>
                  ),
                  onClick: (evt, data) => rejectHandler(evt, data),
                },
              ]}
            />
            <div>
              <Modal
                show={openAcceptItemModal}
                onHide={handleCloseAcceptItemModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Accept SKU/VIN</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <p>Are you sure you want to accept?</p>
                        <Formik
                          initialValues={initialValueForAcceptItem}
                          validationSchema={validationSchemaForAcceptItem}
                          onSubmit={onClickAcceptHandler}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="comment">Comment</label>
                                  <Field
                                    as="textarea"
                                    name="comment"
                                    className="form-control"
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    name="comment"
                                    component={TextError}
                                  />
                                </div>
                                <div className="clearfix">
                                  <button
                                    type="button"
                                    className="cancelbtn button1"
                                    onClick={handleCloseAcceptItemModal}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="submit"
                                    className="deletebtn button1"
                                    disabled={!formik.isValid}
                                  >
                                    Accept
                                  </button>
                                </div>
                                <div className="mt-3">
                                  <Collapse in={isErrorAcceptItem}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsErrorAcceptItem(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      {errorMsg}
                                    </Alert>
                                  </Collapse>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                show={openRejectItemModal}
                onHide={handleCloseRejectItemModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Reject SKU/VIN</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        {/* <p>Are you sure you want to reject?</p> */}
                        <Formik
                          initialValues={initialValueForRejectItem}
                          validationSchema={validationSchemaForRejectItem}
                          onSubmit={onClickRejectHandler}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="rejectionType">
                                    Select the Rejection Type
                                  </label>
                                  <Field
                                    as="select"
                                    id="rejectionType"
                                    name="rejectionType"
                                    className="form-control"
                                  >
                                    <option value="" disabled selected>
                                      Choose one...
                                    </option>
                                    <option key={`supplier`} value={1}>
                                      Send back to supplier or Rework
                                    </option>
                                    <option key={`scrap`} value={2}>
                                      Scrap
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                    name="rejectionType"
                                    component={TextError}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="comment">Comment</label>
                                  <Field
                                    as="textarea"
                                    name="comment"
                                    className="form-control"
                                    autocomplete="off"
                                  />
                                  <ErrorMessage
                                    name="comment"
                                    component={TextError}
                                  />
                                </div>
                                <div class="clearfix">
                                  <button
                                    type="button"
                                    className="cancelbtn button1"
                                    onClick={handleCloseRejectItemModal}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="submit"
                                    className="deletebtn button1"
                                    disabled={!formik.isValid}
                                  >
                                    Reject
                                  </button>
                                </div>
                                <div className="mt-3">
                                  <Collapse in={isErrorRejectItem}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsErrorRejectItem(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Could not reject the SKU/VIN
                                    </Alert>
                                  </Collapse>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    warehouses: state.warehouses,
    rejections: state.rejections,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getWarehouses: () => dispatch(getWarehouses()),
    getRejections: (location, status) =>
      dispatch(getRejections(location, status)),
    acceptRejection: (payload) => dispatch(acceptRejection(payload)),
    createRejection: (payload) => dispatch(createRejection(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rejections);
