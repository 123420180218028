import {
    GET_WAREHOUSEOCCUPANCY_REQUEST,
    GET_WAREHOUSEOCCUPANCY_SUCCESS,
    GET_WAREHOUSEOCCUPANCY_FAILURE,
  } from "./WarehouseOccupancyTypes";
  
  const initialStateForWarehouseOccupancy = {
    loading: false,
    WarehouseOccupancy: [],
    error: "",
  };
  
  const getWarehouseOccupancyReducer = (state = initialStateForWarehouseOccupancy, action) => {
    switch (action.type) {
      case GET_WAREHOUSEOCCUPANCY_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_WAREHOUSEOCCUPANCY_SUCCESS:
        return {
          loading: false,
          WarehouseOccupancy: action.payload,
          error: "",
        };
      case GET_WAREHOUSEOCCUPANCY_FAILURE:
        return {
          loading: false,
          WarehouseOccupancy: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  
  
  export { getWarehouseOccupancyReducer};
  