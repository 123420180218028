// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*********************\n * Mobillor Technologies Pvt. ltd. CONFIDENTIAL\n * __________________\n * \n *  Mobillor Technologies Pvt. Ltd. \n *  All Rights Reserved. \n * \n * NOTICE:  All information contained herein is, and remains\n * the property of Mobillor Technologies Pvt. Ltd.  \n * The intellectual and technical concepts contained\n * herein are proprietary to Mobillor Technologies Pvt. Ltd.\n * may be covered by Rebublic Of India and Foreign Patents,\n * patents in process, and are protected by trade secret or copyright law.\n * Dissemination of this information or reproduction of this material\n * is strictly forbidden unless prior written permission is obtained\n * from Mobillor Technologies Pvt. Ltd.\n*/\n\n.header_header__3Env3 {\n  /* background-color: #000000; */\n  background-color: black;\n  /* background-color: darkgray; */\n}\n\n@media only screen and (min-width: 320px) {\n  .header_logo__1y3Xg {\n    width: 100px;\n    padding: 4px;\n    height: auto;\n    background-size: 100% auto !important;\n  }\n}\n\n/* Extra Small Devices, Phones */\n\n@media only screen and (min-width: 480px) {\n  .header_logo__1y3Xg {\n    width: 100px;\n    padding: 4px;\n    height: auto;\n    background-size: 100% auto !important;\n  }\n}\n\n/* Small Devices, Tablets */\n\n@media only screen and (min-width: 768px) {\n  .header_logo__1y3Xg {\n    width: 200px;\n    padding: 8px;\n    height: auto;\n    background-size: 100% auto !important;\n  }\n}\n\n/* Medium Devices, Desktops */\n\n@media only screen and (min-width: 992px) {\n  .header_logo__1y3Xg {\n    width: 200px;\n    padding: 8px;\n    height: auto;\n    background-size: 100% auto !important;\n  }\n}\n\n/* Large Devices, Wide Screens */\n\n@media only screen and (min-width: 1200px) {\n  .header_logo__1y3Xg {\n    width: 200px;\n    padding: 8px;\n    height: auto;\n    background-size: 100% auto !important;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"header": "header_header__3Env3",
	"logo": "header_logo__1y3Xg"
};
module.exports = exports;
