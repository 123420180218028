import {
  GET_FINISHEDGOODS_REQUEST,
  GET_FINISHEDGOODS_SUCCESS,
  GET_FINISHEDGOODS_FAILURE,
  GET_VINBYFGCODE_REQUEST,
  GET_VINBYFGCODE_SUCCESS,
  GET_VINBYFGCODE_FAILURE,
} from "./FinishedGoodsTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getFinishedGoodsRequest = () => {
  return {
    type: GET_FINISHEDGOODS_REQUEST,
  };
};

const getFinishedGoodsSuccess = (payload) => {
  return {
    type: GET_FINISHEDGOODS_SUCCESS,
    payload: payload,
  };
};

const getFinishedGoodsFailure = (error) => {
  return {
    type: GET_FINISHEDGOODS_FAILURE,
    payload: error,
  };
};

const getFinishedGoods = () => {
  return (dispatch) => {
    dispatch(getFinishedGoodsRequest());
    let url = `${baseUrl}/fg_process/get_all_tr`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getFinishedGoodsSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getFinishedGoodsFailure(err.message));
      });
  };
};

const getVinByFgcodeRequest = () => {
  return {
    type: GET_VINBYFGCODE_REQUEST,
  };
};

const getVinByFgcodeSuccess = (payload) => {
  return {
    type: GET_VINBYFGCODE_SUCCESS,
    payload: payload,
  };
};

const getVinByFgcodeFailure = (error) => {
  return {
    type: GET_VINBYFGCODE_FAILURE,
    payload: error,
  };
};

const getVinByFgCode = (fgCode) => {
  return (dispatch) => {
    dispatch(getVinByFgcodeRequest());
    let url = `${baseUrl}/fg_process/get_fg_by_fgcode?fg_code=${fgCode}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getVinByFgcodeSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getVinByFgcodeFailure(err.message));
      });
  };
};

export { getFinishedGoods, getVinByFgCode };
