import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { makeStyles } from "@material-ui/core/styles";
import drawerCss from "../../style/drawer";
import classNames from "classnames";
import { connect } from "react-redux";
import {
  updateSelectedItem,
  getAllGRN,
  getLineItemsByGrn,
  individualPrint,
  packagePrint,
} from "../../store";
import MaterialTable from "material-table";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, FieldArray } from "formik";
import { format, addMinutes } from "date-fns";
import { useHistory } from "react-router-dom";

import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import Config from "../../config/index";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const desLength = Config.descriptionLength;

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

const GRN = ({
  drawerState,
  handleListItemClick,
  getAllGRN,
  allGRN,
  getLineItemsByGrn,
  lineItemsByGrn,
  individualPrint,
  packagePrint,
}) => {
  useEffect(() => {
    handleListItemClick(null, 2);
  }, []);

  const classes = useStyles();
  let drawer = drawerState;
  const history = useHistory();

  let loginDetails = cookies.get("loginDetails");
  let role = loginDetails.data.role;

  useEffect(() => {
    getAllGRN();
  }, []);

  const [state, setState] = useState({
    columns: [
      { title: "GRN No", field: "grnNo" },
      { title: "ASN Code", field: "asncode" },
      { title: "GRN Date", field: "grnDate" },
      // { title: "GR Qty", field: "grQty" },
      { title: "Vendor Code/Name", field: "vendorCodeName" },
      { title: "Action", field: "action" },
    ],
    data: [],
  });

  const [tableHeading, setTableHeading] = useState("");
  const [vendorCodeName, setVendorCodeName] = useState("");

  const [openGrnItemModal, setOpenGrnItemModal] = useState(false);

  const handleOpenGrnItemModal = () => {
    setOpenGrnItemModal(!openGrnItemModal);
  };

  const [lineItems, setLineItems] = useState({});

  const handleOpenLineItemsModal = (lineItems = {}) => {
    setLineItems(lineItems);
  };

  const [grn, setGrn] = useState("");

  useEffect(() => {
    getLineItemsByGrn(grn);
  }, [grn]);

  useEffect(() => {
    let tempGRN = [];
    let data = allGRN.GRN ? allGRN.GRN : [];
    data.map((singleGRN) => {
      const formatDate = new Date(singleGRN.grnDateTime);

      singleGRN["grnNo"] = singleGRN.grnNumber;
      singleGRN["asncode"] = singleGRN.asnCode;
      singleGRN["grnDate"] = format(
        addMinutes(formatDate, formatDate.getTimezoneOffset()),
        "do MMMM yyyy"
        // "do MMMM yyyy, h:mm a"
      );
      // singleGRN["quantity"] = singleGRN.grQty;
      // singleGRN["nob"];
      singleGRN[
        "vendorCodeName"
      ] = `${singleGRN.vendorCode} / ${singleGRN.vendorName}`;
      singleGRN["action"] = (
        <button
          className="btn btn-success"
          type="button"
          onClick={() => {
            setGrn(singleGRN.grnNumber);
            handleOpenLineItemsModal(singleGRN);
            setOpenGrnItemModal(true);
            setTableHeading(singleGRN.grnNumber);
            setVendorCodeName(
              `${singleGRN.vendorCode} / ${singleGRN.vendorName}`
            );
          }}
        >
          View Line Items
        </button>
      );
      tempGRN.push(singleGRN);
    });
    setState({ ...state, data: tempGRN });
  }, [allGRN]);

  const [singleLineItem, setSingleLineItem] = useState({});

  const handleOpenSingleLineItemModal = (item = {}) => {
    setSingleLineItem(item);
  };

  useEffect(() => {
    let tempLineItems = [];
    let data = lineItemsByGrn.lineItems ? lineItemsByGrn?.lineItems : [];
    data.map((lineItem) => {
      let des = lineItem?.itemDescription;
      let len = desLength;
      const formatDate = new Date(lineItem.mfgDateTime);
      const lineItemObject = {
        itemCodeDes: `${lineItem.itemCode} / ${
          des ? des.substring(0, len) : ""
        }`,
        mfgDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        quantity: `${lineItem.itemQty} ${lineItem.uom}`,
        nob: lineItem.noOfBox,
        lot: lineItem.lotNumber,
        itemCategory: lineItem.itemCategoryCode
          ? lineItem.itemCategoryCode
          : lineItem.itemCategoryDescription,
        action: (
          <button
            type="button"
            // disabled={
            //   lineItem.isPrinted === 1 && role !== "admin" ? true : false
            // }
            className={
              lineItem.isPrinted === 0 ? `btn btn-info` : `btn btn-danger`
            }
            onClick={() => {
              if (lineItem.isPrinted === 0) {
                handleOpenSingleLineItemModal(lineItem);
                handleOpenPrintLabelModal();
              }
              if (lineItem.isPrinted === 1) {
                history.push("/reprint");
              }
            }}
          >
            {lineItem.isPrinted === 0 ? `Print` : `Re-Print`}
          </button>
        ),
      };
      tempLineItems.push(lineItemObject);
    });
    setItemTable({ ...itemTable, data: tempLineItems });
  }, [lineItemsByGrn]);

  const [itemTable, setItemTable] = useState({
    columns: [
      { title: "Item Code/Des", field: "itemCodeDes" },
      { title: "MFG Date", field: "mfgDate" },
      { title: "Quantity", field: "quantity" },
      { title: "NOB", field: "nob" },
      { title: "LOT", field: "lot" },
      {
        title: "Item Category",
        field: "itemCategory",
      },
      // { title: "Warehouse", field: "warehouseName" },
      { title: "Action", field: "action" },
    ],
    data: [],
  });

  const [openPrintLabelModal, setOpenPrintLabelModal] = useState(false);

  const handleOpenPrintLabelModal = () => {
    setOpenPrintLabelModal(true);
  };
  const handleClosePrintLabelModal = () => {
    setBalance("");
    setConfirmationMsg(false);
    setOpenPrintLabelModal(false);
  };

  const initialValue = {
    printingType: "",
    noOfPackages: "",
    qtyInPackage: "",
    packageArray: [],
    // location: "",
    // type: true,
  };

  const onSubmitForPrint = async (values, { resetForm }) => {
    setOpenPrintLabelModal(false);
    if (values.printingType === "Individual Printing") {
      const payload = singleLineItem;

      let res = await individualPrint(payload);

      if (res.status) {
        getAllGRN();
        getLineItemsByGrn(grn);
        setConfirmationMsg(false);
        // setOpenGrnItemModal(false);
        // handleOpenSingleLineItemModal(singleLineItem);
        // handleOpenLineItemsModal(lineItems);
      }
      if (!res.status) {
      }
    }

    if (values.printingType === "Package Printing") {
      let arr = values.packageArray;
      const payload = { ...singleLineItem, packages: arr.filter((n) => n) };

      // setOpenPrintLabelModal(false);

      let res = await packagePrint(payload);

      if (res.status) {
        getAllGRN();
        getLineItemsByGrn(grn);
        setConfirmationMsg(false);
        // setOpenGrnItemModal(false);
      }
      if (!res.status) {
      }
    }
  };

  const [confirmationMsg, setConfirmationMsg] = useState(false);

  const onClickForConfirmation = () => {
    setConfirmationMsg(true);
  };

  const [sumOfArray, setSumOfArray] = useState("");

  const [balance, setBalance] = useState("");

  useEffect(() => {
    let rem = singleLineItem.itemQty - sumOfArray;
    if (isNaN(rem)) {
      setBalance("");
    }
    if (!isNaN(rem)) {
      setBalance(rem);
    }
  }, [sumOfArray]);

  let pushPackage;

  // const [isErrorDeleteItemAllocation, setIsErrorDeleteItemAllocation] =
  //   useState(false);

  const [openBalanceItemModal, setOpenBalanceItemModal] = useState(false);

  // const handleOpenBalanceItemModal = (data = {}) => {
  //   // setItemAllocationData(data);
  //   setOpenBalanceItemModal(true);
  // };

  const handleCloseBalanceItemModal = () => {
    setOpenBalanceItemModal(false);
  };

  const [balanceItemMsg, setBalanceItemMsg] = useState("");

  useEffect(() => {
    if (balance > 0) {
      setBalanceItemMsg(`${balance} more qty to be printed is
      remaining`);
    }
    if (balance < 0) {
      setBalanceItemMsg(`${Math.abs(balance)} qty needs to be removed`);
    }
  }, [balance]);

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title="GRN"
              columns={state.columns}
              data={state.data}
              options={{ draggable: false }}
            />
            <div>
              <Modal
                show={openGrnItemModal}
                onHide={handleOpenGrnItemModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
                size="xl"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Items</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body modal-body">
                        <MaterialTable
                          title={
                            <div>
                              <h5>GRN: {tableHeading}</h5>
                              <h5>Vendor: {vendorCodeName}</h5>
                            </div>
                          }
                          columns={itemTable.columns}
                          data={itemTable.data}
                          options={{ draggable: false }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>

            <div>
              <Modal
                show={openPrintLabelModal}
                onHide={handleClosePrintLabelModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Print Label</Modal.Title>
                </Modal.Header>
                {/* <div className="modal-body"> */}
                <div className="row modal-body">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Formik
                          initialValues={initialValue}
                          onSubmit={onSubmitForPrint}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label
                                    htmlFor="printingType"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Type of Printing
                                  </label>
                                  <div>
                                    <Field
                                      type="radio"
                                      name="printingType"
                                      value="Individual Printing"
                                    />
                                    <span
                                      style={{
                                        marginLeft: "0.5rem",
                                        fontSize: "15px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Individual Printing
                                    </span>
                                  </div>
                                  <div>
                                    <Field
                                      type="radio"
                                      name="printingType"
                                      value="Package Printing"
                                    />
                                    <span
                                      style={{
                                        marginLeft: "0.5rem",
                                        fontSize: "15px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Package Printing
                                    </span>
                                  </div>
                                </div>

                                {formik.values.printingType ===
                                  "Package Printing" && (
                                  <div>
                                    <div style={{ display: "flex" }}>
                                      <h5>Total: {singleLineItem.itemQty}</h5>
                                      <h5 style={{ marginLeft: "2rem" }}>
                                        {formik.values.packageArray.length >
                                          0 && `Balance: ${balance}`}
                                      </h5>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                      {formik.values.packageArray.length ===
                                        0 && (
                                        <div className="form-group">
                                          <label htmlFor="noOfPackages">
                                            Number of Packages
                                          </label>
                                          <Field
                                            type="text"
                                            name="noOfPackages"
                                            className="form-control"
                                            autocomplete="off"
                                            style={{ width: "8rem" }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                    {formik.values.packageArray.length ===
                                      0 && (
                                      <div>
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          style={{ marginBottom: "1rem" }}
                                          onClick={() => {
                                            for (
                                              let i = 0;
                                              i < formik.values.noOfPackages;
                                              i++
                                            ) {
                                              pushPackage();
                                            }
                                          }}
                                        >
                                          Create Package
                                        </button>
                                      </div>
                                    )}
                                    <div>
                                      <FieldArray name="packageArray">
                                        {(fieldArrayProps) => {
                                          const { push, remove, form } =
                                            fieldArrayProps;
                                          const { values } = form;
                                          const { packageArray } = values;
                                          pushPackage = push;
                                          return (
                                            <div>
                                              {packageArray.map(
                                                (type, index) => (
                                                  <div
                                                    key={index}
                                                    style={{
                                                      display: "flex",
                                                    }}
                                                  >
                                                    <label
                                                      htmlFor="packageArray"
                                                      style={{
                                                        marginTop: "0.4rem",
                                                      }}
                                                    >
                                                      <h5>
                                                        Package {index + 1}
                                                      </h5>
                                                    </label>
                                                    <Field
                                                      name={`packageArray[${index}]`}
                                                      type="number"
                                                      autocomplete="off"
                                                      className="form-control"
                                                      style={{
                                                        width: "8rem",
                                                        marginLeft: "1rem",
                                                      }}
                                                      value={
                                                        formik.values
                                                          .packageArray[index]
                                                      }
                                                      onChange={(e) => {
                                                        formik.handleChange(e);
                                                      }}
                                                      onBlur={() => {
                                                        setSumOfArray(
                                                          formik.values.packageArray.reduce(
                                                            (a, b) => a + b,
                                                            0
                                                          )
                                                        );
                                                      }}
                                                    />

                                                    <span>
                                                      {index > 0 && (
                                                        <button
                                                          type="button"
                                                          onClick={(e) => {
                                                            remove(index);

                                                            setSumOfArray(
                                                              formik.values.packageArray.reduce(
                                                                (a, b) => a + b,
                                                                0
                                                              )
                                                            );
                                                          }}
                                                          style={{
                                                            marginLeft: "1rem",
                                                            marginTop: "0.4rem",
                                                          }}
                                                        >
                                                          <i
                                                            className="fa fa-times"
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          ></i>
                                                        </button>
                                                      )}
                                                    </span>
                                                  </div>
                                                )
                                              )}
                                              {balance !== 0 &&
                                                formik.values.packageArray
                                                  .length > 0 && (
                                                  <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    style={{
                                                      marginBottom: "1rem",
                                                    }}
                                                    onClick={() => {
                                                      push("");
                                                    }}
                                                  >
                                                    Add Package
                                                  </button>
                                                )}
                                            </div>
                                          );
                                        }}
                                      </FieldArray>
                                    </div>
                                  </div>
                                )}
                                <h6>
                                  {formik.values.printingType ===
                                    "Individual Printing" &&
                                    confirmationMsg &&
                                    `Do you want to print ${singleLineItem.itemQty} individual labels?`}
                                  {formik.values.printingType ===
                                    "Package Printing" &&
                                    confirmationMsg &&
                                    `Do you want to print ${formik.values.packageArray.length} package labels?`}
                                </h6>
                                {balance !== 0 && balance !== "" && (
                                  <h6 style={{ color: "red" }}>
                                    {balanceItemMsg}
                                  </h6>
                                )}
                                <div class="clearfix">
                                  {!confirmationMsg &&
                                    formik.values.printingType !== "" && (
                                      <button
                                        type="button"
                                        onClick={onClickForConfirmation}
                                        className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                        // disabled={
                                        //   formik.values.printingType === ""
                                        //     ? true
                                        //     : !formik.isValid &&
                                        //       formik.values.printingType ===
                                        //         "Package Printing" &&
                                        //       balance !== 0
                                        //     ? true
                                        //     : false
                                        // }
                                        disabled={
                                          formik.values.printingType ===
                                          "Package Printing"
                                            ? (balance === 0) === true
                                              ? false
                                              : true
                                            : false
                                        }
                                      >
                                        Print Label
                                      </button>
                                    )}
                                  {confirmationMsg && (
                                    <span>
                                      <button
                                        type="button"
                                        className="cancelbtn button1"
                                        onClick={() => {
                                          setOpenPrintLabelModal(false);
                                          setConfirmationMsg(false);
                                        }}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        type="submit"
                                        className="deletebtn button1"
                                      >
                                        Yes
                                      </button>
                                    </span>
                                  )}
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                        {/* <p>Are you sure you want to Print Label?</p> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </Modal>
            </div>

            <div>
              <Modal
                show={openBalanceItemModal}
                onHide={handleCloseBalanceItemModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
                // size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title style={{ color: "red" }}>Warning</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <h4>{balanceItemMsg}</h4>

                        <button
                          type="button"
                          className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                          onClick={handleCloseBalanceItemModal}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    allGRN: state.allGRN,
    lineItemsByGrn: state.lineItemsByGrn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getAllGRN: () => dispatch(getAllGRN()),
    getLineItemsByGrn: (grn) => dispatch(getLineItemsByGrn(grn)),
    individualPrint: (payload) => dispatch(individualPrint(payload)),
    packagePrint: (payload) => dispatch(packagePrint(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GRN);
