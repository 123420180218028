export const GET_SALESORDER_REQUEST = "GET_SALESORDER_REQUEST";
export const GET_SALESORDER_SUCCESS = "GET_SALESORDER_SUCCESS";
export const GET_SALESORDER_FAILURE = "GET_SALESORDER_FAILURE";

export const PUT_APPROVEVIN_REQUEST = "PUT_APPROVEVIN_REQUEST";
export const PUT_APPROVEVIN_SUCCESS = "PUT_APPROVEVIN_SUCCESS";
export const PUT_APPROVEVIN_FAILURE = "PUT_APPROVEVIN_FAILURE";

export const GET_VINSBYTRIPNO_REQUEST = "GET_VINSBYTRIPNO_REQUEST";
export const GET_VINSBYTRIPNO_SUCCESS = "GET_VINSBYTRIPNO_SUCCESS";
export const GET_VINSBYTRIPNO_FAILURE = "GET_VINSBYTRIPNO_FAILURE";
