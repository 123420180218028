import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { makeStyles } from "@material-ui/core/styles";
import drawerCss from "../../style/drawer";
import classNames from "classnames";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import {
  updateSelectedItem,
  getWarehouses,
  getGrnTimestamp,
  getAllGRN,
} from "../../store";
import Config from "../../config/index";
import DatePicker from "react-datepicker";
import { format, addMinutes } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

const desLength = Config.descriptionLength;

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

const useTabStyles = makeStyles((theme) => ({
  DateFilter: {
    marginLeft: "1rem",
  },
}));

const GrnTimestamp = ({
  drawerState,
  handleListItemClick,
  getWarehouses,
  getAllGRN,
  allGRN,
  warehouses,
  getGrnTimestamp,
  getGrnTimestampState,
}) => {
  useEffect(() => {
    handleListItemClick(null, 24);
  }, []);

  const tabClasses = useTabStyles();
  const classes = useStyles();
  let drawer = drawerState;

  useEffect(() => {
    getAllGRN();
  }, []);

  useEffect(() => {
    getWarehouses();
  }, []);

  const [grnIdSelected, setGrnIdSelected] = useState("");
  const [warehouseIdSelected, setWarehouseIdSelected] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    // if (startDate || endDate) {
    const isoStartDate = new Date(
      (startDate ? startDate : new Date()).getTime() -
        (startDate ? startDate : new Date()).getTimezoneOffset() * 60000
    ).toISOString();

    const isoEndDate = new Date(
      (endDate ? endDate : new Date()).getTime() -
        (endDate ? endDate : new Date()).getTimezoneOffset() * 60000
    ).toISOString();

    getGrnTimestamp(
      grnIdSelected,
      warehouseIdSelected,
      isoStartDate,
      isoEndDate
    );

    // }
  }, [grnIdSelected, warehouseIdSelected, startDate, endDate]);

  const [grnOptions, setGrnOptions] = useState([]);

  useEffect(() => {
    let tempGrnOptions = [];
    let data = allGRN.GRN ? allGRN.GRN : [];
    data.map((singleGRN) => {
      tempGrnOptions.push({
        key: singleGRN.grnNumber,
        value: singleGRN.grnNumber,
      });
    });
    setGrnOptions(tempGrnOptions);
  }, [allGRN]);

  const [warehouseOptions, setWarehouseOptions] = useState([]);

  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      tempWarehouseOptions.push({
        key: warehouse.warehouseName,
        value: warehouse.id,
      });
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  const [state, setState] = useState({
    columns: [
      { title: "GRN", field: "grnNumber" },
      { title: "Warehouse", field: "warehouseName" },
      { title: "Start Time", field: "putawayStartTime" },
      { title: "End Time", field: "putawayEndTime" },
    ],
    data: [],
  });

  useEffect(() => {
   
    let tempReports = [];
    let data = getGrnTimestampState.GrnTimestamp
      ? getGrnTimestampState.GrnTimestamp
      : [];
    data.map((singleData) => {
      let startTime = new Date(singleData.putawayStartTime);
      let endTime = new Date(singleData.putawayEndTime);
      const dataObject = {
        grnNumber: singleData.grnNumber,
        warehouseName: singleData.warehouseName,
        putawayStartTime: format(
          addMinutes(startTime, startTime.getTimezoneOffset()),
          "do MMMM yyyy, h:mm a"
        ),
        putawayEndTime: format(
          addMinutes(endTime, endTime.getTimezoneOffset()),
          "do MMMM yyyy, h:mm a"
        ),
      };
      tempReports.push(dataObject);
    });
    setState({ ...state, data: tempReports });
  }, [getGrnTimestampState]);

  const [showFilters, setShowFilters] = useState(false);

  const showFiltersHandler = () => {
    setShowFilters(!showFilters);
  };

  const changeGrn = (e) => {
    setGrnIdSelected(e.target.value);
  };

  const changeWarehouse = (e) => {
    setWarehouseIdSelected(e.target.value);
  };

  // const onChange = (dates) => {
  //   const [start, end] = dates;
  //   setStartDate(start);
  //   setEndDate(end);
  // };

  // const [dateRange, setDateRange] = useState([null, null]);
  // const [startDate, endDate] = dateRange;

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title={
                <div>
                  <br />
                  <h5>GRN Timestamp</h5>
                  <br />
                  {!showFilters && <br />}
                  {!showFilters && <br />}
                  {showFilters && (
                    <span style={{ display: "flex" }}>
                      <select
                        style={{ width: "11rem" }}
                        as="select"
                        id="warehouseId"
                        name="warehouseId"
                        className="form-control"
                        onChange={changeWarehouse}
                      >
                        <option value="" selected>
                          All Warehouses
                        </option>
                        {warehouseOptions.map((option) => {
                          return (
                            <option key={option.value} value={option.value}>
                              {option.key}
                            </option>
                          );
                        })}
                      </select>
                      <select
                        style={{
                          marginLeft: "1rem",
                          width: "11rem",
                          marginRight: "1rem",
                        }}
                        as="select"
                        id="itemId"
                        name="itemId"
                        className="form-control"
                        onChange={changeGrn}
                      >
                        <option value="" selected>
                          All GRN
                        </option>
                        {grnOptions.map((option) => {
                          return (
                            <option key={option.value} value={option.value}>
                              {option.key}
                            </option>
                          );
                        })}
                      </select>
                      <span style={{ width: "11rem" }}>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Start Date"
                          maxDate={new Date()}
                          onChange={(date) => setStartDate(date)}
                          isClearable={true}
                          withPortal
                          selectsStart
                          selected={startDate}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </span>
                      <span
                        style={{
                          marginLeft: "0.5rem",
                          marginRight: "0.5rem",
                          marginTop: "0.5rem",
                        }}
                      >
                        -
                      </span>
                      <span style={{ width: "11rem" }}>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="End Date"
                          maxDate={new Date()}
                          onChange={(date) => setEndDate(date)}
                          isClearable={true}
                          withPortal
                          selectsEnd
                          selected={endDate}
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </span>
                    </span>
                  )}
                </div>
              }
              columns={state.columns}
              data={state.data}
              localization={{
                body: {
                  emptyDataSourceMessage: "Choose a filter to display data",
                },
              }}
              actions={[
                {
                  icon: "search",
                  tooltip: "Show Filters",
                  isFreeAction: true,
                  onClick: () => {
                    showFiltersHandler();
                  },
                },
              ]}
              options={{
                draggable: false,
                exportButton: true,
                exportAllData: true,
                exportFileName: `GRN Timestamp`,
                filtering: true,
              }}
              components={{
                Action: (props) => (
                  <button
                    style={{ width: "4.5rem" }}
                    onClick={() => props.action.onClick()}
                  >
                    Filters
                  </button>
                ),
              }}
            />
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    allGRN: state.allGRN,
    warehouses: state.warehouses,
    getGrnTimestampState: state.getGrnTimestamp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getAllGRN: () => dispatch(getAllGRN()),
    getWarehouses: () => dispatch(getWarehouses()),
    getGrnTimestamp: (grnNumber, warehouseId, startDate, endDate) =>
      dispatch(getGrnTimestamp(grnNumber, warehouseId, startDate, endDate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GrnTimestamp);
