import {
    GET_PICKLISTAGEING_REQUEST,
    GET_PICKLISTAGEING_SUCCESS,
    GET_PICKLISTAGEING_FAILURE,
  } from "./PicklistAgeingTypes";
  
  const initialStateForPicklistAgeing = {
    loading: false,
    PicklistAgeing: [],
    error: "",
  };
  
  const getPicklistAgeingReducer = (state = initialStateForPicklistAgeing, action) => {
    switch (action.type) {
      case GET_PICKLISTAGEING_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_PICKLISTAGEING_SUCCESS:
        return {
          loading: false,
          PicklistAgeing: action.payload,
          error: "",
        };
      case GET_PICKLISTAGEING_FAILURE:
        return {
          loading: false,
          PicklistAgeing: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  
  
  export { getPicklistAgeingReducer};
  