/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import TiLogo from "../assets/tilogo.png";
import tilogo1 from "../assets/tilogo1.png";
import tilogo2 from "../assets/tilogo2.png";
import tilogo3 from "../assets/tilogo3.png";
import tilogo4 from "../assets/tilogo4.png";
import tilogo5 from "../assets/tilogo5.png";
import tilogo7 from "../assets/tilogo7.png";
import tilogo9 from "../assets/tilogo9.png";
import React, { useState, useEffect } from "react";
import headerCss from "../style/header.module.css";
import logo from "../assets/mobillor.png";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  IconButton,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import StoreMallDirectoryIcon from "@material-ui/icons/StoreMallDirectory";
import StorefrontIcon from "@material-ui/icons/Storefront";
import ReceiptIcon from "@material-ui/icons/Receipt";
import LabelIcon from "@material-ui/icons/Label";
import DescriptionIcon from "@material-ui/icons/Description";
import CreateIcon from "@material-ui/icons/Create";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import CategoryIcon from "@material-ui/icons/Category";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import BlockIcon from "@material-ui/icons/Block";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import LockIcon from "@material-ui/icons/Lock";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import ListAltIcon from "@material-ui/icons/ListAlt";
import headerStyle from "../style/headerStyle";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  handleDrawerOpen,
  updateSelectedItem,
  getProfile,
  warehousesTypesHandle,
  userManagementModuleHandle,
  reportsHandle,
} from "../store";

import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import PrintIcon from "@material-ui/icons/Print";
import MobileIcon from "@material-ui/icons/MobileFriendly";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import StarBorder from "@material-ui/icons/StarBorder";
import BusinessIcon from "@material-ui/icons/Business";
import GroupIcon from "@material-ui/icons/Group";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import InsertDriveFileTwoToneIcon from "@material-ui/icons/InsertDriveFileTwoTone";
import ListAltRoundedIcon from "@material-ui/icons/ListAltRounded";
import Tooltip from "@material-ui/core/Tooltip";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AssignmentIcon from "@material-ui/icons/Assignment";
import NoteIcon from "@material-ui/icons/Note";
import EventNoteOutlinedIcon from "@material-ui/icons/EventNoteOutlined";
import SubjectIcon from "@material-ui/icons/Subject";
import PrintTwoToneIcon from "@material-ui/icons/PrintTwoTone";
import Cookies from "universal-cookie";
import Config from "../config/index";
let mobillorUrl = Config.mobillorUrl;
const cookies = new Cookies();

const useStyles = makeStyles((theme) => headerStyle(theme));

const Header = ({
  warehousesState,
  warehousesTypesHandle,
  drawerState,
  handleDrawerOpen,
  selectedIndex,
  handleListItemClick,
  getProfile,
  profileData,
  userManagementModuleHandle,
  userManagementModuleState,
  reportsHandle,
  reportsState,
}) => {
  const classes = useStyles();

  const [state, setState] = useState({
    anchorEl: null,
  });

  const handleMenu = (event) => {
    setState({ ...state, anchorEl: event.currentTarget });
  };
  const handleClose = () => {
    setState({ ...state, anchorEl: null });
  };

  let userDetails;
  let roles;

  if (cookies.get("loginDetails") !== undefined) {
    roles = cookies.get("loginDetails").data.roleName;
  }

  useEffect(() => {
    if (cookies.get("loginDetails") !== undefined) {
      userDetails = cookies.get("loginDetails").data;
      let userId = userDetails.userId;
      getProfile(userId);
    }
  }, []);

  let loginStatus = cookies.get("loginDetails")
    ? cookies.get("loginDetails")
    : false;

  const { anchorEl } = state;
  const open = Boolean(anchorEl);

  // if (true) {
  if (loginStatus.status) {
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classes.appBar}
          foojon={classNames(classes.appBar, {
            [classes.appBarShift]: drawerState.open,
          })}
        >
          <Toolbar disableGutters={true}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={classes.menuButton}
            >
              <MenuIcon
                classes={{
                  root: drawerState.open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed,
                }}
              />
            </IconButton>
            <Typography
              variant="h6"
              color="inherit"
              className={`${classes.grow} ${headerCss.header}`}
              noWrap
            >
              {/* <img src={logo} alt="logo" className={headerCss.logo} /> */}
              <img
               // src={logo} 
                // src={tilogo7}
               // alt="logo"
                //className={headerCss.logo}
                // style={{ float: "right" }}
              />
            </Typography>
            <div>
              <div>
                <Tooltip
                  title={
                    profileData.profile.userData
                      ? profileData.profile.userData.name
                      : ""
                  }
                >
                  <IconButton
                    aria-owns={open ? "menu-appbar" : undefined}
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                    style={{ backgroundColor: "black" }}
                  >
                    <AccountCircle />
                  </IconButton>
                </Tooltip>
              </div>

              <div>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    className="link"
                    exact
                    to="/profile"
                    activeClassName="main-nav-active"
                    component={NavLink}
                  >
                    Profile{" "}
                  </MenuItem>
                  <MenuItem
                    className="link"
                    exact
                    to="/reset_password"
                    activeClassName="main-nav-active"
                    component={NavLink}
                  >
                    {" "}
                    Reset Password
                  </MenuItem>
                  <MenuItem
                    className="link"
                    exact
                    to="/logout"
                    activeClassName="main-nav-active"
                    component={NavLink}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: drawerState.open,
            [classes.drawerClose]: !drawerState.open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: drawerState.open,
              [classes.drawerClose]: !drawerState.open,
            }),
          }}
          open={drawerState.open}
        >
          <div className={classes.toolbar} style={{ marginTop: "1.2rem" }} />
          <List>
            <ListItem
              button
              key="Home"
              component={NavLink}
              to="/home"
              exact
              selected={selectedIndex === 15}
              onClick={(event) => handleListItemClick(event, 15)}
            >
              <ListItemIcon className={classes.icon}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button onClick={warehousesTypesHandle}>
              <ListItemIcon>
                <StoreOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Warehouses" />
              {warehousesState.open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={warehousesState.open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  selected={selectedIndex === 1}
                  onClick={(event) => handleListItemClick(event, 1)}
                  button
                  key="Source Warehouse"
                  component={NavLink}
                  to="/source_warehouse"
                  exact
                  style={{ paddingLeft: "30px" }}
                >
                  <ListItemIcon>
                    <StoreMallDirectoryIcon />
                  </ListItemIcon>
                  <ListItemText primary="Source Warehouse" />
                </ListItem>
                <ListItem
                  selected={selectedIndex === 7}
                  onClick={(event) => handleListItemClick(event, 7)}
                  button
                  component={NavLink}
                  key="Delivery Warehouse"
                  to="/delivery_warehouse"
                  exact
                  style={{ paddingLeft: "30px" }}
                >
                  <ListItemIcon>
                    <StorefrontIcon />
                  </ListItemIcon>
                  <ListItemText primary="Delivery Warehouse" />
                </ListItem>
                <ListItem
                  selected={selectedIndex === 12}
                  onClick={(event) => handleListItemClick(event, 12)}
                  button
                  component={NavLink}
                  key="FG Warehouse"
                  to="/fg_warehouse"
                  exact
                  style={{ paddingLeft: "30px" }}
                >
                  <ListItemIcon>
                    <LocalShippingOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="FG Warehouse" />
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              button
              key="GRN Details"
              component={NavLink}
              to="/grn_details"
              exact
              selected={selectedIndex === 2}
              onClick={(event) => handleListItemClick(event, 2)}
            >
              <ListItemIcon className={classes.icon}>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary="GRN Details" />
            </ListItem>
            <ListItem
              button
              key="Reprint"
              component={NavLink}
              to="/reprint"
              exact
              selected={selectedIndex === 28}
              onClick={(event) => handleListItemClick(event, 28)}
            >
              <ListItemIcon className={classes.icon}>
                <PrintTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary="Reprint" />
            </ListItem>
            <ListItem
              button
              key="Zone Allocation"
              component={NavLink}
              to="/zone_allocation"
              exact
              selected={selectedIndex === 3}
              onClick={(event) => handleListItemClick(event, 3)}
            >
              <ListItemIcon className={classes.icon}>
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText primary="Zone Allocation" />
            </ListItem>
            <ListItem
              button
              key="Item Allocation"
              component={NavLink}
              to="/item_allocation"
              exact
              selected={selectedIndex === 8}
              onClick={(event) => handleListItemClick(event, 8)}
            >
              <ListItemIcon className={classes.icon}>
                <LabelIcon />
              </ListItemIcon>
              <ListItemText primary="Item Allocation" />
            </ListItem>
            <ListItem
              button
              key="Item Master"
              component={NavLink}
              to="/item_master"
              exact
              selected={selectedIndex === 4}
              onClick={(event) => handleListItemClick(event, 4)}
            >
              <ListItemIcon className={classes.icon}>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Item Master" />
            </ListItem>
            <ListItem
              button
              key="Create Pickup List"
              component={NavLink}
              to="/create_pickup_list"
              exact
              selected={selectedIndex === 5}
              onClick={(event) => handleListItemClick(event, 5)}
            >
              <ListItemIcon className={classes.icon}>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="Create Pickup List" />
            </ListItem>
            <ListItem
              button
              key="Pickup List"
              component={NavLink}
              to="/pickup_list"
              exact
              selected={selectedIndex === 6}
              onClick={(event) => handleListItemClick(event, 6)}
            >
              <ListItemIcon className={classes.icon}>
                <FormatListNumberedIcon />
              </ListItemIcon>
              <ListItemText primary="Pickup List" />
            </ListItem>
            <ListItem
              button
              key="Create Transfer Order"
              component={NavLink}
              to="/create_transfer"
              exact
              selected={selectedIndex === 13}
              onClick={(event) => handleListItemClick(event, 13)}
            >
              <ListItemIcon className={classes.icon}>
                <SwapHorizIcon />
              </ListItemIcon>
              <ListItemText primary="Create Transfer Order" />
            </ListItem>
            <ListItem
              button
              key="Transfer Order"
              component={NavLink}
              to="/transfer_order"
              exact
              selected={selectedIndex === 14}
              onClick={(event) => handleListItemClick(event, 14)}
            >
              <ListItemIcon className={classes.icon}>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Transfer Order" />
            </ListItem>
            <ListItem
              button
              key="Finished Goods"
              component={NavLink}
              to="/finished_goods"
              exact
              selected={selectedIndex === 9}
              onClick={(event) => handleListItemClick(event, 9)}
            >
              <ListItemIcon className={classes.icon}>
                <AllInboxIcon />
              </ListItemIcon>
              <ListItemText primary="Finished Goods" />
            </ListItem>
            <ListItem
              button
              key="Dispatch"
              component={NavLink}
              to="/dispatch"
              exact
              selected={selectedIndex === 10}
              onClick={(event) => handleListItemClick(event, 10)}
            >
              <ListItemIcon className={classes.icon}>
                <LocalShippingIcon />
              </ListItemIcon>
              <ListItemText primary="Dispatch" />
            </ListItem>
            <ListItem
              button
              key="Rejection Warehouse"
              component={NavLink}
              to="/rejection_warehouse"
              exact
              selected={selectedIndex === 11}
              onClick={(event) => handleListItemClick(event, 11)}
            >
              <ListItemIcon className={classes.icon}>
                <BlockIcon />
              </ListItemIcon>
              <ListItemText primary="Rejection Warehouse" />
            </ListItem>
            <ListItem button onClick={userManagementModuleHandle}>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="User Mgmt." />
              {userManagementModuleState.open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={userManagementModuleState.open}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem
                  selected={selectedIndex === 18}
                  onClick={(event) => handleListItemClick(event, 18)}
                  button
                  key="Department"
                  component={NavLink}
                  to="/department"
                  exact
                  style={{ paddingLeft: "40px" }}
                >
                  <ListItemIcon>
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText primary="Department" />
                </ListItem>
                <ListItem
                  selected={selectedIndex === 19}
                  onClick={(event) => handleListItemClick(event, 19)}
                  button
                  key="User Roles"
                  component={NavLink}
                  to="/user_roles"
                  exact
                  style={{ paddingLeft: "40px" }}
                >
                  <ListItemIcon>
                    <StarBorder />
                  </ListItemIcon>
                  <ListItemText primary="User Roles" />
                </ListItem>
                <ListItem
                  selected={selectedIndex === 20}
                  onClick={(event) => handleListItemClick(event, 20)}
                  button
                  key="User Details"
                  component={NavLink}
                  to="/user_details"
                  exact
                  style={{ paddingLeft: "40px" }}
                >
                  <ListItemIcon>
                    <GroupAddIcon />
                  </ListItemIcon>
                  <ListItemText primary="User Details" />
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              selected={selectedIndex === 16}
              onClick={(event) => handleListItemClick(event, 16)}
              button
              key="Printer Management"
              component={NavLink}
              to="/printer_management"
              exact
            >
              <ListItemIcon>
                <PrintIcon />
              </ListItemIcon>
              <ListItemText primary="Printer Management" />
            </ListItem>
            <ListItem
              selected={selectedIndex === 17}
              onClick={(event) => handleListItemClick(event, 17)}
              button
              key="Device Management"
              component={NavLink}
              to="/device_management"
              exact
            >
              <ListItemIcon>
                <MobileIcon />
              </ListItemIcon>
              <ListItemText primary="Device Management" />
            </ListItem>

            <ListItem button onClick={reportsHandle}>
              <ListItemIcon>
                <FileCopyIcon />
              </ListItemIcon>
              <ListItemText primary="Reports" />
              {reportsState.open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={reportsState.open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  selected={selectedIndex === 21}
                  onClick={(event) => handleListItemClick(event, 21)}
                  button
                  key="Warehouse Occupancy"
                  component={NavLink}
                  to="/warehouse_occupancy"
                  exact
                  style={{ paddingLeft: "40px" }}
                >
                  <ListItemIcon>
                    <InsertDriveFileOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Warehouse Occu." />
                </ListItem>
                <ListItem
                  selected={selectedIndex === 22}
                  onClick={(event) => handleListItemClick(event, 22)}
                  button
                  key="Yard Occupancy"
                  component={NavLink}
                  to="/yard_occupancy"
                  exact
                  style={{ paddingLeft: "40px" }}
                >
                  <ListItemIcon>
                    <SubjectIcon />
                  </ListItemIcon>
                  <ListItemText primary="Yard Occupancy" />
                </ListItem>
                <ListItem
                  selected={selectedIndex === 24}
                  onClick={(event) => handleListItemClick(event, 24)}
                  button
                  key="GRN Timestamp"
                  component={NavLink}
                  to="/grn_timestamp"
                  exact
                  style={{ paddingLeft: "40px" }}
                >
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary="GRN Timestamp" />
                </ListItem>
                <ListItem
                  selected={selectedIndex === 25}
                  onClick={(event) => handleListItemClick(event, 25)}
                  button
                  key="Picklist Ageing"
                  component={NavLink}
                  to="/picklist_ageing"
                  exact
                  style={{ paddingLeft: "40px" }}
                >
                  <ListItemIcon>
                    <ListAltRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Picklist Ageing" />
                </ListItem>
                <ListItem
                  selected={selectedIndex === 26}
                  onClick={(event) => handleListItemClick(event, 26)}
                  button
                  key="Inventory Ageing"
                  component={NavLink}
                  to="/inventory_ageing"
                  exact
                  style={{ paddingLeft: "40px" }}
                >
                  <ListItemIcon>
                    <NoteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Inventory Ageing" />
                </ListItem>
                <ListItem
                  selected={selectedIndex === 27}
                  onClick={(event) => handleListItemClick(event, 27)}
                  button
                  key="FG Ageing"
                  component={NavLink}
                  to="/fg_ageing"
                  exact
                  style={{ paddingLeft: "40px" }}
                >
                  <ListItemIcon>
                    <EventNoteOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="FG Ageing" />
                </ListItem>
                <ListItem
                  selected={selectedIndex === 23}
                  onClick={(event) => handleListItemClick(event, 23)}
                  button
                  key="Rejection Ageing"
                  component={NavLink}
                  to="/rejection_ageing"
                  exact
                  style={{ paddingLeft: "40px" }}
                >
                  <ListItemIcon>
                    <InsertDriveFileTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Rejection Ageing" />
                </ListItem>
              </List>
            </Collapse>
          </List>
          <Divider />
          <List>
            <ListItem
              button
              key="Logout"
              component={NavLink}
              to="/logout"
              exact
            >
              <ListItemIcon>
                <LockIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Drawer>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar className={headerCss.header}>
            <img src={logo} alt="logo" className={headerCss.logo} />
          </Toolbar>
        </AppBar>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    selectedIndex: state.setecedItem.selectedIndex,
    profileData: state.profile,
    warehousesState: state.warehousesState,
    userManagementModuleState: state.userManagementModuleState,
    reportsState: state.reportsState,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    handleDrawerOpen: () => dispatch(handleDrawerOpen()),
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getProfile: (userId) => dispatch(getProfile(userId)),
    warehousesTypesHandle: () => dispatch(warehousesTypesHandle()),
    userManagementModuleHandle: () => dispatch(userManagementModuleHandle()),
    reportsHandle: () => dispatch(reportsHandle()),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Header);
