import {
  GET_GRNTIMESTAMP_REQUEST,
  GET_GRNTIMESTAMP_SUCCESS,
  GET_GRNTIMESTAMP_FAILURE,
} from "./GrnTimestampTypes";
import axios from "axios";
import Config from "../../../config/index";
let baseUrl = Config.baseUrl;

const getGrnTimestampRequest = () => {
  return {
    type: GET_GRNTIMESTAMP_REQUEST,
  };
};

const getGrnTimestampSuccess = (payload) => {
  return {
    type: GET_GRNTIMESTAMP_SUCCESS,
    payload: payload,
  };
};

const getGrnTimestampFailure = (error) => {
  return {
    type: GET_GRNTIMESTAMP_FAILURE,
    payload: error,
  };
};

const getGrnTimestamp = (grnNumber, warehouseId, startDate, endDate) => {
  return (dispatch) => {
    dispatch(getGrnTimestampRequest());
    let url = `${baseUrl}/reports/get_grn_putaway_time?${
      grnNumber === "" ? "" : `grn_number=${grnNumber}`
    }${
      warehouseId === "" ? "" : `&warehouse_id=${warehouseId}`
    }&putaway_start_time=${startDate}&putaway_end_time=${endDate}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getGrnTimestampSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getGrnTimestampFailure(err.message));
      });
  };
};

export { getGrnTimestamp };
