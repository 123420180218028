export const GET_REJECTIONS_REQUEST = "GET_REJECTIONS_REQUEST";
export const GET_REJECTIONS_SUCCESS = "GET_REJECTIONS_SUCCESS";
export const GET_REJECTIONS_FAILURE = "GET_REJECTIONS_FAILURE";

export const PUT_ACCEPTREJECTION_REQUEST = "PUT_ACCEPTREJECTION_REQUEST";
export const PUT_ACCEPTREJECTION_SUCCESS = "PUT_ACCEPTREJECTION_SUCCESS";
export const PUT_ACCEPTREJECTION_FAILURE = "PUT_ACCEPTREJECTION_FAILURE";

export const CREATE_REJECTION_REQUEST = "CREATE_REJECTION_REQUEST";
export const CREATE_REJECTION_SUCCESS = "CREATE_REJECTION_SUCCESS";
export const CREATE_REJECTION_FAILURE = "CREATE_REJECTION_FAILURE";
