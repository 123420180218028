import {
  GET_CREATEPICKUPLIST_REQUEST,
  GET_CREATEPICKUPLIST_SUCCESS,
  GET_CREATEPICKUPLIST_FAILURE,
  GET_PICKUPLIST_REQUEST,
  GET_PICKUPLIST_SUCCESS,
  GET_PICKUPLIST_FAILURE,
  CREATEPICKUPLIST_REQUEST,
  CREATEPICKUPLIST_SUCCESS,
  CREATEPICKUPLIST_FAILURE,
  GET_PICKUPITEM_REQUEST,
  GET_PICKUPITEM_SUCCESS,
  GET_PICKUPITEM_FAILURE,
} from "./PickupListTypes";

const initialStateForCreatePickupList = {
  loading: false,
  createPickupList: [],
  error: "",
};

const createPickupListReducer = (
  state = initialStateForCreatePickupList,
  action
) => {
  switch (action.type) {
    case GET_CREATEPICKUPLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CREATEPICKUPLIST_SUCCESS:
      return {
        loading: false,
        createPickupList: action.payload,
        error: "",
      };
    case GET_CREATEPICKUPLIST_FAILURE:
      return {
        loading: false,
        createPickupList: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForCreatePickupListPutRequest = {
  loading: false,
  createPickupList: [],
  error: "",
};

const createPickupListPutRequestReducer = (
  state = initialStateForCreatePickupListPutRequest,
  action
) => {
  switch (action.type) {
    case CREATEPICKUPLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATEPICKUPLIST_SUCCESS:
      return {
        loading: false,
        createPickupList: action.payload,
        error: "",
      };
    case CREATEPICKUPLIST_FAILURE:
      return {
        loading: false,
        createPickupList: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForPickupList = {
  loading: false,
  pickupList: [],
  error: "",
};

const pickupListReducer = (state = initialStateForPickupList, action) => {
  switch (action.type) {
    case GET_PICKUPLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PICKUPLIST_SUCCESS:
      return {
        loading: false,
        pickupList: action.payload,
        error: "",
      };
    case GET_PICKUPLIST_FAILURE:
      return {
        loading: false,
        pickupList: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForPickupItem = {
  loading: false,
  pickupItem: [],
  error: "",
};

const getPickupItemReducer = (state = initialStateForPickupItem, action) => {
  switch (action.type) {
    case GET_PICKUPITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PICKUPITEM_SUCCESS:
      return {
        loading: false,
        pickupItem: action.payload,
        error: "",
      };
    case GET_PICKUPITEM_FAILURE:
      return {
        loading: false,
        pickupItem: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  createPickupListReducer,
  createPickupListPutRequestReducer,
  pickupListReducer,
  getPickupItemReducer,
};
