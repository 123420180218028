/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
*/

import {
    GET_USER_ROLES_REQUEST,
    GET_USER_ROLES_SUCCESS,
    GET_USER_ROLES_FAILURE,
    CREATE_USER_ROLE_REQUEST,
    CREATE_USER_ROLE_SUCCESS,
    CREATE_USER_ROLE_FAILURE,
    UPDATE_USER_ROLE_REQUEST,
    UPDATE_USER_ROLE_SUCCESS,
    UPDATE_USER_ROLE_FAILURE,
    DELETE_USER_ROLE_REQUEST,
    DELETE_USER_ROLE_SUCCESS,
    DELETE_USER_ROLE_FAILURE,
    GET_PRODUCTS_BY_CLIENT_ID_REQUEST,
    GET_PRODUCTS_BY_CLIENT_ID_SUCCESS,
    GET_PRODUCTS_BY_CLIENT_ID_FAILURE,
    GET_USER_ROLES_BY_DEPARTMENT_REQUEST,
    GET_USER_ROLES_BY_DEPARTMENT_SUCCESS,
    GET_USER_ROLES_BY_DEPARTMENT_FAILURE
} from './userRolesTypes';

const initialState = {
    loading: false,
    userRoles: {},
    error: ""
}

const getUserRolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_ROLES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_USER_ROLES_SUCCESS:
            return {
                loading: true,
                userRoles: action.payload,
                error: ''
            }
        case GET_USER_ROLES_FAILURE:
            return {
                loading: false,
                userRoles: {},
                error: action.payload
            }
        default:
            return state
    }
}


const initialStateForCreate = {
    loading: false,
    userRole: {},
    error: ""
}

const createUserRoleReducer = (state = initialStateForCreate, action) => {
    switch (action.type) {
        case CREATE_USER_ROLE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_USER_ROLE_SUCCESS:
            return {
                loading: true,
                userRole: action.payload,
                error: ''
            }
        case CREATE_USER_ROLE_FAILURE:
            return {
                loading: false,
                userRole: {},
                error: action.payload
            }
        default:
            return state
    }
}

const initialStateForUpdate = {
    loading: false,
    userRole: {},
    error: ""
}

const updateUserRoleReducer = (state = initialStateForUpdate, action) => {
    switch (action.type) {
        case UPDATE_USER_ROLE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_USER_ROLE_SUCCESS:
            return {
                loading: true,
                userRole: action.payload,
                error: ''
            }
        case UPDATE_USER_ROLE_FAILURE:
            return {
                loading: false,
                userRole: {},
                error: action.payload
            }
        default:
            return state
    }
}

// const initialStateForUpdate = {
//     loading: false,
//     userRole: {},
//     error: ""
// }

// const updateUserRoleReducer = (state = initialStateForUpdate, action) => {
//     switch (action.type) {
//         case UPDATE_USER_ROLE_REQUEST:
//             return {
//                 ...state,
//                 loading: true
//             }
//         case UPDATE_USER_ROLE_SUCCESS:
//             return {
//                 loading: true,
//                 userRole: action.payload,
//                 error: ''
//             }
//         case UPDATE_USER_ROLE_FAILURE:
//             return {
//                 loading: false,
//                 userRole: {},
//                 error: action.payload
//             }
//         default:
//             return state
//     }
// }

const initialStateForDelete = {
    loading: false,
    userRole: {},
    error: ""
}

const deleteUserRoleReducer = (state = initialStateForDelete, action) => {
    switch (action.type) {
        case DELETE_USER_ROLE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_USER_ROLE_SUCCESS:
            return {
                loading: true,
                userRole: action.payload,
                error: ''
            }
        case DELETE_USER_ROLE_FAILURE:
            return {
                loading: false,
                userRole: {},
                error: action.payload
            }
        default:
            return state
    }
}

const initialStateForProduct = {
    loading: false,
    products: {},
    error: ""
}

const getProductsReducer = (state = initialStateForProduct, action) => {
    switch (action.type) {
        case GET_PRODUCTS_BY_CLIENT_ID_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_PRODUCTS_BY_CLIENT_ID_SUCCESS:
            return {
                loading: true,
                products: action.payload,
                error: ''
            }
        case GET_PRODUCTS_BY_CLIENT_ID_FAILURE:
            return {
                loading: false,
                products: {},
                error: action.payload
            }
        default:
            return state
    }
}

const initialStateForUserRolesByDepartment = {
    loading: false,
    roles: {},
    error: ""
}

const getUserRolesByDepartment = (state = initialStateForUserRolesByDepartment, action) => {
    switch (action.type) {
        case GET_USER_ROLES_BY_DEPARTMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_USER_ROLES_BY_DEPARTMENT_SUCCESS:
            return {
                loading: true,
                roles: action.payload,
                error: ''
            }
        case GET_USER_ROLES_BY_DEPARTMENT_FAILURE:
            return {
                loading: false,
                roles: {},
                error: action.payload
            }
        default:
            return state
    }
}

export {
    getUserRolesReducer,
    createUserRoleReducer,
    updateUserRoleReducer,
    deleteUserRoleReducer,
    getProductsReducer,
    getUserRolesByDepartment
}