/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import classNames from "classnames";
import { connect } from "react-redux";
import Header from "../Header";
import drawerCss from "../../style/drawer";
import { getUsers, addUser, deleteUser, updateSelectedItem } from "../../store";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import Footer from "../Footer";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../utils/TextError";
import Alert from "@material-ui/lab/Alert";
import Cookies from "universal-cookie";
import "../../style/delete.css";
const cookies = new Cookies();

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

function UserManagement(props) {
  const { users, getUsers, addUser, deleteUser, handleListItemClick } = props;

  const [state, setState] = useState({
    columns: [
      { title: "Name", field: "name" },
      { title: "Email-Id", field: "email" },
      { title: "Expiry Type", field: "expiryType" },
      { title: "Remaining Days", field: "remainingDays" },
      { title: "User Role", field: "role" },
      { title: "Action", field: "actions" },
    ],
    data: [],
  });

  const expiryTypes = [
    { key: "2 Days", value: "2_days" },
    { key: "1 Week", value: "1_week" },
    { key: "1 Month", value: "1_month" },
    { key: "Never expire", value: "never_expire" },
  ];
  const [isErrorForEmail, setIsErrorForEmail] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  let drawer = props.drawerState;
  let clientId = cookies.get("loginDetails").data.clientId;
  useEffect(() => {
    getUsers(clientId);
  }, [getUsers]);

  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [singleUserDataForDelete, setUserDataForDelete] = useState({});

  const handleOpenDeleteUserModal = (user = {}) => {
    setUserDataForDelete(user);
    setOpenDeleteUserModal(true);
  };
  const handleCloseDeleteUserModal = () => {
    setOpenDeleteUserModal(false);
  };

  useEffect(() => {
    const expiryTypesTemp = [
      { key: "2_days", value: "2 Days" },
      { key: "1_week", value: "1 Week" },
      { key: "1_month", value: "1 Month" },
      { key: "never_expire", value: "Never expire" },
    ];
    let usersDataTemp = [];
    let usersData = users.users ? users.users : [];
    usersData.map((user) => {
      user["expiryType"] = expiryTypesTemp[user.expiryType];
      user["actions"] = (
        <div>
          {/* <button type="button" className="btn btn-success" style={{ marginRight: "5px" }} onClick={() => handleOpenUpdateDepartmentModal(department)}><i className="fa fa-edit"></i></button> */}
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handleOpenDeleteUserModal(user)}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      );
      usersDataTemp.push(user);
    });
    setState({ ...state, data: usersDataTemp });
  }, [users]);

  useEffect(() => {
    handleListItemClick(null, 2);
  });

  const [createUsersModal, setCreateUsersModal] = useState(false);
  const handleCreateUsersModal = () => {
    setCreateUsersModal(!createUsersModal);
  };

  const checkEmail = (email) => {
    let tempEmailforAdmin = cookies.get("loginDetails").data.email;
    let tempEmailForNewUser = email.split("@");
    tempEmailforAdmin = tempEmailforAdmin.split("@");
    tempEmailforAdmin = tempEmailforAdmin[1].split(".");
    tempEmailForNewUser = tempEmailForNewUser[1].split(".");
    if (
      tempEmailForNewUser[0] === tempEmailforAdmin[0] ||
      tempEmailForNewUser[0] === "mobillor"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const initialValue = {
    name: "",
    email: "",
    validTill: "",
    clientId: clientId,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Required!"),
    email: Yup.string().email("Invalid email format").required("Required!"),
    validTill: Yup.string().required("Required!"),
  });

  const [isErrorCreateUser, setIsErrorCreateUser] = useState(false);
  const [isSuccessCreateUser, setIsSuccessCreateUser] = useState(false);

  const onSubmit = async (values, { resetForm }) => {
    if (checkEmail(values.email)) {
      let res = await addUser(values);

      if (res.status) {
        setIsSuccessCreateUser(true);
        resetForm();
        getUsers(clientId);
      }
      if (!res.status) {
        setIsErrorCreateUser(true);
      }
    } else {
      setIsErrorForEmail(true);
    }
  };

  const deleteUserData = async () => {
    let id = singleUserDataForDelete.id;
    let response = await deleteUser(id);
    if (response.status) {
      await getUsers(clientId);
      handleCloseDeleteUserModal();
    }
  };

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title="Users"
              columns={state.columns}
              data={state.data}
              actions={[
                {
                  icon: "add",
                  tooltip: "Add New User",
                  isFreeAction: true,
                  onClick: (event) => handleCreateUsersModal(),
                },
              ]}
            />
            <div>
              <Modal
                show={createUsersModal}
                onHide={handleCreateUsersModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Create User</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Formik
                          initialValues={initialValue}
                          validationSchema={validationSchema}
                          onSubmit={onSubmit}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="name">Name</label>
                                  <Field
                                    type="text"
                                    name="name"
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component={TextError}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="email">Email</label>
                                  <Field
                                    type="text"
                                    name="email"
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component={TextError}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="validTill">Expiry Type</label>
                                  <Field
                                    as="select"
                                    id="validTill"
                                    name="validTill"
                                    className="form-control"
                                  >
                                    <option value="" disabled selected>
                                      Choose one...
                                    </option>
                                    {expiryTypes.map((option) => {
                                      return (
                                        <option
                                          key={option.key}
                                          value={option.value}
                                        >
                                          {option.key}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                  <ErrorMessage
                                    name="validTill"
                                    component={TextError}
                                  />
                                </div>
                                <div className="mt-3">
                                  <Collapse in={isErrorCreateUser}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsErrorCreateUser(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Please check your user. Server error!
                                    </Alert>
                                  </Collapse>
                                  <Collapse in={isSuccessCreateUser}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccessCreateUser(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      User Create successfully
                                    </Alert>
                                  </Collapse>
                                  <Collapse in={isErrorForEmail}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsErrorForEmail(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Please check your email domain!
                                    </Alert>
                                  </Collapse>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                    disabled={!formik.isValid}
                                  >
                                    Create User
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                show={openDeleteUserModal}
                onHide={handleCloseDeleteUserModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Delete User </Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <p>Are you sure you want to delete this user?</p>
                        <div class="clearfix">
                          <button
                            type="button"
                            className="cancelbtn button1"
                            onClick={handleCloseDeleteUserModal}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="deletebtn button1"
                            onClick={deleteUserData}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    drawerState: state.drawerState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (clientId) => dispatch(getUsers(clientId)),
    addUser: (newData) => dispatch(addUser(newData)),
    deleteUser: (id) => dispatch(deleteUser(id)),
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
