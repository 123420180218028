/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React, { useEffect, useState } from "react";
import Header from "../Header";
import { connect } from "react-redux";
import drawerCss from "../../style/drawer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import "../../style/profile.css";
import { getProfile, updateSelectedItem } from "../../store";
import Footer from "../Footer";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const useStyles = makeStyles((theme) => drawerCss(theme));

function Profile({
  profileData,
  getProfile,
  drawerState,
  handleListItemClick,
}) {
  const classes = useStyles();
  const theme = useTheme();
  let drawer = drawerState;
  let userId = cookies.get("loginDetails").data.userId;
  useEffect(() => {
    getProfile(userId);
  }, [getProfile]);

  useEffect(() => {
    handleListItemClick(null, 0);
  }, []);

  const [profileInfo, setProfileInfo] = useState({});
  useEffect(() => {
    let temp = profileData.profile.data ? profileData.profile.data[0] : {};
    setProfileInfo(temp);
  }, [profileData]);

  {
    return (
      <div>
        <section id="cover" className="min-vh-100">
          <Header />
          <main
            className={classNames(classes.content, {
              [classes.contentShift]: drawer.open ? false : true,
            })}
          >
            <div className="screenSize">
              <div classNam="container">
                <div className="row">
                  <div className="col-md-7">
                    <div className="card ">
                      <div className="card-header">
                        {" "}
                        <h4>User Profile</h4>
                      </div>
                      <div className="card-block">
                        <div className="box box-info">
                          <div className="box-body">
                            <br></br>
                            <div className="col-sm-6">
                              <div align="center">
                                {" "}
                                <img
                                  alt="User Pic"
                                  src="https://p.kindpng.com/picc/s/78-785827_user-profile-avatar-login-account-male-user-icon.png"
                                  id="profile-image1"
                                  className="img-circle img-fluid"
                                />
                                <input
                                  id="profile-image-upload"
                                  className="hidden"
                                  type="file"
                                />
                              </div>
                            </div>
                            <br />
                            <div className="col-sm-6 profileName">
                              <h4>{profileInfo.name}</h4>
                              <span>
                                <p>{profileInfo.role}</p>
                              </span>
                            </div>
                            <div className="clearfix"></div>
                            <hr style={{ margin: "5px 0 5px 0" }} />

                            <div className="col-sm-5 col-xs-6 tital">
                              Name :
                            </div>
                            <div className="col-sm-7 col-xs-6 ">
                              {profileInfo.name}
                            </div>
                            <div className="clearfix"></div>
                            <div className="bot-border"></div>

                            <div className="col-sm-5 col-xs-6 tital ">
                              Email-Id
                            </div>
                            <div className="col-sm-7">{profileInfo.email}</div>
                            <div className="clearfix"></div>
                            <div className="bot-border"></div>

                            <div className="col-sm-5 col-xs-6 tital  ">
                              User Role
                            </div>
                            <div className="col-sm-7">{profileInfo.role}</div>

                            <div className="clearfix"></div>
                            <div className="bot-border"></div>

                            <div className="col-sm-5 col-xs-6 tital">
                              Client-Id :
                            </div>
                            <div className="col-sm-7">
                              {profileInfo.clientId}
                            </div>

                            <div className="clearfix"></div>
                            <div className="bot-border"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profileData: state.profile,
    drawerState: state.drawerState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (userId) => dispatch(getProfile(userId)),
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
