export const GET_CREATEPICKUPLIST_REQUEST = "GET_CREATEPICKUPLIST_REQUEST";
export const GET_CREATEPICKUPLIST_SUCCESS = "GET_CREATEPICKUPLIST_SUCCESS";
export const GET_CREATEPICKUPLIST_FAILURE = "GET_CREATEPICKUPLIST_FAILURE";

export const CREATEPICKUPLIST_REQUEST = "CREATEPICKUPLIST_REQUEST";
export const CREATEPICKUPLIST_SUCCESS = "CREATEPICKUPLIST_SUCCESS";
export const CREATEPICKUPLIST_FAILURE = "CREATEPICKUPLIST_FAILURE";

export const GET_PICKUPLIST_REQUEST = "GET_PICKUPLIST_REQUEST";
export const GET_PICKUPLIST_SUCCESS = "GET_PICKUPLIST_SUCCESS";
export const GET_PICKUPLIST_FAILURE = "GET_PICKUPLIST_FAILURE";

export const GET_PICKUPITEM_REQUEST = "GET_PICKUPITEM_REQUEST";
export const GET_PICKUPITEM_SUCCESS = "GET_PICKUPITEM_SUCCESS";
export const GET_PICKUPITEM_FAILURE = "GET_PICKUPITEM_FAILURE";
