/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
*/

import {
    GET_LOGIN_ID_REQUEST,
    GET_LOGIN_ID_SUCCESS,
    GET_LOGIN_ID_FAILURE
} from './loginIdTypes'


const initialState = {
    loading: false,
    loginIds: {},
    error: ""
}

const getLoginIdsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOGIN_ID_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_LOGIN_ID_SUCCESS:
            return {
                loading: true,
                loginIds: action.payload,
                error: ''
            }
        case GET_LOGIN_ID_FAILURE:
            return {
                loading: false,
                loginIds: {},
                error: action.payload
            }
        default:
            return state
    }
}

export {
    getLoginIdsReducer
}