import React, { useState, useEffect } from "react";
import Header from "../../Header";
import { connect } from "react-redux";
import drawerCss from "../../../style/drawer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import Footer from "../../Footer";
import MaterialTable from "material-table";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../../utils/TextError";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import {
  getUsersDetails,
  createUserDetails,
  updateUserDetails,
  deleteUserDetails,
  getDepartments,
  getUserRoles,
  getUserRolesByDepartment,
  getLoginIds,
  updateSelectedItem,
} from "../../../store";
import "../../../style/delete.css";
import Select from "react-select";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const useStyles = makeStyles((theme) => drawerCss(theme));

function UserDetails({
  drawerState,
  handleListItemClick,
  userDetailsReducer,
  getUsersDetails,
  createUserDetails,
  updateUserDetails,
  deleteUserDetails,
  getUserRoles,
  userRoles,
  getDepartments,
  departments,
  getLoginIds,
  getLoginIdsReducer,
  getUserRolesByDepartment,
  userRolesByDepartmentData,
}) {
  const classes = useStyles();
  const theme = useTheme();
  let drawer = drawerState;
  let userDetails = cookies.get("loginDetails").data;
  let clientId = userDetails.clientId;
  let userId = userDetails.id ? userDetails.id : "5fbca8f7590ba86a040c094d";
  const [state, setState] = useState({
    columns: [
      { title: "Name", field: "name" },
      { title: "Login-ID", field: "loginId" },
      // { title: 'Email-Id', field: 'email' },
      // { title: "Phone Number", field: "phoneNumber" },
      { title: "Department", field: "departmentName" },
      { title: "Role", field: "roleName" },
      { title: "Actions", field: "actions", export: false },
    ],
    data: [],
  });

  useEffect(() => {
    getUsersDetails();
  }, [getUsersDetails]);

  useEffect(() => {
    getUserRoles(clientId);
  }, [getUserRoles]);

  useEffect(() => {
    getDepartments();
  }, [getDepartments]);

  useEffect(() => {
    getLoginIds();
  }, [getLoginIds]);

  useEffect(() => {
    handleListItemClick(null, 20);
  });

  const [loginIdsOptions, setLoginIdsOptions] = useState([]);
  useEffect(() => {
    let loginIdsDataTemp = [];
    let loginIdsData = getLoginIdsReducer.loginIds.data
      ? getLoginIdsReducer.loginIds.data
      : [];
    loginIdsData.map((loginId) => {
      loginIdsDataTemp.push({
        label: loginId.loginId + " - " + loginId.name,
        value: loginId.loginId,
      });
    });
    setLoginIdsOptions(loginIdsDataTemp);
  }, [getLoginIdsReducer]);

  const [loginIdSelected, setLoginIdSelected] = useState("");
  const handleLoginIdSelected = (selectedOption) => {
    setLoginIdSelected(selectedOption);
  };

  const [rolesOptions, setRolesOptions] = useState([]);
  useEffect(() => {
    let roles = userRolesByDepartmentData.roles.rolesData
      ? userRolesByDepartmentData.roles.rolesData
      : [];
    let rolesOptionsTemp = [];
    roles.map((role) => {
      rolesOptionsTemp.push({ label: role.role, value: role.roleId });
    });
    setRolesOptions(rolesOptionsTemp);
  }, [userRolesByDepartmentData]);

  const [roleSelected, setRoleSelected] = useState("");
  const handleRoleSelected = (selectedOption) => {
    setRoleSelected(selectedOption);
  };

  const [departmentsOptions, setDepartmentsptions] = useState([]);
  useEffect(() => {
    let tempDepartment = [];
    let departmentsTemp = departments.departments.departmentsData
      ? departments.departments.departmentsData
      : [];
    departmentsTemp.map((department) => {
      tempDepartment.push({
        label: department.departmentName,
        value: department.departmentId,
      });
    });
    setDepartmentsptions(tempDepartment);
  }, [departments]);
  const [departmentSelected, setDepartmentSelected] = useState("");
  const handleDepartmentSelected = (selectedOption) => {
    setDepartmentSelected(selectedOption);
    getUserRolesByDepartment(selectedOption.value);
  };

  const [openDeleteUserDetailsModal, setOpenDeleteUserDetailsModal] =
    useState(false);
  const [singleUserDetailsDataForDelete, setUserDetailsDataForDelete] =
    useState({});

  const handleOpenDeleteUserDetailsModal = (userDetails = {}) => {
    setUserDetailsDataForDelete(userDetails);
    setOpenDeleteUserDetailsModal(true);
  };
  const handleCloseDeleteUserDetailsModal = () => {
    setOpenDeleteUserDetailsModal(false);
  };

  const [openEditUserDetailsModal, setOpenEditUserDetailsModal] =
    useState(false);
  const [singleUserDetailsDataForUpdate, setSingleUserDetailsDataForUpdate] =
    useState({});
  const handleOpenEditUserDetailsModal = (userDetails = {}) => {
    getUserRolesByDepartment(userDetails.departmentId);
    setLoginIdSelected({
      label: userDetails.loginId,
      value: userDetails.loginId,
    });
    setDepartmentSelected({
      label: userDetails.departmentName,
      value: userDetails.departmentId,
    });
    setRoleSelected({ label: userDetails.roleName, value: userDetails.roleId });
    setSingleUserDetailsDataForUpdate(userDetails);
    setOpenEditUserDetailsModal(true);
  };
  const handleCloseEditUserDetailsModal = () => {
    setRoleSelected("");
    setDepartmentSelected("");
    setLoginIdSelected("");
    setOpenEditUserDetailsModal(false);
    setIsErrorForUpdate(false);
    setIsSuccessForUpdate(false);
  };

  useEffect(() => {
    let tempUserDetails = [];
    let userDetails = userDetailsReducer.usersDetails.userData
      ? userDetailsReducer.usersDetails.userData
      : [];
    userDetails.map((user) => {
      user["actions"] = (
        <div>
          {/* <button type="button" className="btn btn-primary" style={{ marginRight: "5px" }}><i className="fa fa-eye"></i></button> */}
          <button
            title="Edit User Details"
            type="button"
            className="btn btn-success"
            style={{ marginRight: "5px" }}
            onClick={() => handleOpenEditUserDetailsModal(user)}
          >
            <i className="fa fa-edit"></i>
          </button>
          <button
            title="Delete User Details"
            type="button"
            className="btn btn-danger"
            onClick={() => handleOpenDeleteUserDetailsModal(user)}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      );
      tempUserDetails.push(user);
    });
    setState({ ...state, data: tempUserDetails });
  }, [userDetailsReducer]);

  const [createUserDetailsModal, setCreateUserDetailsModal] = useState(false);
  const handleCreateUserDetailsModal = () => {
    setCreateUserDetailsModal(!createUserDetailsModal);
    setRoleSelected("");
    setDepartmentSelected("");
    setIsErrorCreateUserDetails(false);
    setIsSuccessCreateUserDetails(false);
  };

  const initialValue = {
    loginId: "",
    roleId: "",
    roleName: "",
    departmentId: "",
    departmentName: "",
    createdBy: userId,
    clientId: clientId,
  };

  // const validationSchema = Yup.object({
  //     name: Yup.string().required("Required!"),
  //     email: Yup.string().email('Invalid email format').required("Required!"),
  //     phoneNumber: Yup.string().required("Required!")
  // })

  const [isErrorCreateUserDetails, setIsErrorCreateUserDetails] =
    useState(false);
  const [isSuccessCreateUserDetails, setIsSuccessCreateUserDetails] =
    useState(false);

  const onSubmit = async (values, { resetForm }) => {
    values.loginId = loginIdSelected.value;
    values.roleId = roleSelected.value;
    values.roleName = roleSelected.label;
    values.departmentId = departmentSelected.value;
    values.departmentName = departmentSelected.label;

    let res = await createUserDetails(values);
    if (res.status) {
      setIsSuccessCreateUserDetails(true);
      setRoleSelected("");
      setDepartmentSelected("");
      setLoginIdSelected("");
      getUsersDetails();
    }
    if (!res.status) {
      setIsErrorCreateUserDetails(true);
    }
    resetForm();
  };

  const initialValueForUpdate = {
    loginId: singleUserDetailsDataForUpdate.loginId,
    // name: singleUserDetailsDataForUpdate.name,
    // email: singleUserDetailsDataForUpdate.email,
    // phoneNumber: singleUserDetailsDataForUpdate.phoneNumber,
    roleId: singleUserDetailsDataForUpdate.roleId,
    roleName: singleUserDetailsDataForUpdate.roleName,
    departmentId: singleUserDetailsDataForUpdate.departmentId,
    departmentName: singleUserDetailsDataForUpdate.departmentName,
    createdBy: singleUserDetailsDataForUpdate.createdBy,
    clientId: singleUserDetailsDataForUpdate.clientId,
    userId: singleUserDetailsDataForUpdate.userId,
  };

  // const validationSchemaForUpdate = Yup.object({
  //     name: Yup.string().required("Required!"),
  //     email: Yup.string().email('Invalid email format').required("Required!"),
  //     phoneNumber: Yup.string().required("Required!"),
  // })

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);
  const onSubmitForUpdate = async (values, { resetForm }) => {
    values.loginId = loginIdSelected.value;
    values.roleId = roleSelected.value;
    values.roleName = roleSelected.label;
    values.departmentId = departmentSelected.value;
    values.departmentName = departmentSelected.label;
    let res = await updateUserDetails(values);
    if (res.status) {
      setIsSuccessForUpdate(true);
      getUsersDetails();
    }
    if (!res.status) {
      setIsErrorForUpdate(true);
    }
    // resetForm();
  };

  const deleteUserDetailsData = async () => {
    let userId = singleUserDetailsDataForDelete.userId;
    let response = await deleteUserDetails(userId);
    if (response.status) {
      await getUsersDetails();
      handleCloseDeleteUserDetailsModal();
    }
  };
  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title="User Details"
              columns={state.columns}
              data={state.data}
              actions={[
                {
                  icon: "add",
                  tooltip: "Add New User",
                  isFreeAction: true,
                  onClick: (event) => handleCreateUserDetailsModal(),
                  iconProps: {
                    color: "error",
                    fontSize: "large",
                  },
                },
              ]}
              options={{
                exportButton: true,
                exportAllData: true,
                draggable: false,
              }}
            />
            <div>
              <Modal
                show={createUserDetailsModal}
                onHide={handleCreateUserDetailsModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Assign Role Permissions</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        {/* validationSchema={validationSchema} */}
                        <Formik
                          initialValues={initialValue}
                          onSubmit={onSubmit}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="name">Login-Id</label>
                                  <Select
                                    value={loginIdSelected}
                                    onChange={handleLoginIdSelected}
                                    options={loginIdsOptions}
                                  />
                                </div>
                                {/* <div className="form-group">
                                                                    <label htmlFor="email">Email</label>
                                                                    <Field type="text" name="email" className="form-control" />
                                                                    <ErrorMessage name="email" component={TextError} />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="phoneNumber">Phone Number</label>
                                                                    <Field type="text" name="phoneNumber" className="form-control" />
                                                                    <ErrorMessage name="phoneNumber" component={TextError} />
                                                                </div> */}
                                <div className="form-group">
                                  <label htmlFor="department">Department</label>
                                  <Select
                                    value={departmentSelected}
                                    onChange={handleDepartmentSelected}
                                    options={departmentsOptions}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="role">Role</label>
                                  <Select
                                    value={roleSelected}
                                    onChange={handleRoleSelected}
                                    options={rolesOptions}
                                  />
                                </div>

                                <div className="mt-3">
                                  <Collapse in={isErrorCreateUserDetails}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsErrorCreateUserDetails(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      please check your user details. Server
                                      error!
                                    </Alert>
                                  </Collapse>
                                  <Collapse in={isSuccessCreateUserDetails}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccessCreateUserDetails(
                                              false
                                            );
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      User Details Create successfully
                                    </Alert>
                                  </Collapse>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                    disabled={!formik.isValid}
                                  >
                                    Create User Details
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                show={openDeleteUserDetailsModal}
                onHide={handleCloseDeleteUserDetailsModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Delete User Details</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <p>Are you sure you want to delete this user?</p>
                        <div class="clearfix">
                          <button
                            type="button"
                            className="cancelbtn button1"
                            onClick={handleCloseDeleteUserDetailsModal}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="deletebtn button1"
                            onClick={deleteUserDetailsData}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                show={openEditUserDetailsModal}
                onHide={handleCloseEditUserDetailsModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Update Role Permissions</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      {/* validationSchema={validationSchemaForUpdate} */}
                      <div className="card-body">
                        <Formik
                          initialValues={initialValueForUpdate}
                          onSubmit={onSubmitForUpdate}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="name">Login-Id</label>
                                  <Select
                                    value={loginIdSelected}
                                    onChange={handleLoginIdSelected}
                                    options={loginIdsOptions}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="department">Department</label>
                                  <Select
                                    value={departmentSelected}
                                    onChange={handleDepartmentSelected}
                                    options={departmentsOptions}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="role">Role</label>
                                  <Select
                                    value={roleSelected}
                                    onChange={handleRoleSelected}
                                    options={rolesOptions}
                                  />
                                </div>
                                <div className="mt-3">
                                  <Collapse in={isErrorForUpdate}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsErrorForUpdate(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      please check your user details details.
                                      Server error!
                                    </Alert>
                                  </Collapse>
                                  <Collapse in={isSuccessForUpdate}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccessForUpdate(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      User update successfully
                                    </Alert>
                                  </Collapse>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                    disabled={!formik.isValid}
                                  >
                                    Update Role Permissions
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    userDetailsReducer: state.getUsersDetailsReducer,
    userRoles: state.getUserRolesReducer,
    departments: state.getDepartmentsReducer,
    getLoginIdsReducer: state.getLoginIdsReducer,
    userRolesByDepartmentData: state.getUserRolesByDepartment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getUsersDetails: () => dispatch(getUsersDetails()),
    createUserDetails: (payload) => dispatch(createUserDetails(payload)),
    updateUserDetails: (payload) => dispatch(updateUserDetails(payload)),
    deleteUserDetails: (userId) => dispatch(deleteUserDetails(userId)),
    getUserRoles: (clientId) => dispatch(getUserRoles(clientId)),
    getDepartments: () => dispatch(getDepartments()),
    getLoginIds: () => dispatch(getLoginIds()),
    getUserRolesByDepartment: (departmentId) =>
      dispatch(getUserRolesByDepartment(departmentId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
