/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

const config = {
  auth3Url: "https://auth.mobillor.net/authapi",
  mobillorUrl: "http://13.71.2.248:8000",
  licensesUrl: "http://65.0.227.223:3000/api/v2",
  productName: "Digital&Industry4.0Suite",
  dashboardUrl: "http://localhost:4000",
  environmentType: "development",
  userManagementUrl: "https://dev.mobillor.net/demo-bkd",
  scenariosUrl: "http://10.10.2.36:3001",
  baseUrl: "https://dev.mobillor.net/demo-bkd/api",
  descriptionLength: 36,
};

export default config;
