/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 * 
 *  Mobillor Technologies Pvt. Ltd. 
 *  All Rights Reserved.
 * 
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.  
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
*/

import {
    USER_REQUEST,
    USER_SUCCESS,
    USER_FAILURE
} from './userTypes'

const initialState = {
    loading: false,
    users: [],
    error: ""
}

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case USER_SUCCESS:
            return {
                loading: true,
                users: action.payload,
                error: ''
            }
        case USER_FAILURE:
            return {
                loading: false,
                users: [],
                error: action.payload
            }
        default:
            return state
    }
}



export default usersReducer