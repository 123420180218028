/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Home from "./components/Home";
import Warehouse from "./components/Warehouse/Warehouse";
import GRN from "./components/GRN/GRN";
import ZoneAllocation from "./components/ZoneAllocation/ZoneAllocation";
import ItemMaster from "./components/ItemMaster/ItemMaster";
import PickupList from "./components/PickupList/PickupList";
import CreatePickupList from "./components/CreatePickupList/CreatePickupList";
import DeliveryWarehouse from "./components/DeliveryWarehouse/DeliveryWarehouse";
import ItemAllocation from "./components/ItemAllocation/ItemAllocation";
import FinishedGoods from "./components/FinishedGoods/FinishedGoods";
import Rejections from "./components/Rejections/Rejections";
import Dispatch from "./components/Dispatch/Dispatch";
import ViewLocation from "./components/ViewLocation/ViewLocation";

import { isExpired, decodeToken } from "react-jwt";
import Cookies from "universal-cookie";
import Users from "./components/UserManagement/Users";
import Profile from "./components/UserManagement/Profile";
import SetPassword from "./components/UserManagement/SetPassword";
import ForgotPassword from "./components/UserManagement/ForgotPassword";
import ResetPassword from "./components/UserManagement/ResetPassword";
import SetPasswordForNewUser from "./components/UserManagement/SetPasswordForNewUser";
import Logout from "./components/UserManagement/Logout";
import Login from "./components/UserManagement/Login";
import Config from "./config/index";
import FGWarehouse from "./components/FGWarehouse/FGWarehouse";
import CreateTransfer from "./components/CreateTransfer/CreateTransfer";
import TransferOrder from "./components/TransferOrder/TransferOrder";
import PrinterOnboarding from "./components/PrinterOnboarding/PinterOnboarding";
import DeviceOnboarding from "./components/DeviceOnboarding/DeviceOnboarding";
import UserRoles from "./components/UserManagementModule/UserRoles/UserRoles";
import UserDetails from "./components/UserManagementModule/UserDetails/UserDetails";
import Department from "./components/UserManagementModule/Department/Department";
import InventoryAgeing from "./components/Reports/InventoryAgeing";
import WarehouseOccupancy from "./components/Reports/WarehouseOccupancy";
import YardOccupancy from "./components/Reports/YardOccupancy";
import GrnTimestamp from "./components/Reports/GrnTimestamp";
import PicklistAgeing from "./components/Reports/PicklistAgeing";
import FgAgeing from "./components/Reports/FgAgeing";
import RejectionAgeing from "./components/Reports/RejectionAgeing";
import Reprint from "./components/Reprint/Reprint";

let mobillorUrl = Config.mobillorUrl;

const cookies = new Cookies();

function AppRoutesBackup() {
  let loginDetails = cookies.get("loginDetails");
  if (loginDetails) {
    let token = loginDetails.jwtToken;
    const isMyTokenExpired = isExpired(token);
    if (loginDetails.status && !isMyTokenExpired) {
      cookies.set("jwtForMobillorEditor", token, { path: "/" });
      cookies.Domain = mobillorUrl;
      return (
        <Router>
          <Switch>
            <Route exact path="/home" component={Home} />
            <Route exact path="/source_warehouse" component={Warehouse} />
            <Route
              exact
              path="/delivery_warehouse"
              component={DeliveryWarehouse}
            />
            <Route exact path="/fg_warehouse" component={FGWarehouse} />
            <Route
              exact
              path="/:warehouseType/:warehouseId"
              component={ViewLocation}
            />
            <Route exact path="/create_transfer" component={CreateTransfer} />
            <Route exact path="/transfer_order" component={TransferOrder} />
            <Route exact path="/grn_details" component={GRN} />
            <Route exact path="/reprint" component={Reprint} />
            <Route exact path="/zone_allocation" component={ZoneAllocation} />
            <Route exact path="/item_allocation" component={ItemAllocation} />
            <Route exact path="/item_master" component={ItemMaster} />
            <Route
              exact
              path="/create_pickup_list"
              component={CreatePickupList}
            />
            <Route exact path="/pickup_list" component={PickupList} />
            <Route exact path="/finished_goods" component={FinishedGoods} />
            <Route exact path="/rejection_warehouse" component={Rejections} />
            <Route exact path="/dispatch" component={Dispatch} />
            <Route
              exact
              path="/printer_management"
              component={PrinterOnboarding}
            />
            <Route
              exact
              path="/device_management"
              component={DeviceOnboarding}
            />

            <Route
              exact
              path="/warehouse_occupancy"
              component={WarehouseOccupancy}
            />
            <Route exact path="/yard_occupancy" component={YardOccupancy} />
            <Route exact path="/inventory_ageing" component={InventoryAgeing} />
            <Route exact path="/picklist_ageing" component={PicklistAgeing} />
            <Route exact path="/fg_ageing" component={FgAgeing} />
            <Route exact path="/rejection_ageing" component={RejectionAgeing} />
            <Route exact path="/grn_timestamp" component={GrnTimestamp} />

            <Route exact path="/user_roles" component={UserRoles} />
            <Route exact path="/user_details" component={UserDetails} />
            <Route exact path="/department" component={Department} />

            <Route exact path="/logout" component={Logout} />
            <Route exact path="/users" component={Users} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/reset_password" component={ResetPassword} />

            <Redirect to="/home" />
          </Switch>
        </Router>
      );
    } else {
      return (
        <Router>
          <Switch>
            <Route exact path="/logout" component={Logout} />
            <Redirect to="/logout" />
          </Switch>
        </Router>
      );
    }
  } else {
    return (
      <Router>
        <Switch>
          <Route exact path="/forgot_password" component={ForgotPassword} />
          <Route exact path="/set_password/:email" component={SetPassword} />
          <Route
            exact
            path="/set_password_for_new_user"
            component={SetPasswordForNewUser}
          />
          <Route exact path="/" component={Login} />
          <Redirect to="/" />
        </Switch>
      </Router>
    );
  }
}

export default AppRoutesBackup;
