import {
  GET_SKUFORREPRINT_REQUEST,
  GET_SKUFORREPRINT_SUCCESS,
  GET_SKUFORREPRINT_FAILURE,
  REPRINT_REQUEST,
  REPRINT_SUCCESS,
  REPRINT_FAILURE,
} from "./ReprintTypes";

const initialState = {
  loading: false,
  sku: [],
  error: "",
};

const getSkuForReprintReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SKUFORREPRINT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SKUFORREPRINT_SUCCESS:
      return {
        loading: false,
        sku: action.payload,
        error: "",
      };
    case GET_SKUFORREPRINT_FAILURE:
      return {
        loading: false,
        sku: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForReprint = {
  loading: false,
  sku: {},
  error: "",
};

const reprintReducer = (state = initialStateForReprint, action) => {
  switch (action.type) {
    case REPRINT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REPRINT_SUCCESS:
      return {
        loading: false,
        sku: action.payload,
        error: "",
      };
    case REPRINT_FAILURE:
      return {
        loading: false,
        sku: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getSkuForReprintReducer, reprintReducer };
