import {
    GET_GRNTIMESTAMP_REQUEST,
    GET_GRNTIMESTAMP_SUCCESS,
    GET_GRNTIMESTAMP_FAILURE,
  } from "./GrnTimestampTypes";
  
  const initialStateForGrnTimestamp = {
    loading: false,
    GrnTimestamp: [],
    error: "",
  };
  
  const getGrnTimestampReducer = (state = initialStateForGrnTimestamp, action) => {
    switch (action.type) {
      case GET_GRNTIMESTAMP_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_GRNTIMESTAMP_SUCCESS:
        return {
          loading: false,
          GrnTimestamp: action.payload,
          error: "",
        };
      case GET_GRNTIMESTAMP_FAILURE:
        return {
          loading: false,
          GrnTimestamp: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  
  
  export { getGrnTimestampReducer};
  