import {
  GET_SKUFORREPRINT_REQUEST,
  GET_SKUFORREPRINT_SUCCESS,
  GET_SKUFORREPRINT_FAILURE,
  REPRINT_REQUEST,
  REPRINT_SUCCESS,
  REPRINT_FAILURE,
} from "./ReprintTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getSkuForReprintRequest = () => {
  return {
    type: GET_SKUFORREPRINT_REQUEST,
  };
};

const getSkuForReprintSuccess = (payload) => {
  return {
    type: GET_SKUFORREPRINT_SUCCESS,
    payload: payload,
  };
};

const getSkuForReprintFailure = (error) => {
  return {
    type: GET_SKUFORREPRINT_FAILURE,
    payload: error,
  };
};

const getSkuForReprint = () => {
  return (dispatch) => {
    dispatch(getSkuForReprintRequest());
    let url = `${baseUrl}/reprint/get_sku`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getSkuForReprintSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getSkuForReprintFailure(err.message));
      });
  };
};

const reprintRequest = () => {
  return {
    type: REPRINT_REQUEST,
  };
};

const reprintSuccess = (payload) => {
  return {
    type: REPRINT_SUCCESS,
    payload: payload,
  };
};

const reprintFailure = (error) => {
  return {
    type: REPRINT_FAILURE,
    payload: error,
  };
};

const reprint = (payload) => {
  return (dispatch) => {
    dispatch(reprintRequest());
    let url = `${baseUrl}/reprint_sku`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(reprintSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(reprintFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export { getSkuForReprint, reprint };
