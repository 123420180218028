import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { makeStyles } from "@material-ui/core/styles";
import drawerCss from "../../style/drawer";
import classNames from "classnames";
import { connect } from "react-redux";
import {
  updateSelectedItem,
  getSalesOrder,
  getVinStatusByTripNo,
  approveVin,
} from "../../store";
import MaterialTable from "material-table";

import Modal from "react-bootstrap/Modal";
import { format, addMinutes } from "date-fns";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import Config from "../../config/index";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const desLength = Config.descriptionLength;

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

const Dispatch = ({
  drawerState,
  handleListItemClick,
  getSalesOrder,
  salesOrder,
  getVinStatusByTripNo,
  vinStatus,
  approveVin,
}) => {
  useEffect(() => {
    handleListItemClick(null, 10);
  }, []);

  const classes = useStyles();
  let drawer = drawerState;

  let loginDetails = cookies.get("loginDetails");
  let role = loginDetails.data.role;

  useEffect(() => {
    getSalesOrder();
  }, []);

  const [state, setState] = useState({
    columns: [
      { title: "Trip No", field: "tripNo" },
      /*  { title: "Trip Line", field: "tripLine" }, */
      { title: "Status", field: "status" },
      { title: "Qty", field: "qty" },
      { title: "Date & Time", field: "dateTime" },
    ],
    data: [],
  });
  const [vin, setVin] = useState([]);
  const [fgCode, setFgCode] = useState("");
  const [tripNo, setTripNo] = useState("");

  useEffect(() => {
    getVinStatusByTripNo(tripNo);
  }, [tripNo]);

  useEffect(() => {
    let tempSalesOrder = [];
    let data = salesOrder.salesOrder ? salesOrder.salesOrder : [];

    data.map((order) => {
      const formatDate = new Date(order.cd);
      const orderData = {
        tripNo: order.tripNo,
        status: (
          <button
            type="button"
            className={
              (order.status === 0 && `btn btn-danger`) ||
              (order.status === 1 && `btn btn-success`) ||
              (order.status === 2 && `btn btn-warning`)
            }
            style={{ width: "7rem" }}
            onClick={() => {
              setTripNo(order.tripNo);
              setFgCode(order.fgCode);
              setVin(order.vin);
              // handleOpenVinModal();
              setOpenVinModal(true);
            }}
          >
            {(order.status === 0 && `Not Picked`) ||
              (order.status === 1 && `Delivered`) ||
              (order.status === 2 && `Picked`)}
          </button>
        ),
        qty: `${order.qty} ${order.uom}`,
        dateTime: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy, h:mm a"
        ),
      };
      tempSalesOrder.push(orderData);
    });
    setState({ ...state, data: tempSalesOrder });
  }, [salesOrder]);

  const [vinModal, setOpenVinModal] = useState(false);

  const handleOpenVinModal = () => {
    setOpenVinModal(!vinModal);
  };

  const [vinTable, setVinTable] = useState({
    columns: [
      { title: "VIN", field: "vin" },
      { title: "FG Code/Des", field: "fgCodeDes" },
      { title: "Trip Line No", field: "tripLineNo" },
      { title: "SO/Line", field: "soLine" },
      { title: "Customer Name", field: "customerName" },
      { title: "Accessories", field: "accessories" },
      { title: "Status", field: "status" },
    ],
    data: [],
  });

  useEffect(() => {
    let tempVin = [];
    let data = vinStatus.vins ? vinStatus.vins : [];

    data.map((vin) => {
      vin.Vins.map((vin) => {
        let des = vin?.description;
        let len = desLength;
        const vinInfo = {
          vin: vin.vin,
          fgCodeDes: `${vin.fgCode} / ${des ? des.substring(0, len) : ""}`,
          tripLineNo: "",
          soLine: `${vin.salesOrderNumber} / ${vin.salesOrderLineNumber}`,
          customerName: vin.CustomerName,
          status: (
            <h4>
              <span
                className={
                  (vin.isDelivered === 0 &&
                    vin.isVinHold === 0 &&
                    `badge badge-danger`) ||
                  (vin.isDelivered === 1 &&
                    vin.isVinHold === 1 &&
                    `badge badge-success`) ||
                  (vin.isDelivered === 0 &&
                    vin.isVinHold === 1 &&
                    `badge badge-warning`)
                }
                style={{ width: "7rem" }}
              >
                {(vin.isDelivered === 0 &&
                  vin.isVinHold === 0 &&
                  `Not Picked`) ||
                  (vin.isDelivered === 1 &&
                    vin.isVinHold === 1 &&
                    `Delivered`) ||
                  (vin.isDelivered === 0 && vin.isVinHold === 1 && `Picked`)}
              </span>
            </h4>
          ),
        };
        tempVin.push(vinInfo);
      });
    });
    setVinTable({
      ...vinTable,
      data: tempVin,
    });
  }, [vinStatus]);

  const [isErrorApproveVin, setIsErrorApproveVin] = useState(false);

  const [openApproveVinModal, setOpenApproveVinModal] = useState(false);

  const [approveVinData, setApproveVinData] = useState({});

  const handleOpenApproveVinModal = (data = {}) => {
    setApproveVinData(data);
    setOpenApproveVinModal(true);
  };

  const handleCloseApproveVinModal = () => {
    setOpenApproveVinModal(false);
  };

  const approveVinHandler = (event, data) => {
    handleOpenApproveVinModal(data);
  };

  const onClickApproveVin = async () => {
    let vin = [];
    approveVinData.map((data) => {
      const object = {
        vin: data.vin,
      };
      vin.push(object);
    });

    let res = await approveVin(vin);

    if (res.status) {
      handleCloseApproveVinModal();
      getVinStatusByTripNo(tripNo);
      getSalesOrder();
    }
    if (!res.status) {
      setIsErrorApproveVin(true);
    }
  };

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title="Dispatch"
              columns={state.columns}
              data={state.data}
              options={{ draggable: false }}
            />
            <div>
              <Modal
                show={vinModal}
                onHide={handleOpenVinModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
                size="xl"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Trip No: {tripNo}</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body modal-body">
                        <MaterialTable
                          title="Vehicle Identification Number"
                          columns={vinTable.columns}
                          data={vinTable.data}
                          options={{
                            draggable: false,
                            // selection: true,
                            // toolbarButtonAlignment: "left",
                          }}
                          // actions={[
                          //   {
                          //     // tooltip: "Create Pick List",
                          //     icon: () => (
                          //       <button
                          //         disabled={role !== "admin" ? true : false}
                          //         className="btn btn-info"
                          //       >
                          //         Approve
                          //       </button>
                          //     ),
                          //     onClick: (evt, data) =>
                          //       approveVinHandler(evt, data),
                          //   },
                          // ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                show={openApproveVinModal}
                onHide={handleCloseApproveVinModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Create Pickup List</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <p>Are you sure you want to approve?</p>

                        <div class="clearfix">
                          <button
                            type="button"
                            className="cancelbtn button1"
                            onClick={handleCloseApproveVinModal}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="deletebtn button1"
                            onClick={onClickApproveVin}
                          >
                            Approve
                          </button>
                        </div>
                        <div className="mt-3">
                          <Collapse in={isErrorApproveVin}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorApproveVin(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Could not Approve the VIN
                            </Alert>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    salesOrder: state.salesOrder,
    vinStatus: state.vinStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getSalesOrder: () => dispatch(getSalesOrder()),
    getVinStatusByTripNo: (salesOrder) =>
      dispatch(getVinStatusByTripNo(salesOrder)),
    approveVin: (payload) => dispatch(approveVin(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dispatch);
