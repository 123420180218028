import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { makeStyles } from "@material-ui/core/styles";
import drawerCss from "../../style/drawer";
import classNames from "classnames";
import { connect } from "react-redux";
import {
  updateSelectedItem,
  getWarehouses,
  updateWarehouse,
  addZoneToWarehouse,
} from "../../store";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../utils/TextError";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import Select from "react-select";
import Switch from "@material-ui/core/Switch";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

const FGWarehouse = ({
  drawerState,
  handleListItemClick,
  getWarehouses,
  warehouses,
  updateWarehouse,
  addZoneToWarehouse,
}) => {
  useEffect(() => {
    handleListItemClick(null, 12);
  }, []);

  const classes = useStyles();
  let drawer = drawerState;
  const history = useHistory();

  let loginDetails = cookies.get("loginDetails");
  let role = loginDetails.data.role;

  useEffect(() => {
    getWarehouses();
  }, []);

  const [state, setState] = useState({
    columns: [
      { title: "Warehouse Code", field: "warehouseCode" },
      { title: "Warehouse", field: "warehouse" },
      { title: "Status", field: "status" },
      { title: "Action", field: "action" },
    ],
    data: [],
  });

  const [warehouseIdSelected, setWarehouseIdSelected] = useState("");

  const [openWarehouseStatusModal, setOpenWarehouseStatusModal] =
    useState(false);

  const handleOpenWarehouseStatusModal = () => {
    setOpenWarehouseStatusModal(true);
  };
  const handleCloseWarehouseStatusModal = () => {
    setOpenWarehouseStatusModal(false);
  };

  const [singleWarehouseDate, setSingleWarehouseDate] = useState({});

  const updateStatus = async () => {
    singleWarehouseDate["isActive"] = !singleWarehouseDate.isActive;
    let res = await updateWarehouse(singleWarehouseDate);

    if (res.status) {
      await getWarehouses();
      handleCloseWarehouseStatusModal();
    }
  };

  useEffect(() => {
    let tempWarehouses = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      if (warehouse.type === "f") {
        warehouse["warehouseCode"] = warehouse.warehouseCode;
        warehouse["warehouse"] = warehouse.warehouseName;
        warehouse["status"] = (
          <Switch
            color="primary"
            checked={warehouse.isActive === 1 ? true : false}
            onChange={() => {
              handleOpenWarehouseStatusModal();
              setSingleWarehouseDate(warehouse);
            }}
          />
        );
        warehouse["action"] = (
          <div>
            {warehouse.isActive === 1 && (
              <button
                style={{ width: "8.2rem" }}
                title="View Locations"
                className="btn btn-info"
                type="button"
                onClick={() => {
                  history.push(`/${warehouse.type}/${warehouse.id}`);
                }}
              >
                View locations
              </button>
            )}
            <button
              style={{ width: "8.2rem" }}
              type="button"
              className="btn btn-success spacing1"
              disabled={role !== "admin" ? true : false}
              onClick={() => {
                setAddZoneModal(true);
                setWarehouseIdSelected(warehouse.id);
              }}
            >
              Add Zone
            </button>
          </div>
        );
        tempWarehouses.push(warehouse);
      }
    });
    setState({ ...state, data: tempWarehouses });
  }, [warehouses]);

  const [isSuccessAddZone, setIsSuccessAddZone] = useState(false);
  const [isErrorAddZone, setIsErrorAddZone] = useState(false);

  const [addZoneModal, setAddZoneModal] = useState(false);
  const handleAddZoneModal = () => {
    setAddZoneModal(!addZoneModal);
  };

  const initialValueForAddZone = {
    zoneName: "",
  };

  const validationSchemaForAddZone = Yup.object({
    zoneName: Yup.string().required("Enter the Zone Name"),
  });

  const onSubmitForAddZone = async (values, { resetForm }) => {
    const payload = {
      zoneName: values.zoneName,
      warehouseId: warehouseIdSelected,
    };

    let res = await addZoneToWarehouse(payload);

    if (res.status) {
      setIsSuccessAddZone(true);
      await getWarehouses();
    }
    if (!res.status) {
      setIsErrorAddZone(true);
    }
    resetForm();
  };

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title="FG Warehouse"
              columns={state.columns}
              data={state.data}
              options={{ draggable: false }}
            />
            <div>
              <Modal
                show={openWarehouseStatusModal}
                onHide={handleCloseWarehouseStatusModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Change Status</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <p>
                          Are you sure you want to change the status of this
                          warehouse?
                        </p>
                        <div class="clearfix">
                          <button
                            type="button"
                            className="cancelbtn button1"
                            onClick={handleCloseWarehouseStatusModal}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="deletebtn button1"
                            onClick={updateStatus}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                show={addZoneModal}
                onHide={handleAddZoneModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Add Zone</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Formik
                          initialValues={initialValueForAddZone}
                          validationSchema={validationSchemaForAddZone}
                          onSubmit={onSubmitForAddZone}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="zoneName">Zone Name</label>
                                  <Field
                                    type="text"
                                    name="zoneName"
                                    className="form-control"
                                    autocomplete="off"
                                  />
                                  <ErrorMessage
                                    name="zoneName"
                                    component={TextError}
                                  />
                                </div>
                                <div className="mt-3">
                                  <Collapse in={isErrorAddZone}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsErrorAddZone(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Could not add the Zone
                                    </Alert>
                                  </Collapse>
                                  <Collapse in={isSuccessAddZone}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccessAddZone(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Zone added successfully
                                    </Alert>
                                  </Collapse>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                    disabled={!formik.isValid}
                                  >
                                    Add Zone
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    warehouses: state.warehouses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getWarehouses: () => dispatch(getWarehouses()),
    updateWarehouse: (payload) => dispatch(updateWarehouse(payload)),
    addZoneToWarehouse: (payload) => dispatch(addZoneToWarehouse(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FGWarehouse);
