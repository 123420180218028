export const GET_WAREHOUSE_REQUEST = "GET_WAREHOUSE_REQUEST";
export const GET_WAREHOUSE_SUCCESS = "GET_WAREHOUSE_SUCCESS";
export const GET_WAREHOUSE_FAILURE = "GET_WAREHOUSE_FAILURE";

export const GET_YARDOCCUPANCY_REQUEST = "GET_YARDOCCUPANCY_REQUEST";
export const GET_YARDOCCUPANCY_SUCCESS = "GET_YARDOCCUPANCY_SUCCESS";
export const GET_YARDOCCUPANCY_FAILURE = "GET_YARDOCCUPANCY_FAILURE";

export const GET_INVENTORY_REQUEST = "GET_INVENTORY_REQUEST";
export const GET_INVENTORY_SUCCESS = "GET_INVENTORY_SUCCESS";
export const GET_INVENTORY_FAILURE = "GET_INVENTORY_FAILURE";

export const GET_PICKUP_REQUEST = "GET_PICKUP_REQUEST";
export const GET_PICKUP_SUCCESS = "GET_PICKUP_SUCCESS";
export const GET_PICKUP_FAILURE = "GET_PICKUP_FAILURE";

export const GET_FINISHEDGOODSHP_REQUEST = "GET_FINISHEDGOODSHP_REQUEST";
export const GET_FINISHEDGOODSHP_SUCCESS = "GET_FINISHEDGOODSHP_SUCCESS";
export const GET_FINISHEDGOODSHP_FAILURE = "GET_FINISHEDGOODSHP_FAILURE";

export const GET_PDI_REQUEST = "GET_PDI_REQUEST";
export const GET_PDI_SUCCESS = "GET_PDI_SUCCESS";
export const GET_PDI_FAILURE = "GET_PDI_FAILURE";

export const GET_REJECTION_REQUEST = "GET_REJECTION_REQUEST";
export const GET_REJECTION_SUCCESS = "GET_REJECTION_SUCCESS";
export const GET_REJECTION_FAILURE = "GET_REJECTION_FAILURE";
