export const GET_ALLOCATEDZONES_REQUEST = "GET_ALLOCATEDZONES_REQUEST";
export const GET_ALLOCATEDZONES_SUCCESS = "GET_ALLOCATEDZONES_SUCCESS";
export const GET_ALLOCATEDZONES_FAILURE = "GET_ALLOCATEDZONES_FAILURE";

export const ADD_ZONEALLOCATION_REQUEST = "ADD_ZONEALLOCATION_REQUEST";
export const ADD_ZONEALLOCATION_SUCCESS = "ADD_ZONEALLOCATION_SUCCESS";
export const ADD_ZONEALLOCATION_FAILURE = "ADD_ZONEALLOCATION_FAILURE";

export const REMOVE_ZONEALLOCATION_REQUEST = "REMOVE_ZONEALLOCATION_REQUEST";
export const REMOVE_ZONEALLOCATION_SUCCESS = "REMOVE_ZONEALLOCATION_SUCCESS";
export const REMOVE_ZONEALLOCATION_FAILURE = "REMOVE_ZONEALLOCATION_FAILURE";
