import {
  GET_REJECTIONS_REQUEST,
  GET_REJECTIONS_SUCCESS,
  GET_REJECTIONS_FAILURE,
  PUT_ACCEPTREJECTION_REQUEST,
  PUT_ACCEPTREJECTION_SUCCESS,
  PUT_ACCEPTREJECTION_FAILURE,
  CREATE_REJECTION_REQUEST,
  CREATE_REJECTION_SUCCESS,
  CREATE_REJECTION_FAILURE,
} from "./RejectionsTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getRejectionsRequest = () => {
  return {
    type: GET_REJECTIONS_REQUEST,
  };
};

const getRejectionsSuccess = (payload) => {
  return {
    type: GET_REJECTIONS_SUCCESS,
    payload: payload,
  };
};

const getRejectionsFailure = (error) => {
  return {
    type: GET_REJECTIONS_FAILURE,
    payload: error,
  };
};

const getRejections = (location, status) => {
  return (dispatch) => {
    dispatch(getRejectionsRequest());
    let url = `${baseUrl}/rejection/get_rejection_list?warehouse_id=${location}&status=${status}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getRejectionsSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getRejectionsFailure(err.message));
      });
  };
};

const acceptRejectionRequest = () => {
  return {
    type: PUT_ACCEPTREJECTION_REQUEST,
  };
};

const acceptRejectionSuccess = (payload) => {
  return {
    type: PUT_ACCEPTREJECTION_SUCCESS,
    payload: payload,
  };
};

const acceptRejectionFailure = (error) => {
  return {
    type: PUT_ACCEPTREJECTION_FAILURE,
    payload: error,
  };
};

const acceptRejection = (payload) => {
  return (dispatch) => {
    dispatch(acceptRejectionRequest());
    let url = `${baseUrl}/production/rejection_process/accept_skuvin`;
    return axios
      .put(url, payload)
      .then((response) => {
        dispatch(acceptRejectionSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(acceptRejectionFailure(err.message));
        return { status: false, message: err };
      });
  };
};

const createRejectionRequest = () => {
  return {
    type: CREATE_REJECTION_REQUEST,
  };
};

const createRejectionSuccess = (payload) => {
  return {
    type: CREATE_REJECTION_SUCCESS,
    payload: payload,
  };
};

const createRejectionFailure = (error) => {
  return {
    type: CREATE_REJECTION_FAILURE,
    payload: error,
  };
};

const createRejection = (payload) => {
  return (dispatch) => {
    dispatch(createRejectionRequest());
    let url = `${baseUrl}/production/rejection_process/reject_skuvin`;
    return axios
      .put(url, payload)
      .then((response) => {
        dispatch(createRejectionSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createRejectionFailure(err.message));
        return { status: false, message: err };
      });
  };
};

export { getRejections, acceptRejection, createRejection };
