import React, { useState, useEffect } from "react";
import Header from "../../Header";
import { connect } from "react-redux";
import drawerCss from "../../../style/drawer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import Footer from "../../Footer";
import MaterialTable from "material-table";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../../utils/TextError";

import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import {
  createUserRole,
  deleteUserRole,
  getModulesByProductId,
  getProducts,
  getUserRoles,
  updateSelectedItem,
  getDepartments,
  updateUserRole,
} from "../../../store";
import "../../../style/delete.css";
import Select from "react-select";
import Cookies from "universal-cookie";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
const cookies = new Cookies();

const useStyles = makeStyles((theme) => drawerCss(theme));

function UserRoles({
  drawerState,
  handleListItemClick,
  userRoles,
  getUserRoles,
  createUserRole,
  updateUserRole,
  deleteUserRole,
  getModulesByProductId,
  getDepartments,
  departments,
  getProducts,
  products,
}) {
  const [state, setState] = useState({
    columns: [
      { title: "Department Name", field: "departmentName" },
      { title: "Product Name", field: "productName" },
      { title: "User Role", field: "role" },
      { title: "Modules", field: "modules" },
      { title: "Actions", field: "actions" },
    ],
    data: [],
  });
  const classes = useStyles();
  const theme = useTheme();
  let drawer = drawerState;
  let userDetails = cookies.get("loginDetails").data;
  let clientId = userDetails.clientId;

  // useEffect(() => {
  //   getProducts(clientId);
  // }, [getProducts]);

  let [productsList, setProductsList] = useState([]);
  let [modules, setModules] = useState({});

  useEffect(async () => {
    let tempModulesData = {};
    let modulesData = await getModulesByProductId();
    tempModulesData["Digital Warehouse Automation"] = modulesData.data;

    setModules(tempModulesData);
  }, []);

  // useEffect(() => {
  //   let productsData = products.products.products
  //     ? products.products.products
  //     : [];
  //   let productListTemp = [];
  //   let tempModulesData = {};
  //   productsData.map(async (product) => {
  //     productListTemp.push({
  //       productName: product.name,
  //       productId: product.productId,
  //     });
  //     let modulesData = await getModulesByProductId();
  //
  //     tempModulesData[product.name] = modulesData.modulesData
  //       ? modulesData.modulesData
  //       : [];
  //   });
  //   setModules(tempModulesData);
  //   setProductsList(productListTemp);
  // }, [products]);

  useEffect(() => {
    handleListItemClick(null, 19);
  });

  useEffect(() => {
    getDepartments();
  }, [getDepartments]);

  const [departmentSelected, setDepartmentSelected] = useState({});
  const handleDepartmentSelected = (selectedOption) => {
    setDepartmentSelected(selectedOption);
  };

  const [departmentsOptions, setDepartmentsOptions] = useState([]);
  useEffect(() => {
    let tempDepartments = departments.departments.departmentsData
      ? departments.departments.departmentsData
      : [];
    let departmentOptionsTemp = [];
    tempDepartments.map((department) => {
      departmentOptionsTemp.push({
        label: department.departmentName,
        value: department.departmentId,
      });
    });
    setDepartmentsOptions(departmentOptionsTemp);
  }, [departments]);

  const [openViewUserRoleModal, setOpenViewUserRoleModal] = useState(false);
  const [singleUserRoleDataForView, setUserRoleDataForView] = useState({});
  const handleOpenViewUserRoleModal = (role = {}) => {
    setUserRoleDataForView(role);
    setOpenViewUserRoleModal(true);
  };

  const handleCloseViewUserRoleModal = () => {
    setOpenViewUserRoleModal(false);
  };

  const [openDeleteUserRoleModal, setOpenDeleteUserRoleModal] = useState(false);
  const [singleUserRoleDataForDelete, setUserRoleDataForDelete] = useState({});
  const handleOpenDeleteUserRoleModal = (role = {}) => {
    setUserRoleDataForDelete(role);
    setOpenDeleteUserRoleModal(true);
  };

  const handleCloseDeleteUserRoleModal = () => {
    setOpenDeleteUserRoleModal(false);
  };

  const [openUpdateUserRoleModal, setOpenUpdateUserRoleModal] = useState(false);
  const [singleUserRoleDataForUpdate, setUserRoleDataForUpdate] = useState({});
  const handleOpenUpdateUserRoleModal = (role = {}) => {
    setUserRoleDataForUpdate(role);
    setOpenUpdateUserRoleModal(true);
    setIsErrorForUpdate(false);
    setIsSuccessForUpdate(false);
    setModulesActions(role.modules);
    setDepartmentSelected({
      label: role.departmentName,
      value: role.departmentId,
    });
  };

  const handleCloseUpdateUserRoleModal = () => {
    setOpenUpdateUserRoleModal(false);
    setIsSuccessForUpdate(false);
    setIsErrorForUpdate(false);
    setModulesActions([]);
    setDepartmentSelected({});
    setProductSelectedCheckBox([]);
  };

  useEffect(() => {
    getUserRoles();
  }, [getUserRoles]);

  useEffect(() => {
    let tempUserRoles = [];
    let userRolesData = userRoles.userRoles.rolesData
      ? userRoles.userRoles.rolesData
      : [];
    let userRolesDataTemp = [...userRolesData];
    userRolesDataTemp.map((role) => {
      let roleTemp = { ...role };
      let modulesTemp = [];
      let productNames = [];
      let modulesData = role["modules"] ? role["modules"] : [];
      let modulesDataTemp = [...modulesData];
      modulesDataTemp.map((module) => {
        if (!modulesTemp.some((data) => data === module.moduleName)) {
          modulesTemp.push(module.moduleName);
        }
        if (!productNames.some((data) => data === module.productName)) {
          productNames.push(module.productName);
        }
      });
      role["actions"] = (
        <div>
          <button
            title="View User Role"
            type="button"
            className="btn btn-info spacing3"
            // style={{ marginRight: "5px" }}
            onClick={() => handleOpenViewUserRoleModal(roleTemp)}
          >
            <i className="fa fa-eye"></i>
          </button>
          <button
            title="Edit User Role"
            type="button"
            className="btn btn-success spacing3"
            // style={{ marginRight: "5px" }}
            onClick={() => handleOpenUpdateUserRoleModal(roleTemp)}
          >
            <i className="fa fa-edit"></i>
          </button>
          <button
            title="Delete User Role"
            type="button"
            className="btn btn-danger spacing3"
            onClick={() => handleOpenDeleteUserRoleModal(roleTemp)}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      );
      role["productName"] = productNames.toString();
      role["modules"] = modulesTemp.toString();
      tempUserRoles.push(role);
    });
    setState({ ...state, data: tempUserRoles });
  }, [userRoles]);

  const [createUserRoleModal, setCreateUserRoleModal] = useState(false);
  const handleCreateUserRolesModal = () => {
    setDepartmentSelected({});
    setIsSuccessCreateUserRole(false);
    setIsErrorCreateUserRole(false);
    setCreateUserRoleModal(!createUserRoleModal);
  };

  const initialValue = {
    role: "",
    departmentId: "",
    departmentName: "",
    modules: [],
  };

  const validationSchema = Yup.object({
    role: Yup.string().required("Enter the Role"),
  });

  const [isErrorCreateUserRole, setIsErrorCreateUserRole] = useState(false);
  const [isSuccessCreateUserRole, setIsSuccessCreateUserRole] = useState(false);
  const onSubmit = async (values, { resetForm }) => {
    values.departmentId = departmentSelected.value;
    values.departmentName = departmentSelected.label;
    values.modules = modulesActions;

    let res = await createUserRole(values);
    if (res.status) {
      setIsSuccessCreateUserRole(true);
      setModulesActions([]);
      setDepartmentSelected({});
      setProductSelectedCheckBox([]);
      // setTimeout(() => {
      //     window.location.reload()
      // }, 1000)
      await getUserRoles();
    }
    if (!res.status) {
      setIsErrorCreateUserRole(true);
    }
    resetForm();
  };

  const initialValueForUpdate = {
    roleId: singleUserRoleDataForUpdate.roleId,
    role: singleUserRoleDataForUpdate.role,
    departmentIdId: singleUserRoleDataForUpdate.departmentIdId,
    departmentIdName: singleUserRoleDataForUpdate.departmentIdName,
    modules: singleUserRoleDataForUpdate.modules,
  };
  const validationSchemaForUpdate = Yup.object({
    role: Yup.string().required("Enter the Role"),
  });

  const [isErrorForUpdate, setIsErrorForUpdate] = useState(false);
  const [isSuccessForUpdate, setIsSuccessForUpdate] = useState(false);

  const onSubmitForUpdate = async (values, { resetForm }) => {
    values.departmentId = departmentSelected.value;
    values.departmentName = departmentSelected.label;
    values.modules = modulesActions;

    let res = await updateUserRole(values);
    if (res.status) {
      setIsSuccessForUpdate(true);

      // setTimeout(() => {
      //     window.location.reload()
      // }, 1000)

      getUserRoles();
    }
    if (!res.status) {
      setIsErrorForUpdate(true);
    }
  };

  const deleteUserRolesData = async () => {
    let roleId = singleUserRoleDataForDelete.roleId;
    let response = await deleteUserRole(roleId);
    if (response.status) {
      await getUserRoles();
      handleCloseDeleteUserRoleModal();
      // setTimeout(() => {
      //     window.location.reload()
      // }, 1000)
    }
  };

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [modulesActions, setModulesActions] = useState([]);

  const handleChangeCheckBox = (
    event,
    productId,
    moduleId,
    productName,
    moduleName,
    action
  ) => {
    let modulesActionsTemp = [...modulesActions];

    if (
      modulesActionsTemp.some(
        (data) => data.moduleName === moduleName && data.action === action
      )
    ) {
      let index = modulesActionsTemp.findIndex(
        (module) => module.action === action && module.moduleName === moduleName
      );
      modulesActionsTemp.splice(index, 1);
    } else {
      modulesActionsTemp.push({
        productId: productId,
        moduleId: moduleId,
        productName: productName,
        moduleName: moduleName,
        action: action,
        [action]: true,
      });
    }

    setModulesActions(modulesActionsTemp);
  };

  const hanldeProductCheckBox = async (name, productId) => {
    await checkedAllProductAction(name, productId);
  };

  const [productSelectedCheckBox, setProductSelectedCheckBox] = useState([]);
  const checkedAllProductAction = (name, productId) => {
    let productSelectedCheckBoxTemp = [...productSelectedCheckBox];
    let modulesData = modules[name] ? modules[name] : [];
    let modulesActionsTemp = [...modulesActions];

    modulesData.map((module) => {
      if (modulesActionsTemp.some((data) => data.moduleName === module.name)) {
        let indexView = modulesActionsTemp.findIndex(
          (data) => data.view && data.moduleName === module.name
        );
        modulesActionsTemp.splice(indexView, 1);
        let indexUpdate = modulesActionsTemp.findIndex(
          (data) => data.update && data.moduleName === module.name
        );
        modulesActionsTemp.splice(indexUpdate, 1);
        let indexDelete = modulesActionsTemp.findIndex(
          (data) => data.delete && data.moduleName === module.name
        );
        modulesActionsTemp.splice(indexDelete, 1);
        let indexProduct = productSelectedCheckBoxTemp.findIndex(
          (data) => data.name === true
        );
        productSelectedCheckBoxTemp.splice(indexProduct, 1);
      } else {
        modulesActionsTemp.push({
          productId: productId,
          moduleId: module.moduleId,
          productName: name,
          moduleName: module.name,
          action: "view",
          view: true,
        });
        modulesActionsTemp.push({
          productId: productId,
          moduleId: module.moduleId,
          productName: name,
          moduleName: module.name,
          action: "update",
          update: true,
        });
        modulesActionsTemp.push({
          productId: productId,
          moduleId: module.moduleId,
          productName: name,
          moduleName: module.name,
          action: "delete",
          delete: true,
        });
        if (!productSelectedCheckBoxTemp.some((data) => data[name] === true)) {
          productSelectedCheckBoxTemp.push({ [name]: true });
        }
      }
    });
    setProductSelectedCheckBox(productSelectedCheckBoxTemp);
    setModulesActions(modulesActionsTemp);
  };

  const getModulesList = (name, productId) => {
    let modulesData = modules[name] ? modules[name] : [];
    let data = [];
    modulesData.map((module) => {
      const labelId = `checkbox-list-secondary-label-${module.name}`;

      data.push(
        <ListItem>
          <ListItemText id={labelId} primary={`${module.name}`} />
          <ListItemSecondaryAction>
            <FormControlLabel
              control={
                <Checkbox
                  edge="end"
                  onChange={() =>
                    handleChangeCheckBox(
                      "",
                      productId,
                      module.moduleId,
                      name,
                      module.name,
                      "view"
                    )
                  }
                  checked={modulesActions.some(
                    (data) => data.moduleName === module.name && data.view
                  )}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              }
              //   label="View"
            />
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
    return <List>{data}</List>;
  };

  const getAccordion = () => {
    let data = [];
    // productsList.map((product) => {

    data.push(
      <Accordion
        expanded={expanded === "Digital Warehouse Automation"}
        onChange={handleChange("Digital Warehouse Automation")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <Checkbox
                checked={productSelectedCheckBox.some(
                  (data) => data["Digital Warehouse Automation"] === true
                )}
                onClick={() =>
                  hanldeProductCheckBox(
                    "Digital Warehouse Automation",
                    "a6aa3794-5c18-41fe-a73b-b184a5c7fd38"
                  )
                }
              />
            }
            label={"Digital Warehouse Automation"}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {getModulesList(
              "Digital Warehouse Automation",
              "a6aa3794-5c18-41fe-a73b-b184a5c7fd38"
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
    // });
    return data;
  };

  const getModulesListForView = (modules) => {
    let data = [];
    modules.map((module) => {
      if (module.action === "view") {
        data.push(
          <div className="form-row">
            <div class="col">
              {" "}
              <strong>Product Name :</strong>{" "}
              {module.productName ? module.productName : ""}{" "}
            </div>
            <div class="col">
              {" "}
              <strong>Module Name :</strong>{" "}
              {module.moduleName ? module.moduleName : ""}{" "}
            </div>
            <div class="col">
              {" "}
              {/* <strong>Action :</strong> {module.action ? module.action : ""}{" "} */}
              <strong>Action :</strong> {module.action ? "Allowed" : ""}{" "}
            </div>
          </div>
        );
      }
    });
    return data;
  };

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title="User Roles"
              columns={state.columns}
              data={state.data}
              options={{ draggable: false }}
              actions={[
                {
                  icon: "add",
                  tooltip: "Add New User Role",
                  isFreeAction: true,
                  onClick: (event) => handleCreateUserRolesModal(),
                  iconProps: {
                    color: "error",
                    fontSize: "large",
                  },
                },
              ]}
            />
            <div>
              <Modal
                show={createUserRoleModal}
                onHide={handleCreateUserRolesModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Create User Role</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body modal-body">
                        <Formik
                          initialValues={initialValue}
                          validationSchema={validationSchema}
                          onSubmit={onSubmit}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="role">Role Name</label>
                                  <Field
                                    type="text"
                                    name="role"
                                    className="form-control"
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    name="role"
                                    component={TextError}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="departmentName">
                                    Department Name
                                  </label>
                                  <Select
                                    value={departmentSelected}
                                    onChange={handleDepartmentSelected}
                                    options={departmentsOptions}
                                  />
                                </div>
                                {productsList ? getAccordion() : null}
                                <div className="mt-3">
                                  <Collapse in={isErrorCreateUserRole}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsErrorCreateUserRole(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      please check your user roles details.
                                      Server error!
                                    </Alert>
                                  </Collapse>

                                  <Collapse in={isSuccessCreateUserRole}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccessCreateUserRole(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      User Role Create successfully
                                    </Alert>
                                  </Collapse>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                    disabled={!formik.isValid}
                                  >
                                    Create User Role
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                show={openUpdateUserRoleModal}
                onHide={handleCloseUpdateUserRoleModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Update User Role</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body modal-body">
                        <Formik
                          initialValues={initialValueForUpdate}
                          validationSchema={validationSchemaForUpdate}
                          onSubmit={onSubmitForUpdate}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="role">Role Name</label>
                                  <Field
                                    type="text"
                                    name="role"
                                    className="form-control"
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    name="role"
                                    component={TextError}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="departmentName">
                                    Department Name
                                  </label>
                                  <Select
                                    value={departmentSelected}
                                    onChange={handleDepartmentSelected}
                                    options={departmentsOptions}
                                  />
                                </div>
                                {productsList ? getAccordion() : null}
                                <div className="mt-3">
                                  <Collapse in={isErrorForUpdate}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsErrorForUpdate(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      please check your user roles details.
                                      Server error!
                                    </Alert>
                                  </Collapse>

                                  <Collapse in={isSuccessForUpdate}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccessForUpdate(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      User Role update successfully
                                    </Alert>
                                  </Collapse>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                    disabled={!formik.isValid}
                                  >
                                    Update User Role
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                show={openDeleteUserRoleModal}
                onHide={handleCloseDeleteUserRoleModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Delete User Role</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <p>Are you sure you want to delete this user role?</p>

                        <div class="clearfix">
                          <button
                            type="button"
                            className="cancelbtn button1"
                            onClick={handleCloseDeleteUserRoleModal}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="deletebtn button1"
                            onClickCapture={deleteUserRolesData}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                show={openViewUserRoleModal}
                onHide={handleCloseViewUserRoleModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>User Role</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <article
                          class="card mt-2 border border-danger"
                          style={{ width: "auto" }}
                        >
                          <div class="card-body">
                            <h6 className="card-subtitle mb-2 text-muted">
                              Role Details
                            </h6>
                            <div className="form-row">
                              <div class="col">
                                {" "}
                                <strong>Role Name :</strong>{" "}
                                {singleUserRoleDataForView.role
                                  ? singleUserRoleDataForView.role
                                  : ""}{" "}
                              </div>

                              <div class="col">
                                {" "}
                                <strong>Department Name :</strong>{" "}
                                {singleUserRoleDataForView.departmentName
                                  ? singleUserRoleDataForView.departmentName
                                  : ""}{" "}
                              </div>
                            </div>
                          </div>
                        </article>
                        <article
                          class="card mt-2 border border-danger"
                          style={{ width: "auto" }}
                        >
                          <div class="card-body">
                            <h6 className="card-subtitle mb-2 text-muted">
                              Modules
                            </h6>
                            {singleUserRoleDataForView.modules
                              ? getModulesListForView(
                                  singleUserRoleDataForView.modules
                                )
                              : null}

                            {/* <div className="form-row">
                                                            <div class="col"> <strong>Role Name :</strong> {singleUserRoleDataForView.role ? singleUserRoleDataForView.role : ''} </div>

                                                            <div class="col"> <strong>Department Name :</strong>  {singleUserRoleDataForView.departmentName ? singleUserRoleDataForView.departmentName : ''} </div>
                                                        </div> */}
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    userRoles: state.getUserRolesReducer,
    departments: state.getDepartmentsReducer,
    products: state.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getUserRoles: () => dispatch(getUserRoles()),
    createUserRole: (payload) => dispatch(createUserRole(payload)),
    updateUserRole: (payload) => dispatch(updateUserRole(payload)),
    deleteUserRole: (userRoleId) => dispatch(deleteUserRole(userRoleId)),
    getDepartments: () => dispatch(getDepartments()),
    getModulesByProductId: () => dispatch(getModulesByProductId()),
    getProducts: (clientId) => dispatch(getProducts(clientId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRoles);

{
  /* <FormControlLabel
              control={
                <Checkbox
                  edge="end"
                  onChange={() =>
                    handleChangeCheckBox(
                      "",
                      productId,
                      module.moduleId,
                      name,
                      module.name,
                      "update"
                    )
                  }
                  checked={modulesActions.some(
                    (data) => data.moduleName === module.name && data.update
                  )}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              }
              label="Update"
            />
            <FormControlLabel
              control={
                <Checkbox
                  edge="end"
                  onChange={() =>
                    handleChangeCheckBox(
                      "",
                      productId,
                      module.moduleId,
                      name,
                      module.name,
                      "delete"
                    )
                  }
                  checked={modulesActions.some(
                    (data) => data.moduleName === module.name && data.delete
                  )}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              }
              label="Delete"
            /> */
}
