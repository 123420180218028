import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { makeStyles } from "@material-ui/core/styles";
import drawerCss from "../../style/drawer";
import classNames from "classnames";
import { connect } from "react-redux";
import { updateSelectedItem, getAllTransferOrders } from "../../store";
import MaterialTable from "material-table";
import Config from "../../config/index";

const desLength = Config.descriptionLength;

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

const TransferOrder = ({
  drawerState,
  handleListItemClick,
  getAllTransferOrders,
  transferOrders,
}) => {
  useEffect(() => {
    handleListItemClick(null, 14);
  }, []);

  const classes = useStyles();
  let drawer = drawerState;

  useEffect(() => {
    getAllTransferOrders();
  }, []);

  const [state, setState] = useState({
    columns: [
      { title: "Transfer Order No", field: "trOrderNo" },
      { title: "Item Code/Description", field: "itemCodeDes" },
      { title: "SKU", field: "sku" },
      { title: "Source Location", field: "srcLoc" },
      { title: "Delivery Location", field: "delLoc" },
      { title: "Qty", field: "qty" },
      { title: "Status", field: "status" },
    ],
    data: [],
  });

  useEffect(() => {
    let tempTransferOrders = [];
    let data = transferOrders.transferOrders
      ? transferOrders.transferOrders
      : [];

    data.map((to) => {
      let des = to?.itemDescription;
      let len = desLength;
      const toObject = {
        trOrderNo: to.transferOrderCode,
        itemCodeDes: `${to.itemCode} / ${des ? des.substring(0, len) : ""}`,
        sku: to.cSku,
        qty: `${to.transferQty} ${to.uom}`,
        srcLoc: to.sWarehouse,
        delLoc: to.dWarehouse,
        status: (
          <h4>
            <span
              className={
                (to.isPickup === 0 &&
                  to.isPutaway === 0 &&
                  `badge badge-danger`) ||
                (to.isPickup === 1 &&
                  to.isPutaway === 1 &&
                  `badge badge-success`) ||
                `badge badge-warning`
              }
              style={{ width: "7rem" }}
            >
              {(to.isPickup === 0 && to.isPutaway === 0 && `Not Picked`) ||
                (to.isPickup === 1 && to.isPutaway === 1 && `Delivered`) ||
                `Picked`}
            </span>
          </h4>
        ),
      };
      tempTransferOrders.push(toObject);
    });
    setState({ ...state, data: tempTransferOrders });
  }, [transferOrders]);

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title="Transfer Order"
              columns={state.columns}
              data={state.data}
              options={{ draggable: false }}
            />
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    transferOrders: state.transferOrders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getAllTransferOrders: () => dispatch(getAllTransferOrders()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferOrder);
