import {
  PRINTLABEL_INDIVIDUALPRINT_REQUEST,
  PRINTLABEL_INDIVIDUALPRINT_SUCCESS,
  PRINTLABEL_INDIVIDUALPRINT_FAILURE,
  PRINTLABEL_PACKAGEPRINT_REQUEST,
  PRINTLABEL_PACKAGEPRINT_SUCCESS,
  PRINTLABEL_PACKAGEPRINT_FAILURE,
  TRANSFERORDER_INDIVIDUALPRINT_REQUEST,
  TRANSFERORDER_INDIVIDUALPRINT_SUCCESS,
  TRANSFERORDER_INDIVIDUALPRINT_FAILURE,
  TRANSFERORDER_PACKAGEPRINT_REQUEST,
  TRANSFERORDER_PACKAGEPRINT_SUCCESS,
  TRANSFERORDER_PACKAGEPRINT_FAILURE,
} from "./LabelPrintingTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const individualPrintRequest = () => {
  return {
    type: PRINTLABEL_INDIVIDUALPRINT_REQUEST,
  };
};

const individualPrintSuccess = (payload) => {
  return {
    type: PRINTLABEL_INDIVIDUALPRINT_SUCCESS,
    payload: payload,
  };
};

const individualPrintFailure = (error) => {
  return {
    type: PRINTLABEL_INDIVIDUALPRINT_FAILURE,
    payload: error,
  };
};

const individualPrint = (payload) => {
  return (dispatch) => {
    dispatch(individualPrintRequest());
    let url = `${baseUrl}/print_individual`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(individualPrintSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(individualPrintFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const packagePrintRequest = () => {
  return {
    type: PRINTLABEL_PACKAGEPRINT_REQUEST,
  };
};

const packagePrintSuccess = (payload) => {
  return {
    type: PRINTLABEL_PACKAGEPRINT_SUCCESS,
    payload: payload,
  };
};

const packagePrintFailure = (error) => {
  return {
    type: PRINTLABEL_PACKAGEPRINT_FAILURE,
    payload: error,
  };
};

const packagePrint = (payload) => {
  return (dispatch) => {
    dispatch(packagePrintRequest());
    let url = `${baseUrl}/print_package`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(packagePrintSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(packagePrintFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const transferOrderIndividualPrintRequest = () => {
  return {
    type: TRANSFERORDER_INDIVIDUALPRINT_REQUEST,
  };
};

const transferOrderIndividualPrintSuccess = (payload) => {
  return {
    type: TRANSFERORDER_INDIVIDUALPRINT_SUCCESS,
    payload: payload,
  };
};

const transferOrderIndividualPrintFailure = (error) => {
  return {
    type: TRANSFERORDER_INDIVIDUALPRINT_FAILURE,
    payload: error,
  };
};

const transferOrderIndividualPrint = (payload) => {
  return (dispatch) => {
    dispatch(transferOrderIndividualPrintRequest());
    let url = `${baseUrl}/transfer_order/print_individual`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(transferOrderIndividualPrintSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(transferOrderIndividualPrintFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const transferOrderPackagePrintRequest = () => {
  return {
    type: TRANSFERORDER_PACKAGEPRINT_REQUEST,
  };
};

const transferOrderPackagePrintSuccess = (payload) => {
  return {
    type: TRANSFERORDER_PACKAGEPRINT_SUCCESS,
    payload: payload,
  };
};

const transferOrderPackagePrintFailure = (error) => {
  return {
    type: TRANSFERORDER_PACKAGEPRINT_FAILURE,
    payload: error,
  };
};

const transferOrderPackagePrint = (payload) => {
  return (dispatch) => {
    dispatch(transferOrderPackagePrintRequest());
    let url = `${baseUrl}/transfer_order/print_package`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(transferOrderPackagePrintSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(transferOrderPackagePrintFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export {
  individualPrint,
  packagePrint,
  transferOrderIndividualPrint,
  transferOrderPackagePrint,
};
