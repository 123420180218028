import {
  GET_YARDOCCUPANCY_REQUEST,
  GET_YARDOCCUPANCY_SUCCESS,
  GET_YARDOCCUPANCY_FAILURE,
} from "./YardOccupancyTypes";
import axios from "axios";
import Config from "../../../config/index";
let baseUrl = Config.baseUrl;

const getYardOccupancyRequest = () => {
  return {
    type: GET_YARDOCCUPANCY_REQUEST,
  };
};

const getYardOccupancySuccess = (payload) => {
  return {
    type: GET_YARDOCCUPANCY_SUCCESS,
    payload: payload,
  };
};

const getYardOccupancyFailure = (error) => {
  return {
    type: GET_YARDOCCUPANCY_FAILURE,
    payload: error,
  };
};

const getYardOccupancy = (fgId, warehouseId) => {
  return (dispatch) => {
    dispatch(getYardOccupancyRequest());
    let url = `${baseUrl}/reports/get_FG_occupancy?fg_id=${fgId}&warehouse_id=${warehouseId}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getYardOccupancySuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getYardOccupancyFailure(err.message));
      });
  };
};

export { getYardOccupancy };
