import {
  GET_PICKLISTAGEING_REQUEST,
  GET_PICKLISTAGEING_SUCCESS,
  GET_PICKLISTAGEING_FAILURE,
} from "./PicklistAgeingTypes";
import axios from "axios";
import Config from "../../../config/index";
let baseUrl = Config.baseUrl;

const getPicklistAgeingRequest = () => {
  return {
    type: GET_PICKLISTAGEING_REQUEST,
  };
};

const getPicklistAgeingSuccess = (payload) => {
  return {
    type: GET_PICKLISTAGEING_SUCCESS,
    payload: payload,
  };
};

const getPicklistAgeingFailure = (error) => {
  return {
    type: GET_PICKLISTAGEING_FAILURE,
    payload: error,
  };
};

const getPicklistAgeing = (status, startDate, endDate) => {
  return (dispatch) => {
    dispatch(getPicklistAgeingRequest());
    let url = `${baseUrl}/reports/get_pickup_list_by_date?status=${status}&start_pickup_time=${startDate}&end_pickup_time=${endDate}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getPicklistAgeingSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getPicklistAgeingFailure(err.message));
      });
  };
};

export { getPicklistAgeing };
