export const GETWAREHOUSE_REQUEST = "GETWAREHOUSE_REQUEST";
export const GETWAREHOUSE_SUCCESS = "GETWAREHOUSE_SUCCESS";
export const GETWAREHOUSE_FAILURE = "GETWAREHOUSE_FAILURE";

export const GETWAREHOUSE_BYID_REQUEST = "GETWAREHOUSE_BYID_REQUEST";
export const GETWAREHOUSE_BYID_SUCCESS = "GETWAREHOUSE_BYID_SUCCESS";
export const GETWAREHOUSE_BYID_FAILURE = "GETWAREHOUSE_BYID_FAILURE";

export const UPDATEWAREHOUSE_REQUEST = "UPDATEWAREHOUSE_REQUEST";
export const UPDATEWAREHOUSE_SUCCESS = "UPDATEWAREHOUSE_SUCCESS";
export const UPDATEWAREHOUSE_FAILURE = "UPDATEWAREHOUSE_FAILURE";
