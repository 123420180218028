/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 * 
 *  Mobillor Technologies Pvt. Ltd. 
 *  All Rights Reserved.
 * 
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.  
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
*/

const initialState = {
    selectedIndex: 0
};

let setecedItem = (state = initialState, action) => {
    switch (action.type) {
        case "SECTECTED_UPDATE_DRAWER":
            return {
                ...state,
                selectedIndex: action.payload
            }
        default: return state
    }
}

const updateDrawerSelectedItem = (index) => {
    return {
        type: "SECTECTED_UPDATE_DRAWER",
        payload: index
    }
}

const updateSelectedItem = (event, index) => {
    return (dispatch) => {
        dispatch(updateDrawerSelectedItem(index))
    }
};

export { setecedItem, updateSelectedItem }