import {
  GET_ITEMMASTER_REQUEST,
  GET_ITEMMASTER_SUCCESS,
  GET_ITEMMASTER_FAILURE,
  EDIT_ITEMMASTER_REQUEST,
  EDIT_ITEMMASTER_SUCCESS,
  EDIT_ITEMMASTER_FAILURE,
  GET_BINSBYITEMCODE_REQUEST,
  GET_BINSBYITEMCODE_SUCCESS,
  GET_BINSBYITEMCODE_FAILURE,
  UPLOADCSV_REQUEST,
  UPLOADCSV_SUCCESS,
  UPLOADCSV_FAILURE,
} from "./ItemMasterTypes";

const initialState = {
  loading: false,
  itemMaster: [],
  error: "",
};

const getItemMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ITEMMASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ITEMMASTER_SUCCESS:
      return {
        loading: false,
        itemMaster: action.payload,
        error: "",
      };
    case GET_ITEMMASTER_FAILURE:
      return {
        loading: false,
        itemMaster: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForEditItemMaster = {
  loading: false,
  itemMaster: [],
  error: "",
};

const editItemMasterReducer = (
  state = initialStateForEditItemMaster,
  action
) => {
  switch (action.type) {
    case EDIT_ITEMMASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_ITEMMASTER_SUCCESS:
      return {
        loading: false,
        itemMaster: action.payload,
        error: "",
      };
    case EDIT_ITEMMASTER_FAILURE:
      return {
        loading: false,
        itemMaster: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForBins = {
  loading: false,
  bins: [],
  error: "",
};

const getBinsByItemCodeReducer = (state = initialStateForBins, action) => {
  switch (action.type) {
    case GET_BINSBYITEMCODE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BINSBYITEMCODE_SUCCESS:
      return {
        loading: false,
        bins: action.payload,
        error: "",
      };
    case GET_BINSBYITEMCODE_FAILURE:
      return {
        loading: false,
        bins: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForUploadCsv = {
  loading: false,
  file: {},
  error: "",
};

const uploadCsvReducer = (state = initialStateForUploadCsv, action) => {
  switch (action.type) {
    case UPLOADCSV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPLOADCSV_SUCCESS:
      return {
        loading: false,
        file: action.payload,
        error: "",
      };
    case UPLOADCSV_FAILURE:
      return {
        loading: false,
        file: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getItemMasterReducer,
  editItemMasterReducer,
  getBinsByItemCodeReducer,
  uploadCsvReducer,
};
