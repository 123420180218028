export const GETLOCATION_BYWAREHOUSE_REQUEST =
  "GETLOCATION_BYWAREHOUSE_REQUEST";
export const GETLOCATION_BYWAREHOUSE_SUCCESS =
  "GETLOCATION_BYWAREHOUSE_SUCCESS";
export const GETLOCATION_BYWAREHOUSE_FAILURE =
  "GETLOCATION_BYWAREHOUSE_FAILURE";

export const ADDLOCATION_TOWAREHOUSE_REQUEST =
  "ADDLOCATION_TOWAREHOUSE_REQUEST";
export const ADDLOCATION_TOWAREHOUSE_SUCCESS =
  "ADDLOCATION_TOWAREHOUSE_SUCCESS";
export const ADDLOCATION_TOWAREHOUSE_FAILURE =
  "ADDLOCATION_TOWAREHOUSE_FAILURE";

export const GETITEM_BYLOCATION_REQUEST = "GETITEM_BYLOCATION_REQUEST";
export const GETITEM_BYLOCATION_SUCCESS = "GETITEM_BYLOCATION_SUCCESS";
export const GETITEM_BYLOCATION_FAILURE = "GETITEM_BYLOCATION_FAILURE";

export const GETLOCATION_BYWAREHOUSEANDZONE_REQUEST =
  "GETLOCATION_BYWAREHOUSEANDZONE_REQUEST";
export const GETLOCATION_BYWAREHOUSEANDZONE_SUCCESS =
  "GETLOCATION_BYWAREHOUSEANDZONE_SUCCESS";
export const GETLOCATION_BYWAREHOUSEANDZONE_FAILURE =
  "GETLOCATION_BYWAREHOUSEANDZONE_FAILURE";

export const DELETE_LOCATION_REQUEST = "DELETE_LOCATION_REQUEST";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
export const DELETE_LOCATION_FAILURE = "DELETE_LOCATION_FAILURE";
