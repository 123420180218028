import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { makeStyles } from "@material-ui/core/styles";
import drawerCss from "../../style/drawer";
import classNames from "classnames";
import { connect } from "react-redux";
import {
  updateSelectedItem,
  getWarehouses,
  getAllocatedItems,
  getItemMaster,
  getZonesByWarehouse,
  getOpenBinsByWarehouseAndZone,
  addBinAllocation,
  removeBinAllocation,
  editBinAllocation,
} from "../../store";
import MaterialTable from "material-table";
import Select from "react-select";

import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../utils/TextError";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import Config from "../../config/index";

const desLength = Config.descriptionLength;

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

const ItemAllocation = ({
  drawerState,
  handleListItemClick,
  getWarehouses,
  warehouses,
  getAllocatedItems,
  allocatedBins,
  getItemMaster,
  itemMaster,
  getZonesByWarehouse,
  zonesByWarehouse,
  getOpenBinsByWarehouseAndZone,
  openBinsByWarehouse,
  addBinAllocation,
  removeBinAllocation,
  editBinAllocation,
}) => {
  useEffect(() => {
    handleListItemClick(null, 8);
  }, []);

  const classes = useStyles();
  let drawer = drawerState;

  const [warehouseIdSelected, setWarehouseIdSelected] = useState("");
  const [zoneIdSelected, setZoneIdSelected] = useState("");

  useEffect(() => {
    getWarehouses();
  }, []);

  useEffect(() => {
    getAllocatedItems(warehouseIdSelected);
  }, [warehouseIdSelected]);

  useEffect(() => {
    getItemMaster();
  }, []);

  useEffect(() => {
    getZonesByWarehouse(warehouseIdSelected);
  }, [warehouseIdSelected]);

  useEffect(() => {
    getOpenBinsByWarehouseAndZone(warehouseIdSelected, zoneIdSelected);
  }, [warehouseIdSelected, zoneIdSelected]);

  const [state, setState] = useState({
    columns: [
      { title: "Item Code", field: "itemCode" },
      { title: "Item Name", field: "itemName" },
      { title: "Zone", field: "zone" },
      { title: "Location/Bin", field: "location" },
      { title: "Action", field: "action" },
    ],
    data: [],
  });

  const [warehouseOptions, setWarehouseOptions] = useState([]);

  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      if (warehouse.type === "d") {
        tempWarehouseOptions.push({
          key: warehouse.warehouseName,
          value: warehouse.id,
        });
      }
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  useEffect(() => {
    setWarehouseIdSelected(warehouseOptions[0]?.value);
  }, [warehouseOptions]);

  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = itemMaster.itemMaster ? itemMaster.itemMaster : [];
    data.map((item) => {
      tempItemCodeOptions.push({
        key: item.itemCode,
        value: item.id,
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [itemMaster]);

  const [zoneOptions, setZoneOptions] = useState([]);

  useEffect(() => {
    let tempZonesByWarehouse = [];
    let data = zonesByWarehouse.zones ? zonesByWarehouse.zones : [];
    data.map((zone) => {
      tempZonesByWarehouse.push({
        key: zone.zoneName,
        value: zone.id,
      });
    });
    setZoneOptions(tempZonesByWarehouse);
  }, [zonesByWarehouse]);

  const [openBinsOptions, setOpenBinsOptions] = useState([]);

  useEffect(() => {
    let tempOpenBins = [];
    let data = openBinsByWarehouse.bins ? openBinsByWarehouse.bins : [];

    data.map((bin) => {
      tempOpenBins.push({
        key: bin.locationName,
        value: bin.locationId,
      });
    });
    setOpenBinsOptions(tempOpenBins);
  }, [openBinsByWarehouse]);

  useEffect(() => {
    let tempAllocatedBins = [];
    let data = allocatedBins.bins ? allocatedBins.bins : [];

    data.map((item) => {
      let des = item?.itemName;
      let len = desLength;
      const itemObject = {
        itemCode: item.itemCode,
        itemName: des ? des.substring(0, len) : "",
        zone: item.zoneName,
        location: item.locationName === null ? "" : item.locationName,
        action:
          item.itemName !== "Open Bins" ? (
            <div>
              {item.locationName !== null && (
                <button
                  title="Edit BIN"
                  className="btn btn-success"
                  type="button"
                  onClick={() => {
                    getOpenBinsByWarehouseAndZone(
                      warehouseIdSelected,
                      item.zoneId
                    );
                    setItemAllocationData(item);
                    setEditItemAllocationModal(true);
                  }}
                >
                  <i className="fa fa-pencil"></i>
                </button>
              )}
              {item.locationName === null && (
                <button
                  // style={{
                  //   marginLeft: `${item.locationName === null ? null : "1rem"}`,
                  // }}
                  title="Add BIN"
                  className={`btn btn-info ${
                    item.locationName === null ? null : `spacing3`
                  }`}
                  type="button"
                  onClick={() => {
                    setZoneIdSelected(item.zoneId);
                    setItemAllocationData(item);
                    setAddItemAllocationModal(true);
                  }}
                >
                  <i className="fa fa-plus"></i>
                </button>
              )}
              <button
                // style={{ marginLeft: "1rem" }}
                title="Remove Item Allocation"
                className="btn btn-danger spacing3"
                type="button"
                onClick={() => {
                  handleOpenDeleteItemAllocationModal(item);
                }}
              >
                <i className="fa fa-trash"></i>
              </button>
            </div>
          ) : (
            ""
          ),
      };
      tempAllocatedBins.push(itemObject);
    });
    setState({ ...state, data: tempAllocatedBins });
  }, [allocatedBins]);

  const [isSuccessAllocateItem, setIsSuccessAllocateItem] = useState(false);
  const [isErrorAllocateItem, setIsErrorAllocateItem] = useState(false);

  const [allocateItemModal, setAllocateItemModal] = useState(false);
  const handleAllocateItemModal = () => {
    setAllocateItemModal(!allocateItemModal);
  };

  const [itemAllocationData, setItemAllocationData] = useState({});

  const initialValue = {
    itemCode: "",
    zoneName: "",
    bin: "",
  };

  const validationSchema = Yup.object({
    itemCode: Yup.string().required("Select the Item Code"),
    zoneName: Yup.string().required("Select the Zone"),
    // bin: Yup.string().required("Select the BIN"),
  });

  const onSubmit = async (values, { resetForm }) => {
    const payload = {
      warehouseId: warehouseIdSelected,
      itemId: values.itemCode,
      zoneId: values.zoneName,
      locationId: values.bin ? values.bin : 0,
    };

    let res = await addBinAllocation(payload);

    if (res.status) {
      setIsSuccessAllocateItem(true);
      getOpenBinsByWarehouseAndZone(warehouseIdSelected, zoneIdSelected);
      getAllocatedItems(warehouseIdSelected);
    }
    if (!res.status) {
      setIsErrorAllocateItem(true);
    }
    resetForm();
  };

  const [isSuccessAddItemAllocation, setIsSuccessAddItemAllocation] =
    useState(false);
  const [isErrorAddItemAllocation, setIsErrorAddItemAllocation] =
    useState(false);

  const [addItemAllocationModal, setAddItemAllocationModal] = useState(false);
  const handleAddItemAllocationModal = () => {
    setAddItemAllocationModal(!addItemAllocationModal);
  };

  const initialValueForAddItemAllocation = {
    bin: "",
  };

  const validationSchemaForAddItemAllocation = Yup.object({
    bin: Yup.string().required("Select the BIN"),
  });

  const onSubmitForAddItemAllocation = async (values, { resetForm }) => {
    const payload = {
      warehouseId: warehouseIdSelected,
      itemId: itemAllocationData.itemId,
      zoneId: itemAllocationData.zoneId,
      locationId: values.bin,
    };

    let res = await editBinAllocation(payload);

    if (res.status) {
      setIsSuccessAddItemAllocation(true);
      getOpenBinsByWarehouseAndZone(warehouseIdSelected, zoneIdSelected);
      getAllocatedItems(warehouseIdSelected);
    }
    if (!res.status) {
      setIsErrorAddItemAllocation(true);
    }
    resetForm();
  };

  const [isSuccessEditItemAllocation, setIsSuccessEditItemAllocation] =
    useState(false);
  const [isErrorEditItemAllocation, setIsErrorEditItemAllocation] =
    useState(false);

  const [editItemAllocationModal, setEditItemAllocationModal] = useState(false);
  const handleEditItemAllocationModal = () => {
    setEditItemAllocationModal(!editItemAllocationModal);
  };

  const initialValueForEditItemAllocation = {
    bin: "",
  };

  const validationSchemaForEditItemAllocation = Yup.object({
    bin: Yup.string().required("Select the BIN"),
  });

  const onSubmitForEditItemAllocation = async (values, reset) => {
    const payload = {
      itemId: itemAllocationData.itemId,
      warehouseId: warehouseIdSelected,
      zoneId: itemAllocationData.zoneId,
      locationId: values.bin,
    };

    let res = await editBinAllocation(payload);

    if (res.status) {
      setIsSuccessEditItemAllocation(true);
      getAllocatedItems(warehouseIdSelected);
    }
    if (!res.status) {
      setIsErrorEditItemAllocation(true);
    }
  };

  const [isErrorDeleteItemAllocation, setIsErrorDeleteItemAllocation] =
    useState(false);

  const [openDeleteItemAllocationModal, setOpenDeleteItemAllocationModal] =
    useState(false);

  const handleOpenDeleteItemAllocationModal = (data = {}) => {
    setItemAllocationData(data);
    setOpenDeleteItemAllocationModal(true);
  };

  const handleCloseDeleteItemAllocationModal = () => {
    setOpenDeleteItemAllocationModal(false);
  };

  const onClickDeleteItemAllocation = async () => {
    let res = await removeBinAllocation(
      warehouseIdSelected,
      itemAllocationData.itemId,
      itemAllocationData.zoneId,
      itemAllocationData.locationId
    );

    if (res.status) {
      getAllocatedItems(warehouseIdSelected);
      setOpenDeleteItemAllocationModal(false);
    }
    if (!res.status) {
      setIsErrorEditItemAllocation(true);
    }
  };

  const changeWarehouse = (e) => {
    setWarehouseIdSelected(e.target.value);
  };

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title={
                <div>
                  <br />
                  <h5>Item Allocation</h5>
                  <br />
                  <select
                    as="select"
                    id="warehouseName"
                    name="warehouseName"
                    className="form-control"
                    onChange={changeWarehouse}
                    style={{ width: "10.4rem" }}
                  >
                    {warehouseOptions.map((option) => {
                      return (
                        <option key={option.key} value={option.value}>
                          {option.key}
                        </option>
                      );
                    })}
                  </select>
                </div>
              }
              columns={state.columns}
              data={state.data}
              options={{ draggable: false }}
              actions={[
                {
                  icon: "add",
                  tooltip: "Allocate Zone",
                  isFreeAction: true,
                  onClick: (event) => handleAllocateItemModal(),
                  iconProps: {
                    color: "error",
                    fontSize: "large",
                  },
                },
              ]}
            />
            <div>
              <Modal
                show={allocateItemModal}
                onHide={handleAllocateItemModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Item Allocation</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Formik
                          initialValues={initialValue}
                          validationSchema={validationSchema}
                          onSubmit={onSubmit}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="itemCode">
                                    Select the Item Code
                                  </label>
                                  <Field
                                    as="select"
                                    id="itemCode"
                                    name="itemCode"
                                    className="form-control"
                                  >
                                    <option value="" disabled selected>
                                      Choose one...
                                    </option>
                                    {itemCodeOptions.map((option) => {
                                      return (
                                        <option
                                          key={option.key}
                                          value={option.value}
                                        >
                                          {option.key}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                  <ErrorMessage
                                    name="itemCode"
                                    component={TextError}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="zoneName">
                                    Select the Zone
                                  </label>
                                  <Field
                                    as="select"
                                    id="zoneName"
                                    name="zoneName"
                                    className="form-control"
                                  >
                                    <option value="" disabled selected>
                                      Choose one...
                                    </option>
                                    {zoneOptions.map((option) => {
                                      return (
                                        <option
                                          key={option.key}
                                          value={option.value}
                                        >
                                          {option.key}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                  <ErrorMessage
                                    name="zoneName"
                                    component={TextError}
                                  />
                                </div>
                                {formik.values.zoneName &&
                                  setZoneIdSelected(formik.values.zoneName)}
                                <div className="form-group">
                                  <label htmlFor="bin">
                                    Select the BIN (Optional)
                                  </label>
                                  <Field
                                    as="select"
                                    id="bin"
                                    name="bin"
                                    className="form-control"
                                  >
                                    <option value="" disabled selected>
                                      Choose one...
                                    </option>
                                    {openBinsOptions.map((option) => {
                                      return (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.key}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                  <ErrorMessage
                                    name="bin"
                                    component={TextError}
                                  />
                                </div>
                                <div className="mt-3">
                                  <Collapse in={isErrorAllocateItem}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsErrorAllocateItem(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Could not Allocate the Item
                                    </Alert>
                                  </Collapse>
                                  <Collapse in={isSuccessAllocateItem}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccessAllocateItem(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Item Allocated successfully
                                    </Alert>
                                  </Collapse>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                    // disabled={!formik.isValid}
                                  >
                                    Allocate Item
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                show={addItemAllocationModal}
                onHide={handleAddItemAllocationModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Add BIN</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Formik
                          initialValues={initialValueForAddItemAllocation}
                          validationSchema={
                            validationSchemaForAddItemAllocation
                          }
                          onSubmit={onSubmitForAddItemAllocation}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="bin">Select the BIN</label>
                                  <Field
                                    as="select"
                                    id="bin"
                                    name="bin"
                                    className="form-control"
                                  >
                                    <option value="" disabled selected>
                                      Choose one...
                                    </option>
                                    {openBinsOptions.map((option) => {
                                      return (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.key}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                  <ErrorMessage
                                    name="bin"
                                    component={TextError}
                                  />
                                </div>
                                <div className="mt-3">
                                  <Collapse in={isErrorAddItemAllocation}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsErrorAddItemAllocation(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Could not add the BIN
                                    </Alert>
                                  </Collapse>
                                  <Collapse in={isSuccessAddItemAllocation}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccessAddItemAllocation(
                                              false
                                            );
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      BIN added successfully
                                    </Alert>
                                  </Collapse>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                    disabled={!formik.isValid}
                                  >
                                    Add BIN
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                show={editItemAllocationModal}
                onHide={handleEditItemAllocationModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit BIN</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Formik
                          initialValues={initialValueForEditItemAllocation}
                          validationSchema={
                            validationSchemaForEditItemAllocation
                          }
                          onSubmit={onSubmitForEditItemAllocation}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="bin">Select the BIN</label>
                                  <Field
                                    as="select"
                                    id="bin"
                                    name="bin"
                                    className="form-control"
                                  >
                                    <option
                                      value={itemAllocationData?.locationId}
                                      selected
                                    >
                                      {itemAllocationData?.locationName}
                                    </option>
                                    {openBinsOptions.map((option) => {
                                      return (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.key}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                  <ErrorMessage
                                    name="bin"
                                    component={TextError}
                                  />
                                </div>
                                <div className="mt-3">
                                  <Collapse in={isErrorEditItemAllocation}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsErrorEditItemAllocation(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Could not edit the BIN
                                    </Alert>
                                  </Collapse>
                                  <Collapse in={isSuccessEditItemAllocation}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccessEditItemAllocation(
                                              false
                                            );
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      BIN edited successfully
                                    </Alert>
                                  </Collapse>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                    disabled={!formik.isValid}
                                  >
                                    Edit BIN
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                show={openDeleteItemAllocationModal}
                onHide={handleCloseDeleteItemAllocationModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Delete Item Allocation</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <p>
                          Are you sure you want to delete the item allocation?
                        </p>

                        <div class="clearfix">
                          <button
                            type="button"
                            className="cancelbtn button1"
                            onClick={handleCloseDeleteItemAllocationModal}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="deletebtn button1"
                            onClick={onClickDeleteItemAllocation}
                          >
                            Delete
                          </button>
                        </div>
                        <div className="mt-3">
                          <Collapse in={isErrorDeleteItemAllocation}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorDeleteItemAllocation(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Could not Delete the Item Allocation
                            </Alert>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    warehouses: state.warehouses,
    allocatedBins: state.allocatedBins,
    itemMaster: state.itemMaster,
    zonesByWarehouse: state.zonesByWarehouse,
    openBinsByWarehouse: state.openBinsByWarehouse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getWarehouses: () => dispatch(getWarehouses()),
    getAllocatedItems: (id) => dispatch(getAllocatedItems(id)),
    getItemMaster: () => dispatch(getItemMaster()),
    getZonesByWarehouse: (id) => dispatch(getZonesByWarehouse(id)),
    getOpenBinsByWarehouseAndZone: (id, zoneId) =>
      dispatch(getOpenBinsByWarehouseAndZone(id, zoneId)),
    addBinAllocation: (payload) => dispatch(addBinAllocation(payload)),
    editBinAllocation: (payload) => dispatch(editBinAllocation(payload)),
    removeBinAllocation: (warehouseId, itemId, zoneId, locationId) =>
      dispatch(removeBinAllocation(warehouseId, itemId, zoneId, locationId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemAllocation);
