export const PRINTLABEL_INDIVIDUALPRINT_REQUEST =
  "PRINTLABEL_INDIVIDUALPRINT_REQUEST";
export const PRINTLABEL_INDIVIDUALPRINT_SUCCESS =
  "PRINTLABEL_INDIVIDUALPRINT_SUCCESS";
export const PRINTLABEL_INDIVIDUALPRINT_FAILURE =
  "PRINTLABEL_INDIVIDUALPRINT_FAILURE";

export const PRINTLABEL_PACKAGEPRINT_REQUEST =
  "PRINTLABEL_PACKAGEPRINT_REQUEST";
export const PRINTLABEL_PACKAGEPRINT_SUCCESS =
  "PRINTLABEL_PACKAGEPRINT_SUCCESS";
export const PRINTLABEL_PACKAGEPRINT_FAILURE =
  "PRINTLABEL_PACKAGEPRINT_FAILURE";

export const TRANSFERORDER_INDIVIDUALPRINT_REQUEST =
  "TRANSFERORDER_INDIVIDUALPRINT_REQUEST";
export const TRANSFERORDER_INDIVIDUALPRINT_SUCCESS =
  "TRANSFERORDER_INDIVIDUALPRINT_SUCCESS";
export const TRANSFERORDER_INDIVIDUALPRINT_FAILURE =
  "TRANSFERORDER_INDIVIDUALPRINT_FAILURE";

export const TRANSFERORDER_PACKAGEPRINT_REQUEST =
  "TRANSFERORDER_PACKAGEPRINT_REQUEST";
export const TRANSFERORDER_PACKAGEPRINT_SUCCESS =
  "TRANSFERORDER_PACKAGEPRINT_SUCCESS";
export const TRANSFERORDER_PACKAGEPRINT_FAILURE =
  "TRANSFERORDER_PACKAGEPRINT_FAILURE";
