/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_LOGIN_ID_REQUEST,
  GET_LOGIN_ID_SUCCESS,
  GET_LOGIN_ID_FAILURE,
} from "./loginIdTypes";

import axios from "axios";
import Config from "../../../config/index";
// let userManagementUrl = Config.userManagementUrl;
let baseUrl = Config.baseUrl;
// let userManagementUrl = `http://13.71.2.248:1338/api/v2/users_management`;

const getLoginIdsRequest = () => {
  return {
    type: GET_LOGIN_ID_REQUEST,
  };
};

const getLoginIdsSuccess = (payload) => {
  return {
    type: GET_LOGIN_ID_SUCCESS,
    payload: payload,
  };
};

const getLoginIdsFaliure = (error) => {
  return {
    type: GET_LOGIN_ID_FAILURE,
    payload: error,
  };
};

const getLoginIds = () => {
  return (dispatch) => {
    dispatch(getLoginIdsRequest());
    let url = `${baseUrl}/user_access/get_login_id`;
    return axios
      .get(url)
      .then((response) => {
        dispatch(getLoginIdsSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getLoginIdsFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export { getLoginIds };
