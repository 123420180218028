import {
  GET_ALLGRN_REQUEST,
  GET_ALLGRN_SUCCESS,
  GET_ALLGRN_FAILURE,
  GET_LINEITEMSBYGRN_REQUEST,
  GET_LINEITEMSBYGRN_SUCCESS,
  GET_LINEITEMSBYGRN_FAILURE,
} from "./GRNTypes";

const initialState = {
  loading: false,
  GRN: [],
  error: "",
};

const getAllGRNReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALLGRN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALLGRN_SUCCESS:
      return {
        loading: false,
        GRN: action.payload,
        error: "",
      };
    case GET_ALLGRN_FAILURE:
      return {
        loading: false,
        GRN: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForLineItems = {
  loading: false,
  lineItems: [],
  error: "",
};

const getLineItemsByGrnReducer = (state = initialStateForLineItems, action) => {
  switch (action.type) {
    case GET_LINEITEMSBYGRN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LINEITEMSBYGRN_SUCCESS:
      return {
        loading: false,
        lineItems: action.payload,
        error: "",
      };
    case GET_LINEITEMSBYGRN_FAILURE:
      return {
        loading: false,
        lineItems: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getAllGRNReducer, getLineItemsByGrnReducer };
