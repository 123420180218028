import {
  GET_ITEMALLOCATION_REQUEST,
  GET_ITEMALLOCATION_SUCCESS,
  GET_ITEMALLOCATION_FAILURE,
  ADD_ITEMALLOCATION_REQUEST,
  ADD_ITEMALLOCATION_SUCCESS,
  ADD_ITEMALLOCATION_FAILURE,
  REMOVE_ITEMALLOCATION_REQUEST,
  REMOVE_ITEMALLOCATION_SUCCESS,
  REMOVE_ITEMALLOCATION_FAILURE,
  EDIT_ITEMALLOCATION_REQUEST,
  EDIT_ITEMALLOCATION_SUCCESS,
  EDIT_ITEMALLOCATION_FAILURE,
} from "./ItemAllocationTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getAllocatedItemsRequest = () => {
  return {
    type: GET_ITEMALLOCATION_REQUEST,
  };
};

const getAllocatedItemsSuccess = (payload) => {
  return {
    type: GET_ITEMALLOCATION_SUCCESS,
    payload: payload,
  };
};

const getAllocatedItemsFailure = (error) => {
  return {
    type: GET_ITEMALLOCATION_FAILURE,
    payload: error,
  };
};

const getAllocatedItems = (warehouseId) => {
  return (dispatch) => {
    dispatch(getAllocatedItemsRequest());
    let url = `${baseUrl}/production/pickup/item_mapping?warehouse_id=${warehouseId}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getAllocatedItemsSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getAllocatedItemsFailure(err.message));
      });
  };
};

const addBinAllocationRequest = () => {
  return {
    type: ADD_ITEMALLOCATION_REQUEST,
  };
};

const addBinAllocationSuccess = (payload) => {
  return {
    type: ADD_ITEMALLOCATION_SUCCESS,
    payload: payload,
  };
};

const addBinAllocationFailure = (error) => {
  return {
    type: ADD_ITEMALLOCATION_FAILURE,
    payload: error,
  };
};

const addBinAllocation = (payload) => {
  return (dispatch) => {
    dispatch(addBinAllocationRequest());
    let url = `${baseUrl}/production/pickup/allocation_table`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(addBinAllocationSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(addBinAllocationFailure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const removeBinAllocationRequest = () => {
  return {
    type: REMOVE_ITEMALLOCATION_REQUEST,
  };
};

const removeBinAllocationSuccess = (payload) => {
  return {
    type: REMOVE_ITEMALLOCATION_SUCCESS,
    payload: payload,
  };
};

const removeBinAllocationFailure = (error) => {
  return {
    type: REMOVE_ITEMALLOCATION_FAILURE,
    payload: error,
  };
};

const removeBinAllocation = (warehouseId, itemId, zoneId, locationId) => {
  return (dispatch) => {
    dispatch(removeBinAllocationRequest());
    let url = `${baseUrl}/production/pickup/delete_item_allocation?warehouse_id=${warehouseId}&item_id=${itemId}&zone_id=${zoneId}&location_id=${locationId}`;
    return axios
      .delete(url)
      .then((response) => {
        dispatch(removeBinAllocationSuccess(response));
        return { status: true };
      })
      .catch((err) => {
        dispatch(removeBinAllocationFailure(err.message));
        return { status: false };
      });
  };
};

const editBinAllocationRequest = () => {
  return {
    type: EDIT_ITEMALLOCATION_REQUEST,
  };
};

const editBinAllocationSuccess = (payload) => {
  return {
    type: EDIT_ITEMALLOCATION_SUCCESS,
    payload: payload,
  };
};

const editBinAllocationFailure = (error) => {
  return {
    type: EDIT_ITEMALLOCATION_FAILURE,
    payload: error,
  };
};

const editBinAllocation = (payload) => {
  return (dispatch) => {
    dispatch(editBinAllocationRequest());
    let url = `${baseUrl}/production/pickup/update_location`;
    return axios
      .put(url, payload)
      .then((response) => {
        dispatch(editBinAllocationSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(editBinAllocationFailure(err.message));
        return { status: false, message: err.message };
      });
  };
};

export {
  getAllocatedItems,
  addBinAllocation,
  removeBinAllocation,
  editBinAllocation,
};
