/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  CREATE_USER_DETAILS_REQUEST,
  CREATE_USER_DETAILS_SUCCESS,
  CREATE_USER_DETAILS_FAILURE,
  UPDATE_USER_DETAILS_REQUEST,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
  DELETE_USER_DETAILS_REQUEST,
  DELETE_USER_DETAILS_SUCCESS,
  DELETE_USER_DETAILS_FAILURE,
} from "./userDetailsTypes";

const initialState = {
  loading: false,
  usersDetails: {},
  error: "",
};

const getUsersDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        loading: true,
        usersDetails: action.payload,
        error: "",
      };
    case GET_USER_DETAILS_FAILURE:
      return {
        loading: false,
        usersDetails: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForCreate = {
  loading: false,
  userDetails: {},
  error: "",
};

const createUserDetailsReducer = (state = initialStateForCreate, action) => {
  switch (action.type) {
    case CREATE_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_USER_DETAILS_SUCCESS:
      return {
        loading: true,
        userDetails: action.payload,
        error: "",
      };
    case CREATE_USER_DETAILS_FAILURE:
      return {
        loading: false,
        userDetails: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForUpdate = {
  loading: false,
  userDetails: {},
  error: "",
};

const updateUserDetailsReducer = (state = initialStateForUpdate, action) => {
  switch (action.type) {
    case UPDATE_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_DETAILS_SUCCESS:
      return {
        loading: true,
        userDetails: action.payload,
        error: "",
      };
    case UPDATE_USER_DETAILS_FAILURE:
      return {
        loading: false,
        userDetails: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDelete = {
  loading: false,
  userDetails: {},
  error: "",
};

const deleteUserDetailsReducer = (state = initialStateForDelete, action) => {
  switch (action.type) {
    case DELETE_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_USER_DETAILS_SUCCESS:
      return {
        loading: true,
        userDetails: action.payload,
        error: "",
      };
    case DELETE_USER_DETAILS_FAILURE:
      return {
        loading: false,
        userDetails: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getUsersDetailsReducer,
  createUserDetailsReducer,
  updateUserDetailsReducer,
  deleteUserDetailsReducer,
};
