import {
  GETSKU_BYITEMCODEANDWAREHOUSECODE_REQUEST,
  GETSKU_BYITEMCODEANDWAREHOUSECODE_SUCCESS,
  GETSKU_BYITEMCODEANDWAREHOUSECODE_FAILURE,
  GET_TRANSFERORDERS_REQUEST,
  GET_TRANSFERORDERS_SUCCESS,
  GET_TRANSFERORDERS_FAILURE,
} from "./TransferOrderTypes";

const initialState = {
  loading: false,
  sku: [],
  error: "",
};

const getSkuByItemCodeAndWarehouseCodeReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GETSKU_BYITEMCODEANDWAREHOUSECODE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETSKU_BYITEMCODEANDWAREHOUSECODE_SUCCESS:
      return {
        loading: false,
        sku: action.payload,
        error: "",
      };
    case GETSKU_BYITEMCODEANDWAREHOUSECODE_FAILURE:
      return {
        loading: false,
        sku: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAllTransferOrders = {
  loading: false,
  transferOrders: [],
  error: "",
};

const getAllTransferOrdersReducer = (
  state = initialStateForAllTransferOrders,
  action
) => {
  switch (action.type) {
    case GET_TRANSFERORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TRANSFERORDERS_SUCCESS:
      return {
        loading: false,
        transferOrders: action.payload,
        error: "",
      };
    case GET_TRANSFERORDERS_FAILURE:
      return {
        loading: false,
        transferOrders: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getSkuByItemCodeAndWarehouseCodeReducer, getAllTransferOrdersReducer };
