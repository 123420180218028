import {
  GETSKU_BYITEMCODEANDWAREHOUSECODE_REQUEST,
  GETSKU_BYITEMCODEANDWAREHOUSECODE_SUCCESS,
  GETSKU_BYITEMCODEANDWAREHOUSECODE_FAILURE,
  GET_TRANSFERORDERS_REQUEST,
  GET_TRANSFERORDERS_SUCCESS,
  GET_TRANSFERORDERS_FAILURE,
} from "./TransferOrderTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getSkuByItemCodeAndWarehouseCodeRequest = () => {
  return {
    type: GETSKU_BYITEMCODEANDWAREHOUSECODE_REQUEST,
  };
};

const getSkuByItemCodeAndWarehouseCodeSuccess = (payload) => {
  return {
    type: GETSKU_BYITEMCODEANDWAREHOUSECODE_SUCCESS,
    payload: payload,
  };
};

const getSkuByItemCodeAndWarehouseCodeFailure = (error) => {
  return {
    type: GETSKU_BYITEMCODEANDWAREHOUSECODE_FAILURE,
    payload: error,
  };
};

const getSkuByItemCodeAndWarehouseCode = (itemId, warehouseId) => {
  return (dispatch) => {
    dispatch(getSkuByItemCodeAndWarehouseCodeRequest());
    let url = `${baseUrl}/transfer_order/sku_by_item_code_&_wh_code?item_id=${itemId}&warehouse_code=${warehouseId}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getSkuByItemCodeAndWarehouseCodeSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getSkuByItemCodeAndWarehouseCodeFailure(err.message));
      });
  };
};

const getTransferOrdersRequest = () => {
  return {
    type: GET_TRANSFERORDERS_REQUEST,
  };
};

const getTransferOrdersSuccess = (payload) => {
  return {
    type: GET_TRANSFERORDERS_SUCCESS,
    payload: payload,
  };
};

const getTransferOrdersFailure = (error) => {
  return {
    type: GET_TRANSFERORDERS_FAILURE,
    payload: error,
  };
};

const getAllTransferOrders = () => {
  return (dispatch) => {
    dispatch(getTransferOrdersRequest());
    let url = `${baseUrl}/transfer_order/get_all_transfer_orders`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getTransferOrdersSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getTransferOrdersFailure(err.message));
      });
  };
};

export { getSkuByItemCodeAndWarehouseCode, getAllTransferOrders };
