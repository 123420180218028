import {
    GET_FGAGEING_REQUEST,
    GET_FGAGEING_SUCCESS,
    GET_FGAGEING_FAILURE,
  } from "./FgAgeingTypes";
  
  const initialStateForFgAgeing = {
    loading: false,
    FgAgeing: [],
    error: "",
  };
  
  const getFgAgeingReducer = (state = initialStateForFgAgeing, action) => {
    switch (action.type) {
      case GET_FGAGEING_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_FGAGEING_SUCCESS:
        return {
          loading: false,
          FgAgeing: action.payload,
          error: "",
        };
      case GET_FGAGEING_FAILURE:
        return {
          loading: false,
          FgAgeing: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  
  
  export { getFgAgeingReducer};
  