export const GET_ITEMALLOCATION_REQUEST = "GET_ITEMALLOCATION_REQUEST";
export const GET_ITEMALLOCATION_SUCCESS = "GET_ITEMALLOCATION_SUCCESS";
export const GET_ITEMALLOCATION_FAILURE = "GET_ITEMALLOCATION_FAILURE";

export const ADD_ITEMALLOCATION_REQUEST = "ADD_ITEMALLOCATION_REQUEST";
export const ADD_ITEMALLOCATION_SUCCESS = "ADD_ITEMALLOCATION_SUCCESS";
export const ADD_ITEMALLOCATION_FAILURE = "ADD_ITEMALLOCATION_FAILURE";

export const REMOVE_ITEMALLOCATION_REQUEST = "REMOVE_ITEMALLOCATION_REQUEST";
export const REMOVE_ITEMALLOCATION_SUCCESS = "REMOVE_ITEMALLOCATION_SUCCESS";
export const REMOVE_ITEMALLOCATION_FAILURE = "REMOVE_ITEMALLOCATION_FAILURE";

export const EDIT_ITEMALLOCATION_REQUEST = "EDIT_ITEMALLOCATION_REQUEST";
export const EDIT_ITEMALLOCATION_SUCCESS = "EDIT_ITEMALLOCATION_SUCCESS";
export const EDIT_ITEMALLOCATION_FAILURE = "EDIT_ITEMALLOCATION_FAILURE";
