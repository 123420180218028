export const GETSKU_BYITEMCODEANDWAREHOUSECODE_REQUEST =
  "GETSKU_BYITEMCODEANDWAREHOUSECODE_REQUEST";
export const GETSKU_BYITEMCODEANDWAREHOUSECODE_SUCCESS =
  "GETSKU_BYITEMCODEANDWAREHOUSECODE_SUCCESS";
export const GETSKU_BYITEMCODEANDWAREHOUSECODE_FAILURE =
  "GETSKU_BYITEMCODEANDWAREHOUSECODE_FAILURE";

export const GET_TRANSFERORDERS_REQUEST = "GET_TRANSFERORDERS_REQUEST";
export const GET_TRANSFERORDERS_SUCCESS = "GET_TRANSFERORDERS_SUCCESS";
export const GET_TRANSFERORDERS_FAILURE = "GET_TRANSFERORDERS_FAILURE";
