import {
  GET_INVENTORYAGEING_REQUEST,
  GET_INVENTORYAGEING_SUCCESS,
  GET_INVENTORYAGEING_FAILURE,
} from "./InventoryAgeingTypes";
import axios from "axios";
import Config from "../../../config/index";
let baseUrl = Config.baseUrl;

const getInventoryAgeingRequest = () => {
  return {
    type: GET_INVENTORYAGEING_REQUEST,
  };
};

const getInventoryAgeingSuccess = (payload) => {
  return {
    type: GET_INVENTORYAGEING_SUCCESS,
    payload: payload,
  };
};

const getInventoryAgeingFailure = (error) => {
  return {
    type: GET_INVENTORYAGEING_FAILURE,
    payload: error,
  };
};

const getInventoryAgeing = (itemId, warehouseId, caregoryId, status) => {
  return (dispatch) => {
    dispatch(getInventoryAgeingRequest());
    let url = `${baseUrl}/reports/inventory_by_expiry_days?${
      itemId ? `item_id=${itemId}` : ""
    }${warehouseId ? `&warehouse_id=${warehouseId}` : ""}${
      caregoryId ? `&category_id=${caregoryId}` : ""
    }&status=${status}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getInventoryAgeingSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getInventoryAgeingFailure(err.message));
      });
  };
};

export { getInventoryAgeing };
