import {
  GETWAREHOUSE_REQUEST,
  GETWAREHOUSE_SUCCESS,
  GETWAREHOUSE_FAILURE,
  GETWAREHOUSE_BYID_REQUEST,
  GETWAREHOUSE_BYID_SUCCESS,
  GETWAREHOUSE_BYID_FAILURE,
  UPDATEWAREHOUSE_REQUEST,
  UPDATEWAREHOUSE_SUCCESS,
  UPDATEWAREHOUSE_FAILURE,
} from "./WarehouseTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getWarehouseRequest = () => {
  return {
    type: GETWAREHOUSE_REQUEST,
  };
};

const getWarehouseSuccess = (payload) => {
  return {
    type: GETWAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const getWarehouseFailure = (error) => {
  return {
    type: GETWAREHOUSE_FAILURE,
    payload: error,
  };
};

const getWarehouses = () => {
  return (dispatch) => {
    dispatch(getWarehouseRequest());
    let url = `${baseUrl}/warehouses`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getWarehouseSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getWarehouseFailure(err.message));
      });
  };
};

const getWarehouseByIdRequest = () => {
  return {
    type: GETWAREHOUSE_BYID_REQUEST,
  };
};

const getWarehouseByIdSuccess = (payload) => {
  return {
    type: GETWAREHOUSE_BYID_SUCCESS,
    payload: payload,
  };
};

const getWarehouseByIdFailure = (error) => {
  return {
    type: GETWAREHOUSE_BYID_FAILURE,
    payload: error,
  };
};

const getWarehouseById = (id) => {
  return (dispatch) => {
    dispatch(getWarehouseByIdRequest());
    let url = `${baseUrl}/warehouses/by_id?warehouse_id=${id}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getWarehouseByIdSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getWarehouseByIdFailure(err.message));
      });
  };
};

const updateWarehouseRequest = () => {
  return {
    type: UPDATEWAREHOUSE_REQUEST,
  };
};

const updateWarehouseSuccess = (payload) => {
  return {
    type: UPDATEWAREHOUSE_SUCCESS,
    payload: payload,
  };
};

const updateWarehouseFailure = (error) => {
  return {
    type: UPDATEWAREHOUSE_FAILURE,
    payload: error,
  };
};

const updateWarehouse = (payload) => {
  return (dispatch) => {
    dispatch(updateWarehouseRequest());
    let url = `${baseUrl}/warehouse`;
    return axios
      .put(url, payload)
      .then((response) => {
        dispatch(updateWarehouseSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateWarehouseFailure(err.message));
        return { status: false, message: err.message };
      });
  };
};

export { getWarehouses, getWarehouseById, updateWarehouse };
