import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { makeStyles } from "@material-ui/core/styles";
import drawerCss from "../../style/drawer";
import classNames from "classnames";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { updateSelectedItem, getPicklistAgeing } from "../../store";
import Config from "../../config/index";
import DatePicker from "react-datepicker";
import { format, addMinutes } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

const desLength = Config.descriptionLength;

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

const PicklistAgeing = ({
  drawerState,
  handleListItemClick,

  getPicklistAgeing,
  getPicklistAgeingState,
}) => {
  useEffect(() => {
    handleListItemClick(null, 25);
  }, []);

  const classes = useStyles();
  let drawer = drawerState;

  const [statusIdSelected, setStatusIdSelected] = useState(undefined);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const isoStartDate = new Date(
      (startDate ? startDate : new Date()).getTime() -
        (startDate ? startDate : new Date()).getTimezoneOffset() * 60000
    ).toISOString();

    const isoEndDate = new Date(
      (endDate ? endDate : new Date()).getTime() -
        (endDate ? endDate : new Date()).getTimezoneOffset() * 60000
    ).toISOString();

    

    getPicklistAgeing(statusIdSelected, isoStartDate, isoEndDate);
  }, [statusIdSelected, startDate, endDate]);

  const [state, setState] = useState({
    columns: [
      { title: "Picklist No", field: "pickupListCode" },
      { title: "Start Time", field: "starttime" },
      { title: "End Time", field: "endtime" },
      { title: "Status", field: "status" },
    ],
    data: [],
  });

  useEffect(() => {
    let tempReports = [];
    let data = getPicklistAgeingState.PicklistAgeing
      ? getPicklistAgeingState.PicklistAgeing
      : [];
    data.map((singleData) => {
      let startTime = new Date(singleData.startPickupTime);
      let endTime = new Date(singleData.endPickupTime);
      const dataObject = {
        pickupListCode: singleData.pickupListCode,
        starttime: format(
          addMinutes(startTime, startTime.getTimezoneOffset()),
          "do MMMM yyyy, h:mm a"
        ),
        endtime: format(
          addMinutes(endTime, endTime.getTimezoneOffset()),
          "do MMMM yyyy, h:mm a"
        ),
        status: singleData.status,
      };
      tempReports.push(dataObject);
    });
    setState({ ...state, data: tempReports });
  }, [getPicklistAgeingState]);

  const [showFilters, setShowFilters] = useState(false);

  const showFiltersHandler = () => {
    setShowFilters(!showFilters);
  };

  const changeStatus = (e) => {
    
    setStatusIdSelected(e.target.value);
  };

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title={
                <div>
                  <br />
                  <h5>Picklist Ageing</h5>
                  <br />
                  {!showFilters && <br />}
                  {!showFilters && <br />}
                  {showFilters && (
                    <span style={{ display: "flex" }}>
                      <select
                        style={{
                          width: "11rem",
                          marginRight: "1rem",
                        }}
                        as="select"
                        id="status"
                        name="status"
                        className="form-control"
                        onChange={changeStatus}
                      >
                        <option value={`${undefined}`} defaultValue>
                          All Status
                        </option>
                        <option value={0}>less than 4 hrs</option>
                        <option value={1}>greater than 4 hrs</option>
                      </select>
                      <span style={{ width: "11rem" }}>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Start Date"
                          maxDate={new Date()}
                          onChange={(date) => setStartDate(date)}
                          isClearable={true}
                          withPortal
                          selectsStart
                          selected={startDate}
                          startDate={startDate}
                          endDate={endDate}
                          // minDate={startDate}
                        />
                      </span>
                      <span
                        style={{
                          marginLeft: "0.5rem",
                          marginRight: "0.5rem",
                          marginTop: "0.5rem",
                        }}
                      >
                        -
                      </span>
                      <span style={{ width: "11rem" }}>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="End Date"
                          maxDate={new Date()}
                          onChange={(date) => setEndDate(date)}
                          isClearable={true}
                          withPortal
                          selectsEnd
                          selected={endDate}
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </span>
                    </span>
                  )}
                </div>
              }
              columns={state.columns}
              data={state.data}
              localization={{
                body: {
                  emptyDataSourceMessage: "Choose a filter to display data",
                },
              }}
              actions={[
                {
                  icon: "search",
                  tooltip: "Show Filters",
                  isFreeAction: true,
                  onClick: () => {
                    showFiltersHandler();
                  },
                },
              ]}
              options={{
                draggable: false,
                exportButton: true,
                exportAllData: true,
                exportFileName: `Picklist Ageing`,
                filtering: true,
              }}
              components={{
                Action: (props) => (
                  <button
                    style={{ width: "4.5rem" }}
                    onClick={() => props.action.onClick()}
                  >
                    Filters
                  </button>
                ),
              }}
            />
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    getPicklistAgeingState: state.getPicklistAgeing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getPicklistAgeing: (status, startDate, endDate) =>
      dispatch(getPicklistAgeing(status, startDate, endDate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PicklistAgeing);
