/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  SET_PASSWORD_REQUEST,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILURE,
} from "./setPasswordTypes";
import axios from "axios";
import Config from "../../../config/index";
let auth3Url = Config.auth3Url;
let baseUrl = Config.baseUrl;

const setPasswordRequest = () => {
  return {
    type: SET_PASSWORD_REQUEST,
  };
};

const setPasswordSuccess = (payload) => {
  return {
    type: SET_PASSWORD_SUCCESS,
    payload: payload,
  };
};

const setPasswordFaliure = (error) => {
  return {
    type: SET_PASSWORD_FAILURE,
    payload: error,
  };
};

const setPassword = (setPasswordState) => {
  return (dispatch) => {
    let payload = {
      email: setPasswordState.email,
      newpassword: setPasswordState.newpassword,
      confirmpassword: setPasswordState.confirmpassword,
    };
    dispatch(setPasswordRequest());
    // let url = `${auth3Url}/password/reset_password`;
    let url = `${baseUrl}/user_access/set_password`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(setPasswordSuccess(response.data));
        return { status: true };
      })
      .catch((err) => {
        dispatch(setPasswordFaliure(err.message));
        return { status: false };
      });
  };
};

export { setPassword };
