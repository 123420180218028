import {
  GET_SALESORDER_REQUEST,
  GET_SALESORDER_SUCCESS,
  GET_SALESORDER_FAILURE,
  GET_VINSBYTRIPNO_REQUEST,
  GET_VINSBYTRIPNO_SUCCESS,
  GET_VINSBYTRIPNO_FAILURE,
  PUT_APPROVEVIN_REQUEST,
  PUT_APPROVEVIN_SUCCESS,
  PUT_APPROVEVIN_FAILURE,
} from "./DispatchTypes";

const initialState = {
  loading: false,
  salesOrder: [],
  error: "",
};

const getSalesOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SALESORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SALESORDER_SUCCESS:
      return {
        loading: false,
        salesOrder: action.payload,
        error: "",
      };
    case GET_SALESORDER_FAILURE:
      return {
        loading: false,
        salesOrder: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForApproveVin = {
  loading: false,
  vins: [],
  error: "",
};

const approveVinReducer = (state = initialStateForApproveVin, action) => {
  switch (action.type) {
    case PUT_APPROVEVIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PUT_APPROVEVIN_SUCCESS:
      return {
        loading: false,
        vins: action.payload,
        error: "",
      };
    case PUT_APPROVEVIN_FAILURE:
      return {
        loading: false,
        vins: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForVinStatus = {
  loading: false,
  vins: [],
  error: "",
};

const getVinStatusByTripNoReducer = (
  state = initialStateForVinStatus,
  action
) => {
  switch (action.type) {
    case GET_VINSBYTRIPNO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_VINSBYTRIPNO_SUCCESS:
      return {
        loading: false,
        vins: action.payload,
        error: "",
      };
    case GET_VINSBYTRIPNO_FAILURE:
      return {
        loading: false,
        vins: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getSalesOrderReducer,
  approveVinReducer,
  getVinStatusByTripNoReducer,
};
