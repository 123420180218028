import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { makeStyles } from "@material-ui/core/styles";
import drawerCss from "../../style/drawer";
import classNames from "classnames";
import { connect } from "react-redux";
import {
  updateSelectedItem,
  getAllCategories,
  getZonesByCategoryAndWarehouse,
  getLocationsByWarehouse,
  getLocationsByWarehouseAndZone,
  addLocationToWarehouse,
  deleteLocation,
  getItemByLocation,
  getWarehouseById,
} from "../../store";
import { useParams } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../utils/TextError";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import MaterialTable from "material-table";
import Typography from "@material-ui/core/Typography";
import Config from "../../config/index";

const desLength = Config.descriptionLength;

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

const ViewLocation = ({
  drawerState,
  handleListItemClick,
  getAllCategories,
  allCategories,
  getZonesByCategoryAndWarehouse,
  zonesByCategoryAndWarehouse,
  getLocationsByWarehouse,
  getLocationsByWarehouseAndZone,
  locationsByWarehouseAndZone,
  locationsByWarehouse,
  addLocationToWarehouse,
  deleteLocation,
  getItemByLocation,
  item,
  getWarehouseById,
  warehouseById,
}) => {
  useEffect(() => {
    handleListItemClick(
      null,
      (warehouseType === "s" && 1) ||
        (warehouseType === "d" && 7) ||
        (warehouseType === "f" && 12)
    );
  }, []);

  const classes = useStyles();
  let drawer = drawerState;

  const { warehouseType, warehouseId } = useParams();

  const [categoryIdSelected, setCategoryIdSelected] = useState(
    warehouseType === "s" ? "" : 1
  );

  const [zoneIdSelected, setZoneIdSelected] = useState("");

  const [warehouseLocations, setWarehouseLocations] = useState([]);

  useEffect(() => {
    getLocationsByWarehouse(warehouseId);
  }, [warehouseId]);

  useEffect(() => {
    setWarehouseLocations(locationsByWarehouse.locations);
  }, [locationsByWarehouse]);

  useEffect(() => {
    getAllCategories();
  }, []);

  useEffect(() => {
    getZonesByCategoryAndWarehouse(warehouseId, categoryIdSelected);
  }, [warehouseId, categoryIdSelected]);

  useEffect(() => {
    getWarehouseById(warehouseId);
  }, [warehouseId]);

  const [warehouseNameHeading, setWarehouseNameHeading] = useState("");

  useEffect(() => {
    setWarehouseNameHeading(warehouseById.warehouse[0]?.warehouseName);
  }, [warehouseById]);

  const zoneTypes = [
    { key: "Assigned Zone", value: "Assigned Zone" },
    { key: "Unassigned Zone", value: "Unassigned Zone" },
  ];

  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    let tempCategoryOptions = [];
    let data = allCategories.categories ? allCategories.categories : [];
    data.map((category) => {
      tempCategoryOptions.push({
        key: category.categoryName,
        value: category.id,
      });
    });
    setCategoryOptions(tempCategoryOptions);
  }, [allCategories]);

  const [assignedZonesOptions, setAssignedZonesOptions] = useState([]);

  useEffect(() => {
    let tempAssignedZonesOptions = [];
    let data = zonesByCategoryAndWarehouse.zones
      ? zonesByCategoryAndWarehouse.zones
      : [];
    data.map((zone) => {
      if (zone.isEmpty === 0) {
        tempAssignedZonesOptions.push({
          key: zone.zoneName,
          value: zone.id,
        });
      }
    });
    setAssignedZonesOptions(tempAssignedZonesOptions);
  }, [zonesByCategoryAndWarehouse]);

  const [unassignedZonesOptions, setUnassignedZonesOptions] = useState([]);

  useEffect(() => {
    let tempUnassignedZonesOptions = [];
    let data = zonesByCategoryAndWarehouse.zones
      ? zonesByCategoryAndWarehouse.zones
      : [];
    data.map((zone) => {
      if (zone.isEmpty === 1) {
        tempUnassignedZonesOptions.push({
          key: zone.zoneName,
          value: zone.id,
        });
      }
    });
    setUnassignedZonesOptions(tempUnassignedZonesOptions);
  }, [zonesByCategoryAndWarehouse]);

  const [isSuccessAddLocation, setIsSuccessAddLocation] = useState(false);
  const [isErrorAddLocation, setIsErrorAddLocation] = useState(false);

  const [addLocationModal, setAddLocationModal] = useState(false);
  const handleAddLocationModal = () => {
    getZonesByCategoryAndWarehouse(warehouseId, categoryIdSelected);
    setAddLocationModal(!addLocationModal);
  };

  const initialValue = {
    zoneType: "",
    zoneCategory: "",
    zoneName: "",
    location: "",
  };

  const validationSchema = Yup.object({
    zoneType: Yup.string().required("Select the Zone"),
    location: Yup.string().required("Enter the Location"),
  });

  const onSubmitForAddLocation = async (values, { resetForm }) => {
    const payload = {
      warehouseId: warehouseId,
      categoryId: categoryIdSelected,
      // categoryId: values.zoneCategory,
      zoneId: values.zoneName,
      locationName: values.location,
    };

    let res = await addLocationToWarehouse(payload);

    if (res.status) {
      setIsSuccessAddLocation(true);
      getZonesByCategoryAndWarehouse(warehouseId, categoryIdSelected);
      getLocationsByWarehouse(warehouseId);
    }
    if (!res.status) {
      setIsErrorAddLocation(true);
    }
    resetForm();
  };

  const initialValueForDelete = {
    zoneCategory: "",
    zoneName: "",
    location: "",
  };

  const validationSchemaForDelete = Yup.object({
    // zoneType: Yup.string().required("Select the Zone"),
    location: Yup.string().required("Select the Location"),
  });

  const onSubmitForDeleteLocation = async (values, { resetForm }) => {
    let res = await deleteLocation(values.location);

    if (res.status) {
      setIsSuccessDeleteLocation(true);
      getZonesByCategoryAndWarehouse(warehouseId, categoryIdSelected);
      getLocationsByWarehouse(warehouseId);
      getLocationsByWarehouseAndZone(
        warehouseId,
        categoryIdSelected,
        zoneIdSelected
      );
    }
    if (!res.status) {
      setIsErrorDeleteLocation(true);
    }
    resetForm();
  };

  const [isSuccessDeleteLocation, setIsSuccessDeleteLocation] = useState(false);
  const [isErrorDeleteLocation, setIsErrorDeleteLocation] = useState(false);

  const [deleteLocationModal, setDeleteLocationModal] = useState(false);
  const handleDeleteLocationModal = () => {
    getZonesByCategoryAndWarehouse(warehouseId, categoryIdSelected);
    setDeleteLocationModal(!deleteLocationModal);
  };

  const [locationsOptions, setLocationsOptions] = useState([]);

  useEffect(() => {
    getLocationsByWarehouseAndZone(
      warehouseId,
      categoryIdSelected,
      zoneIdSelected
    );
  }, [warehouseId, zoneIdSelected, categoryIdSelected]);

  useEffect(() => {
    let tempLocationsOptions = [];
    let data = locationsByWarehouseAndZone.locations
      ? locationsByWarehouseAndZone.locations
      : [];

    data.map((location) => {
      location.details[0].arr.map((loc) => {
        tempLocationsOptions.push({
          key: loc.locationName,
          value: loc.id,
        });
      });
    });
    setLocationsOptions(tempLocationsOptions);
  }, [locationsByWarehouseAndZone, zoneIdSelected, categoryIdSelected]);

  const [filterButtonName, setFilterButtonName] = useState("Filter");

  const [isSuccessFilter, setIsSuccessFilter] = useState(false);
  const [isErrorFilter, setIsErrorFilter] = useState(false);

  const [filterModal, setFilterModal] = useState(false);
  const handleFilterModal = () => {
    setFilterModal(!filterModal);
  };

  const initialValueForFilter = {
    zoneCategory: "",
    zone: "",
  };

  const filterButtonClick = () => {
    if (filterButtonName === "Reset Filter") {
      getLocationsByWarehouse(warehouseId);
      setFilterButtonName("Filter");
    }

    if (filterButtonName === "Filter") {
      setFilterModal(!filterModal);
    }
  };

  const onSubmitForFilter = (values) => {
    getLocationsByWarehouse(warehouseId, values.zoneCategory, values.zone);
    setFilterButtonName("Reset Filter");
    setFilterModal(false);
  };

  const [itemDetailsModal, setOpenItemDetailsModal] = useState(false);

  const handleOpenItemDetailsModal = () => {
    setOpenItemDetailsModal(!itemDetailsModal);
  };

  const [itemTable, setItemTable] = useState({
    columns: [
      { title: "Item Code", field: "itemCode" },
      { title: "SKU", field: "sku" },
      { title: "Description", field: "itemDescription" },
      { title: "Quantity", field: "quantity" },
    ],
    data: [],
  });

  const [fgDetailsModal, setOpenFgDetailsModal] = useState(false);

  const handleOpenFgDetailsModal = () => {
    setOpenFgDetailsModal(!fgDetailsModal);
  };

  const [fgTable, setFgTable] = useState({
    columns: [
      { title: "FG Code", field: "fgCode" },
      { title: "VIN", field: "vin" },
    ],
    data: [],
  });

  const [tableHeading, setTableHeading] = useState("");
  const [zoneTableHeading, setZoneTableHeading] = useState("");

  const [locationId, setLocationId] = useState("");
  const [zoneId, setZoneId] = useState("");

  useEffect(() => {
    getItemByLocation(warehouseId, zoneId, locationId, warehouseType);
  }, [warehouseId, zoneId, locationId, warehouseType]);

  useEffect(() => {
    let tempItem = [];
    let data = item.item ? item.item : [];

    if (warehouseType === "f") {
      data.map((item) => {
        const itemData = {
          fgCode: item.fgCode,
          vin: item.vin,
        };
        tempItem.push(itemData);
      });
      setFgTable({ ...fgTable, data: tempItem });
    } else {
      data.map((item) => {
        let des = item?.itemDescription;
        let len = desLength;
        const itemData = {
          itemCode: item.itemCode,
          sku: item.sku,
          itemDescription: des ? des.substring(0, len) : "",
          quantity:
            warehouseType === "s"
              ? `${item.qty} ${item.uom}`
              : `${item.qty} ${item.uom}`,
        };
        tempItem.push(itemData);
      });
      setItemTable({ ...itemTable, data: tempItem });
    }
  }, [item]);

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <Typography variant="h4" style={{ marginBottom: "1rem" }}>
              {warehouseNameHeading}
            </Typography>

            <div>
              <button
                className="btn btn-info"
                type="button"
                onClick={handleAddLocationModal}
              >
                Add location
              </button>
              <button
                className="btn btn-info"
                type="button"
                style={{
                  marginLeft: "2.5rem",
                  height: "2.44rem",
                  width: "8.6rem",
                }}
                onClick={handleDeleteLocationModal}
              >
                Delete Location
              </button>
              <button
                className="btn btn-info"
                type="button"
                style={{
                  marginLeft: "2.5rem",
                  height: "2.44rem",
                  width: "7.2rem",
                }}
                onClick={filterButtonClick}
              >
                {filterButtonName}
              </button>

              <div
                className="legend badge-success"
                style={{
                  float: "right",
                  marginRight: "18rem",
                  marginLeft: "1rem",
                }}
              >
                Empty
              </div>
              <div className="legend badge-danger" style={{ float: "right" }}>
                Occupied
              </div>
              {warehouseLocations.map((category) => (
                <div>
                  {warehouseType === "s" && (
                    <h3 className="categoryStyle">{category.categoryName}</h3>
                  )}
                  {category.details.map((zone) => (
                    <div>
                      <h5 style={{ marginTop: "1.5rem" }}>
                        Zone: {zone.zoneName}
                      </h5>
                      <div className="masterStoreGrid">
                        {zone.arr.map((location) => (
                          <div
                            className={`masterStoreItem ${
                              location.isEmpty === 1
                                ? `badge-success`
                                : `badge-danger`
                            }`}
                          >
                            {location.isEmpty === 0 && (
                              <button
                                className="btn badge-danger btn-lg"
                                onClick={() => {
                                  setZoneId(location.zoneId);
                                  setLocationId(location.id);
                                  setTableHeading(location.locationName);
                                  setZoneTableHeading(location.zoneName);
                                  // handleOpenItemDetailsModal();
                                  if (warehouseType === "f") {
                                    handleOpenFgDetailsModal();
                                  } else {
                                    handleOpenItemDetailsModal();
                                  }
                                }}
                              >
                                {location.locationName}
                              </button>
                            )}
                            {location.isEmpty === 1 && (
                              <h4 className="badge-success btn-lg">
                                {location.locationName}
                              </h4>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
              <div>
                <Modal
                  show={addLocationModal}
                  onHide={handleAddLocationModal}
                  aria-labelledby="contained-modal-title-vcenter"
                  className="screenSize"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add Location</Modal.Title>
                  </Modal.Header>
                  <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <Formik
                            initialValues={initialValue}
                            validationSchema={validationSchema}
                            onSubmit={onSubmitForAddLocation}
                          >
                            {(formik) => {
                              return (
                                <Form>
                                  {warehouseType === "s" && (
                                    <div className="form-group">
                                      <label htmlFor="zoneCategory">
                                        Select the Zone Category
                                      </label>
                                      <Field
                                        as="select"
                                        id="zoneCategory"
                                        name="zoneCategory"
                                        className="form-control"
                                      >
                                        <option value="" disabled selected>
                                          Choose one...
                                        </option>
                                        {categoryOptions.map((option) => {
                                          return (
                                            <option
                                              key={option.key}
                                              value={option.value}
                                            >
                                              {option.key}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                      <ErrorMessage
                                        name="zoneCategory"
                                        component={TextError}
                                      />
                                    </div>
                                  )}
                                  <div className="form-group">
                                    <label htmlFor="zoneType">
                                      Select the Zone Type
                                    </label>
                                    <Field
                                      as="select"
                                      id="zoneType"
                                      name="zoneType"
                                      className="form-control"
                                    >
                                      <option value="" disabled selected>
                                        Choose one...
                                      </option>
                                      {zoneTypes.map((option) => {
                                        return (
                                          <option
                                            key={option.key}
                                            value={option.value}
                                          >
                                            {option.key}
                                          </option>
                                        );
                                      })}
                                    </Field>
                                    <ErrorMessage
                                      name="zoneType"
                                      component={TextError}
                                    />
                                  </div>
                                  {warehouseType === "s" &&
                                    formik.values.zoneCategory &&
                                    setCategoryIdSelected(
                                      formik.values.zoneCategory
                                    )}
                                  <div className="form-group">
                                    <label htmlFor="zoneName">
                                      Select the Zone
                                    </label>
                                    <Field
                                      as="select"
                                      id="zoneName"
                                      name="zoneName"
                                      className="form-control"
                                    >
                                      <option value="" disabled selected>
                                        Choose one...
                                      </option>
                                      {formik.values.zoneType ===
                                        "Assigned Zone" &&
                                        assignedZonesOptions.map((option) => {
                                          return (
                                            <option
                                              key={option.key}
                                              value={option.value}
                                            >
                                              {option.key}
                                            </option>
                                          );
                                        })}
                                      {formik.values.zoneType ===
                                        "Unassigned Zone" &&
                                        unassignedZonesOptions.map((option) => {
                                          return (
                                            <option
                                              key={option.key}
                                              value={option.value}
                                            >
                                              {option.key}
                                            </option>
                                          );
                                        })}
                                    </Field>
                                    <ErrorMessage
                                      name="zoneName"
                                      component={TextError}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="location">Location</label>
                                    <Field
                                      type="text"
                                      name="location"
                                      className="form-control"
                                      autocomplete="off"
                                    />
                                    <ErrorMessage
                                      name="location"
                                      component={TextError}
                                    />
                                  </div>
                                  <div className="mt-3">
                                    <Collapse in={isErrorAddLocation}>
                                      <Alert
                                        severity="error"
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setIsErrorAddLocation(false);
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                      >
                                        Could not add the Location
                                      </Alert>
                                    </Collapse>
                                    <Collapse in={isSuccessAddLocation}>
                                      <Alert
                                        severity="success"
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setIsSuccessAddLocation(false);
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                      >
                                        Location added successfully
                                      </Alert>
                                    </Collapse>
                                  </div>
                                  <div className="mt-3">
                                    <button
                                      type="submit"
                                      className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                      disabled={!formik.isValid}
                                    >
                                      Add Location
                                    </button>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
              <div>
                <Modal
                  show={filterModal}
                  onHide={handleFilterModal}
                  aria-labelledby="contained-modal-title-vcenter"
                  className="screenSize"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                  </Modal.Header>
                  <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <Formik
                            initialValues={initialValueForFilter}
                            // validationSchema={validationSchemaForFilter}
                            onSubmit={onSubmitForFilter}
                          >
                            {(formik) => {
                              return (
                                <Form>
                                  {warehouseType === "s" && (
                                    <div className="form-group">
                                      <label htmlFor="zoneCategory">
                                        Filter Category wise
                                      </label>
                                      <Field
                                        as="select"
                                        id="zoneCategory"
                                        name="zoneCategory"
                                        className="form-control"
                                      >
                                        <option value="" disabled selected>
                                          Choose one...
                                        </option>
                                        {categoryOptions.map((option) => {
                                          return (
                                            <option
                                              key={option.key}
                                              value={option.value}
                                            >
                                              {option.key}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                      <ErrorMessage
                                        name="zoneCategory"
                                        component={TextError}
                                      />
                                    </div>
                                  )}
                                  {warehouseType === "s" &&
                                    formik.values.zoneCategory &&
                                    setCategoryIdSelected(
                                      formik.values.zoneCategory
                                    )}
                                  <div className="form-group">
                                    <label htmlFor="zone">
                                      Select the Zone
                                    </label>
                                    <Field
                                      as="select"
                                      id="zone"
                                      name="zone"
                                      className="form-control"
                                    >
                                      <option value="" disabled selected>
                                        Choose one...
                                      </option>
                                      {assignedZonesOptions.map((option) => {
                                        return (
                                          <option
                                            key={option.key}
                                            value={option.value}
                                          >
                                            {option.key}
                                          </option>
                                        );
                                      })}
                                    </Field>
                                    <ErrorMessage
                                      name="zone"
                                      component={TextError}
                                    />
                                  </div>
                                  <div className="mt-3">
                                    <Collapse in={isErrorFilter}>
                                      <Alert
                                        severity="error"
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setIsErrorFilter(false);
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                      >
                                        Could not apply the filters
                                      </Alert>
                                    </Collapse>
                                    <Collapse in={isSuccessFilter}>
                                      <Alert
                                        severity="success"
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setIsSuccessFilter(false);
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                      >
                                        Filters applied
                                      </Alert>
                                    </Collapse>
                                  </div>
                                  <div className="mt-3">
                                    <button
                                      type="submit"
                                      className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                      disabled={!formik.isValid}
                                    >
                                      Apply Filter
                                    </button>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
              <div>
                <Modal
                  show={itemDetailsModal}
                  onHide={handleOpenItemDetailsModal}
                  aria-labelledby="contained-modal-title-vcenter"
                  className="screenSize"
                  size="xl"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Items</Modal.Title>
                  </Modal.Header>
                  <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <MaterialTable
                            title={`Location: ${zoneTableHeading}${tableHeading}`}
                            columns={itemTable.columns}
                            data={itemTable.data}
                            options={{ draggable: false }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
              <div>
                <Modal
                  show={fgDetailsModal}
                  onHide={handleOpenFgDetailsModal}
                  aria-labelledby="contained-modal-title-vcenter"
                  className="screenSize"
                  size="xl"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>FG</Modal.Title>
                  </Modal.Header>
                  <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <MaterialTable
                            title={`Location: ${zoneTableHeading}${tableHeading}`}
                            columns={fgTable.columns}
                            data={fgTable.data}
                            options={{ draggable: false }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
              <div>
                <Modal
                  show={deleteLocationModal}
                  onHide={handleDeleteLocationModal}
                  aria-labelledby="contained-modal-title-vcenter"
                  className="screenSize"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Delete Location</Modal.Title>
                  </Modal.Header>
                  <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <Formik
                            initialValues={initialValueForDelete}
                            validationSchema={validationSchemaForDelete}
                            onSubmit={onSubmitForDeleteLocation}
                          >
                            {(formik) => {
                              return (
                                <Form>
                                  {warehouseType === "s" && (
                                    <div className="form-group">
                                      <label htmlFor="zoneCategory">
                                        Select the Zone Category
                                      </label>
                                      <Field
                                        as="select"
                                        id="zoneCategory"
                                        name="zoneCategory"
                                        className="form-control"
                                      >
                                        <option value="" disabled selected>
                                          Choose one...
                                        </option>
                                        {categoryOptions.map((option) => {
                                          return (
                                            <option
                                              key={option.key}
                                              value={option.value}
                                            >
                                              {option.key}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                      <ErrorMessage
                                        name="zoneCategory"
                                        component={TextError}
                                      />
                                    </div>
                                  )}
                                  {/* <div className="form-group">
                                    <label htmlFor="zoneType">
                                      Select the Zone Type
                                    </label>
                                    <Field
                                      as="select"
                                      id="zoneType"
                                      name="zoneType"
                                      className="form-control"
                                    >
                                      <option value="" disabled selected>
                                        Choose one...
                                      </option>
                                      {zoneTypes.map((option) => {
                                        return (
                                          <option
                                            key={option.key}
                                            value={option.value}
                                          >
                                            {option.key}
                                          </option>
                                        );
                                      })}
                                    </Field>
                                    <ErrorMessage
                                      name="zoneType"
                                      component={TextError}
                                    />
                                  </div> */}
                                  {warehouseType === "s" &&
                                    formik.values.zoneCategory &&
                                    setCategoryIdSelected(
                                      formik.values.zoneCategory
                                    )}
                                  <div className="form-group">
                                    <label htmlFor="zoneName">
                                      Select the Zone
                                    </label>
                                    <Field
                                      as="select"
                                      id="zoneName"
                                      name="zoneName"
                                      className="form-control"
                                    >
                                      <option value="" disabled selected>
                                        Choose one...
                                      </option>
                                      {assignedZonesOptions.map((option) => {
                                        return (
                                          <option
                                            key={option.key}
                                            value={option.value}
                                          >
                                            {option.key}
                                          </option>
                                        );
                                      })}
                                    </Field>
                                    <ErrorMessage
                                      name="zoneName"
                                      component={TextError}
                                    />
                                  </div>
                                  {formik.values.zoneName &&
                                    setZoneIdSelected(formik.values.zoneName)}
                                  <div className="form-group">
                                    <label htmlFor="location">Location</label>
                                    <Field
                                      as="select"
                                      id="location"
                                      name="location"
                                      className="form-control"
                                    >
                                      <option value="" disabled selected>
                                        Choose one...
                                      </option>
                                      {locationsOptions.map((option) => {
                                        return (
                                          <option
                                            key={option.key}
                                            value={option.value}
                                          >
                                            {option.key}
                                          </option>
                                        );
                                      })}
                                    </Field>
                                    <ErrorMessage
                                      name="location"
                                      component={TextError}
                                    />
                                  </div>
                                  <div className="mt-3">
                                    <Collapse in={isErrorDeleteLocation}>
                                      <Alert
                                        severity="error"
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setIsErrorDeleteLocation(false);
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                      >
                                        Could not delete the Location
                                      </Alert>
                                    </Collapse>
                                    <Collapse in={isSuccessDeleteLocation}>
                                      <Alert
                                        severity="success"
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setIsSuccessDeleteLocation(false);
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                      >
                                        Location deleted successfully
                                      </Alert>
                                    </Collapse>
                                  </div>
                                  <div className="mt-3">
                                    <button
                                      type="submit"
                                      className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                      disabled={!formik.isValid}
                                    >
                                      Delete Location
                                    </button>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    locationsByWarehouse: state.locationsByWarehouse,
    locationsByWarehouseAndZone: state.locationsByWarehouseAndZone,
    item: state.item,
    allCategories: state.allCategories,
    zonesByCategoryAndWarehouse: state.zonesByCategoryAndWarehouse,
    warehouseById: state.warehouseById,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getLocationsByWarehouse: (warehouseId, categoryId, zoneId) =>
      dispatch(getLocationsByWarehouse(warehouseId, categoryId, zoneId)),
    getLocationsByWarehouseAndZone: (warehouseId, categoryId, zoneId) =>
      dispatch(getLocationsByWarehouseAndZone(warehouseId, categoryId, zoneId)),
    addLocationToWarehouse: (payload) =>
      dispatch(addLocationToWarehouse(payload)),
    deleteLocation: (id) => dispatch(deleteLocation(id)),
    getItemByLocation: (warehouseId, zoneId, locationId, warehouseType) =>
      dispatch(
        getItemByLocation(warehouseId, zoneId, locationId, warehouseType)
      ),
    getAllCategories: () => dispatch(getAllCategories()),
    getZonesByCategoryAndWarehouse: (warehouseId, categoryId) =>
      dispatch(getZonesByCategoryAndWarehouse(warehouseId, categoryId)),
    getWarehouseById: (id) => dispatch(getWarehouseById(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewLocation);
