import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import dashboardCard from "../../style/dashboardCard";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Link as RouterLink } from "react-router-dom";
import { getPickup } from "../../store";

import "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { useRef } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => dashboardCard(theme));

const PickupHomePageCard = ({ getPickup, pickupList }) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    getPickup();
  }, []);

  const [started, setStarted] = useState(0);
  const [notStarted, setNotStarted] = useState(0);

  useEffect(() => {
    setStarted(pickupList.pickupList.started);
    setNotStarted(pickupList.pickupList.notStarted);
  }, [pickupList]);

  const data = {
    labels: ["Not started (> 30 mins)", "Started (> 4 hrs)"],
    datasets: [
      {
        data: [notStarted, started],
        backgroundColor: ["#F9CEEE", "#99C4C8"],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        // position: "right",
        // align: "start",
      },
    },
    title: {
      display: true,
      text: "Bar Chart",
      // text: "Doughnut Chart",
    },
  };

  const chartRef = useRef();
  const onClick = (event) => {
    history.push(`/picklist_ageing`);
  };

  return (
    <div>
      <Card>
        <CardContent>
          <h4>Pickup List</h4>
          <hr />
          <Bar
            className={classes.root}
            data={data}
            options={options}
            onClick={onClick}
            ref={chartRef}
            height={240}
          />
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    pickupList: state.getPickupListHomePage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPickup: () => dispatch(getPickup()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PickupHomePageCard);
