import {
  GET_ALLOCATEDZONES_REQUEST,
  GET_ALLOCATEDZONES_SUCCESS,
  GET_ALLOCATEDZONES_FAILURE,
  ADD_ZONEALLOCATION_REQUEST,
  ADD_ZONEALLOCATION_SUCCESS,
  ADD_ZONEALLOCATION_FAILURE,
  REMOVE_ZONEALLOCATION_REQUEST,
  REMOVE_ZONEALLOCATION_SUCCESS,
  REMOVE_ZONEALLOCATION_FAILURE,
} from "./ZoneAllocationTypes";

const initialState = {
  loading: false,
  zones: [],
  error: "",
};

const getAllocatedZonesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALLOCATEDZONES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALLOCATEDZONES_SUCCESS:
      return {
        loading: false,
        zones: action.payload,
        error: "",
      };
    case GET_ALLOCATEDZONES_FAILURE:
      return {
        loading: false,
        zones: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAddZone = {
  loading: false,
  zone: [],
  error: "",
};

const addZoneAllocationReducer = (state = initialStateForAddZone, action) => {
  switch (action.type) {
    case ADD_ZONEALLOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_ZONEALLOCATION_SUCCESS:
      return {
        loading: false,
        zone: action.payload,
        error: "",
      };
    case ADD_ZONEALLOCATION_FAILURE:
      return {
        loading: false,
        zone: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForRemoveZone = {
  loading: false,
  zone: [],
  error: "",
};

const removeZoneAllocationReducer = (
  state = initialStateForRemoveZone,
  action
) => {
  switch (action.type) {
    case REMOVE_ZONEALLOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_ZONEALLOCATION_SUCCESS:
      return {
        loading: false,
        zone: action.payload,
        error: "",
      };
    case REMOVE_ZONEALLOCATION_FAILURE:
      return {
        loading: false,
        zone: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getAllocatedZonesReducer,
  addZoneAllocationReducer,
  removeZoneAllocationReducer,
};
