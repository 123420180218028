import {
  GET_CREATEPICKUPLIST_REQUEST,
  GET_CREATEPICKUPLIST_SUCCESS,
  GET_CREATEPICKUPLIST_FAILURE,
  GET_PICKUPLIST_REQUEST,
  GET_PICKUPLIST_SUCCESS,
  GET_PICKUPLIST_FAILURE,
  CREATEPICKUPLIST_REQUEST,
  CREATEPICKUPLIST_SUCCESS,
  CREATEPICKUPLIST_FAILURE,
  GET_PICKUPITEM_REQUEST,
  GET_PICKUPITEM_SUCCESS,
  GET_PICKUPITEM_FAILURE,
} from "./PickupListTypes";
import axios from "axios";
import Config from "../../config/index";
let baseUrl = Config.baseUrl;

const getCreatePickupListRequest = () => {
  return {
    type: GET_CREATEPICKUPLIST_REQUEST,
  };
};

const getCreatePickupListSuccess = (payload) => {
  return {
    type: GET_CREATEPICKUPLIST_SUCCESS,
    payload: payload,
  };
};

const getCreatePickupListFailure = (error) => {
  return {
    type: GET_CREATEPICKUPLIST_FAILURE,
    payload: error,
  };
};

const getCreatePickupList = (warehouseId) => {
  return (dispatch) => {
    dispatch(getCreatePickupListRequest());
    let url = `${baseUrl}/production/transaction?warehouse_id=${warehouseId}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getCreatePickupListSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getCreatePickupListFailure(err.message));
      });
  };
};

const createPickupListRequest = () => {
  return {
    type: CREATEPICKUPLIST_REQUEST,
  };
};

const createPickupListSuccess = (payload) => {
  return {
    type: CREATEPICKUPLIST_SUCCESS,
    payload: payload,
  };
};

const createPickupListFailure = (error) => {
  return {
    type: CREATEPICKUPLIST_FAILURE,
    payload: error,
  };
};

const createPickupList = (payload) => {
  return (dispatch) => {
    dispatch(createPickupListRequest());
    let url = `${baseUrl}/production/create_pickup`;
    return axios
      .put(url, payload)
      .then((response) => {
        dispatch(createPickupListSuccess(response));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createPickupListFailure(err.message));
        return { status: false, message: err.message };
      });
  };
};

const getPickupListRequest = () => {
  return {
    type: GET_PICKUPLIST_REQUEST,
  };
};

const getPickupListSuccess = (payload) => {
  return {
    type: GET_PICKUPLIST_SUCCESS,
    payload: payload,
  };
};

const getPickupListFailure = (error) => {
  return {
    type: GET_PICKUPLIST_FAILURE,
    payload: error,
  };
};

const getPickupList = (warehouseId, status, ageing) => {
  return (dispatch) => {
    dispatch(getPickupListRequest());
    let url = `${baseUrl}/production/pickup_list?${
      warehouseId === "" ? "" : `warehouse_id=${warehouseId}`
    }&delivery_status=${status}&ageing=${ageing}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getPickupListSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getPickupListFailure(err.message));
      });
  };
};

const getPickupItemRequest = () => {
  return {
    type: GET_PICKUPITEM_REQUEST,
  };
};

const getPickupItemSuccess = (payload) => {
  return {
    type: GET_PICKUPITEM_SUCCESS,
    payload: payload,
  };
};

const getPickupItemFailure = (error) => {
  return {
    type: GET_PICKUPITEM_FAILURE,
    payload: error,
  };
};

const getPickupItem = (pickupCode) => {
  return (dispatch) => {
    dispatch(getPickupItemRequest());
    let url = `${baseUrl}/production/pickup_item_dashboard?pickup_code=${pickupCode}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getPickupItemSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getPickupItemFailure(err.message));
      });
  };
};

export { getCreatePickupList, createPickupList, getPickupList, getPickupItem };
