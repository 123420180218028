/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React from "react";
import { useHistory } from "react-router-dom";
import Header from "../Header";
import "../../style/resetPassword.css";
import drawerCss from "../../style/drawer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { setPasswordForNewUser } from "../../store";
import "../../style/form1.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../utils/TextError";
import PasswordStrengthMeter from "./PasswordStrengthMeter";
import Cookies from "universal-cookie";
import Config from "../../config/index";
let dashboardUrl = Config.dashboardUrl;
const cookies = new Cookies();

const useStyles = makeStyles((theme) => drawerCss(theme));
function SetPasswordForNewUser(props) {
  let history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  let { isAuth, setPasswordForNewUser } = props;

  let email = isAuth.userData.data.email;
  const initialValue = {
    email: email,
    newpassword: "",
    confirmpassword: "",
  };

  const validationSchema = Yup.object({
    newpassword: Yup.string()
      .required("Required!")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase"
      ),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref("newpassword"), ""], "Passwords must match")
      .required("Required!"),
  });
  const onSubmit = async (values) => {
    let temp = await setPasswordForNewUser(values);
    if (temp.status) {
      cookies.set("loginDetails", JSON.stringify(isAuth.userData), {
        path: "/",
      });
      cookies.Domain = dashboardUrl;
      window.location.reload();
      setTimeout(() => {
        history.push("/home");
      }, 1000);
    }
  };
  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main>
          <div className="screenSize">
            <div id="cover-caption">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-5 col-lg-8 col-md-8 col-sm-12 col-xs-12 col mx-auto form-4">
                    <div className="card">
                      <h4 className="card-header">Reset Password</h4>
                      <div className="card-body">
                        <Formik
                          initialValues={initialValue}
                          validationSchema={validationSchema}
                          onSubmit={onSubmit}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="newpassword">
                                    New Password
                                  </label>
                                  <Field
                                    type="password"
                                    name="newpassword"
                                    className="form-control"
                                    placeholder="Min-lenght-8"
                                  />
                                  <PasswordStrengthMeter
                                    password={formik.values.newpassword}
                                  />
                                  <ErrorMessage
                                    name="newpassword"
                                    component={TextError}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlfor="ConfirmPassword">
                                    Confirm Password
                                  </label>
                                  <Field
                                    name="confirmpassword"
                                    className="form-control"
                                    placeholder="Min-lenght-8"
                                    type="password"
                                  />
                                  <ErrorMessage
                                    name="confirmpassword"
                                    component={TextError}
                                  />
                                </div>
                                {props.setPasswordData.error ? (
                                  <div
                                    className="alert alert-danger alert-dismissible fade show"
                                    role="alert"
                                  >
                                    <span class="help-block">
                                      something went wrong
                                    </span>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="alert"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {props.setPasswordData.succeeded ? (
                                  <div
                                    className="alert alert-success alert-dismissible fade show"
                                    role="alert"
                                  >
                                    <span class="help-block">
                                      Password reset successfully
                                    </span>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="alert"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    float: "right",
                                    display: "inline",
                                  }}
                                >
                                  <button
                                    type="submit"
                                    className="btn btn-danger"
                                    disabled={!formik.isValid}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    setPasswordData: state.setPasswordReducer,
    isAuth: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPasswordForNewUser: (resetPasswordState) =>
      dispatch(setPasswordForNewUser(resetPasswordState)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetPasswordForNewUser);
