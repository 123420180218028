import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { makeStyles } from "@material-ui/core/styles";
import drawerCss from "../../style/drawer";
import classNames from "classnames";
import { connect } from "react-redux";
import {
  updateSelectedItem,
  getWarehouses,
  getPickupList,
  getPickupItem,
} from "../../store";
import MaterialTable from "material-table";
import Modal from "react-bootstrap/Modal";
import { format, addMinutes } from "date-fns";
import Config from "../../config/index";

const desLength = Config.descriptionLength;

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

const PickupList = ({
  drawerState,
  handleListItemClick,
  getWarehouses,
  warehouses,
  getPickupList,
  pickupList,
  getPickupItem,
  pickupItem,
}) => {
  useEffect(() => {
    handleListItemClick(null, 6);
  }, []);

  const classes = useStyles();
  let drawer = drawerState;

  const [warehouseIdSelected, setWarehouseIdSelected] = useState("");
  const [statusSelected, setStatusSelected] = useState("undefined");
  const [ageingSelected, setAgeingSelected] = useState("undefined");

  // useEffect(() => {
  //   setWarehouseIdSelected(warehouses.warehouses[0]?.id);
  // }, [warehouses]);

  useEffect(() => {
    getWarehouses();
  }, []);

  useEffect(() => {
    getPickupList(warehouseIdSelected, statusSelected, ageingSelected);
  }, [warehouseIdSelected, statusSelected, ageingSelected]);

  const [pickupCode, setPickupCode] = useState("");

  const [tableHeading, setTableHeading] = useState("");
  const [zoneTableHeading, setZoneTableHeading] = useState("");

  useEffect(() => {
    getPickupItem(pickupCode);
  }, [pickupCode]);

  const [state, setState] = useState({
    columns: [
      { title: "Picklist No", field: "picklistNo" },
      { title: "From Source Location", field: "sourceLocation" },
      { title: "Status", field: "status", export: false },
      { title: "Created Date", field: "createdDate" },
      { title: "Pickup Start Time", field: "pickupStartTime" },
      { title: "Pickup End Time", field: "pickupEndTime" },
      { title: "Ageing", field: "ageing" },
    ],
    data: [],
  });

  const [pickupItemModal, setOpenPickupItemModal] = useState(false);

  const handleOpenPickupItemModal = () => {
    getPickupList(warehouseIdSelected, statusSelected);
    getPickupItem(pickupCode);
    setOpenPickupItemModal(!pickupItemModal);
  };

  const [pickupItemTable, setPickupItemTable] = useState({
    columns: [
      { title: "Transaction No", field: "transactionCode" },
      { title: "SU No", field: "suNo" },
      { title: "Item Code", field: "itemCode" },
      { title: "Description", field: "description" },
      { title: "Pick Qty", field: "pickQty" },
      { title: "Status", field: "status" },
    ],
    data: [],
  });

  const [warehouseOptions, setWarehouseOptions] = useState([]);

  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      tempWarehouseOptions.push({
        key: warehouse.warehouseName,
        value: warehouse.id,
      });
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  useEffect(() => {
    let tempPickupList = [];
    let data = pickupList.pickupList ? pickupList.pickupList : [];

    data.map((item) => {
      const printDate = new Date(item.cd);
      const startDate = new Date(item.startPickupTime);
      const endDate = new Date(item.endPickupTime);
      const itemObject = {
        picklistNo: item.pickupListCode,
        sourceLocation: item.sWarehouse,
        status: (
          <button
            type="button"
            className={
              (item.status === 0 && `btn btn-danger`) ||
              (item.status === 1 && `btn btn-success`) ||
              (item.status === 2 && `btn btn-warning`)
            }
            style={{ width: "7rem" }}
            onClick={() => {
              setPickupCode(item.pickupListCode);
              handleOpenPickupItemModal();
            }}
          >
            {(item.status === 0 && `Not Picked`) ||
              (item.status === 1 && `Delivered`) ||
              (item.status === 2 && `Picked`)}
          </button>
        ),
        createdDate: format(
          addMinutes(printDate, printDate.getTimezoneOffset()),
          "do MMMM yyyy, h:mm a"
        ),
        ageing: `${
          (item.status === 1 && item.ageingStatus === 1 && `Delivered`) ||
          (item.status === 1 && item.ageingStatus === 0 && `Delivered`) ||
          (item.status !== 1 && item.ageingStatus === 0 && `> 30 mins`) ||
          (item.status !== 1 && item.ageingStatus === 1 && `< 30 mins`)
        }`,
        pickupStartTime:
          item.startPickupTime === "NULL"
            ? ""
            : format(
                addMinutes(startDate, startDate.getTimezoneOffset()),
                "do MMMM yyyy, h:mm a"
              ),
        pickupEndTime:
          item.endPickupTime === "NULL"
            ? ""
            : format(
                addMinutes(endDate, endDate.getTimezoneOffset()),
                "do MMMM yyyy, h:mm a"
              ),
      };
      tempPickupList.push(itemObject);
    });
    setState({ ...state, data: tempPickupList });
  }, [pickupList]);

  useEffect(() => {
    let tempPickupItem = [];
    let data = pickupItem.pickupItem ? pickupItem.pickupItem : [];
    data.map((item) => {
      setTableHeading(item.locationName);
      setZoneTableHeading(item.zoneName);

      item.itemList.map((item) => {
        let des = item?.itemDescription;
        let len = desLength;
        const itemObject = {
          transactionCode: item.transactionCode,
          suNo: item.sku,
          itemCode: item.itemCode,
          description: des ? des.substring(0, len) : "",
          pickQty: `${item.qty} ${item.uom}`,
          status: (
            <h4>
              <span
                className={
                  (item.isPickup === 0 &&
                    item.isPutaway === 0 &&
                    `badge badge-danger`) ||
                  (item.isPickup === 1 &&
                    item.isPutaway === 1 &&
                    `badge badge-success`) ||
                  (item.isPickup === 1 &&
                    item.isPutaway === 0 &&
                    `badge badge-warning`)
                }
                style={{ width: "7rem" }}
              >
                {(item.isPickup === 0 &&
                  item.isPutaway === 0 &&
                  `Not Picked`) ||
                  (item.isPickup === 1 &&
                    item.isPutaway === 1 &&
                    `Delivered`) ||
                  (item.isPickup === 1 && item.isPutaway === 0 && `Picked`)}
              </span>
            </h4>
          ),
        };
        tempPickupItem.push(itemObject);
      });
    });
    setPickupItemTable({ ...pickupItemTable, data: tempPickupItem });
  }, [pickupItem]);

  const statusOptions = [
    { key: "All Status", value: "undefined" },
    { key: "Not Picked", value: 0 },
    { key: "Picked", value: 2 },
    { key: "Delivered", value: 1 },
  ];

  const changeWarehouse = (e) => {
    setWarehouseIdSelected(e.target.value);
  };

  const changeStatus = (e) => {
    setStatusSelected(e.target.value);
  };

  const changeAgeing = (e) => {
    setAgeingSelected(e.target.value);
  };

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title={
                <div>
                  <br />
                  <h5>Pickup List</h5>
                  <br />
                  <span style={{ display: "flex" }}>
                    <select
                      style={{ width: "11rem" }}
                      as="select"
                      id="warehouseName"
                      name="warehouseName"
                      className="form-control"
                      onChange={changeWarehouse}
                    >
                      <option value="" selected>
                        All Warehouses
                      </option>
                      {warehouseOptions.map((option) => {
                        return (
                          <option key={option.key} value={option.value}>
                            {option.key}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      style={{ marginLeft: "1rem", width: "11rem" }}
                      as="select"
                      id="status"
                      name="status"
                      className="form-control"
                      onChange={changeStatus}
                    >
                      {statusOptions.map((option) => {
                        return (
                          <option key={option.key} value={option.value}>
                            {option.key}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      style={{ marginLeft: "1rem", width: "11rem" }}
                      as="select"
                      id="ageing"
                      name="ageing"
                      className="form-control"
                      onChange={changeAgeing}
                    >
                      <option value={`${undefined}`} defaultValue>
                        All Ageing
                      </option>
                      <option value={1}>less than 30 days</option>
                      <option value={2}>greater than 30 days</option>
                    </select>
                  </span>
                </div>
              }
              columns={state.columns}
              data={state.data}
              options={{
                draggable: false,
                exportButton: true,
                exportAllData: true,
                exportFileName: `Pickup List`,
              }}
            />
            <div>
              <Modal
                show={pickupItemModal}
                onHide={handleOpenPickupItemModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
                size="xl"
              >
                <Modal.Header closeButton>
                  <Modal.Title>{pickupCode}</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <MaterialTable
                          title={`Location: ${zoneTableHeading}${tableHeading}`}
                          columns={pickupItemTable.columns}
                          data={pickupItemTable.data}
                          options={{ draggable: false }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    warehouses: state.warehouses,
    pickupList: state.pickupList,
    pickupItem: state.pickupItem,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getWarehouses: () => dispatch(getWarehouses()),
    getPickupList: (id, status, ageing) =>
      dispatch(getPickupList(id, status, ageing)),
    getPickupItem: (pickupCode) => dispatch(getPickupItem(pickupCode)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PickupList);
