import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { makeStyles } from "@material-ui/core/styles";
import drawerCss from "../../style/drawer";
import classNames from "classnames";
import { connect } from "react-redux";
import { updateSelectedItem, getSkuForReprint, reprint } from "../../store";
import MaterialTable from "material-table";
import Config from "../../config/index";

import Modal from "react-bootstrap/Modal";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";

const desLength = Config.descriptionLength;

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

const Reprint = ({
  drawerState,
  handleListItemClick,
  getSkuForReprint,
  skuForReprint,
  reprint,
}) => {
  useEffect(() => {
    handleListItemClick(null, 28);
  }, []);

  const classes = useStyles();
  let drawer = drawerState;

  useEffect(() => {
    getSkuForReprint();
  }, []);

  const [state, setState] = useState({
    columns: [
      { title: "GRN", field: "grn" },
      { title: "SKU", field: "sku" },
      { title: "Item Code/Des", field: "itemCodeDes" },
      { title: "Qty", field: "qty" },
      { title: "Location", field: "location" },
      { title: "Action", field: "action" },
    ],
    data: [],
  });

  useEffect(() => {
    let tempSkus = [];
    let data = skuForReprint.sku ? skuForReprint.sku : [];

    data.map((sku) => {
      let des = sku?.itemDescription;
      let len = desLength;
      const object = {
        grn: sku.grnNumber,
        sku: sku.sku,
        itemCodeDes: `${sku.itemCode} / ${des ? des.substring(0, len) : ""}`,
        qty: `${sku.trQty} ${sku.uom}`,
        location: `${sku.warehouseName} / ${sku.zoneName} ${sku.locationName}`,
        action: (
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => {
              handleOpenReprintModal(sku);
            }}
          >
            Reprint
          </button>
        ),
      };
      tempSkus.push(object);
    });
    setState({ ...state, data: tempSkus });
  }, [skuForReprint]);

  const [reprintData, setReprintData] = useState({});

  const [isErrorReprint, setIsErrorReprint] = useState(false);

  const [openReprintModal, setOpenReprintModal] = useState(false);

  const handleOpenReprintModal = (data = {}) => {
    setReprintData(data);
    setOpenReprintModal(true);
  };

  const handleCloseReprintModal = () => {
    setOpenReprintModal(false);
  };

  const onClickReprint = async () => {
    let res = await reprint(reprintData);

    if (res.status) {
      getSkuForReprint();
      setOpenReprintModal(false);
    }
  };

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title="Reprint"
              columns={state.columns}
              data={state.data}
              options={{ draggable: false }}
            />
            <div>
              <Modal
                show={openReprintModal}
                onHide={handleCloseReprintModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Reprint</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <p>Are you sure you want to reprint?</p>

                        <div class="clearfix">
                          <button
                            type="button"
                            className="cancelbtn button1"
                            onClick={handleCloseReprintModal}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="deletebtn button1"
                            onClick={onClickReprint}
                          >
                            Yes
                          </button>
                        </div>
                        <div className="mt-3">
                          <Collapse in={isErrorReprint}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorReprint(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Could not reprint
                            </Alert>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    skuForReprint: state.skuForReprint,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getSkuForReprint: () => dispatch(getSkuForReprint()),
    reprint: (payload) => dispatch(reprint(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reprint);
