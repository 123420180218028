import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { makeStyles } from "@material-ui/core/styles";
import drawerCss from "../../style/drawer";
import classNames from "classnames";
import { connect } from "react-redux";
import {
  updateSelectedItem,
  getFinishedGoods,
  getVinByFgCode,
} from "../../store";
import MaterialTable from "material-table";
import { format, addMinutes } from "date-fns";

import Modal from "react-bootstrap/Modal";
import Config from "../../config/index";

const desLength = Config.descriptionLength;

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

const FinishedGoods = ({
  drawerState,
  handleListItemClick,
  getFinishedGoods,
  finishedGoods,
  getVinByFgCode,
  vins,
}) => {
  useEffect(() => {
    handleListItemClick(null, 9);
  }, []);

  const classes = useStyles();
  let drawer = drawerState;

  //   const [vin, setVin] = useState([]);
  const [serialNo, setSerialNo] = useState([]);
  const [fgCode, setFgCode] = useState("");

  useEffect(() => {
    getFinishedGoods();
  }, []);

  useEffect(() => {
    getVinByFgCode(fgCode);
  }, [fgCode]);

  const [state, setState] = useState({
    columns: [
      { title: "FG Code", field: "fgCode" },
      { title: "Date & Time", field: "dateTime" },
      { title: "FG Description", field: "fgDescription" },
      { title: "Qty", field: "qty" },
      { title: "Status", field: "status" },
      { title: "VIN", field: "vin" },
      // { title: "Serial No", field: "serialNo" },
    ],
    data: [],
  });

  useEffect(() => {
    let tempFinishedGoods = [];
    let data = finishedGoods.finishedGoods ? finishedGoods.finishedGoods : [];
    data.map((fg) => {
      let des = fg?.description;
      let len = desLength;
      const time = new Date(fg.cd);
      const fgObject = {
        fgCode: fg.fgCode,
        dateTime: format(
          addMinutes(time, time.getTimezoneOffset()),
          "do MMMM yyyy, h:mm a"
        ),
        fgDescription: des ? des.substring(0, len) : "",
        qty: `${fg.qty} ${fg.uom}`,
        status: (
          <h4>
            <span
              className={
                (fg.status === 0 && `badge badge-danger`) ||
                (fg.status === 1 && `badge badge-warning`)
              }
              style={{ width: "7rem" }}
            >
              {(fg.status === 0 && `Picked`) || (fg.status === 1 && `Put Away`)}
            </span>
          </h4>
        ),
        vin: (
          <button
            className="btn btn-info"
            type="button"
            onClick={() => {
              setFgCode(fg.fgCode);
              //   setVin(fg.vin);
              handleOpenVinModal();
            }}
          >
            View
          </button>
        ),
        // serialNo: (
        //   <button
        //     className="btn btn-info"
        //     type="button"
        //     onClick={() => {
        //       setFgCode(fg.fgCode);
        //       setSerialNo(fg.sNo);
        //       handleOpenSerialNoModal();
        //     }}
        //   >
        //     View
        //   </button>
        // ),
      };
      tempFinishedGoods.push(fgObject);
    });
    setState({ ...state, data: tempFinishedGoods });
  }, [finishedGoods]);

  const [vinModal, setOpenVinModal] = useState(false);

  const handleOpenVinModal = () => {
    getFinishedGoods();
    getVinByFgCode(fgCode);
    setOpenVinModal(!vinModal);
  };

  const [serialNoModal, setOpenSerialNoModal] = useState(false);

  const handleOpenSerialNoModal = () => {
    getFinishedGoods();
    setOpenSerialNoModal(!serialNoModal);
  };

  const [vinTable, setVinTable] = useState({
    columns: [
      { title: "VIN", field: "vin" },
      { title: "Serial No", field: "serialNo" },
      { title: "Status", field: "status" },
    ],
    data: [],
  });

  const [serialNoTable, setSerialNoTable] = useState({
    columns: [{ title: "Serial No", field: "serialNo" }],
    data: [],
  });

  useEffect(() => {
    let tempVin = [];
    let data = vins.vin ? vins.vin : [];
    data.map((vins) => {
      vins.FgList.map((vin) => {
        const vinInfo = {
          vin: vin.vin,
          serialNo: vin.sNo,
          status: (
            <h4>
              <span
                className={
                  (vin.isPutaway === 0 && `badge badge-danger`) ||
                  (vin.isPutaway === 1 && `badge badge-warning`)
                }
                style={{ width: "7rem" }}
              >
                {(vin.isPutaway === 0 && `Picked`) ||
                  (vin.isPutaway === 1 && `Put Away`)}
              </span>
            </h4>
          ),
        };
        tempVin.push(vinInfo);
      });
    });
    setVinTable({ ...vinTable, data: tempVin });
  }, [vins]);

  useEffect(() => {
    let tempSerialNo = [];
    let data = serialNo ? serialNo : [];
    data.map((sNo) => {
      const serialNoInfo = {
        serialNo: sNo,
      };
      tempSerialNo.push(serialNoInfo);
    });
    setSerialNoTable({
      ...serialNoTable,
      data: tempSerialNo,
    });
  }, [serialNo]);

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title="Finished Goods"
              columns={state.columns}
              data={state.data}
              options={{ draggable: false }}
            />
            <div>
              <Modal
                show={vinModal}
                onHide={handleOpenVinModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>{fgCode}</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <MaterialTable
                          title="Vehicle Identification Number"
                          columns={vinTable.columns}
                          data={vinTable.data}
                          options={{ draggable: false }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <Modal
                show={serialNoModal}
                onHide={handleOpenSerialNoModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>{fgCode}</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <MaterialTable
                          title="Serial No"
                          columns={serialNoTable.columns}
                          data={serialNoTable.data}
                          options={{ draggable: false }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    finishedGoods: state.finishedGoods,
    vins: state.vin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getFinishedGoods: () => dispatch(getFinishedGoods()),
    getVinByFgCode: (fgCode) => dispatch(getVinByFgCode(fgCode)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinishedGoods);
