import {
  GET_FINISHEDGOODS_REQUEST,
  GET_FINISHEDGOODS_SUCCESS,
  GET_FINISHEDGOODS_FAILURE,
  GET_VINBYFGCODE_REQUEST,
  GET_VINBYFGCODE_SUCCESS,
  GET_VINBYFGCODE_FAILURE,
} from "./FinishedGoodsTypes";

const initialState = {
  loading: false,
  finishedGoods: [],
  error: "",
};

const getFinishedGoodsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FINISHEDGOODS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_FINISHEDGOODS_SUCCESS:
      return {
        loading: false,
        finishedGoods: action.payload,
        error: "",
      };
    case GET_FINISHEDGOODS_FAILURE:
      return {
        loading: false,
        finishedGoods: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForVin = {
  loading: false,
  vin: [],
  error: "",
};

const getVinByFgCodeReducer = (state = initialStateForVin, action) => {
  switch (action.type) {
    case GET_VINBYFGCODE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_VINBYFGCODE_SUCCESS:
      return {
        loading: false,
        vin: action.payload,
        error: "",
      };
    case GET_VINBYFGCODE_FAILURE:
      return {
        loading: false,
        vin: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { getFinishedGoodsReducer, getVinByFgCodeReducer };
