import {
    GET_INVENTORYAGEING_REQUEST,
    GET_INVENTORYAGEING_SUCCESS,
    GET_INVENTORYAGEING_FAILURE,
  } from "./InventoryAgeingTypes";
  
  const initialStateForInventoryAgeing = {
    loading: false,
    InventoryAgeing: [],
    error: "",
  };
  
  const getInventoryAgeingReducer = (state = initialStateForInventoryAgeing, action) => {
    switch (action.type) {
      case GET_INVENTORYAGEING_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_INVENTORYAGEING_SUCCESS:
        return {
          loading: false,
          InventoryAgeing: action.payload,
          error: "",
        };
      case GET_INVENTORYAGEING_FAILURE:
        return {
          loading: false,
          InventoryAgeing: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  
  
  export { getInventoryAgeingReducer};
  