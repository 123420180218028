import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { makeStyles } from "@material-ui/core/styles";
import drawerCss from "../../style/drawer";
import classNames from "classnames";
import { connect } from "react-redux";
import {
  updateSelectedItem,
  getSkuByItemCodeAndWarehouseCode,
  getItemMaster,
  getWarehouses,
  transferOrderIndividualPrint,
  transferOrderPackagePrint,
} from "../../store";
import MaterialTable from "material-table";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../utils/TextError";
import Config from "../../config/index";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const desLength = Config.descriptionLength;

const useStyles = makeStyles((theme) => drawerCss(theme, "5%"));

const CreateTransfer = ({
  drawerState,
  handleListItemClick,
  getSkuByItemCodeAndWarehouseCode,
  skuByItemCodeAndWarehouseCode,
  getItemMaster,
  itemMaster,
  getWarehouses,
  warehouses,
  transferOrderIndividualPrint,
  transferOrderPackagePrint,
}) => {
  useEffect(() => {
    handleListItemClick(null, 13);
  }, []);

  const classes = useStyles();
  let drawer = drawerState;

  let loginDetails = cookies.get("loginDetails");
  let role = loginDetails.data.role;

  const [itemIdSelected, setItemIdSelected] = useState("");
  const [warehouseIdSelected, setWarehouseIdSelected] = useState("");
  const [transferOrderTypeSelected, setTransferOrderTypeSelected] =
    useState("");
  const [itemIdClicked, setItemIdClicked] = useState("");

  useEffect(() => {
    getItemMaster();
  }, []);

  useEffect(() => {
    getWarehouses();
  }, []);

  useEffect(() => {
    getSkuByItemCodeAndWarehouseCode(itemIdSelected, warehouseIdSelected);
  }, [itemIdSelected, warehouseIdSelected]);

  const [itemCodeOptions, setItemCodeOptions] = useState([]);

  useEffect(() => {
    let tempItemCodeOptions = [];
    let data = itemMaster.itemMaster ? itemMaster.itemMaster : [];
    data.map((item) => {
      tempItemCodeOptions.push({
        key: item.itemCode,
        value: item.id,
      });
    });
    setItemCodeOptions(tempItemCodeOptions);
  }, [itemMaster]);

  const [warehouseOptions, setWarehouseOptions] = useState([]);

  useEffect(() => {
    let tempWarehouseOptions = [];
    let data = warehouses.warehouses ? warehouses.warehouses : [];
    data.map((warehouse) => {
      tempWarehouseOptions.push({
        key: warehouse.warehouseName,
        value: warehouse.id,
      });
    });
    setWarehouseOptions(tempWarehouseOptions);
  }, [warehouses]);

  const [singleSku, setSingleSku] = useState({});

  const handleOpenSingleSku = (sku = {}) => {
    setSingleSku(sku);
  };

  const [state, setState] = useState({
    columns: [
      { title: "SKU", field: "sku" },
      { title: "Item Code/Description", field: "itemCodeDes" },
      { title: "Qty", field: "qty" },
      { title: "Location", field: "location" },
      { title: "Action", field: "action", filtering: false },
    ],
    data: [],
  });

  useEffect(() => {
    let tempSku = [];
    let data = skuByItemCodeAndWarehouseCode.sku
      ? skuByItemCodeAndWarehouseCode.sku
      : [];

    data.map((singleSku) => {
      let des = singleSku?.itemDescription;
      let len = desLength;

      const skuObject = {
        sku: singleSku.sku,
        itemCodeDes: `${singleSku.itemCode} / ${
          des ? des.substring(0, len) : ""
        }`,
        qty: `${singleSku.qty}  ${singleSku.uom}`,
        location: `${singleSku.warehouseName} / ${singleSku.zoneName}${singleSku.locationName}`,
        action: (
          <button
            className="btn btn-info"
            disabled={role !== "admin" ? true : false}
            onClick={() => {
              setItemIdClicked(singleSku.itemId);
              handleOpenSingleSku(singleSku);
              handleOpenPrintLabelModal();
              // setOpenPrintLabelModal(true);
            }}
          >
            Create
          </button>
        ),
      };
      tempSku.push(skuObject);
    });
    setState({ ...state, data: tempSku });
  }, [skuByItemCodeAndWarehouseCode]);

  const [openPrintLabelModal, setOpenPrintLabelModal] = useState(false);

  const handleOpenPrintLabelModal = () => {
    setOpenPrintLabelModal(true);
  };

  const handleClosePrintLabelModal = () => {
    setBalance("");
    // setEnteredTransferQty("");
    // setSumOfArray("");
    // setBalanceItemMsg("");
    setConfirmationMsg(false);
    setOpenPrintLabelModal(false);
  };

  const initialValue = {
    transferQty: "",
    deliveryLocation: "",
    printingType: "",
    noOfPackages: "",
    qtyInPackage: "",
    packageArray: [],
  };

  const validationSchema = Yup.object({
    transferQty: Yup.number()
      .max(singleSku.qty, "Enter a valid qty")
      .required("Enter the Transfer Qty"),
    deliveryLocation: Yup.string().required("Select the Delivery Location"),
  });

  const onSubmitForPrint = async (values, { resetForm }) => {
    if (values.printingType === "Individual Printing") {
      const payload = {
        itemId: itemIdClicked,
        sku: singleSku.sku,
        transferQty: values.transferQty,
        dWarehouseId: values.deliveryLocation,
        slocationId: singleSku.locationId,
      };

      let res = await transferOrderIndividualPrint(payload);

      if (res.status) {
        getSkuByItemCodeAndWarehouseCode(itemIdSelected, warehouseIdSelected);
        setConfirmationMsg(false);
        setOpenPrintLabelModal(false);
      }
      if (!res.status) {
      }
    }

    if (values.printingType === "Package Printing") {
      const payload = {
        itemId: itemIdClicked,
        sku: singleSku.sku,
        packages: values.packageArray,
        dWarehouseId: values.deliveryLocation,
        slocationId: singleSku.locationId,
      };

      let res = await transferOrderPackagePrint(payload);

      if (res.status) {
        getSkuByItemCodeAndWarehouseCode(itemIdSelected, warehouseIdSelected);
        setConfirmationMsg(false);
        setOpenPrintLabelModal(false);
      }
      if (!res.status) {
      }
    }
  };

  const [confirmationMsg, setConfirmationMsg] = useState(false);

  const onClickForConfirmation = () => {
    setConfirmationMsg(true);
  };

  const [sumOfArray, setSumOfArray] = useState("");

  const [balance, setBalance] = useState("");

  const [enteredTransferQty, setEnteredTransferQty] = useState("");

  useEffect(() => {
    let rem = enteredTransferQty - sumOfArray;

    if (isNaN(rem)) {
      setBalance("");
    }
    if (!isNaN(rem)) {
      setBalance(rem);
    }
  }, [sumOfArray]);

  let pushPackage;

  const [balanceItemMsg, setBalanceItemMsg] = useState("");

  useEffect(() => {
    if (balance > 0) {
      setBalanceItemMsg(`${balance} more qty to be printed is
      remaining`);
    }
    if (balance < 0) {
      setBalanceItemMsg(`${Math.abs(balance)} qty needs to be removed`);
    }
    if (balance === "") {
      setBalanceItemMsg("");
    }
  }, [balance]);

  const transferOrderTypesOptions = [
    { key: "Transfer Order", value: "transferOrder" },
    { key: "Item-to-Item", value: "itemToItem" },
  ];

  const changeItem = (e) => {
    setItemIdSelected(e.target.value);
  };

  const changeWarehouse = (e) => {
    setWarehouseIdSelected(e.target.value);
  };

  const changeTransferOrderType = (e) => {
    setTransferOrderTypeSelected(e.target.value);
  };

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title={
                <div>
                  <br />
                  <h5>Create Transfer Order</h5>
                  <br />
                  <span style={{ display: "flex" }}>
                    <select
                      style={{ width: "11rem" }}
                      as="select"
                      id="itemCode"
                      name="itemCode"
                      className="form-control"
                      onChange={changeItem}
                    >
                      <option value="" selected>
                        All Items
                      </option>
                      {itemCodeOptions.map((option) => {
                        return (
                          <option key={option.value} value={option.value}>
                            {option.key}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      style={{ marginLeft: "1rem", width: "11rem" }}
                      as="select"
                      id="warehouseCode"
                      name="warehouseCode"
                      className="form-control"
                      onChange={changeWarehouse}
                    >
                      <option value="" selected>
                        All locations
                      </option>
                      {warehouseOptions.map((option) => {
                        return (
                          <option key={option.value} value={option.value}>
                            {option.key}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      style={{ marginLeft: "1rem", width: "11rem" }}
                      as="select"
                      id="transferOrderType"
                      name="transferOrderType"
                      className="form-control"
                      onChange={changeTransferOrderType}
                    >
                      {transferOrderTypesOptions.map((option) => {
                        return (
                          <option key={option.value} value={option.value}>
                            {option.key}
                          </option>
                        );
                      })}
                    </select>
                  </span>
                </div>
              }
              columns={state.columns}
              data={state.data}
              options={{
                draggable: false,
                filtering: true,
              }}
            />
            <div>
              <Modal
                show={openPrintLabelModal}
                onHide={handleClosePrintLabelModal}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Transfer Order</Modal.Title>
                </Modal.Header>
                <div className="row modal-body">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Formik
                          initialValues={initialValue}
                          validationSchema={validationSchema}
                          onSubmit={onSubmitForPrint}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label
                                    htmlFor="printingType"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Type of Printing
                                  </label>
                                  <div>
                                    <Field
                                      type="radio"
                                      name="printingType"
                                      value="Individual Printing"
                                    />
                                    <span
                                      style={{
                                        marginLeft: "0.5rem",
                                        fontSize: "15px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Individual Printing
                                    </span>
                                  </div>
                                  <div>
                                    <Field
                                      type="radio"
                                      name="printingType"
                                      value="Package Printing"
                                    />
                                    <span
                                      style={{
                                        marginLeft: "0.5rem",
                                        fontSize: "15px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Package Printing
                                    </span>
                                  </div>
                                </div>
                                {formik.values.printingType !== "" && (
                                  <span>
                                    <div className="form-group">
                                      <label htmlFor="transferQty">
                                        Quantity
                                      </label>
                                      <Field
                                        type="number"
                                        name="transferQty"
                                        className="form-control"
                                        autoComplete="off"
                                      />
                                      <ErrorMessage
                                        name="transferQty"
                                        component={TextError}
                                      />
                                    </div>
                                    {formik.values.transferQty &&
                                      setEnteredTransferQty(
                                        formik.values.transferQty
                                      )}
                                    <div className="form-group">
                                      <label htmlFor="deliveryLocation">
                                        Select the Delivery Location
                                      </label>
                                      <Field
                                        as="select"
                                        id="deliveryLocation"
                                        name="deliveryLocation"
                                        className="form-control"
                                      >
                                        <option value="" disabled defaultValue>
                                          Choose one...
                                        </option>
                                        {warehouseOptions.map((option) => {
                                          return (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.key}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                      <ErrorMessage
                                        name="deliveryLocation"
                                        component={TextError}
                                      />
                                    </div>
                                  </span>
                                )}
                                {formik.values.printingType ===
                                  "Package Printing" && (
                                  <div>
                                    <div style={{ display: "flex" }}>
                                      <h5>
                                        Total: {formik.values.transferQty}
                                      </h5>
                                      <h5 style={{ marginLeft: "2rem" }}>
                                        {formik.values.packageArray.length >
                                          0 && `Balance: ${balance}`}
                                      </h5>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                      {formik.values.packageArray.length ===
                                        0 && (
                                        <div className="form-group">
                                          <label htmlFor="noOfPackages">
                                            Number of Packages
                                          </label>
                                          <Field
                                            type="text"
                                            name="noOfPackages"
                                            className="form-control"
                                            autoComplete="off"
                                            style={{ width: "8rem" }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                    {formik.values.packageArray.length ===
                                      0 && (
                                      <div>
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          style={{ marginBottom: "1rem" }}
                                          onClick={() => {
                                            for (
                                              let i = 0;
                                              i < formik.values.noOfPackages;
                                              i++
                                            ) {
                                              pushPackage();
                                            }
                                          }}
                                        >
                                          Create Package
                                        </button>
                                      </div>
                                    )}
                                    <div>
                                      <FieldArray name="packageArray">
                                        {(fieldArrayProps) => {
                                          const { push, remove, form } =
                                            fieldArrayProps;
                                          const { values } = form;
                                          const { packageArray } = values;
                                          pushPackage = push;
                                          return (
                                            <div>
                                              {packageArray.map(
                                                (type, index) => (
                                                  <div
                                                    key={index}
                                                    style={{ display: "flex" }}
                                                  >
                                                    <label
                                                      htmlFor="packageArray"
                                                      style={{
                                                        marginTop: "0.4rem",
                                                      }}
                                                    >
                                                      <h5>
                                                        Package {index + 1}
                                                      </h5>
                                                    </label>
                                                    <Field
                                                      name={`packageArray[${index}]`}
                                                      type="number"
                                                      autoComplete="off"
                                                      className="form-control"
                                                      style={{
                                                        width: "8rem",
                                                        marginLeft: "1rem",
                                                      }}
                                                      value={
                                                        formik.values
                                                          .packageArray[index]
                                                      }
                                                      onChange={(e) => {
                                                        formik.handleChange(e);
                                                      }}
                                                      onBlur={() => {
                                                        setSumOfArray(
                                                          formik.values.packageArray.reduce(
                                                            (a, b) => a + b,
                                                            0
                                                          )
                                                        );
                                                      }}
                                                    />

                                                    <span>
                                                      {index > 0 && (
                                                        <button
                                                          type="button"
                                                          onClick={(e) => {
                                                            remove(index);

                                                            setSumOfArray(
                                                              formik.values.packageArray.reduce(
                                                                (a, b) => a + b,
                                                                0
                                                              )
                                                            );
                                                          }}
                                                          style={{
                                                            marginLeft: "1rem",
                                                            marginTop: "0.4rem",
                                                          }}
                                                        >
                                                          <i
                                                            className="fa fa-times"
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          ></i>
                                                        </button>
                                                      )}
                                                    </span>
                                                  </div>
                                                )
                                              )}
                                              {balance !== 0 &&
                                                formik.values.packageArray
                                                  .length > 0 && (
                                                  <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    style={{
                                                      marginBottom: "1rem",
                                                    }}
                                                    onClick={() => {
                                                      push("");
                                                    }}
                                                  >
                                                    Add Package
                                                  </button>
                                                )}
                                            </div>
                                          );
                                        }}
                                      </FieldArray>
                                    </div>
                                  </div>
                                )}
                                <h6>
                                  {formik.values.printingType ===
                                    "Individual Printing" &&
                                    confirmationMsg &&
                                    `Do you want to print ${formik.values.transferQty} individual labels?`}
                                  {formik.values.printingType ===
                                    "Package Printing" &&
                                    confirmationMsg &&
                                    `Do you want to print ${formik.values.packageArray.length} package labels?`}
                                </h6>
                                {balance !== 0 && balance !== "" && (
                                  <h6 style={{ color: "red" }}>
                                    {/* {formik.values.printingType ===
                                      "Package Printing" && balanceItemMsg} */}
                                    {balanceItemMsg}
                                  </h6>
                                )}
                                <div className="clearfix">
                                  {!confirmationMsg &&
                                    formik.values.printingType !== "" && (
                                      <button
                                        type="button"
                                        onClick={onClickForConfirmation}
                                        className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                        // disabled={
                                        //   formik.values.printingType === ""
                                        //     ? true
                                        //     : formik.values.printingType ===
                                        //         "Package Printing" &&
                                        //       balance !== 0 &&
                                        //       formik.isValid
                                        //     ? true
                                        //     : false
                                        // }

                                        // disabled={
                                        //   (formik.values.printingType ===
                                        //     "Package Printing" &&
                                        //     balance === 0 &&
                                        //     formik.isValid) === true
                                        //     ? false
                                        //     : true
                                        // }
                                        disabled={
                                          formik.values.printingType ===
                                          "Individual Printing"
                                            ? formik.isValid === true
                                              ? false
                                              : true
                                            : (balance === 0 &&
                                                formik.isValid) === true
                                            ? false
                                            : true
                                        }
                                      >
                                        Print Label
                                      </button>
                                    )}
                                  {confirmationMsg && (
                                    <span>
                                      <button
                                        type="button"
                                        className="cancelbtn button1"
                                        onClick={() => {
                                          handleClosePrintLabelModal();
                                          // setBalance("");
                                          // setConfirmationMsg(false);
                                          // setOpenPrintLabelModal(false);
                                        }}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        type="submit"
                                        className="deletebtn button1"
                                      >
                                        Yes
                                      </button>
                                    </span>
                                  )}
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    skuByItemCodeAndWarehouseCode: state.skuByItemCodeAndWarehouseCode,
    itemMaster: state.itemMaster,
    warehouses: state.warehouses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    getSkuByItemCodeAndWarehouseCode: (itemId, warehouseId) =>
      dispatch(getSkuByItemCodeAndWarehouseCode(itemId, warehouseId)),
    getItemMaster: () => dispatch(getItemMaster()),
    getWarehouses: () => dispatch(getWarehouses()),
    transferOrderIndividualPrint: (payload) =>
      dispatch(transferOrderIndividualPrint(payload)),
    transferOrderPackagePrint: (payload) =>
      dispatch(transferOrderPackagePrint(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTransfer);

// const [init, setInit] = useState({});
// const [disableButton, setDisableButton] = useState(true);
// const [formIsValid, setFormIsValid] = useState("");

// useEffect(() => {
//   if (init.printingType !== "") {
//     if (
//       (init.printingType === "Individual Printing" && formIsValid) === true
//     ) {
//       setDisableButton(false);
//     }
//     if (
//       (init.printingType === "Package Printing" &&
//         balance === 0 &&
//         formIsValid) === true
//     ) {
//       setDisableButton(false);
//     }
//   }
// }, [init, formIsValid, balance]);
