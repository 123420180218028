import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import dashboardCard from "../../style/dashboardCard";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Link as RouterLink } from "react-router-dom";
import { getPdi } from "../../store";

import "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { useRef } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => dashboardCard(theme));

const PdiHomePageCard = ({ getPdi, pdi }) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    getPdi();
  }, []);

  const [lessThanThirty, setLessThanThirty] = useState(0);
  const [thirtyOneToSixty, setThirtyOneToSixty] = useState(0);
  const [sixtyOneToNinety, setSixtyOneToNinety] = useState(0);
  const [greaterThanNinety, setGreaterThanNinety] = useState(0);
  const [expired, setExpired] = useState(0);

  useEffect(() => {
    setLessThanThirty(pdi.pdi.lessThanThirty);
    setThirtyOneToSixty(pdi.pdi.thirtyOneToSixty);
    setSixtyOneToNinety(pdi.pdi.sixtyOneToNinety);
    setGreaterThanNinety(pdi.pdi.greaterThanNinety);
    setExpired(pdi.pdi.expired);
  }, [pdi]);

  const data = {
    labels: ["< 30 days", "31-60 days", "61-90 days", "> 90 days", "Expired"],
    datasets: [
      {
        data: [
          lessThanThirty,
          thirtyOneToSixty,
          sixtyOneToNinety,
          greaterThanNinety,
          expired,
        ],
        backgroundColor: ["#9772FB", "#FFCD38", "#BABD42", "#EE5007"],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    title: {
      display: true,
      text: "Doughnut Chart",
    },
  };

  const chartRef = useRef();

  return (
    <div>
      <Card>
        <CardContent>
          <h4>PDI</h4>
          <hr />
          <Bar
            className={classes.root}
            data={data}
            options={options}
            ref={chartRef}
            height={240}
          />
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    pdi: state.getPdiHomePage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPdi: () => dispatch(getPdi()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PdiHomePageCard);
