/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
} from "./userTypes";
import axios from "axios";
import Config from "../../../config/index";
let auth3Url = Config.auth3Url;

const deleteUserRequest = () => {
  return {
    type: DELETE_USER_REQUEST,
  };
};

const deleteUserSuccess = (payload) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: payload,
  };
};

const deleteUserFaliure = (error) => {
  return {
    type: DELETE_USER_FAILURE,
    payload: error,
  };
};

const userRequest = () => {
  return {
    type: USER_REQUEST,
  };
};

const userSuccess = (payload) => {
  return {
    type: USER_SUCCESS,
    payload: payload,
  };
};

const userFaliure = (error) => {
  return {
    type: USER_FAILURE,
    payload: error,
  };
};

const addUserRequest = () => {
  return {
    type: ADD_USER_REQUEST,
  };
};

const addUserSuccess = (payload) => {
  return {
    type: ADD_USER_SUCCESS,
    payload: payload,
  };
};

const addUserFaliure = (error) => {
  return {
    type: ADD_USER_FAILURE,
    payload: error,
  };
};

const getUsers = (clientId) => {
  return function (dispatch) {
    dispatch(userRequest());
    let url = `${auth3Url}/mobilloruser/get_users/${clientId}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(userSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(userFaliure(error.message));
      });
  };
};

const addUser = (userDetails) => {
  return (dispatch) => {
    dispatch(addUserRequest());
    let url = `${auth3Url}/mobilloruser/user`;
    return axios
      .post(url, userDetails)
      .then((response) => {
        dispatch(addUserSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(addUserFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const deleteUser = (id) => {
  return (dispatch) => {
    let url = `${auth3Url}/mobilloruser/delete_user/${id}`;
    dispatch(deleteUserRequest());
    return axios
      .delete(url)
      .then((response) => {
        dispatch(deleteUserSuccess(response.data));
        return { status: true };
      })
      .catch((error) => {
        dispatch(deleteUserFaliure(error.message));
        return { status: false };
      });
  };
};
export { getUsers, addUser, deleteUser };
