/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React from "react";
import { connect } from "react-redux";

function Footer({ drawerState }) {
  let drawer = drawerState;
  return (
    <div style={{ marginLeft: drawer.open ? "250px" : "75px" }}>
      <footer className="page-footer font-small mdb-color pt-4">
        <div className="container text-center text-md-left">
          <div className="row d-flex align-items-center">
            <div className="col-md-7 col-lg-8">
              {/* <p className=" text-center text-md-left">© 2020 Copyright :
          <a className="link" href="http://mobillor.com/">
                                    <strong> Mobillor Technologies Pvt. Ltd.</strong>
                                </a>
                            </p> */}
              <p className=" text-center text-md-left">
                Developed and maintained by :
                <a className="link" href="http://mobillor.com/">
                  <strong> Mobillor Technologies Pvt. Ltd.</strong>
                </a>
              </p>
            </div>

            <div className="col-md-5 col-lg-4 ml-lg-0"></div>
          </div>
        </div>
      </footer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
  };
};

export default connect(mapStateToProps, undefined)(Footer);
