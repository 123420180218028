import React, { useState, useEffect } from "react";
import Header from "../Header";
import { connect } from "react-redux";
import drawerCss from "../../style/drawer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import Footer from "../Footer";
import MaterialTable from "material-table";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../utils/TextError";

import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import "../../style/delete.css";
import Select from "react-select";
import Cookies from "universal-cookie";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import {
  createPrinter,
  deletePrinter,
  getPrinters,
  updateSelectedItem,
  updatePrinter,
} from "../../store";
const cookies = new Cookies();

const useStyles = makeStyles((theme) => drawerCss(theme));

function PrinterOnboarding({
  drawerState,
  handleListItemClick,
  createPrinter,
  getPrinters,
  printersReducer,
  deletePrinter,
  updatePrinter,
}) {
  const classes = useStyles();
  const theme = useTheme();
  let drawer = drawerState;
  let userDetails = cookies.get("loginDetails").data;
  let clientId = userDetails.clientId;

  useEffect(() => {
    handleListItemClick(null, 16);
  });

  const [state, setState] = useState({
    columns: [
      { title: "Name", field: "name" },
      { title: "Location", field: "location" },
      { title: "IP Address", field: "ip" },
      { title: "PORT", field: "port" },
      { title: "Actions", field: "actions" },
    ],
    data: [],
  });

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [printerData, setPrinterData] = useState(false);

  useEffect(() => {
    getPrinters();
  }, [getPrinters]);

  useEffect(() => {
    let printersData = printersReducer.printers
      ? Array.from(printersReducer.printers)
      : [];
    let printersTemp = [];
    printersData.map((printer) => {
      printer["actions"] = (
        <div>
          {/* <button type="button" className="btn btn-primary" style={{ marginRight: "5px" }} onClick={() => handleOpenViewUserRoleModal(roleTemp)}>
            <i className="fa fa-eye"></i>
        </button> */}
          <button
            type="button"
            className="btn btn-success"
            style={{ marginRight: "5px" }}
            onClick={() => handleUpdatePrinterModalOpen(printer)}
          >
            <i className="fa fa-edit"></i>
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handleDeletePrinterModalOpen(printer)}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      );
      printersTemp.push(printer);
    });
    setState({ ...state, data: printersTemp });
  }, [printersReducer]);

  //   Start create printer code

  const [createPrinterModal, setCreatePrinterModal] = useState(false);
  const handleCreatePrinterModalOpen = () => {
    setIsSuccess(false);
    setIsError(false);
    setCreatePrinterModal(true);
  };

  const handleCreatePrinterModalClose = () => {
    setIsSuccess(false);
    setIsError(false);
    setCreatePrinterModal(false);
  };

  const initialValue = {
    name: "",
    location: "",
    ip: "",
    port: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Enter the Name"),
    location: Yup.string().required("Enter the Location"),
    ip: Yup.string().required("Enter the IP"),
    port: Yup.string().required("Enter the Port"),
  });

  const onSubmit = async (values, { resetForm }) => {
    let res = await createPrinter(values);
    if (res.status) {
      setIsSuccess(true);
      await getPrinters();
      resetForm();
    }
    if (!res.status) {
      setIsError(true);
    }
  };

  //   End create printer code

  //   Start Update printer code

  const [updatePrinterModal, setUpdatePrinterModal] = useState(false);
  const handleUpdatePrinterModalOpen = (printer) => {
    setIsSuccess(false);
    setIsError(false);
    setUpdatePrinterModal(true);
    setPrinterData(printer);
  };

  const handleUpdatePrinterModalClose = () => {
    setIsSuccess(false);
    setIsError(false);
    setUpdatePrinterModal(false);
    setPrinterData({});
  };

  const initialValueForUpdate = {
    name: printerData.name ? printerData.name : "",
    location: printerData.location ? printerData.location : "",
    ip: printerData.ip ? printerData.ip : "",
    port: printerData.port ? printerData.port : "",
  };

  const validationSchemaForUpdate = Yup.object({
    name: Yup.string().required("Enter the Name"),
    location: Yup.string().required("Enter the Location"),
    ip: Yup.string().required("Enter the IP"),
    port: Yup.string().required("Enter the Port"),
  });

  const onSubmitForUpdate = async (values) => {
    values.id = printerData.id;
    let res = await updatePrinter(values);
    if (res.status) {
      setIsSuccess(true);
      await getPrinters();
    }
    if (!res.status) {
      setIsError(true);
    }
  };

  //   End Update printer code

  //   Start Delete printer code

  const [deletPrinterModal, setDeletePrinterModal] = useState(false);
  const handleDeletePrinterModalOpen = (printer) => {
    setDeletePrinterModal(true);
    setPrinterData(printer);
  };

  const handleDeletePrinterModalClose = () => {
    setDeletePrinterModal(false);
    setPrinterData({});
  };

  const deletePrinterData = async () => {
    let printerId = printerData.id;
    let response = await deletePrinter(printerId);
    if (response.status) {
      await getPrinters();
      handleDeletePrinterModalClose();
    }
  };

  //   End Delete printer code

  return (
    <div>
      <section id="cover" className="min-vh-100">
        <Header />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawer.open ? false : true,
          })}
        >
          <div className="screenSize">
            <MaterialTable
              title="Printers"
              columns={state.columns}
              data={state.data}
              options={{ draggable: false }}
              actions={[
                {
                  icon: "add",
                  tooltip: "Add Printer",
                  isFreeAction: true,
                  onClick: (event) => handleCreatePrinterModalOpen(),
                  iconProps: {
                    color: "error",
                    fontSize: "large",
                  },
                },
              ]}
            />
            {/* Start create printer code */}
            <div>
              <Modal
                show={createPrinterModal}
                onHide={handleCreatePrinterModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Create Printer</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Formik
                          initialValues={initialValue}
                          validationSchema={validationSchema}
                          onSubmit={onSubmit}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="role">Name</label>
                                  <Field
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component={TextError}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="role">Location</label>
                                  <Field
                                    type="text"
                                    name="location"
                                    className="form-control"
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    name="location"
                                    component={TextError}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="role">IP Addres</label>
                                  <Field
                                    type="text"
                                    name="ip"
                                    className="form-control"
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    name="ip"
                                    component={TextError}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="role">PORT</label>
                                  <Field
                                    type="text"
                                    name="port"
                                    className="form-control"
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    name="port"
                                    component={TextError}
                                  />
                                </div>
                                <div className="mt-3">
                                  <Collapse in={isError}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsError(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      please check your printer details. Server
                                      error!
                                    </Alert>
                                  </Collapse>

                                  <Collapse in={isSuccess}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccess(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Create Printer successfully
                                    </Alert>
                                  </Collapse>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                    disabled={!formik.isValid}
                                  >
                                    Create Printer
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            {/* End create printer code */}
            {/*    Start Update printer code */}
            <div>
              <Modal
                show={updatePrinterModal}
                onHide={handleUpdatePrinterModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Update Printer</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Formik
                          initialValues={initialValueForUpdate}
                          validationSchema={validationSchemaForUpdate}
                          onSubmit={onSubmitForUpdate}
                        >
                          {(formik) => {
                            return (
                              <Form>
                                <div className="form-group">
                                  <label htmlFor="role">Name</label>
                                  <Field
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component={TextError}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="role">Location</label>
                                  <Field
                                    type="text"
                                    name="location"
                                    className="form-control"
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    name="location"
                                    component={TextError}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="role">IP Addres</label>
                                  <Field
                                    type="text"
                                    name="ip"
                                    className="form-control"
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    name="ip"
                                    component={TextError}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="role">PORT</label>
                                  <Field
                                    type="text"
                                    name="port"
                                    className="form-control"
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    name="port"
                                    component={TextError}
                                  />
                                </div>
                                <div className="mt-3">
                                  <Collapse in={isError}>
                                    <Alert
                                      severity="error"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsError(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      please check your printer details. Server
                                      error!
                                    </Alert>
                                  </Collapse>

                                  <Collapse in={isSuccess}>
                                    <Alert
                                      severity="success"
                                      action={
                                        <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setIsSuccess(false);
                                          }}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      }
                                    >
                                      Printer update successfully
                                    </Alert>
                                  </Collapse>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-block btn-danger btn-lg font-weight-medium auth-form-btn"
                                    disabled={!formik.isValid}
                                  >
                                    Update Printer
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            {/*    End Update printer code */}
            {/*    Start Delete printer code */}
            <div>
              <Modal
                show={deletPrinterModal}
                onHide={handleDeletePrinterModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                className="screenSize"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Delete Printer</Modal.Title>
                </Modal.Header>
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <p>Are you sure you want to delete this printer?</p>

                        <div class="clearfix">
                          <button
                            type="button"
                            className="cancelbtn button1"
                            onClick={handleDeletePrinterModalClose}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="deletebtn button1"
                            onClickCapture={deletePrinterData}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            {/*    End Delete printer code */}
          </div>
        </main>
      </section>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    drawerState: state.drawerState,
    printersReducer: state.printersReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleListItemClick: (event, index) =>
      dispatch(updateSelectedItem(event, index)),
    createPrinter: (payload) => dispatch(createPrinter(payload)),
    getPrinters: () => dispatch(getPrinters()),
    deletePrinter: (id) => dispatch(deletePrinter(id)),
    updatePrinter: (payload, id) => dispatch(updatePrinter(payload, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrinterOnboarding);
